*,
*:after,
*:before {
	margin: 0;
	padding: 0;
	box-sizing: inherit; }
html {
	font-size: 8px;
	$map: (768px: 8px,1366px: 10px);
	@include fz('font-size', $map);
	@media (min-width: 1366px) {
		font-size: 10px; } }
body {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	overflow-x: hidden;
	position: relative; }
a {
	text-decoration: none; }
img {
	display: block;
	max-width: 100%;
	height: auto; }
button {
	cursor: pointer; }
input,
textarea,
button {
	outline: none;
	box-shadow: none; }
#content {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	&::-webkit-scrollbar {
		width: 0; } }

.slick-slide:active,
.slick-slide:focus,
.slick-arrow:hover,
.slick-arrow:focus {
	border: 0;
	outline: 0; }
