$white: #ffffff;
$black: #000;
$blue: #0078BF;
$red: #CA3625;
$orange: #FF7F40;
$pink: #E9C4C6;
$orange-fe: #FEBE85;
$yellow: #F6DFA4;
$dark: #282828;
$purple: #BD55A0;
$purple-45: #452A50;
$green: #007885;

.color-white {
  color: #ffffff!important; }
.color-black {
  color: #000!important; }
.color-blue {
  color: #0078BF!important; }
.color-red {
  color: #CA3625!important; }
.color-orange {
  color: #FF7F40!important; }
.color-pink {
  color: #E9C4C6!important; }
.color-orange-fe {
  color: #FEBE85!important; }
.color-yellow {
  color: #F6DFA4!important; }
.color-dark {
  color: #282828!important; }
.color-purple {
  color: #BD55A0!important; }
.color-green {
  color: #007885!important; }
