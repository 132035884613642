.page-faq
  margin-top: calc( -7.5rem /2 )
  padding-bottom: 6.5rem
  .my-tabs
    box-shadow: 0 .3rem .6rem #00000029
    .tabs-nav-wrap
      .tabs-nav
        list-style: none
        display: flex
        align-items: center
        flex-flow: wrap
        // border: 1px solid #E1E1E1
        // @media (max-width: 767px)
        //   border: none
        //   border-top: 1px solid #E1E1E1
        //   border-left: 1px solid #E1E1E1
        li
          width: 33.33%
          font-size: 1.8rem
          text-transform: capitalize
          color: #9F9F9F
          text-align: center
          height: 7.5rem
          display: inline-flex
          background: #fff
          justify-content: center
          align-items: center
          transition: all .2s
          cursor: pointer
          border-bottom: 1px solid #e1e1e1
          &:not(:last-child)
            border-right: 1px solid #E1E1E1
          &:hover,&.active
            color: #fff
            background: #452A50
            border-color: #452A50
            transition: all .2s
          @media (max-width: 767px)
            width: 50%
            border-bottom: 1px solid #E1E1E1
            border-right: 1px solid #E1E1E1

    .tab-content
      display: none
      padding: 4rem
      background: #fff
      @media (max-width: 767px)
        padding: 20px
.accordions
  .accordion-item
    background: #fff
    box-shadow: 0px .3rem 1.2rem #0000000F
    border-radius: .5rem
    &:not(:last-child)
      margin-bottom: 2rem
    .accordion-heading
      padding-right: 3rem
      font-weight: 600
      font-size: 1.8rem
      color: #452A50
      cursor: pointer
      display: flex
      align-items: center
      box-shadow: 0px .3rem 1.2rem #0000000F
      border-radius: .5rem
      @media (max-width: 767px)
        padding-right: 2rem
        font-size: 1.6rem
      .arrow
        width: 1.4rem
        height: .8rem
        margin-left: auto
        background-image: url(../img/icons/Arrow1_Grey.png)
        background-repeat: no-repeat
        background-position: center center
        background-size: contain
        display: block
        flex-shrink: 0
      .txt
        display: block
        margin-right: 2rem
      .number
        margin-right: 2rem
        width: 5rem
        flex-shrink: 0
        height: 5rem
        border-radius: .5rem
        background: #452A50
        display: flex
        box-shadow: 0px .3rem 1.2rem #0000000F
        color: #fff
        align-items: center
        justify-content: center
        font-weight: bold

    .accordion-content
      display: none
      padding: 4rem
      background: #fff
      p
        &:not(:last-child)
          margin-bottom: 1.5rem
    @media (max-width: 767px)
      .accordion-content
        padding: 20px
    &.active
      .accordion-heading
        .arrow
          background-image: url(../img/icons/Arrow1_Grey-2.png)
