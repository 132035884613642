.page-thank-you
  margin-bottom: 10rem
  .banner
    img
      width: 100%
      height: auto
  .top-content
    margin-top: -15rem
    position: relative
    z-index: 1
    text-align: center
    border-radius: .5rem
    box-shadow: 0px .3rem 1.2rem #0000000F
    background: #F7F5F4
    background-image: url(../img/campaigns/CHARIS_CrossElement.png)
    background-repeat: no-repeat
    background-position: 100% 0
    background-size: 15% auto
    padding: 6.7rem 3rem 3rem
    @media (max-width: 767px)
      padding: 3rem 20px
      margin-top: -5rem
    .inner
      .main-title
        margin-bottom: 3.5rem
      .ref-no
        @extend .ff-2
        font-size: 2.4rem
        letter-spacing: .24rem
        color: #452A50
      p
        &:not(:last-child)
          margin-bottom: 2rem

  .cart-total
    margin-top: 5rem
    border-radius: .5rem
    padding-top: 1.5rem
    background: #F7F5F4
    table
      width: 100%
      td
        font-weight: 600
        padding: .5rem 2rem
        &:nth-child(2)
          text-align: right
      tr
        &:last-child
          td
            padding: 2rem
            border-top: 1px solid #E1E1E1
            color: #A51890
            text-transform: uppercase
        &:nth-last-child(2)
          td
            padding-bottom: 2rem
