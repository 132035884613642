.page-account
  padding: 8rem 0 13.5rem
.account-box
  max-width: 49rem
  box-shadow: 0px 3px 12px #0000000A
  width: 100%
  .head
    padding: 2.8rem
    text-align: center
    color: #fff
    font-weight: 600
    font-size: 1.8rem
    background: #452A50

  .body
    padding: 2.5rem 2.5rem 4rem
    background: #fff
    .list-login
      list-style: none
      padding-bottom: 2rem
      border-bottom: 1px dashed #E1E1E1
      li
        display: flex
        align-items: center
        border: 1px solid #E1E1E1
        border-radius: .3rem
        .icon
          flex-shrink: 0
          width: 3.5rem
          height: 3.5rem
          padding: 1rem
          border-right: 1px solid #E1E1E1
          img
            width: 100%
            height: 100%
            object-fit: scale-down
        .text
          flex: 1
          width: 100%
          text-align: center
        &:not(:last-child)
          margin-bottom: 1.5rem
    .forgot-pass
      margin-top: -1rem
      display: block
      color: $blue
      text-decoration: underline
      font-style: italic
      font-weight: 300
    .custom-checkbox
      margin-top: 2rem
      label
        font-size: 1.5rem
        font-weight: 300
        a
          color: $purple
          margin-left: .5rem
          display: inline-block
          word-break: break-word
          text-decoration: underline
    .link
      display: block
      text-align: center
      margin-top: 3rem
      a
        color: $purple
        text-decoration: underline
        margin-left: .5rem
  .form-wrap
    padding-top: 2rem
  .btn
    margin-top: 3rem
