.page-checkout
  padding: 10rem 0
  .heading
    font-size: 1.8rem
    color: #A51890
    text-transform: capitalize
    margin-bottom: 2.5rem
    letter-spacing: .054rem
    font-weight: 600
  .cart-total
    margin-top: 3.8rem
    border-radius: .5rem
    padding: 1.5rem 0
    background: #F7F5F4
    table
      width: 100%
      td
        font-weight: 600
        padding: .5rem 2rem
        &:nth-child(2)
          text-align: right
      tr
        &:last-child
          td
            padding: 2rem
            border-top: 1px solid #E1E1E1
            border-bottom: 1px solid #E1E1E1
            color: #A51890
            text-transform: uppercase
        &:nth-last-child(2)
          td
            padding-bottom: 2rem
  .bottom-wrap
    padding: 2rem
    .terms
      margin-top: 2.5rem
    label
      font-size: 1.5rem
      &:before
        border: 1px solid $dark
    .btn
      min-width: 23.2rem
      background: #A51890
      margin-top: 3rem
  .payment
    .custom-checkbox
      &:not(:last-child)
        margin-bottom: 2rem
