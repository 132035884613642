.banner-subpage
  padding: 15rem 0
  .heading
    text-align: center
    font-size: 4rem
    letter-spacing: .4rem
    @extend .ff-2
    line-height: calc(50/40)
    font-weight: bold
    color: #fff
.haf-banner
  border-radius: .5rem
  overflow: hidden
  position: relative
  padding: 5rem
  @media (max-width: 767px)
    padding: 5rem 20px
  .title
    text-transform: capitalize
    color: #fff
    font-weight: bold
    @extend .ff-2
    line-height: 1.2
  .desc
    color: #fff
    max-width: 70rem
    margin-top: 2rem
.btn-wrap
  .btn
    &:not(:last-child)
      margin-right: 2rem
.campaigns
  padding: 4rem 0 10rem
  .row-wrapper
    display: flex
    flex-flow: wrap
    justify-content: space-between
    .left-wrap
      width: calc(20% -2rem)
    .right-wrap
      width: calc(80% -2rem)
    @media (max-width: 767px)
      .left-wrap,.right-wrap
        width: 100%
  .left-wrap
    margin-bottom: 4rem
    position: relative
  .mb-filter
    margin-top: 0
    @media (min-width: 768px)
      display: none
  .sidebar
    @media (max-width: 767px)
      display: none
      position: absolute
      left: -20px
      width: 100vw
      top: calc(100% + 2rem)
      background: #fff
      padding: 0 20px
      z-index: 50
      padding-bottom: 3rem
    .cate-txt
      font-size: 2.5rem
      text-transform: capitalize
      @extend .ff-2
      color: #0F5373
      margin-bottom: 1rem
      padding: 0 2rem
      font-weight: 500
    .sidebar-item
      &:not(:last-child)
        margin-bottom: 3rem
      .sidebar-text
        position: relative
        cursor: pointer
        font-weight: 600
        text-transform: uppercase
        color: #452A50
        display: flex
        align-items: center
        padding-bottom: 1rem
        border-bottom: 1px solid #E1E1E1
        justify-content: space-between
        .arrow
          width: 1.3rem
          height: 1rem
          background-image: url(../img/icons/Arrow3_Purple.png)
          transform: scaleY(-1)
          background-repeat: no-repeat
          background-size: contain
          background-position: center
          transition: all .2s
        &.active
          .arrow
            transform: scale(1)
            transition: all .2s
      .menu
        list-style: none
        background: #F4F4F4
        display: none
        padding: .5rem 0
        padding-top: 1.7rem
        .menu-item
          &:not(:last-child)
            margin-bottom: 1rem
          .menu-link
            font-size: 1.5rem
            color: $dark
            text-transform: capitalize
            display: inline-block
            transition: all .3s ease
          &:hover,&.active
            .menu-link
              color: $purple
              text-stroke: .1rem currentColor
              -webkit-text-stroke: .1rem currentColor
              transition: all .3s ease
      .filter-list
        list-style: none
        margin-top: 1.5rem
        display: none
        overflow: hidden
        overflow-y: auto
        max-height: 25rem
        &::-webkit-scrollbar
          width: .3rem
        &::-webkit-scrollbar-track
          border-radius: 20px
          background: transparent
        &::-webkit-scrollbar-thumb
          background: #007885
          border-radius: 20px
        .filter-item
          label
            font-size: 1.5rem
            &:before
              top: .2rem
          &:not(:last-child)
            margin-bottom: 1rem
    .apply-filter
      margin-top: 0
      width: 100%
  .list
    margin: 0 -15px
    .col, [class*=col-]
      padding-right: 15px
      padding-left: 15px
    .item
      margin-bottom: 30px
  .head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 3rem
    .clear-all
      color: $green
      text-transform: capitalize
      text-decoration: underline
  .showing
    color: #707070
    margin-bottom: 3rem
.fancybox-slide--html
  padding: 20px!important
.fancybox-bg
  background: rgba(#282828,.66)
.popup-default
  padding: 0!important
  @media (min-width: 768px)
    width: 35%
  .popup-head
    font-weight: bold
    color: #fff
    text-align: center
    padding: 2rem
    background: #452A50
  .popup-body
    padding: 2.5rem
  .donate-amount
    list-style: none
    display: flex
    flex-flow: wrap
    margin: -1rem
    li
      padding: 1rem
      width: 33.33%
      a
        display: block
        text-align: center
        font-size: 1.5rem
        letter-spacing: .03rem
        height: 3.5rem
        line-height: 3.5rem
        box-sizing: border-box
        padding: 0 2.5rem
        transition: all .2s
        border-radius: .3rem
        border: 1px solid #E1E1E1
        color: #9F9F9F
        background: #F5F5F5
      &:hover,&.active
        a
          color: #fff
          transition: all .2s
          border: 1px solid $green
          background: $green
    .other-amount
      width: 66.66%
    @media (max-width: 575px)
      li
        width: 50%
      .other-amount
        width: 100%
  .learn-more
    display: block
    width: fit-content
    text-decoration: underline
    margin: 0 auto
    margin-top: 2rem
    transition: all .2s
    color: $dark
    font-weight: bold
    text-transform: uppercase
    &:hover
      letter-spacing: .1rem
      transition: all .2s
  .bottom-wrap
    margin-top: 3rem
    border-top: 1px dashed #0000001B
    padding-top: 3rem
  .text
    line-height: 1.6
  .btn-wrap
    display: flex
    flex-flow: wrap
    justify-content: space-between
    .btn
      margin-right: 0!important
      width: calc(50% -1rem )
    @media (max-width: 575px)
      flex-direction: column
      .btn
        width: 100%
  .options
    display: flex
    border-bottom: 1px solid #0000001B
  .option
    width: 50%
    cursor: pointer
    label
      font-size: 1.5rem
      color: #9F9F9F
      background: #fff
      text-transform: capitalize
      text-align: center
      width: 100%
      cursor: pointer
      padding: 2rem
      font-weight: 600
    input
      padding: 0
      height: initial
      width: initial
      margin-bottom: 0
      display: none
      cursor: pointer
    input:checked + label
      color: $purple
      background: #F5E5F0
.donate-haf-popup
  .btn
    width: 100%!important
.fancybox-close-small
  display: block!important
  padding: 0px!important
  width: 1.5rem!important
  top: 2.5rem!important
  right: 2rem!important
  height: 1.5rem!important
  svg
    display: none!important
  &:before
    content: ''
    position: absolute
    width: 1.5rem
    height: 1.5rem
    top: 50%
    left: 50%
    transform: translate(-50%,-50%)
    background-image: url(../img/icons/Cart_Delete.png)
    background-repeat: no-repeat
    background-position: center
    filter: brightness(0) invert(1)
    background-size: contain
.add-to-basket-popup
  text-align: center
  .icon
    width: 12rem
    margin: 0 auto
    img
      width: 100%
      height: auto
  .text
    margin-top: 3rem
  .btn-wrap
    justify-content: center
