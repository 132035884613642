.page-cart
  padding: 10rem 0
  .cart-table
    table
      width: 100%
      border-left: 1px solid #F7F5F5
      border-right: 1px solid #F7F5F5
      thead
        background: #452A50
        color: #fff
        font-size: 1.8rem
        font-weight: 600
        th
          padding: 2.8rem 3rem
          &:nth-child(1)
            width: 70%
      tr
        &:not(:last-child)
          border-bottom: 1px solid #F7F5F5
        td
          padding: 2.8rem 3rem
          .wrap
            display: flex
            align-items: center
            justify-content: space-between
          .edit,.remove
            height: 2.5rem
            img
              height: 100%
              width: auto
      .total
        background: #F5E4F0
        padding: 2.8rem 3rem
        td
          font-weight: bold
          text-transform: uppercase
          &:first-child
            text-align: right
      @media (max-width: 767px)
        th,td
          padding: 2rem!important
        thead
          th
            &:nth-child(1)
              width: 50%
  .btn-wrap
    display: flex
    flex-flow: wrap
    justify-content: space-between
    .btn
      min-width: 37.4rem
      margin-top: 3rem
      margin-right: 0!important
      @media (max-width: 767px)
        width: 100%
.donate-haf
  .img
    width: 10.5rem
    margin: 0 auto
    margin-bottom: 2rem
    img
      width: 100%
      height: auto
  .txt
    margin-bottom: 2rem
    text-align: center
  .donate-amount
    justify-content: center
    li
      &:hover,&.active
        a
          background: $blue
  .btn
    margin-top: 3rem
