.latest-campaigns
  padding-top: 10rem
  .slider
    position: relative
  .swiper-slide
    box-sizing: border-box
    height: auto!important
.campaigns-card
  position: relative
  border-radius: .5rem
  overflow: hidden
  display: flex
  height: 100%
  padding-top: 596/364*100%
  &:after
    content: ""
    inset: 0
    position: absolute
    background: transparent linear-gradient(180deg, #00000000 0%, #0000001B 21%, #000000BA 61%, #000000 100%) 0% 0% no-repeat padding-box
  .card-tags
    position: absolute
    top: 2rem
    left: 2.5rem
    z-index: 9
    display: flex
    align-items: center
    .tag
      font-size: 1.3rem
      text-transform: capitalize
      letter-spacing: .026rem
      color: #fff
      line-height: 2.5rem
      padding: 0 1rem
      border-radius: .3rem
      display: inline-block
      &:not(:last-child)
        margin-right: 1rem
      &-blue
        background: $blue
      &-green
        background: #33CC85
  .caption
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    z-index: 9
    padding: 0 2.5rem 2.5rem
    color: #fff
    display: flex
    flex-direction: column
    .title
      font-size: 1.8rem
      color: #fff
      letter-spacing: .054rem
      text-transform: uppercase
      font-weight: bold
    .desc
      margin-top: 1.5rem
      a
        color: #fff
        display: inline
        word-break: break-word
        margin-left: .5rem
        text-decoration: underline
  .progress-wrapper
    padding-top: 2.8rem
    margin-top: auto
    .progress-txt
      margin-bottom: 1rem
    .bottom
      display: flex
      align-items: center
      justify-content: space-between
      margin-top: 1rem
    .progress
      background-color: #fff
      border-radius: 20px
      position: relative
      height: .5rem
      width: 100%
      &-done
        background: $purple
        border-radius: 20px
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        width: 0
        opacity: 0
        transition: 1s ease 0.3s
  .btn
    width: 100%
    &:hover
      background: none
      border: 1px solid #fff
      color: #fff
