@function r($size) {
	@return ($size/16px)*1rem; }
@mixin line($line) {
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: $line;
	-webkit-box-orient: vertical; }
@mixin img($ratio) {
	padding-top: $ratio * 100%;
	display: block;
	position: relative;
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover; } }
@mixin overlay() {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0; }
@mixin clearfix {
	&::after {
		content: "";
		display: table;
		clear: both; } }
@mixin absCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); }
@mixin mr {
	margin-right: -1.5rem;
	margin-left: -1.5rem; }
@mixin pd {
	padding-left: 1.5rem;
	padding-right: 1.5rem; }
@mixin scrollbar($height, $width, $bg , $color) {
	height: $height;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: $width; }
	&::-webkit-scrollbar-track {
		background: $bg; }
	&::-webkit-scrollbar-thumb {
		background: $color; } }
@mixin line-clamp($clamp) {
	display: -webkit-box;
	-webkit-line-clamp: $clamp;
	-webkit-box-orient: vertical;
	overflow: hidden; }
@mixin placeholder($size, $color,$style) {
	::-webkit-input-placeholder {
		font-size: $size;
		color: $color;
		font-weight: $style; } }
@mixin column-count($count, $gap) {
	-moz-column-count: $count;
	-moz-column-gap: $gap;
	-webkit-column-count: $count;
	-webkit-column-gap: $gap;
	column-count: $count;
	column-gap: $gap; }
@mixin absoluteCenter {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%); }

@mixin list($spacing,$col) {
	display: flex;
	flex-flow: wrap;
	margin-left: calc( -1* #{$spacing});
	margin-bottom: calc( -1* #{$spacing});
	.flex-item {
		margin-left: $spacing;
		margin-bottom: $spacing;
		width: calc( 100% / #{$col} - #{$spacing}); } }
