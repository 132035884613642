.dashboard-banner
  padding: 12.8rem 0 17.4rem
  position: relative
  .heading
    position: relative
    z-index: 1
    text-align: center
    font-size: 4rem
    letter-spacing: .4rem
    text-transform: capitalize
    font-weight: bold
    color: #452A50
    margin-bottom: 5rem
    @extend .ff-2
    max-width: 67rem
    margin: 0 auto
  &:after
    content: ''
    position: absolute
    inset: 0
    background: linear-gradient(90deg, rgba(176,92,157,1) 0%, rgba(0,0,0,0) 64%)
    box-shadow: 0px 3px 12px #0000000F
    opacity: 0.75
.account-dashboard
  margin-top: -12.5rem
  z-index: 2
  position: relative
  .my-tabs
    box-shadow: 0 .3rem .6rem #00000029
    .tabs-nav-wrap
      .tabs-nav
        list-style: none
        display: flex
        align-items: center
        flex-flow: wrap
        // border: 1px solid #E1E1E1
        // @media (max-width: 767px)
        //   border: none
        //   border-top: 1px solid #E1E1E1
        //   border-left: 1px solid #E1E1E1
        li
          width: 25%
          font-size: 1.8rem
          text-transform: capitalize
          color: #9F9F9F
          text-align: center
          height: 7.5rem
          display: inline-flex
          background: #fff
          justify-content: center
          align-items: center
          transition: all .2s
          cursor: pointer
          border-bottom: 1px solid #e1e1e1
          &:not(:last-child)
            border-right: 1px solid #E1E1E1
          &:hover,&.active
            color: #fff
            background: #452A50
            border-color: #452A50
            transition: all .2s
          @media (max-width: 767px)
            width: 50%
            border-bottom: 1px solid #E1E1E1
            border-right: 1px solid #E1E1E1

  .tab-content
    display: none
    background: #fff
    .tab-content-wrapper
      padding: 2.5rem 4rem 4rem
      @media (max-width: 767px)
        padding: 2.5rem 20px 4rem
    .box-top
      background: #F7F5F4
      padding: 2.5rem 4rem
      @media (max-width: 767px)
        padding: 2.5rem 20px
      .step-progress
        margin-top: auto
        @media (max-width: 767px)
          margin-bottom: 3rem
        .step-result
          text-align: left
          color: $purple
          font-weight: bold
          margin-bottom: 1rem
        .bottom
          margin-top: 1rem
          font-weight: bold
        .progress-bar
          background-color: #E1E1E1
          border-radius: 20px
          position: relative
          height: .5rem
          width: 100%
          .progress-done
            background: $purple
            border-radius: 20px
            color: #fff
            display: flex
            align-items: center
            justify-content: center
            height: 100%
            width: 0
            opacity: 0
            transition: 1s ease 0.3s
    .steps-list
      list-style: none
      li
        font-size: 1.8rem
        position: relative
        padding-left: 3rem
        width: fit-content
        &:not(:last-child)
          margin-bottom: 1rem
        &.done
          &:before
            background-image: url(../img/icons/check.png)
        &:before
          content: ''
          position: absolute
          top: .2rem
          left: 0
          width: 1.8rem
          height: 1.8rem
          background-image: url(../img/icons/check-1.png)
          background-position: center
          background-size: contain
        &.important
          padding-right: 3rem
          &:after
            content: ''
            position: absolute
            top: .2rem
            right: 0
            width: 1.8rem
            height: 1.8rem
            background-image: url(../img/icons/exclamation.png)
            background-position: center
            background-size: contain

  .accordions
    .accordion-item
      background: #fff
      box-shadow: 0px .3rem 1.2rem #0000000F
      border-radius: .5rem
      &:not(:last-child)
        margin-bottom: 2rem
      .accordion-heading
        padding-right: 3rem
        font-weight: 600
        font-size: 1.8rem
        color: #452A50
        cursor: pointer
        display: flex
        align-items: center
        box-shadow: 0px .3rem 1.2rem #0000000F
        border-radius: .5rem
        @media (max-width: 767px)
          padding-right: 2rem
          font-size: 1.6rem
        .arrow
          width: 1.4rem
          height: .8rem
          margin-left: auto
          background-image: url(../img/icons/Arrow1_Grey.png)
          background-repeat: no-repeat
          background-position: center center
          background-size: contain
          display: block
          flex-shrink: 0
        .txt
          display: block
          margin-right: 2rem
        .number
          margin-right: 2rem
          width: 5rem
          flex-shrink: 0
          height: 5rem
          border-radius: .5rem
          background: #452A50
          display: flex
          box-shadow: 0px .3rem 1.2rem #0000000F
          color: #fff
          align-items: center
          justify-content: center
          font-weight: bold

      .accordion-content
        display: none
        padding: 4rem
        background: #fff
        p
          &:not(:last-child)
            margin-bottom: 1.5rem
        .wrapper
          display: flex
          flex-flow: wrap
          .image
            width: 26rem
            flex-shrink: 0
            img
              width: 100%
              height: auto
          .content
            width: 100%
            flex: 1
            padding-left: 5rem
          @media (max-width: 767px)
            .image,.content
              width: 100%
            .content
              padding-left: 0
              padding-top: 3rem
        .heading
          font-size: 1.8rem
          text-transform: uppercase
          font-weight: 600
          margin-bottom: 2rem
        .list-content
          list-style: none
          li
            color: #707070
            &:not(:last-child)
              margin-bottom: 1.5rem
            .text
              display: inline-block
              font-weight: 300
              margin-left: .5rem
          .clauses
            text-decoration: underline
            position: relative
            padding-right: 3rem
            width: fit-content
            a
              color: #BD56A0
            &:before
              content: ''
              position: absolute
              top: .2rem
              right: 0
              width: 1.8rem
              height: 1.8rem
              background-image: url(../img/icons/check.png)
              background-position: center
              background-size: contain
        .box
          &:not(:last-child)
            border-bottom: 1px solid #E1E1E1
            padding-bottom: 4rem
          &:not(:first-child)
            padding-top: 4rem
        .my-child-tabs
          .child-tabs-nav-wrap
            border-bottom: 1px solid #E1E1E1
            max-width: 92rem
            overflow: hidden
            overflow-x: auto
            &::-webkit-scrollbar
              height: 0
          .child-tabs-nav
            display: flex
            list-style: none
            align-items: center
            li
              font-size: 1.8rem
              color: #707070
              text-transform: capitalize
              cursor: pointer
              padding: 1rem 2.4rem
              position: relative
              &:after
                content: ''
                position: absolute
                bottom: 0
                height: .3rem
                background: #452A50
                width: 100%
                opacity: 0
                transition: all .2s
                left: 0
              &.active,&:hover
                color: #452A50
                transition: all .2s
                text-stroke: 1px currentColor
                -webkit-text-stroke: 1px currentColor
                &:after
                  opacity: 1
                  transition: all .2s
          .child-tab-content
            padding-top: 4rem
            display: none
        .personal-declaration
          display: flex
          align-items: center
          .icon
            display: block
            width: 2rem
            flex-shrink: 0
            img
              width: 100%
              height: auto
          .txt
            color: #BD55A0
            text-transform: capitalize
            padding-left: 2rem
        .causes
          .list-item
            display: flex
            position: relative
            flex-flow: wrap
            z-index: 1
            background: #fff
            box-shadow: 0px 3px 12px #0000000F
          .item
            width: calc(100% / 6)
            padding-top: calc(100% / 6)
            position: relative
            text-align: center
            transition: all .2s
            border-bottom: 1px solid #F7F5F4
            border-right: 1px solid #F7F5F4
            @media (max-width: 991px)
              width: 25%
              padding-top: 25%
            @media (max-width: 575px)
              width: 33.33%
              padding-top: 33.33%
            @media (max-width: 480px)
              width: 50%
              padding-top: 50%

            .wrap
              display: flex
              flex-direction: column
              align-items: center
              justify-content: center
              position: absolute
              inset: 0
              padding: 2rem
            .icon
              height: 5rem
              margin: 0 auto
              transition: all .2s
              svg
                height: 100%
                width: auto
                path
                  fill: $purple
                  transition: all .2s
            @media (max-width: 767px)
              .icon
                height: 4rem
              .wrap
                padding: 1rem
            @media (max-width: 575px)
              .icon
                height: 3rem
            .title
              color: $dark
              font-weight: normal
              margin-top: 1.5rem
              text-transform: capitalize
              font-size: 1.3rem
              min-height: 3.6rem

            &:hover
              background: #F7F5F4
              transition: all .2s
              .title
                color: $green
                transition: all .2s
                font-weight: bold
              .icon
                transform: scale(1.1)
                transition: all .2s
                svg
                  path
                    fill: $green
                    transition: all .2s
        .organisations
          .items
            display: flex
            flex-flow: wrap
            margin: -.76rem
            .item
              padding: .75rem
              .image
                height: 13rem
                border: 1px solid #F5F5F5
                width: auto
                padding: 1rem
                img
                  height: 100%
                  width: auto
        .declarations-submitted
          display: flex
          align-items: center
          position: relative
          width: fit-content
          padding-right: 3rem
          &:before
            content: ''
            position: absolute
            top: .2rem
            right: 0
            width: 1.8rem
            height: 1.8rem
            background-image: url(../img/icons/check.png)
            background-position: center
            background-size: contain
          .icon
            display: block
            width: 2rem
            flex-shrink: 0
            img
              width: 100%
              height: auto
          .txt
            color: #BD55A0
            text-transform: capitalize
            padding-left: 2rem
      @media (max-width: 767px)
        .accordion-content
          padding: 20px
      &.active
        .accordion-heading
          .arrow
            background-image: url(../img/icons/Arrow1_Grey-2.png)

  //tab2
  .donation-history-content,.recurring-donations-content,.activities-history-content
    padding: 4rem 2.5rem
  .filter-wrapper
    display: flex
    flex-flow: wrap
    align-items: center
    margin-top: -2.5rem
    margin-bottom: 3.5rem
    .filter-item
      margin-right: 2.5rem
      margin-top: 2.5rem
      display: inline-flex
      align-items: center
      .txt
        font-size: 1.5rem
        color: #707070
        text-transform: capitalize
        margin-right: 1.5rem
        font-weight: 300
      @media (max-width: 575px)
        display: flex
        width: 100%
    .btn
      margin-left: 1.5rem
      margin-top: 2.5rem
      @media (max-width: 575px)
        margin-left: 0
  .table
    width: 100%
    border: 1px solid #DDDDDD
    tbody
      tr
        border-top: 1px solid #DDDDDD
        td
          &:nth-child(1),&:nth-child(2)
            color: #BD56A0
    th
      color: #000000
      text-transform: capitalize
      font-weight: bold
    th,td
      padding: 1rem 2rem
    @media (max-width: 767px)
      thead
        position: absolute
        left: -9999px
        overflow: hidden
        width: 0px
        height: 0px
      tr, td, th, thead, tbody, table
        display: block
      tbody
        tr
          border-top: 0
          &:not(:first-child)
            border-top: 1px solid #DDDDDD
        td::before
          display: inline-block
          width: 19rem
          flex: 0 0 19rem
          min-width: 19rem
          text-align: left
          color: #000
          font-weight: bold
          text-transform: capitalize
          content: attr(data-title)
  .recurring-donations-content
    .table
      width: calc(100% - 7.8rem)
      .cancel-btn
        position: absolute
        left: calc(100% + 1.8rem)
        font-size: 1.3rem
        border: 1px solid #6E6E6E
        border-radius: .2rem
        text-transform: uppercase
        top: 1rem
        padding: .3rem .6rem
        transition: all .2s
        color: #6E6E6E
        cursor: pointer
        display: inline-block
        background: none
        &:hover
          border: 1px solid $green
          transition: all .2s
          color: #fff
          background: $green
      tr
        position: relative
      th,td
        padding: 1rem
      .arrow
        display: flex
        align-items: center
        justify-content: flex-end
        cursor: pointer
        img
          flex-shrink: 0
          width: 1.5rem
          height: auto
          transition: all .3s ease
        &.active
          img
            transform: scaleY(-1)
            transition: all .3s ease
      .more-content
        display: none
      table
        tr
          border-top: 0
        th
          color: #707070
        thead
          border-bottom: 1px solid #D9D8D8
      @media (max-width: 767px)
        width: 100%
        tbody
          border-bottom: 1px solid #D9D8D8
        table
          tr
            border: none!important
        .cancel-btn
          position: static
        .arrow
          width: 100%
          justify-content: center
          margin-top: 2rem
          padding: 1.5rem
          background: $green
          border-radius: .2rem
          img
            filter: brightness(0) invert(1)
        .more-content
          border-top: 0
          tbody
            border-bottom: 0
        .more-content > td
          padding-top: 0
          &:before
            display: none
          &:nth-child(1),&:nth-child(3)
            display: none
            &:before
              display: none
    table
      width: 100%
.popup-default.cancel-popup
  max-width: 49rem
  .popup-body
    padding: 2.5rem 2.5rem 4.5rem
    text-align: center
    .txt
      margin-bottom: 1.5rem
    .btn-wrap
      justify-content: center
      gap: 0 1.5rem
      .btn
        width: auto
        min-width: 15.8rem
.head-btn
  padding: 1.5rem 0
  border-bottom: 1px solid #E1E1E1
  ul
    list-style: none
    display: flex
    justify-content: flex-end
    flex-flow: wrap
    padding-right: 1rem
    li
      padding: 0 2rem
      &:not(:last-child)
        border-right: 1px solid #707070
      a
        font-size: 1.5rem
        text-transform: uppercase
        color: #282828
        text-decoration: underline
        cursor: pointer
.main-tags
  list-style: none
  display: flex
  align-items: center
  flex-flow: wrap
  margin: -1rem
  li
    padding: 1rem
    margin-top: 0!important
    margin-bottom: 0!important
    .tag
      font-size: 1.3rem
      text-transform: capitalize
      letter-spacing: .026rem
      color: #fff
      line-height: 2.5rem
      padding: 0 2rem
      background: #452A50
      border-radius: .3rem
      display: inline-block
.recommended-campaigns
  padding-top: 10rem
  .slider
    position: relative
  .swiper-slide
    box-sizing: border-box
    height: auto!important
.recommended-activities
  padding: 10rem 0
  .slider
    position: relative
  .swiper-slide
    box-sizing: border-box
    height: auto!important
.upload-box
  .box-img
    +img(230/260)
    img
      height: 100%!important
  .upload-button
    background: #E1E1E1
    display: flex
    border-radius: .2rem
    padding: .5rem
    width: 100%
    margin-top: 1.5rem
    align-items: center
    justify-content: center
    .icon
      width: 1.5rem
      margin-right: 1rem
      img
        width: 100%
    span
      text-transform: capitalize
  .file-upload
    padding: 0
    border: 0
    background: none
    max-height: 0
    overflow: hidden
    height: auto
    font-size: 1.3rem
    color: #9F9F9F
    font-style: italic
    display: none
  .file-upload-name
    font-size: 1.3rem
    color: #9F9F9F
    width: 100%
    word-break: break-word
    font-style: italic
    margin-top: 1.5rem
.account-dashboard-sub
  margin-top: -4.5rem
  padding-bottom: 10rem
  .inner
    position: relative
    z-index: 2
    background: #fff
    box-shadow: 0px .3rem 1.2rem #0000000A
    border: 1px solid #F4F4F4
  .full-content
    color: #707070
    padding: 4rem
    @media (max-width: 767px)
      padding: 4rem 20px
    p
      margin-bottom: 4rem
    ul
      list-style: none
      li
        &:not(:last-child)
          margin-bottom: 3rem
        .list
          display: flex
          flex-flow: wrap
          margin-top: 1.5rem
          .custom-checkbox
            &:not(:last-child)
              margin-right: 10rem
              @media (max-width: 767px)
                margin-right: 5rem
            input[type="radio"]:focus + label
              &:before
                border: 1px solid #E1E1E1
                background: #B05C9D
                box-shadow: inset 0 0 0 .2rem #fff
            input[type="radio"]:checked + label
              &:before
                border: 1px solid #E1E1E1
                background: #B05C9D
                box-shadow: inset 0 0 0 .2rem #fff
            input[type="checkbox"]:focus + label
              &:before
                border: 1px solid #E1E1E1
                background: #B05C9D
                box-shadow: inset 0 0 0 .2rem #fff
            input[type="checkbox"]:checked + label
              &:before
                border: 1px solid #E1E1E1
                background: #B05C9D
                box-shadow: inset 0 0 0 .2rem #fff

        .note
          margin-top: 1.5rem
          border-bottom: 1px dashed #F4F4F4
          padding-bottom: 3rem
          color: #9F9F9F
          font-weight: 300
  .btn-wrap
    list-style: none
    display: flex
    justify-content: flex-end
    flex-flow: wrap
    padding: 1.5rem 4rem
    border-top: 1px solid #F4F4F4
    @media (max-width: 767px)
      padding: 1.5rem 20px
    li
      padding: 0 2rem
      &:not(:last-child)
        border-right: 1px solid #707070
      a
        font-size: 1.5rem
        text-transform: uppercase
        color: #282828
        text-decoration: underline
        cursor: pointer
  .full-content
    &.volunteer-history-content
      ul
        li
          &:not(:last-child)
            margin-bottom: 2rem
      p
        margin-bottom: 0
      .content
        color: #282828
        padding-top: 1.5rem
        padding-left: 1.8rem
        ul
          &:not(:last-child)
            margin-bottom: 1rem
      .bottom-content
        font-size: 1.4rem
        color: #707070
        text-align: center
        padding-top: 1.5rem
        border-top: 1px solid #E1E1E1
        margin-top: 2.5rem
        font-style: italic
.edit-profile
  .main-tags
    .tag
      display: inline-flex
      align-items: center
      padding: 0 1rem
      .remove
        flex-shrink: 0
        margin-left: 1rem
        display: inline-block
        cursor: pointer
        width: 1rem
        img
          width: 100%
          height: auto
          filter: brightness(0) invert(1)
          transition: all .3s
        &:hover 
          img 
            transform: scale(1.1)

  .accordions
    padding: 4rem
    @media (max-width: 767px)
      padding: 4rem 20px
    .accordion-item
      background: #fff
      box-shadow: 0px .3rem 1.2rem #0000000F
      border-radius: .5rem
      &:not(:last-child)
        margin-bottom: 2rem
      .accordion-heading
        padding-right: 3rem
        font-weight: 600
        font-size: 1.8rem
        color: #452A50
        cursor: pointer
        display: flex
        align-items: center
        box-shadow: 0px .3rem 1.2rem #0000000F
        border-radius: .5rem
        @media (max-width: 767px)
          padding-right: 2rem
          font-size: 1.6rem
        .arrow
          width: 1.4rem
          height: .8rem
          margin-left: auto
          background-image: url(../img/icons/Arrow1_Grey.png)
          background-repeat: no-repeat
          background-position: center center
          background-size: contain
          display: block
          flex-shrink: 0
        .txt
          display: block
          margin-right: 2rem
        .number
          margin-right: 2rem
          width: 5rem
          flex-shrink: 0
          height: 5rem
          border-radius: .5rem
          background: #452A50
          display: flex
          box-shadow: 0px .3rem 1.2rem #0000000F
          color: #fff
          align-items: center
          justify-content: center
          font-weight: bold

      .accordion-content
        display: none
        padding: 4rem
        background: #fff
        p
          &:not(:last-child)
            margin-bottom: 1.5rem
  .wrapper
    display: flex
    flex-flow: wrap
    .image
      width: 26rem
      flex-shrink: 0
      img
        width: 100%
        height: auto
    .content
      width: 100%
      flex: 1
      padding-left: 5rem
    @media (max-width: 767px)
      .image,.content
        width: 100%
      .content
        padding-left: 0
        padding-top: 3rem
  .heading
    font-size: 1.8rem
    text-transform: uppercase
    font-weight: 600
    color: #282828
    margin-bottom: 2rem
  .form-wrap
    max-width: 52rem
  .list-content
    list-style: none
    li
      color: #707070
      &:not(:last-child)
        margin-bottom: 1.5rem
      .text
        display: inline-block
        font-weight: 300
        margin-left: .5rem
    .clauses
      text-decoration: underline
      position: relative
      padding-right: 3rem
      width: fit-content
      a
        color: #BD56A0
      &:before
        content: ''
        position: absolute
        top: .2rem
        right: 0
        width: 1.8rem
        height: 1.8rem
        background-image: url(../img/icons/check.png)
        background-position: center
        background-size: contain
  .box
    &:not(:last-child)
      border-bottom: 1px solid #E1E1E1
      padding-bottom: 4rem
    &:not(:first-child)
      padding-top: 4rem
  .my-child-tabs
    .child-tabs-nav-wrap
      border-bottom: 1px solid #E1E1E1
      max-width: 92rem
      overflow: hidden
      overflow-x: auto
      &::-webkit-scrollbar
        height: 0
    .child-tabs-nav
      display: flex
      list-style: none
      align-items: center
      li
        font-size: 1.8rem
        color: #707070
        text-transform: capitalize
        cursor: pointer
        padding: 1rem 2.4rem
        position: relative
        &:after
          content: ''
          position: absolute
          bottom: 0
          height: .3rem
          background: #452A50
          width: 100%
          opacity: 0
          transition: all .2s
          left: 0
        &.active,&:hover
          color: #452A50
          transition: all .2s
          text-stroke: 1px currentColor
          -webkit-text-stroke: 1px currentColor
          &:after
            opacity: 1
            transition: all .2s
    .child-tab-content
      padding-top: 4rem
      display: none
  .personal-declaration
    display: flex
    align-items: center
    .icon
      display: block
      width: 2rem
      flex-shrink: 0
      img
        width: 100%
        height: auto
    .txt
      color: #BD55A0
      text-transform: capitalize
      padding-left: 2rem
  .causes
    .list-item
      display: flex
      position: relative
      flex-flow: wrap
      z-index: 1
      background: #fff
      box-shadow: 0px 3px 12px #0000000F
    .item
      width: calc(100% / 6)
      padding-top: calc(100% / 6)
      position: relative
      text-align: center
      transition: all .2s
      border-bottom: 1px solid #F7F5F4
      border-right: 1px solid #F7F5F4
      @media (max-width: 991px)
        width: 25%
        padding-top: 25%
      @media (max-width: 575px)
        width: 33.33%
        padding-top: 33.33%
      @media (max-width: 480px)
        width: 50%
        padding-top: 50%
      .remove
        width: 1.5rem
        height: 1.5rem
        border-radius: 50%
        position: absolute
        top: 1rem
        right: 1rem
        cursor: pointer
        z-index: 9
        background: #CC3333
        padding: .4rem
        img
          width: 100%
          height: 100%
          cursor: pointer
          object-fit: scale-down
          filter: brightness(0) invert(1)
      .wrap
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        position: absolute
        inset: 0
        padding: 2rem
      .icon
        height: 5rem
        margin: 0 auto
        transition: all .2s
        svg
          height: 100%
          width: auto
          path
            fill: $purple
            transition: all .2s
      @media (max-width: 767px)
        .icon
          height: 4rem
        .wrap
          padding: 1rem
      @media (max-width: 575px)
        .icon
          height: 3rem
      .title
        color: $dark
        font-weight: normal
        margin-top: 1.5rem
        text-transform: capitalize
        font-size: 1.3rem
        min-height: 3.6rem
      &:hover
        background: #F7F5F4
        transition: all .2s
        .title
          color: $green
          transition: all .2s
          font-weight: bold
        .icon
          transform: scale(1.1)
          transition: all .2s
          svg
            path
              fill: $green
              transition: all .2s
  .add-skillset
    font-size: 1.5rem
    text-decoration: underline
    color: #BD56A0
    margin-top: 1rem
    text-transform: uppercase
  .organisations
    .items
      display: flex
      flex-flow: wrap
      margin: -.76rem
      .item
        padding: .75rem
        position: relative
        .image
          height: 13rem
          border: 1px solid #F5F5F5
          width: auto
          padding: 1rem
          img
            height: 100%
            width: auto
        .remove
          width: 1.5rem
          height: 1.5rem
          border-radius: 50%
          position: absolute
          top: 1.75rem
          right: 1.75rem
          cursor: pointer
          z-index: 9
          background: #CC3333
          padding: .4rem
          img
            width: 100%
            height: 100%
            cursor: pointer
            object-fit: scale-down
            filter: brightness(0) invert(1)
  .declarations-submitted
    display: flex
    align-items: center
    position: relative
    width: fit-content
    padding-right: 3rem
    &:before
      content: ''
      position: absolute
      top: .2rem
      right: 0
      width: 1.8rem
      height: 1.8rem
      background-image: url(../img/icons/check.png)
      background-position: center
      background-size: contain
    .icon
      display: block
      width: 2rem
      flex-shrink: 0
      img
        width: 100%
        height: auto
    .txt
      color: #BD55A0
      text-transform: capitalize
      padding-left: 2rem

.check
  text-decoration: underline
  position: relative
  padding-right: 3rem
  width: fit-content
  a
    color: #BD56A0
  &:before
    content: ''
    position: absolute
    top: .2rem
    right: 0
    width: 1.8rem
    height: 1.8rem
    background-image: url(../img/icons/check.png)
    background-position: center
    background-size: contain
.change-password
  padding: 4rem
  @media (max-width: 767px)
    padding: 4rem 20px
  .form-wrap
    max-width: 100%
  .input
    position: relative
    .toggle-pass
      position: absolute
      top: 50%
      cursor: pointer
      transform: translateY(-50%)
      right: 1.6rem
      em
        color: #707070
        font-size: 1.6rem
      &.active
        em
          &:before
            content: "\ECB7"
