.showcase
  .tabs-nav-wrap
    box-shadow: 0 .3rem 1.2rem #0000000F
    .tabs-nav
      display: flex
      align-items: center
      width: fit-content
      margin: 0 auto
      list-style: none
      li
        font-size: 2rem
        text-transform: uppercase
        letter-spacing: .12rem
        color: #9F9F9F
        line-height: calc(45/20)
        padding: 2rem 8rem
        transition: all .3s
        cursor: pointer
        border-bottom: .4rem solid transparent
        &.active
          border-bottom: .4rem solid #452A50
          color: #452A50
          font-weight: bold
          transition: all .3s
        @media (max-width: 767px)
          padding: 2rem 4rem
  .tab-content
    display: none
