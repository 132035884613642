.dashboard-section
  display: flex
  width: 100%
  background: #F8F8F8
.dashboard-main.active
  padding-left: 0px !important
.dashboard-main
  padding-left: 200px
  transition: padding 0.5s
  min-height: 100vh
  display: flex
  width: 100%
  flex-direction: column
.header-navbar
  padding: 0 2rem
  height: 70px
  display: flex
  width: 100%
  align-items: center
  box-shadow: 0px .2rem .6rem #0000000A
  background: #fff
  position: sticky
  top: 0
  right: 0
  z-index: 999
  .navbar
    display: flex
    width: 100%
    align-items: center
    justify-content: space-between
  .aside-toggle-btn
    color: $dark
    background: #F5E4F0
    cursor: pointer
    width: 3.8rem
    height: 3.8rem
    border-radius: 50%
    display: flex
    align-items: center
    justify-content: center
    svg
      width: 2rem
      height: auto
  .right-wrap
    display: flex
    align-items: center
  .notification
    border-right: 1px solid #EBEBF2
    padding-right: 2rem
    margin-right: 2rem
    &-toggle
      width: 16px
      position: relative
      cursor: pointer
      svg
        width: 100%
        height: auto
        path
          fill: #BCBCCB
      .badge
        position: absolute
        width: 10px
        height: 10px
        top: -2px
        right: -2px
        border-radius: 50%
        display: block
        border: .2rem solid #fff
        background: #BD56A0
  .account
    position: relative
    height: 70px
    display: flex
    align-items: center
    &-toggle
      display: flex
      cursor: pointer
      align-items: center
      .name
        color: #707070
        text-transform: capitalize
      .caret
        flex-shrink: 0
        width: 10px
        margin-left: 10px
        img
          width: 100%
          height: auto
      .avatar
        width: 3.8rem
        height: 3.8rem
        flex-shrink: 0
        margin-left: 1rem
        border-radius: 50%
        overflow: hidden
        background: #F5E4F0
        img
          width: 100%
          height: 100%
          object-fit: cover
    .dropdown-menu
      position: absolute
      top: 100%
      right: 0
      width: 20rem
      background: #fff
      list-style: none
      opacity: 0
      visibility: hidden
      box-shadow: 0px .5rem .4rem #272D3B05
      transition: all .3s
      li
        line-height: 4rem
        transition: all .3s
        a
          transition: all .3s
          font-size: 1.5rem
          display: block
          color: #707070
          padding: 0 1.5rem
          text-transform: capitalize
        &:hover
          background: #F5E4F0
          transition: all .3s
    &:hover
      .dropdown-menu
        opacity: 1
        visibility: visible
.aside-nav.active
  // width: 0px !important
  transform: translateX(-100%)
//aside nav
.aside-nav
  overflow-y: auto
  position: fixed
  top: 0
  bottom: 0
  background-color: #452A50
  width: 200px
  transition: transform 0.5s
  height: 100vh
  padding: 1rem 0
  z-index: 9999
  .aside-brand
    width: 105px
    display: block
    margin: 0 auto
    margin-bottom: .6rem
    svg
      width: 100%
      height: auto
  .nav-link
    display: flex
    align-items: center
    font-size: 15px
    letter-spacing: .03rem
    text-transform: capitalize
    color: #fff
    line-height: 5.3rem
    white-space: nowrap
    padding: 0 2.5rem
    .icon
      width: 16px
      height: 16px
      flex-shrink: 0
      margin-right: 1rem
      img
        width: 100%
        height: 100%
        object-fit: contain
        filter: brightness(0) invert(1)
  .nav-item
    transition: all .3s ease-in-out
    &:hover,&.active
      background: #32183C
      transition: all .3s ease-in-out
      box-shadow: inset 5px 0 0 0 #BD56A0
@media (min-width:300px) and (max-width:1023.98px)
  .dashboard-main
    padding-left: 0px !important
  .aside-nav
    top: 70px !important
    transform: translateX(-100%)
    &.active
      transform: none
  .dashboard-main
    overflow: hidden
  //responsive left nav on mobile
  .header-navbar
    position: fixed !important
  .dashboard-main
    margin-top: 70px
#content-container
  padding: 4rem
  @media (max-width:1023.98px)
    padding: 2rem
  .filter-wrapper
    display: flex
    flex-flow: wrap
    align-items: center
    margin-top: -2rem
    padding-top: 2rem
    .filter-item
      margin-right: 2rem
      margin-top: 2rem
      display: inline-flex
      align-items: center
      .txt
        font-size: 1.5rem
        color: #707070
        text-transform: capitalize
        margin-right: 1.5rem
        font-weight: 300
      @media (max-width: 575px)
        display: flex
        width: 100%
    .btn
      margin-left: 2rem
      margin-top: 2rem
      @media (max-width: 575px)
        margin-left: 0
    .dropdown-select
      background: #fff
    .form-group
      margin-bottom: 0
      position: relative
      input
        background: #fff
        width: 26.7rem
      [type="datetime-local"]
        width: 30.8rem
      [type="date"], [type="datetime-local"]
        font-weight: 300
        padding-right: 5rem
      [type="date"]::-webkit-inner-spin-button, [type="datetime-local"]::-webkit-inner-spin-button
        display: none
      [type="date"]::-webkit-calendar-picker-indicator, [type="datetime-local"]::-webkit-calendar-picker-indicator
        width: 3.2rem
        height: 3.2rem
        cursor: pointer
        border-radius: .3rem
        background: #F5E4F0
        position: absolute
        right: 0
        top: 0
        border: none
        box-shadow: none
        background-image: url(../img/common-icons/calendar.png)
        background-position: center center
        background-repeat: no-repeat
        background-size: 1.7rem auto
  .heading
    font-size: 2.4rem
    @extend .ff-2
    letter-spacing: .048rem
    text-transform: capitalize
    color: #BD56A0
    margin-bottom: 2rem
    font-weight: bold
  .heading-wrap
    display: flex
    flex-flow: wrap
    margin-left: -2rem
    align-items: center
    margin-top: -1rem
    margin-bottom: 2rem
    .heading
      margin-left: 2rem
      margin-top: 1rem
      margin-bottom: 0
    .tag
      font-size: 1.5rem
      text-transform: capitalize
      color: #fff
      border-radius: .5rem
      height: 2.5rem
      padding: 0 2rem
      margin-top: 1rem
      margin-left: 2rem
      &.green
        background: #27E284
      &.blue
        background: #2699FB
  .table-responsive
    border-radius: .5rem
    box-shadow: 0px .5rem .4rem #272D3B05
    background: #FFFFFF
    padding: 0 3.5rem 3.5rem
    &.table-sticky
      .table
        th,td
          &:last-child
            position: sticky
            right: 0
            top: 0
            z-index: 999
            overflow: visible
            background: #fff
          &.active
            z-index: 9999
  .table
    width: 100%
    thead
      border-bottom: 1px solid #F4F2F2
      th
        padding: 1.5rem
        color: #000000
        text-transform: capitalize
        font-weight: bold
        white-space: nowrap
        max-width: 29rem
        &:first-child
          padding-left: 0
        &:last-child
          padding-right: 0

    tr
      border-bottom: 1px solid #F4F2F2
      td
        color: #000000
        padding: 1.5rem
        overflow: hidden
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
        max-width: 29rem
        &:first-child
          padding-left: 0
        &:last-child
          padding-right: 0
  .dropdown.actions-dropdown
    min-width: 11.8rem
    margin-left: auto
    .dropdown-select
      padding: 0 .5rem 0 1rem
      height: 2.7rem
      border-radius: .5rem
      border: 1px solid #BD56A0
      background: #fff
      .dropdown-selected
        font-weight: normal!important
        font-size: 1.5rem
        color: #BD56A0
      .dropdown-caret
        width: unset
        height: unset
        background: none
        em
          color: #BD56A0
          font-size: 1.8rem
    .dropdown-list
      border: 1px solid #EAEAEA
      border-radius: .5rem
      overflow: hidden
      top: calc(100% + .5rem)
      .dropdown-item
        font-size: 1.5rem
        color: #6E6E6E
        font-weight: normal!important
        padding: .8rem 1.5rem
        border-bottom: 0
        line-height: 1
        text-align: left
        &.selected,&:hover
          color: #452A50
          background: #F5E4F0

    &.open
      .dropdown-select
        background: #BD56A0
        .dropdown-selected
          color: #fff
        .dropdown-caret
          em
            color: #fff

  .draft,.pending,.revise,.published,.completed,.closed,.dot-green,.dot-dark,.edited,.submitted
    padding-left: 20px
    position: relative
    white-space: nowrap
    &:before
      content: ""
      position: absolute
      top: 50%
      transform: translateY(-50%)
      left: 0
      width: 10px
      height: 10px
      border-radius: 50%
  .draft,.edited
    &:before
      background: #2699FB
  .pending,.submitted
    &:before
      background: #FF8373
  .revise
    &:before
      background: #FB2626
  .closed
    &:before
      background: #707070
  .completed
    &:before
      background: #FFDA83
  .published
    &:before
      background: #27E284
  .dot-green
    &:before
      background: #27E284
  .dot-dark
    &:before
      background: #B7B7B7

  .view-btn,.edit-btn
    border: 1px solid #BD56A0
    border-radius: .3rem
    padding: 0 1.2rem
    height: 2.7rem
    display: inline-flex
    align-items: center
    color: #BD56A0
    cursor: pointer
    text-transform: uppercase
    transition: all .2s
    &:hover
      transition: all .2s
      color: #707070
      background: #F4F2F2
      border: 1px solid #F4F2F2
  .btn-wrap
    text-align: right
  .form-wrap
    @media screen and (min-width: 768px)
      .form-row
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr))
        grid-gap: 0 6rem
    .input-wrap
      position: relative
      .icon
        position: absolute
        top: 0
        left: 0
        width: 3.6rem
        height: 3.6rem
        display: flex
        align-items: center
        justify-content: center
        border-radius: .3rem 0 0 .3rem
        background: #E1E1E1
        img
          width: 2rem
          height: auto
      input
        text-indent: 3.6rem
    input
      border: 1px solid #F4F2F2
      background: none
    .sharing-link
      display: flex
      align-items: center
      border: 1px solid #F4F2F2
      .icon
        width: 3.6rem
        height: 3.6rem
        display: flex
        flex-shrink: 0
        align-items: center
        justify-content: center
        border-radius: .3rem 0 0 .3rem
        background: #E1E1E1
        margin-right: 2rem
        img
          width: 2rem
          height: auto
    .form-group
      margin-bottom: 2rem
  .tooltip
    padding-top: 1.5rem
    label
      margin-bottom: 1.5rem
    input,textarea,.select-box
      border: none
      background: #fff
    .group
      display: flex
      align-items: center
      padding-left: 2rem
      .custom-checkbox
        &:not(:last-child)
          margin-right: 10rem
        label
          color: #707070
          font-size: 1.5rem
          font-weight: 300
          margin-bottom: 0
          &:before
            background: #f8f8f8
        input[type="radio"]:focus + label
          &:before
            border: 1px solid #E1E1E1
            background: #BD56A0
            box-shadow: inset 0 0 0 .2rem #fff
        input[type="radio"]:checked + label
          &:before
            border: 1px solid #E1E1E1
            background: #BD56A0
            box-shadow: inset 0 0 0 .2rem #fff
        input[type="checkbox"]:focus + label
          &:before
            border: 1px solid #E1E1E1
            background: #BD56A0
            box-shadow: inset 0 0 0 .2rem #fff
        input[type="checkbox"]:checked + label
          &:before
            border: 1px solid #E1E1E1
            background: #BD56A0
            box-shadow: inset 0 0 0 .2rem #fff
    .tooltip-content
      .group
        padding-left: 0
  .tooltip-toggle
    color: #030303
    margin-top: 2rem
    text-transform: capitalize
    font-weight: 600
    padding-left: 2rem
    width: fit-content
    cursor: pointer
  .tooltip-content
    border: 1px solid #70707033
    padding: 2rem
    border-radius: .5rem
    margin-top: 1rem
    background: #f8f8f8
    width: 100%
    position: relative
    font-weight: normal
    color: #707070
    display: none
    &:before
      content: ''
      height: 1.6rem
      width: 1.6rem
      position: absolute
      background-color: #f8f8f8
      top: -.8rem
      left: 2rem
      border-top: #70707033 solid 1px
      border-left: #70707033 solid 1px
      transform: rotate(45deg)
    .form-group
      .txt
        font-weight: bold
        display: block
        padding-left: 1.5rem
    ul
      list-style: disc
      padding-left: 1.8rem
      li
        font-weight: bold
    p
      &:not(:last-child)
        margin-bottom: 2rem
  .list
    list-style: none
    > li
      color: #030303
      font-weight: 600
      &:not(:last-child)
        margin-bottom: 2rem
  .accordions
    .accordion-item
      .accordion-heading
        .txt
          font-size: 2rem
          @extend .ff-2
          letter-spacing: .04rem
          font-weight: bold
          @media (max-width: 767px)
            font-size: 1.8rem
          @media (max-width: 575px)
            font-size: 1.6rem
    .next
      margin-bottom: 4.8rem
      text-align: left!important
      display: none
      .btn
        margin-top: 1rem
  .box
    padding: 2.5rem
    box-shadow: 0px .5rem .6rem #272D3B14
    position: relative
    border-radius: .5rem
    background: #fff
    @media screen and (min-width: 768px)
      .form-row
        display: grid
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr))
        grid-gap: 0 1.5rem
    .remove-box
      position: absolute
      top: 2.5rem
      right: 2.5rem
      width: 2.5rem
      z-index: 9
      cursor: pointer
      img
        width: 100%
        height: auto
        transition: all .3s
      &:hover
        img
          transform: scale(1.15)

  .documents

    .document
      display: inline-flex
      align-items: center
      font-size: 1.8rem
      color: #BD56A0
      text-decoration: underline
      font-weight: 600
      border: 1px solid #DBDBDB
      border-radius: .5rem
      height: 4.8rem
      padding: 0 2.5rem
      background: #FAFAFA
      &:nth-child(1)
        margin-right: 3.5rem
.upload-2
  display: flex
  .box-img
    width: 11rem
    flex-shrink: 0
    border: 1px solid #DBDBDB
    background: #F5E4F0
    position: relative
    border-radius: .5rem
    height: 8.8rem
    img
      border-radius: .5rem
      width: 100%
      height: 100%
      object-fit: cover
    .remove
      width: 1.5rem
      height: 1.5rem
      border-radius: 50%
      position: absolute
      top: -.75rem
      right: -.75rem
      cursor: pointer
      z-index: 9
      background: #CC3333
      padding: .4rem
      transition: all .3s
      img
        width: 100%
        height: 100%
        cursor: pointer
        object-fit: scale-down
        filter: brightness(0) invert(1)
      &:hover
        transform: scale(1.15)

  .p-image
    flex: 1
    width: 100%
    padding-left: 2rem
    display: flex
    flex-direction: column
    justify-content: center
    .txt
      color: #707070
      margin-bottom: 1rem
    .wrap
      display: flex
      align-items: center
      .upload-button
        color: #fff
        text-transform: capitalize
        letter-spacing: .045rem
        padding: 0 1.5rem
        height: 2.5rem
        flex-shrink: 0
        line-height: 2.5rem
        border-radius: .5rem
        cursor: pointer
        background: #BD56A0
        &:hover,&.active
          box-shadow: .1rem .3rem .5rem rgba(0,0,0,0.3)
    input[type="file"]
      display: none
    .file-upload-name
      font-size: 1.3rem
      color: #807E6F
      font-weight: 300
      word-break: break-word
      font-style: italic
      margin-left: 1.5rem
  @media (max-width: 575px)
    flex-direction: column
    .p-image
      padding-left: 0
      padding-top: 2rem
.upload-multiple
  max-width: 70rem
  .buttons
    display: flex
    align-items: center
    margin-top: 2rem
    .btn-prev,.btn-next
      display: block!important
      img
        width: 1rem
    .btn-prev
      margin-right: 2.5rem
  .list-image
  .image
    border: 1px solid #DBDBDB
    display: block
    position: relative
    border-radius: .5rem
    height: 8.8rem
    margin-top: .75rem
    &:not(:last-child)
      margin-right: 2rem
    img
      border-radius: .5rem
      width: 100%
      height: 100%
      object-fit: cover
    .remove
      width: 1.5rem
      height: 1.5rem
      border-radius: 50%
      position: absolute
      top: -.75rem
      right: -.75rem
      cursor: pointer
      z-index: 9
      background: #CC3333
      padding: .4rem
      transition: all .3s
      img
        width: 100%
        height: 100%
        cursor: pointer
        object-fit: scale-down
        filter: brightness(0) invert(1)
        transition: all .3s
      &:hover
        transform: scale(1.1)
    &.hide
      visibility: hidden
      position: absolute
  .txt
    color: #707070
    margin-bottom: 2rem
  .wrap
    display: flex
    align-items: center
    margin-bottom: 1.5rem
    .upload-button
      color: #fff
      text-transform: capitalize
      letter-spacing: .045rem
      padding: 0 1.5rem
      height: 2.5rem
      flex-shrink: 0
      line-height: 2.5rem
      border-radius: .5rem
      cursor: pointer
      background: #BD56A0
      &:hover,&.active
        box-shadow: .1rem .3rem .5rem rgba(0,0,0,0.3)
  input[type="file"]
    display: none
  .file-upload-name
    font-size: 1.3rem
    color: #807E6F
    font-weight: 300
    word-break: break-word
    font-style: italic
    margin-left: 1.5rem
  &.custom
    display: flex
    flex-flow: wrap
    max-width: 100%
    margin: -1rem
    .list-image
      width: 60%
      padding: 1rem
    .left-wrap
      padding: 1rem
      width: 40%
    @media (max-width: 767px)
      .list-image,.left-wrap
        width: 100%
.paging_simple_numbers
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #D7DAE2
  border-radius: .2rem
  width: fit-content
  margin: 0 auto
  font-size: 1.3rem
  margin-top: 3.5rem
  color: #4D4F5C
  a,.ellipsis
    width: 3.2rem
    height: 3.2rem
    display: flex
    color: #4D4F5C
    align-items: center
    transition: all .2s
    cursor: pointer
    justify-content: center
    img
      height: 8px
      width: auto
    &:hover,&.current
      transition: all .2s
      color: #BD56A0
      background: #F5E4F0
  .ellipsis
    border-left: 0
    border-right: 1px solid #D7DAE2
  span
    display: flex
    align-items: center
    border-left: 1px solid #D7DAE2
    a
      border-right: 1px solid #D7DAE2
.dashboard-head
  margin-bottom: 2rem
  display: flex
  flex-flow: wrap
  justify-content: space-between
  margin-top: -1rem
  .back
    display: inline-flex
    align-items: center
    color: #BD56A0
    font-weight: bold
    margin-top: 1rem
    .icon
      display: block
      width: 6px
      flex-shrink: 0
      margin-right: 1rem
      img
        width: 100%
        height: auto
  .btn-wrap
    margin-right: -1rem
    text-align: left!important
    display: flex
    flex-flow: wrap
    align-items: center
    .btn
      min-width: 17.5rem
      margin-top: 1rem
      margin-right: 1rem
      .icon
        display: block
        width: 2.4rem
        flex-shrink: 0
        margin-right: .5rem
        img
          width: 100%
          height: auto
          filter: brightness(0) invert(1)
.btn-bottom
  margin-right: -2rem
  text-align: left!important
  display: flex
  flex-flow: wrap
  align-items: center
  margin-top: 6.7rem
  .btn
    min-width: 17.5rem
    margin-top: 1rem
    margin-right: 2rem
    .icon
      display: block
      width: 2.4rem
      flex-shrink: 0
      margin-right: .5rem
      img
        width: 100%
        height: auto
        filter: brightness(0) invert(1)
  .save-btn
    @media (min-width: 992px)

      margin-left: auto
.dataTables_wrapper
  overflow: hidden
  overflow-x: auto
  &::-webkit-scrollbar
    height: .6rem
  &::-webkit-scrollbar-track
    background: #DDDDDD
  &::-webkit-scrollbar-thumb
    background: #B7B7B7
.box-item
  border-radius: .5rem
  padding: 1.5rem
  background: #fff
  margin-top: 4rem
  .items
    display: flex
    flex-flow: wrap
    margin: -1rem
  .item
    padding: 1rem
    &-1
      width: 45%
      .wrap
        position: relative

    .wrap
      border-radius: .5rem
      min-height: 28.6rem
      box-shadow: 0px .3rem .6rem #272D3B33

      height: 100%
      display: flex
      flex-direction: column
    .progress-wrapper
      padding: 3rem
      .top-txt
        display: flex
        align-items: center
        justify-content: space-between
        color: #fff
        margin-bottom: 1rem
        .days
          font-size: 1.5rem
          font-weight: bold
      .progress-txt
        font-size: 2rem
      .bottom
        margin-top: 1rem
        color: #fff
        .donated
          margin-left: .5rem
      .progress
        background-color: $purple
        border-radius: 20px
        position: relative
        height: .5rem
        width: 100%
        &-done
          background: #fff
          border-radius: 20px
          color: #fff
          display: flex
          align-items: center
          justify-content: center
          height: 100%
          width: 0
          opacity: 0
          transition: 1s ease 0.3s
    .text
      font-size: 1.3rem
      color: #fff
      font-weight: 300
    .title
      font-size: 2.5rem
      font-weight: bold
      @extend .ff-2
      color: #fff
      margin-top: .5rem
    .subtitle
      max-width: 21rem
    .price-txt
      color: #fff
      margin-top: .5rem
    .image
      padding-top: 1.5rem
      margin-top: auto
      img
        width: 100%
        height: auto
    &-2,&-5
      .wrap
        background: transparent linear-gradient(180deg, #EA5D95 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box
        padding: 1.5rem 3rem
        text-align: center
    &-3
      .wrap
        background: transparent linear-gradient(180deg, #BD56A0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box
        padding: 1.5rem 2.5rem
        text-align: right
    &-2
      width: 22%
    &-3
      width: 33%
    &-4
      width: 50%
      .wrap
        padding: 3rem
        .text
          max-width: 17rem
          margin-top: .5rem
        .title
          margin-top: 0
    &-5
      width: 25%
    &-6
      width: 25%
      .wrap
        background: transparent linear-gradient(180deg, #BD56A0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box
        padding: 1.5rem 2.5rem
        text-align: center
    &.left-item
      width: 33.33%
      .wrap
        padding: 3rem 2.5rem
    &.box-chart
      width: 66.66%

  @media (max-width: 767px)
    .item
      &-1,&-4
        width: 100%
      &-2
        width: 40%
      &-3
        width: 60%
      &-5,&-6
        width: 50%
      &.left-item
        width: 100%
      &.box-chart
        width: 100%
  @media (max-width: 575px)
    .item
      &-1,&-4
        width: 100%
      &-2,&-5
        width: 100%
      &-3,&-6
        width: 100%
.popup-default.logs-popup
  padding: 3rem 4rem!important
  width: 100%
  max-width: 108.9rem
  .heading
    font-size: 1.8rem
    text-transform: uppercase
    font-weight: 800
    color: #452A50
  .fancybox-close-small
    border-radius: 50%
    width: 2.5rem!important
    height: 2.5rem!important
    background: #BD55A0
    &:before
      width: 1rem
      height: 1rem
  .accordions.logs-accordion
    border: 1px solid #E1E1E1
    .accordion-item
      padding: 0
      box-shadow: none
      &:not(:last-child)
        margin-bottom: 0
        border-bottom: 1px solid #E1E1E1
      .accordion-heading
        padding: 1rem
        font-size: 1.5rem
        color: #000000
        font-weight: bold
        border-radius: 0
        box-shadow: none
        background: none
      .accordion-content
        display: none
        padding: 0
      &.active
        .accordion-heading
          background: #F5E4F0
  .table
    background: #F7F5F5
    width: 100%
    thead
      background: #E1E1E1
      th
        font-size: 1.5rem
        color: #282828
        text-transform: capitalize
        font-weight: bold
    th,td
      padding: 1rem 2.5rem
    .time,.date
      display: inline-block
    .time
      margin-left: 2rem
    @media (max-width: 767px)
      thead
        position: absolute
        left: -9999px
        overflow: hidden
        width: 0px
        height: 0px
      tr, td, th, thead, tbody, table
        display: block
      tbody
        tr
          border-top: 0
          &:not(:first-child)
            border-top: 1px solid #DDDDDD
        td::before
          display: inline-block
          width: 19rem
          flex: 0 0 19rem
          min-width: 19rem
          text-align: left
          color: #000
          font-weight: bold
          text-transform: capitalize
          content: attr(data-title)
.popup-default.gallery-popup
  padding: calc(3.8rem/2)!important
  width: 100%
  max-width: 108.9rem
  background: none!important
  .heading
    font-size: 1.8rem
    text-transform: uppercase
    font-weight: 800
    color: #452A50
    margin-bottom: 1.5rem
  .fancybox-close-small
    border-radius: 50%
    width: 3.8rem!important
    height: 3.8rem!important
    background: #BD55A0
    opacity: 1!important
    top: 0!important
    right: 0!important
    &:before
      width: 1.5rem
      height: 1.5rem
  .inner
    padding: 3rem 1.5rem 3rem 3rem
    background: #fff
    border-radius: .5rem
    .btn
      min-width: 17.5rem
      width: auto
  .wrapper
    max-height: 50rem
    overflow: hidden
    overflow-y: auto
    padding-right: 1.7rem
    &::-webkit-scrollbar
      width: .3rem
    &::-webkit-scrollbar-track
      background: #F4F2F2
      border-radius: 20px
    &::-webkit-scrollbar-thumb
      background: #707070
      border-radius: 20px
  .images
    display: flex
    flex-flow: wrap
    margin: 0 -1.5rem
    .image
      margin-top: 3rem
      padding: 0 1.5rem
      width: 33.33%
      @media (max-width: 575px)
        width: 50%
      input
        padding: 0
        height: initial
        width: initial
        margin-bottom: 0
        display: none
        cursor: pointer
      label
        display: block
      .img
        border-radius: .5rem
        +img(168/295)
        box-sizing: border-box
        border: .3rem solid transparent
        &::after
          content: ""
          position: absolute
          top: -1.5rem
          right: -1.5rem
          width: 3rem
          height: 3rem
          background-image: url(../img/common-icons/check.png)
          background-repeat: no-repeat
          background-position: center center
          background-size: contain
          opacity: 0
        img
          border-radius: .5rem
      .title
        font-size: 1.2rem
        color: #707070
        letter-spacing: .024rem
        margin-top: 1rem
        font-weight: normal
      input:checked + label
        .img
          border: .3rem solid #B05C9D
          &::after
            opacity: 1
//change-requests
.highlighted
  color: #707070
  padding-left: 6rem
  position: relative
  &:before
    content: ''
    position: absolute
    top: .8rem
    left: 0
    width: 5rem
    height: .5rem
  &.green
    &:before
      background: #27E284
#content-container
  .dashboard-head
    .dropdown.actions-dropdown
      min-width: 17.5rem
      .dropdown-select
        padding: 0 1rem 0 1.5rem
        height: 3.5rem
        border-radius: .5rem
        border: 1px solid #BD56A0
        background: #BD56A0
        .dropdown-selected
          color: #fff
        .dropdown-caret
          em
            color: #fff
      .dropdown-list
        .dropdown-item
          // color: #D33B27
          padding: 0rem 1.5rem
          line-height: 3.5rem
          text-align: left
          &.selected,&:hover
            color: #452A50
            background: #F5E4F0
      &.open
        .dropdown-select
          background: #BD56A0
          .dropdown-selected
            color: #fff
          .dropdown-caret
            em
              color: #fff
              &:before
                content: '\f143'
  .box-item-2
    padding: 15px
    background: #fff
    border-radius: .5rem
    box-shadow: 0px 5px 4px #272D3B05
    .items
      display: flex
      align-items: stretch
      flex-flow: wrap
      margin: -1rem
      .item
        width: 50%
        padding: 1rem
        @media (max-width: 767px)
          width: 100%
        .wrap
          display: block
          border-radius: .5rem
          box-shadow: 0px 5px 4px #272D3B05
          padding: 8rem 2.5rem 7rem
          background: #FCF4F9
        .icon
          width: 5rem
          height: 5rem
          border-radius: 50%
          background: #F5E4F0
          padding: 1.5rem
          margin-bottom: 1.5rem
          img
            width: 100%
            height: 100%
            object-fit: contain
        .title
          font-size: 2rem
          letter-spacing: .04rem
          color: #452A50
          text-transform: capitalize
          font-weight: 800
          margin-bottom: .5rem
        .desc
          color: #452A50
          font-size: 2rem
          letter-spacing: .04rem
  .head
    display: flex
    flex-flow: wrap
    margin: -1rem
    justify-content: space-between
    align-items: center
    margin-bottom: 1rem
    .heading
      padding: 1rem
      margin-bottom: 0
    .roles
      padding: 1rem
      display: flex
      align-items: center
      .txt
        font-size: 1.5rem
        text-transform: capitalize
        color: #707070
        margin-right: 1.5rem
        font-weight: 300
      .dropdown
        min-width: 35rem
        &-select
          border: 1px solid #E2E2E2
          background: #fff
          border-radius: .5rem

.popup-default.cr-popup
  border-radius: .5rem
  box-shadow: 0px 5px 4px #272D3B05
  width: 100%
  max-width: 60.4rem
  .inner
    padding: 5rem
    @media (max-width: 767px)
      padding: 15px
  .txt
    font-size: 2rem
    color: #000000
    text-align: center
    letter-spacing: .04rem
    font-weight: bold
  .image
    max-width: 20rem
    margin: 0 auto
    margin-bottom: 3rem
  .btn-wrap
    justify-content: center
    gap: 0 2rem
    .btn
      width: auto
      min-width: 17.5rem
.chart-tooltip
  box-shadow: none!important
  outline: none
  border: none
  font-size: 1.5rem

  color: #43425D
  font-weight: normal
  background: none
#chart
  @media (max-width: 767px)
    min-height: 30rem
.activated
  width: 1.8rem
  height: 1.8rem
  img
    width: 100%
    height: 100%
    object-fit: contain
.resend-email
  font-size: 1.2rem
  color: #BD56A0
  letter-spacing: .042rem
  font-weight: normal
.export-btn
  display: inline-flex
  align-items: stretch
  background: #452A50
  font-size: 1.5rem
  text-transform: uppercase
  font-weight: normal
  color: #fff
  cursor: pointer
  border-radius: .3rem
  overflow: hidden
  .icon
    width: 3.5rem
    height: 3.5rem
    background: #BD56A0
    padding: 1rem
    flex-shrink: 0
    img
      width: 100%
      height: 100%
      object-fit: contain
      filter: brightness(0) invert(1)
  .txt
    padding: 0 7rem
    line-height: 3.5rem
.mo-admin-donations-indiv
  ul
    list-style: none
    li
      margin-bottom: 1.5rem
      color: #282828
      font-weight: 300
#content-container
  .dashboard-mo
    .form-wrap
      input,.select-box,textarea
        background: #fff
        border: none
      select:invalid
        color: $dark
    .box
      .form-wrap
        input,.select-box,textarea
          border: 1px solid #f4f2f2
          background: none
    textarea
      height: 20rem
  .table-responsive
    .tbl-wrap
      padding-bottom: 0
      .table
        tbody
          tr
            &:last-child
              border: none
      @media (max-width: 400px)
        overflow: hidden
        overflow-x: auto
        .table
          min-width: 400px
        &::-webkit-scrollbar
          height: .6rem
        &::-webkit-scrollbar-track
          background: #DDDDDD
        &::-webkit-scrollbar-thumb
          background: #B7B7B7
  .box-item-2.caas
    background: none
    padding: 0
    margin-top: 4rem
    overflow: hidden
    .items
      margin: -2.25rem
      .item
        padding: 2.25rem
        .wrap
          background: #D7DAE2
          padding: 12rem 3rem 3rem
          .icon
            background: #fff
          .title
            color: #452A50
          .desc
            color: #0A0A0A
  .table-responsive
    position: relative
    &.overlay
      &:after
        content: ''
        width: 30rem
        height: 100%
        background: transparent linear-gradient(180deg, #FFFFFF 0%, #FCFCFC00 100%) 0% 0% no-repeat padding-box
        position: absolute
        pointer-events: none
        top: 0
        right: 0
        z-index: 99
        @media (max-width: 991px)
          width: 20rem
        @media (max-width: 767px)
          width: 10rem
      &.left
        &:after
          left: 0
          right: unset
.cl-popup
  border-radius: .5rem
  box-shadow: 0px 5px 4px #272D3B05
  width: 100%
  .fancybox-close-small
    display: none!important
  .inner
    padding: 3rem 1rem 3rem 3rem
  .head
    display: flex
    align-items: center
    justify-content: space-between
    flex-flow: wrap
    margin: -1rem
    .popup-title
      padding: 1rem
    .sort
      padding: 1rem
      display: inline-flex
      align-items: center
      .txt
        font-size: 1.5rem
        color: #707070
        text-transform: capitalize
        margin-right: 1.5rem
        font-weight: 300
      @media (max-width: 575px)
        display: flex
        width: 100%
  .tab-content
    display: none
  .tabs-nav
    list-style: none
    display: flex
    align-items: center
    border-bottom: 1px solid #F4F2F2
    margin-bottom: 1.5rem
    li
      cursor: pointer
      color: #707070
      text-transform: capitalize
      padding-bottom: 1rem
      border-bottom: 1px solid transparent
      &:hover,&.active
        color: $purple-45
        border-bottom: 1px solid #452A50
      &:not(:last-child)
        margin-right: 2rem
  .table-responsive

    &.overlay
      position: relative
      &:after
        content: ''
        width: 10rem
        height: 100%
        background: transparent linear-gradient(180deg, #FFFFFF 0%, #FCFCFC00 100%) 0% 0%
        position: absolute
        top: 0
        pointer-events: none
        right: 0
        z-index: 99
      &.left
        &:after
          left: 0
          right: unset
    .tbl-wrap
      overflow: hidden
      overflow-x: auto
      overflow-y: auto
      height: 48rem
      @media (max-width: 767px)
        padding-bottom: 2rem
      &::-webkit-scrollbar
        height: .6rem
        width: .5rem
      &::-webkit-scrollbar-track:vertical
        border-radius: 20px
      &::-webkit-scrollbar-track
        background: #DDDDDD
      &::-webkit-scrollbar-thumb
        background: #B7B7B7
      &::-webkit-scrollbar-thumb:vertical
        background: #707070
        border-radius: 20px
    .table
      margin-right: 2rem
      width: 100%
      @media (max-width: 767px)
        min-width: 767px
      thead
        border-bottom: 1px solid #F4F2F2
        th
          padding: 1.5rem
          color: #000000
          text-transform: capitalize
          font-weight: bold
          padding-top: 0
          &:first-child
            padding-left: 4rem
          &:last-child
            padding-right: 0
      tr
        border-bottom: 1px solid #F4F2F2
        td
          color: #000000
          padding: 1.5rem
          &:first-child
            padding-left: 0
          &:last-child
            padding-right: 0
      .pin
        padding-left: 4rem
        position: relative
        &::before
          content: ''
          position: absolute
          top: 50%
          left: 1rem
          width: 1.2rem
          height: 2.5rem
          transform: translateY(-50%)
          background-size: contain
          background-repeat: no-repeat
          background-position: center
        &.red
          &::before
            background-image: url(../img/common-icons/pin-red.png)
        &.dark
          &::before
            background-image: url(../img/common-icons/pin-dark.png)

  .popup-btn
    display: flex
    justify-content: flex-end
    align-items: center
    .btn:nth-child(1)
      margin-right: 2rem
    @media (max-width: 575px)
      justify-content: space-between
.submit-for-review
  @media (max-width: 767px)
    margin-left: unset!important
