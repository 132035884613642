#loader-container
  position: fixed
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 99999
  transition: all 0.3s ease-in-out
  background-color: #fff
  .loader
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 100%
    max-width: 25rem
    &__logo
      width: 3.5rem
      height: 3.5rem
      border-radius: 50%
      position: relative
      margin: 0 auto
      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: inherit
        border: .3rem solid #ebebeb
        border-right-color: $purple
        animation: circleLoading 1s forwards infinite linear
    &__progress
      display: none
      position: relative
      width: 100%
      height: .2rem
      margin-top: 1rem
      border-radius: 1rem
      #progress-percent
        position: absolute
        right: 0
        top: calc( 100% + .5rem )
        font-size: 1rem
        color: #622644
      #progress-bar
        height: 100%
        max-width: 100%
        background: #E0BCCE
        width: 0%
        height: 100%
        position: relative

@keyframes circleLoading
  0%
  100%
    transform: rotate(360deg)
