
.hero-banner
  position: relative
  .slider
    position: relative
    .swiper-slide
      position: relative
      display: flex
      align-items: center
      &:after
        content: ''
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: rgba(#F5E4F0,.21)
      .content
        padding: 0 15px
        position: relative
        z-index: 1
        text-align: center
        max-width: 706px
        margin: 0 auto
        @media (max-width: 991px)
          max-width: 100%
        @media (max-width: 767px)
          text-align: center
        .heading
          color: #fff
          text-transform: none
          font-weight: 900
          opacity: 0
          @extend .ff-2
          letter-spacing: .4rem
          font-size: 4rem
          transform: translate3d(0, 50px, 0)
          transition: .2s ease .4s
        .desc
          color: #fff
          font-size: 2.2rem
          opacity: 0
          transform: translate3d(50px, 0px, 0)
          transition: .2s ease .4s
        .btn-wrap
          opacity: 0
          transform: translate3d(50px, 0px, 0)
          transition: .2s ease .4s
      &-active
        .content
          .heading
            opacity: 1
            transform: translate3d(0, 0, 0)
            transition: .4s ease 1s
          .desc
            opacity: 1
            transform: translate3d(0, 0, 0)
            transition: .4s ease 1.2s
          .btn-wrap
            opacity: 1
            transform: translate3d(0, 0, 0)
            transition: .4s ease 1.4s
    .swiper-pagination
      left: unset!important
      right: 3.5rem
      bottom: unset
      top: 50%
      flex-direction: column
      transform: translateY(-50%)
      span
        margin: 10px 0
