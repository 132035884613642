.header
  position: fixed
  z-index: 99
  top: 0
  left: 0
  width: 100%
  transition: all .3s ease-in-out
  background: #fff
  box-shadow: 0px 8px 24px #0000000D
  .logo
    width: 9.8rem
    flex-shrink: 0
    img
      width: 100%
      height: auto
  .right-box
    width: 100%
    flex: 1
    padding-top: 1.5rem
  .account
    margin-left: auto
    font-size: 1.5rem
    text-transform: uppercase
    font-weight: bold
    color: $purple
    &-toggle
    &-name
      display: none
  .cart
    margin-left: 4rem
    &-toggle
      height: 3.2rem
      cursor: pointer
      position: relative
      img
        height: 100%
        width: auto
      .count
        position: absolute
        top: -1rem
        right: -1rem
        width: 2rem
        height: 2rem
        border-radius: 50%
        background: $purple
        color: #fff
        font-size: 12px
        display: flex
        align-items: center
        justify-content: center
  .top-wrap
    display: flex
    justify-content: flex-end
    align-items: center
    font-size: 1.2rem
    letter-spacing: .024rem
    text-transform: capitalize
    color: #707070
    margin: 0 -1.2rem
    > div
      padding: 0 1.2rem
    .main-site-link
      color: $purple
      border-left: 1px solid #707070
    @media (max-width: 1199.98px)
      justify-content: flex-start
  .header-wrapper
    display: flex
    align-items: center
    .right-box
      padding-left: 3.8rem
    .bottom-wrap
      display: flex
      align-items: center
      height: 6.8rem
    .menu
      &__list
        list-style: none
        display: flex
        align-items: center
        margin-bottom: 0
      &__link
        text-transform: uppercase
        color: #282828
        transition: all .2s
        display: block
        line-height: 1.4
        position: relative
        &:after
          content: ""
          position: absolute
          bottom: -.5rem
          left: 0
          height: 2px
          opacity: 0
          transition: all .2s
          width: 100%
          background: #007885
      .icon-dropdown
        color: $dark
        margin-left: 1rem
        width: 1.5rem
        flex-shrink: 0
        img
          width: 100%
          height: auto
          transition: all .2s
      &__item
        line-height: 6.8rem
        height: 6.8rem
        padding: 0 1.5rem
        display: inline-flex
        align-items: center
        &:hover
          .menu__link
            color: #007885
            text-stroke: 1px currentColor
            -webkit-text-stroke: 1px currentColor
            &:after
              opacity: 1
              transition: all .2s
        @media (min-width: 1200px)
          &.has-dropdown
            position: relative
            display: flex
            align-items: center
            .nav-sub
              position: absolute
              list-style: none
              top: calc( 100% + 2rem)
              transition: all .3s ease-in-out
              left: 0
              opacity: 0
              visibility: hidden
              background: #fff
              width: 20.3rem
              display: block
              z-index: 9
              box-shadow: 0px 8px 24px #0000000D
              li
                line-height: 1.4
                padding: 1rem 1.5rem
                text-align: center
                a
                  text-transform: capitalize
                  color: $dark
                  font-size: 1.5rem
                  white-space: nowrap
                &:hover,&.active
                  background: #F7F5F4
                  a
                    color: $dark
          &:hover
            .icon-dropdown
              img
                transform: scaleY(-1)
                transition: all .2s
            .nav-sub
              opacity: 1
              visibility: visible
              top: 100%
  .toggle-btn
    display: none
  .mobile-nav-inner
    width: 100%
    left: 0
    position: absolute
    top: 100%
    background: #fff
    height: calc(100vh - 9rem)
    overflow: hidden
    z-index: 99
    opacity: 0
    visibility: hidden
    transition: all .4s ease-in-out
    overflow-y: auto
    .menu
      ul
        list-style: none
        li
          transition: transform 0.5s ease-in-out
          &.has-dropdown
            position: relative
            > a
              display: flex
              align-items: center
              justify-content: space-between
            .icon-dropdown
              display: block
              position: absolute
              top: 10px
              right: 15px
              z-index: 9
              width: 30px
              height: 30px
              color: #020C22
              display: flex
              align-items: center
              justify-content: center
              border-radius: .2rem
              img
                width: 15px
                height: auto
              &.active
                img
                  transform: scaleY(-1)
            .nav-sub
              width: 100%
              display: none
              li
                line-height: 1.4
                margin-bottom: 2rem
                a
                  text-transform: capitalize
                &:hover,&.active
                  a
                    color: $green
@media (max-width: 1199.98px)
  .header
    .right-box
      padding: 1.5rem 0
    .top-wrap
      padding: 1.5rem 20px
    .header-wrapper
      .account
        margin-left: auto
      .toggle-btn
        display: block
      .menu
        display: none
  .mobile-nav-inner

    .search
      .search-toggle
        display: none
      .searchbox
        display: block!important
        padding: 20px
        border-bottom: 1px solid rgba($dark,.1)
        input
          width: 100%
          padding: 0 20px
        ::-webkit-input-placeholder
          color: #999
          text-transform: capitalize
    .menu
      display: block
      margin-right: 0
      &__list
        display: flex
        flex-direction: column
        align-items: flex-start
      &__item
        margin-right: 0
        padding: 0 20px
        line-height: 50px
        border-top: 1px solid rgba($dark,.1)
        width: 100%
        padding-right: 50px
        a
          text-transform: uppercase
          color: $dark
          font-size: 1.5rem
        &.active
          .menu__link
            color: $green
            font-weight: bold
    &.active
      opacity: 1
      visibility: visible
      transition: all .4s ease-in-out
.toggle-btn
  margin-left: 3.5rem
  span
    width: 22px
    height: 2px
    background-color: #007885
    border-radius: 2px
    display: block
    margin: 4px auto
    transition: all 0.3s ease-in-out
    text-align: right
    &:nth-child(3)
      width: 22px
      margin-right: 0
  &:hover
    cursor: pointer
  &.active
    span
      &:nth-child(2)
        opacity: 0
      &:nth-child(1)
        width: 22px
        transform: translateY(6px) rotate(45deg)
      &:nth-child(3)
        width: 22px
        transform: translateY(-6px) rotate(-45deg)
.back-drop
  position: fixed
  width: 100%
  height: 100vh
  top: 0
  left: 0
  background: rgba(0, 0, 0, .7)
  opacity: 0
  z-index: 9
  visibility: hidden
  &.active
    visibility: visible
    opacity: 1
    transition: all .3s
@media (min-width: 1200px)
  .search
    position: relative
    margin-left: 3.5rem
    .search-toggle
      width: 3.2rem
      height: 3.2rem
      cursor: pointer
      position: relative
      z-index: 2
      &:after
        content: ""
        width: 100%
        height: 100%
        position: absolute
        top: 0
        left: 0
        background-image: url(../img/icons/Nav_Search.png)
        background-position: center
        background-size: contain
        background-repeat: no-repeat
      &.active
        &:after
          background-image: url(../img/icons/Cart_Delete.png)
          background-position: center
          background-size: contain
          background-repeat: no-repeat
    .searchbox
      position: absolute
      right: 0
      z-index: 1
      top: calc( 100% + 1.8rem)
      display: flex
      align-items: center
      width: 30rem
      display: none
      input
        width: 100%
        flex: 1
        background: #ebebeb
        line-height: 4.5rem
        height: 4.5rem
        padding: 0 2rem
        border-radius: none
        font-size: 1.6rem
        border: none
      ::-webkit-input-placeholder
        color: $dark
        text-transform: capitalize
.page-notice
  background: $green
  text-align: center
  padding: 1rem 0
  color: #fff
