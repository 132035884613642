.member-organisation-indiv
  .banner
    img
      width: 100%
      height: auto
  .top-content
    margin-top: -15rem
    position: relative
    z-index: 1
    border-radius: .5rem
    box-shadow: 0px .3rem 1.2rem #0000000F
    background: #F7F5F4
    background-image: url(../img/campaigns/CHARIS_CrossElement.png)
    background-repeat: no-repeat
    background-position: 100% 0
    background-size: 15% auto
    padding: 3.5rem 6rem
    @media (max-width: 767px)
      padding: 3rem 20px
      margin-top: -5rem
    .inner
      display: flex
      flex-flow: wrap
      justify-content: space-between
      .box-img
        width: calc(30% - 2.5rem)
        img
          width: 100%
          height: auto
      .box-content
        width: calc( 70% - 2.5rem )
      @media (max-width: 575px)
        .box-img,.box-content
          width: 100%
        .box-content
          padding-top: 3rem
      .main-title
        margin-bottom: 3rem
      .list
        li
          display: flex
          align-items: center
          .content
            margin-left: .5rem
          .title
            color: #9F9F9F
            text-transform: capitalize
          .imgs
            display: flex
            .img
              flex-shrink: 0
              height: 2rem
              img
                height: 100%
                width: auto
              &:not(:last-child)
                margin-right: 1.5rem
          &:not(:last-child)
            margin-bottom: 2rem
.member-organisation-indiv-2
  padding: 5rem 0 10rem
  background: linear-gradient(180deg, rgba(0,0,0,0.0032387955182072714) 20%, rgba(245,228,240,1) 20%)
  .my-tabs
    box-shadow: 0 .3rem .6rem #00000029
    border: 1px solid #F4F4F4
    .tabs-nav-wrap
      .tabs-nav
        list-style: none
        display: flex
        align-items: center
        flex-flow: wrap
        // border: 1px solid #E1E1E1
        // @media (max-width: 767px)
        //   border: none
        //   border-top: 1px solid #E1E1E1
        //   border-left: 1px solid #E1E1E1
        li
          width: 25%
          font-size: 1.8rem
          text-transform: capitalize
          color: #9f9f9f
          text-align: center
          height: 7.5rem
          display: inline-flex
          background: #fff
          justify-content: center
          align-items: center
          transition: all .2s
          cursor: pointer
          border-bottom: 1px solid #e1e1e1
          &:not(:last-child)
            border-right: 1px solid #E1E1E1
          &:hover,&.active
            color: #fff
            background: #452A50
            border-color: #452A50
            transition: all .2s
          @media (max-width: 767px)
            width: 50%
            border-bottom: 1px solid #E1E1E1
            border-right: 1px solid #E1E1E1

    .tab-content
      display: none
      padding: 4rem 5rem 5rem
      background: #fff
      p
        &:not(:last-child)
          margin-bottom: 3rem
      .programmes-slider
        &:not(:last-child)
          margin-bottom: 3rem
      .slick-container
        .item
          display: flex
          flex-flow: wrap
          justify-content: space-between
          .box-img
            width: calc(40% - 1.5rem)
          .box-content
            width: calc(60% - 1.5rem )
            .title
              color: #452A50
              text-decoration: underline
              margin-bottom: 2rem
              font-weight: bold
          @media (max-width: 575px)
            .box-img,.box-content
              width: 100%
            .box-content
              padding-top: 3rem
      .contact-us
        select:invalid
          color: $dark
          font-weight: 400
        .heading
          font-size: 1.8rem
          margin-bottom: 2rem
          text-transform: uppercase
      .info
        ul
          list-style: none
        li
          .title
            text-transform: capitalize
            color: #707070
            font-weight: bold
          .name
            margin-bottom: 0!important
          a
            display: block
            color: $purple
          &:not(:last-child)
            margin-bottom: 2rem
        @media (min-width: 768px)
          padding-left: 8rem
        @media (max-width: 767px)
          padding-top: 5rem
.member-organisation-indiv-3
  .banner
    border-radius: .5rem
    overflow: hidden
    position: relative
    padding: 5rem
    @media (max-width: 767px)
      padding: 5rem 20px
    .title
      text-transform: capitalize
      color: #fff
      font-weight: bold
      @extend .ff-2
      line-height: 1.2
    .desc
      color: #fff
      max-width: 70rem
      margin-top: 2rem
.cbpm,.abpm
  .main-title
    margin-bottom: 2.5rem
  .slick-container
    // position: relative
  .tab-content
    display: none
    position: relative
  .slick-container
    margin: -1rem 0
  .slick-list
    margin: 0 -2rem
    padding: 1rem 0
    .slick-slide
      padding: 0 2rem
    @media (max-width: 767px)
      margin: 0 -1.5rem
      .slick-slide
        padding: 0 1.5rem
  .slick-disabled
    opacity: .4
  .tabs-nav
    list-style: none
    display: flex
    align-items: center
    width: fit-content
    margin: 0 auto
    margin-bottom: 4.7rem
    li
      text-transform: capitalize
      color: #707070
      cursor: pointer
      transition: all .2s
      border-bottom: 1px solid transparent
      &:not(:last-child)
        margin-right: 3rem
      &.active
        border-bottom: 1px solid $green
        color: $green
        transition: all .2s
