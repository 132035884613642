.campaigns-indiv
	.top-content
		margin-top: -15rem
		position: relative
		z-index: 1
		text-align: center
		border-radius: .5rem
		box-shadow: 0px .3rem 1.2rem #0000000F
		background: #F7F5F4
		background-image: url(../img/campaigns/CHARIS_CrossElement.png)
		background-repeat: no-repeat
		background-position: 100% 0
		background-size: 15% auto
		padding: 3rem
		@media (max-width: 767px)
			padding: 3rem 20px
			margin-top: -5rem
		.inner
			max-width: 66.2rem
			margin: 0 auto
		.main-title
			@extend .ff-2
			margin-top: 1.4rem
			margin-bottom: 0
		.desc
			margin-top: 2rem
		.progress-wrapper
			padding-top: 2.8rem
			margin-top: auto
			.progress-txt
				text-align: left
				color: $purple
				font-weight: bold
				margin-bottom: 1rem
			.bottom
				display: flex
				align-items: center
				justify-content: space-between
				margin-top: 1rem
				font-weight: bold
			.progress
				background-color: #E1E1E1
				border-radius: 20px
				position: relative
				height: .5rem
				width: 100%
				&-done
					background: $purple
					border-radius: 20px
					color: #fff
					display: flex
					align-items: center
					justify-content: center
					height: 100%
					width: 0
					opacity: 0
					transition: 1s ease 0.3s
		.list
			padding-top: 1.5rem
			list-style: none
			display: flex
			justify-content: space-around
			flex-flow: wrap
			margin: -1rem
			li
				padding: 1rem
				display: flex
				align-items: center
				.content
					margin-left: .5rem
				.title
					color: #9F9F9F
					text-transform: capitalize
				.imgs
					display: flex
					.img
						flex-shrink: 0
						height: 2rem
						img
							height: 100%
							width: auto
						&:not(:last-child)
							margin-right: 1.5rem
		.social-share
			margin-top: 3rem
			display: flex
			justify-content: center
			.txt
				margin-right: 1.5rem
.social-list
	display: flex
	list-style: none
	li
		flex-shrink: 0
		a
			display: inline-block
			width: 2.5rem
			height: 2.5rem
			img
				width: 100%
				object-fit: contain
				height: 100%
		&:not(:last-child)
			margin-right: 1.5rem
.campaigns-indiv-2
	padding: 10rem 0 7.5rem
	.slider
		// padding-bottom: 2.5rem
		position: relative
	.swiper-pagination
		left: 0!important
		transform: none
		bottom: -2.5rem
	.swiper-slide
		box-sizing: border-box
	.inner
		display: flex
		flex-flow: wrap
		align-items: flex-start
		justify-content: space-between
		.box-left
			width: calc(calc( 655/1166 *100%) -1rem)
			border-radius: .5rem
		.box-right
			width: calc(calc( 100% - 655/1166 *100%) -1rem)
			border-radius: .5rem
		@media (max-width: 767px)
			.box-left,.box-right
				width: 100%
			.box-right
				margin-top: 3rem
		.box-right
			box-shadow: inset 0 0 0 1px #E1E1E1
			.top-wrap
				padding: 3rem
		.box-left
			.video-box
				display: block
				position: relative
				.image
					+img(360/645)
					img
						border-radius: .5rem
				&:after
					position: absolute
					content: ''
					background: rgba(#000000,.2)
					inset: 0
					z-index: 1
				.play
					position: absolute
					top: 50%
					left: 50%
					width: 6rem
					height: 6rem
					z-index: 9
					transform: translate(-50%,-50%)
					img
						width: 100%
						height: 100%
						object-fit: contain
	.box-right
		.donate-amount
			list-style: none
			display: flex
			flex-flow: wrap
			margin: -1rem
			li
				padding: 1rem
				width: 33.33%
				a
					display: block
					text-align: center
					font-size: 1.5rem
					letter-spacing: .03rem
					height: 3.5rem
					line-height: 3.5rem
					box-sizing: border-box
					padding: 0 2.5rem
					transition: all .2s
					border-radius: .3rem
					border: 1px solid #E1E1E1
					color: #9F9F9F
					background: #F5F5F5
				&:hover,&.active
					a
						color: #fff
						transition: all .2s
						border: 1px solid $green
						background: $green
			.other-amount
				width: 66.66%
			@media (max-width: 575px)
				li
					width: 50%
				.other-amount
					width: 100%
		.learn-more
			display: block
			width: fit-content
			text-decoration: underline
			margin: 0 auto
			margin-top: 2rem
			transition: all .2s
			color: $dark
			font-weight: bold
			text-transform: uppercase
			&:hover
				letter-spacing: .1rem
				transition: all .2s
		.bottom-wrap
			padding: 3rem
			border-top: 1px dashed #0000001B
		.text
			line-height: 1.6
		.btn-wrap
			display: flex
			flex-flow: wrap
			justify-content: space-between
			.btn
				white-space: nowrap
				margin-right: 0!important
				width: calc(50% -1rem )
			@media (max-width: 575px)
				flex-direction: column
				.btn
					width: 100%
		.options
			display: flex
			border-bottom: 1px solid #0000001B
		.option
			width: 50%
			cursor: pointer
			label
				font-size: 1.5rem
				color: #9F9F9F
				background: #fff
				text-transform: capitalize
				text-align: center
				width: 100%
				cursor: pointer
				padding: 2rem
				font-weight: 600
			input
				padding: 0
				height: initial
				width: initial
				margin-bottom: 0
				display: none
				cursor: pointer
			input:checked + label
				color: $purple
				background: #F5E5F0
.indiv-articles
	padding-bottom: 10rem
	.article
		.image
			position: relative
			&:after
				content: ''
				position: absolute
				inset: 0
				background: rgba(#F7F5F4,.4)
				transition: all .3s ease-in-out
			img
				width: 100%
				height: auto
		&.active
			.image
				&:after
					opacity: 0
					transition: all .3s ease-in-out
	.content-wrapper
		position: relative
		z-index: 2
		.bar
			display: flex
			align-items: stretch
			color: #fff
			margin-top: -4rem
			max-width: 82rem
			.left
				display: flex
				align-items: center
				justify-content: center
				padding: 0 20px
				width: 100%
				height: 8rem
				background: #452A50
			.heading
				font-size: 2.4rem
				color: #fff
				@extend .ff-2
				text-transform: uppercase
				font-weight: 800
				margin-right: 4rem
			.location
				font-weight: bold
			@media (max-width: 575px)
				flex-direction: column
				.left
					width: 100%
		.less-more-btn
			background: $green
			cursor: pointer
			width: fit-content
			flex-shrink: 0
			min-width: 21rem
			text-transform: uppercase
			display: inline-flex
			color: #fff
			height: 8rem
			align-items: center
			padding: 0 4rem 0 3rem
			.icon
				width: 2rem
				margin-right: 3rem
				img
					width: 100%
					height: auto
					transition: all .2s
					filter: brightness(0) invert(1)
				&.up
					img
						transform: scaleY(-1)
						transition: all .2s
		.more-content
			padding-top: 4rem
			display: none
			.top-content
				padding: 0 4rem
				padding-bottom: 4rem
				.inner
					background: #F8EBF4
					padding: 3rem 2rem
					border-radius: .5rem
					margin: 0 auto
				.wrap
					display: flex
					flex-flow: wrap
					justify-content: space-between
					.box-img
						width: calc( 30% - 1rem )
					.box-content
						width: calc( 70% - 1rem )
						.title
							color: $dark
							text-decoration: underline
							margin-bottom: 3rem
							font-weight: bold
					@media (max-width: 575px)
						.box-img,.box-content
							width: 100%
						.box-content
							padding-top: 3rem
			.content
				p
					&:not(:last-child)
						margin-bottom: 2rem
	.article
		&:not(:last-child)
			margin-bottom: 5rem
.people-also-viewed
	.slider
		position: relative
	.swiper-slide
		box-sizing: border-box
	.inner
		border-top: 1px solid #E1E1E1
		padding: 5rem 0 10rem
	.swiper-next,.swiper-prev
		@media (max-width: 1199.98px)
			display: none
.tag
	font-size: 1.3rem
	text-transform: capitalize
	letter-spacing: .026rem
	color: #fff
	line-height: 2.5rem
	padding: 0 1rem
	border-radius: .3rem
	display: inline-block
	&-blue
		background: $blue
	&-green
		background: #33CC85
.donation-closed
	.inner
		align-items: stretch
	.box-right
		padding: 20px
		display: flex
		flex-direction: column
		justify-content: center
		align-items: center
