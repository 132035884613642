.page-policy
  padding: 8rem 0 10rem
  .heading
    font-size: 1.8rem
    color: #452A50
    margin-bottom: 2rem
    font-weight: bold
  ul
    list-style: none
    li
      &:not(:last-child)
        margin-bottom: 2rem
  .bullet
