.page-search
  .banner
    box-shadow: 0px 3px 12px #0000000F
    opacity: 0.75
    img
      width: 100%
      height: auto
  .top-content
    margin-top: -15rem
    position: relative
    z-index: 1
    text-align: center
    border-radius: .5rem
    box-shadow: 0px .3rem 1.2rem #0000000F
    background: #F7F5F4
    background-image: url(../img/campaigns/CHARIS_CrossElement.png)
    background-repeat: no-repeat
    background-position: 100% 0
    background-size: 15% auto
    padding: 6.5rem 3rem
    @media (max-width: 767px)
      padding: 3rem 20px
      margin-top: -5rem
    .inner
      .main-title
        margin-bottom: 3rem
  .content-result
    padding-top: 5rem
    .result
      color: #9F9F9F
      text-align: center
      margin-bottom: 3rem
      .count
        position: relative
        padding-left: 2rem
        margin-left: 2rem
        &:after
          content: ''
          position: absolute
          top: 50%
          transform: translateY(-50%)
          left: 0
          width: 7px
          display: block
          height: 7px
          border-radius: 50%
          background: #9F9F9F
    .items
      margin-bottom: -40px
      .campaigns-card,.activities-card
        margin-bottom: 40px
        height: auto
  .activities
    position: relative
    &:after
      content: ''
      position: absolute
      top: 0
      left: 50%
      height: 1px
      width: 100%
      background: #E1E1E1
      transform: translateX(-50%)
      max-width: 100rem

.form-search
  display: flex
  max-width: 65rem
  margin: 0 auto
  margin-top: 4rem
  align-items: stretch
  border: 1px solid #E1E1E1
  button
    height: 3.5rem
    width: 3.5rem
    img
      width: 100%
      height: 100%
      object-fit: cover
  input
    height: 3.5rem
    background: #fff
  ::-webkit-input-placeholder
    color: #E1E1E1
    text-transform: capitalize
.interested-in
  padding: 5rem 0 10rem
  .slider
    position: relative
  .swiper-slide
    box-sizing: border-box
.activities-card
  box-shadow: 0px 3px 12px #0000000F
  background: #fff
  .top-wrap
    position: relative
    .card-tags
      position: absolute
      top: 2rem
      left: 2.5rem
      z-index: 9
      display: flex
      align-items: center
      .tag
        font-size: 1.3rem
        text-transform: capitalize
        letter-spacing: .026rem
        color: #fff
        line-height: 2.5rem
        padding: 0 1rem
        border-radius: .3rem
        display: inline-block
        &:not(:last-child)
          margin-right: 1rem
        &-blue
          background: $blue
        &-green
          background: #33CC85
        &-red-brounded
          background: #fff
          border: 1px solid #CC3333
          color: #CC3333
  .box-img
    display: block
    +img(202/364)
    border-radius: .5rem
    overflow: hidden
    img
      transition: all .4s ease
  .caption
    padding: 3rem 2.5rem
    .title
      a
        display: block
        text-transform: uppercase
        font-size: 1.8rem
        font-weight: bold
        color: $dark
        letter-spacing: .054rem
    .desc
      margin-top: 1.5rem
      a
        display: inline
        margin-left: .5rem
        color: $purple
        text-decoration: underline
    .slots
      font-size: 1.3rem
      text-transform: capitalize
      letter-spacing: .026rem
      line-height: 2.5rem
      padding: 0 1rem
      border-radius: .3rem
      display: inline-block
      background: #fff
      border: 1px solid #452A50
      color: #452A50
      margin-top: 1.5rem
    .list
      list-style: none
      margin-top: 1.7rem
      margin-left: 0
      margin-right: 0
      li
        display: flex
        .icon
          display: block
          width: 2.5rem
          height: 2.5rem
          flex-shrink: 0
          img
            width: 100%
            height: 100%
            object-fit: contain
        .text
          flex: 1
          width: 100%
          word-break: break-word
          padding-left: 1.5rem
        &:not(:last-child)
          margin-bottom: 1.5rem
    .skillset
      color: #9F9F9F
      margin-top: 1.5rem
      font-style: italic
    .btn
      width: 100%
  &:hover
    .box-img
      img
        transition: all .4s ease
        transform: scale(1.15)
