.color-white {
  color: #ffffff !important; }

.color-black {
  color: #000 !important; }

.color-blue {
  color: #0078BF !important; }

.color-red {
  color: #CA3625 !important; }

.color-orange {
  color: #FF7F40 !important; }

.color-pink {
  color: #E9C4C6 !important; }

.color-orange-fe {
  color: #FEBE85 !important; }

.color-yellow {
  color: #F6DFA4 !important; }

.color-dark {
  color: #282828 !important; }

.color-purple {
  color: #BD55A0 !important; }

.color-green {
  color: #007885 !important; }

html, body {
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: .03rem;
  color: #282828;
  font-size: 1.5rem; }

.fz-60 {
  font-size: 30px; }
  @media (min-width: 375px) {
    .fz-60 {
      font-size: calc(3.02725vw + 18.64783px); } }
  @media (min-width: 1366px) {
    .fz-60 {
      font-size: 60px; } }
  @media (min-width: 1366px) {
    .fz-60 {
      font-size: 60px; } }

.fz-40 {
  font-size: 15px; }
  @media (min-width: 768px) {
    .fz-40 {
      font-size: calc(4.1806vw - 17.10702px); } }
  @media (min-width: 1366px) {
    .fz-40 {
      font-size: 40px; } }
  @media (min-width: 1366px) {
    .fz-40 {
      font-size: 40px; } }

.fz-30 {
  font-size: 15px; }
  @media (min-width: 375px) {
    .fz-30 {
      font-size: calc(1.51362vw + 9.32392px); } }
  @media (min-width: 1366px) {
    .fz-30 {
      font-size: 30px; } }
  @media (min-width: 1366px) {
    .fz-30 {
      font-size: 30px; } }

.fz-42 {
  font-size: 22px; }
  @media (min-width: 768px) {
    .fz-42 {
      font-size: calc(3.34448vw - 3.68562px); } }
  @media (min-width: 1366px) {
    .fz-42 {
      font-size: 42px; } }
  @media (min-width: 1366px) {
    .fz-42 {
      font-size: 42px; } }

.fz-50 {
  font-size: 30px; }
  @media (min-width: 375px) {
    .fz-50 {
      font-size: calc(2.01816vw + 22.43189px); } }
  @media (min-width: 1366px) {
    .fz-50 {
      font-size: 50px; } }
  @media (min-width: 1366px) {
    .fz-50 {
      font-size: 50px; } }

.fz-100 {
  font-size: 50px; }
  @media (min-width: 375px) {
    .fz-100 {
      font-size: calc(5.04541vw + 31.07972px); } }
  @media (min-width: 1366px) {
    .fz-100 {
      font-size: 100px; } }
  @media (min-width: 1366px) {
    .fz-100 {
      font-size: 100px; } }

.ff-2, .heading-24, .heading-20, .main-title, .main-banner .title, .activities .sidebar .cate-txt, .dashboard-banner .heading, .activities-indiv .top-content .main-title, .banner-subpage .heading, .haf-banner .title, .campaigns .sidebar .cate-txt, .campaigns-indiv .top-content .main-title, .indiv-articles .content-wrapper .bar .heading, .higthlights .slider .big-title, .member-organisation-indiv-3 .banner .title, .member-organisation .sidebar .cate-txt, #content-container .heading, #content-container .accordions .accordion-item .accordion-heading .txt, .box-item .item .title, .page-thank-you .top-content .inner .ref-no, .email-page .email-banner .title, .countup-module .list-item .item .number, .donate-banner .title, .blog-card .caption .title a, .hero-banner .slider .swiper-slide .content .heading {
  font-family: 'Montserrat', sans-serif; }

h1, h2, h3 {
  line-height: calc( 50 /40); }

.heading-24 {
  font-size: 2.4rem;
  letter-spacing: .24rem;
  color: #424242;
  text-transform: uppercase;
  font-weight: bold; }

.heading-20 {
  font-size: 2rem;
  letter-spacing: .04rem;
  color: #452A50;
  text-transform: uppercase;
  font-weight: bold; }

.fz-18 {
  font-size: 1.8rem; }

.fz-20 {
  font-size: 2rem; }

.fz-24 {
  font-size: 2.4rem; }

.fa-bars:before {
  content: '\f0c9'; }

.fa-user:before {
  content: '\f007'; }

.fa-shopping-cart:before {
  content: '\f07a'; }

.fa-facebook-f:before {
  content: '\f39e'; }

.fa-youtube:before {
  content: '\f167'; }

.fa-twitter:before {
  content: '\f099'; }

.fa-google-plus-g:before {
  content: '\f0d5'; }

.fa-instagram:before {
  content: '\f16d'; }

.fa-home:before {
  content: '\f015'; }

.fa-envelope:before {
  content: '\f0e0'; }

.fa-clock:before {
  content: '\f017'; }

.fa-phone-volume:before {
  content: '\f2a0'; }

.fa-quote-left:before {
  content: '\f10e'; }

.fa-quote-right:before {
  content: '\f10d'; }

.fa-key:before {
  content: "\f084"; }

.fa-folder-open:before {
  content: "\f07c"; }

.fa-cog:before {
  content: "\f013"; }

.fa-edit:before {
  content: "\f044"; }

.fa-file:before {
  content: "\f15b"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-pencil:before {
  content: "\f303"; }

.fa-map-marker-alt:before {
  content: '\f3c5'; }

.fa-pinterest-p:before {
  content: '\f231'; }

.mdi-arrow-left:before {
  content: '\f04d'; }

.mdi-reload:before {
  content: '\f453'; }

.mdi-account:before {
  content: '\f004'; }

.mdi-magnify:before {
  content: '\f349'; }

.mdi-home:before {
  content: '\f2dc'; }

.mdi-close:before {
  content: '\f156'; }

.mdi-menu:before {
  content: '\f35c'; }

.mdi-phone:before {
  content: '\f3f2'; }

.mdi-email:before {
  content: '\f1ee'; }

.mdi-plus-circle:before {
  content: '\f417'; }

.mdi-alert-circle-outline:before {
  content: '\f5d6'; }

.mdi-calendar:before {
  content: '\f0ed'; }

.mdi-map-marker:before {
  content: '\f34e'; }

.mdi-chevron-double-left:before {
  content: '\f13d'; }

.mdi-chevron-double-right:before {
  content: '\f13e'; }

.mdi-cloud-download:before {
  content: '\f162'; }

.mdi-briefcase:before {
  content: '\f0d6'; }

.mdi-fax:before {
  content: '\f212'; }

.mdi-whatsapp:before {
  content: '\f5a3'; }

.mdi-map:before {
  content: '\f34d'; }

.mdi-printer:before {
  content: '\f42a'; }

.mdi-file-outline:before {
  content: '\f224'; }

.mdi-chevron-down:before {
  content: '\f140'; }

.mdi-chevron-left:before {
  content: '\f141'; }

.mdi-chevron-right:before {
  content: '\f142'; }

.mdi-link:before {
  content: '\f337'; }

.mdi-arrow-right:before {
  content: '\f054'; }

.mdi-facebook:before {
  content: '\f20c'; }

.mdi-linkedin:before {
  content: '\f33b'; }

.fa-twitter:before {
  content: '\f099'; }

.mdi-instagram:before {
  content: '\f2fe'; }

.mdi-twitter:before {
  content: '\f544'; }

.mdi-youtube:before {
  content: '\f5c3'; }

.mdi-play-circle-outline:before {
  content: '\f40d'; }

.mdi-cart:before {
  content: '\f110'; }

.mdi-check-circle-outline:before {
  content: '\f5e1'; }

.lnr-arrow-right:before {
  content: '\e87a'; }

.lnr-arrow-left:before {
  content: '\e879'; }

.lnr-chevron-right:before {
  content: '\e876'; }

.lnr-chevron-left:before {
  content: '\e875'; }

.lnr-chevron-down:before {
  content: '\e874'; }

.lnr-chevron-up:before {
  content: '\e873'; }

.lnr-download:before {
  content: '\e865'; }

.lnr-arrow-up:before {
  content: '\e877'; }

.ri-home-line:before {
  content: '\EE2B'; }

.ri-search:before {
  content: "\F0D1"; }

.ri-phone-line:before {
  content: "\EFEC"; }

.ri-home-5-fill:before {
  content: "\EE1E"; }

.ri-phone-fill:before {
  content: "\EFE9"; }

.ri-mail-fill:before {
  content: "\EEF3"; }

.ri-time-fill:before {
  content: "\F20E"; }

.ri-sound-module-line:before {
  content: "\F162"; }

.ri-subtract-line:before {
  content: "\F1AF"; }

.ri-add-fill:before {
  content: "\EA12"; }

.ri-check-line:before {
  content: "\EB7B"; }

.ri-send-plane-2-line:before {
  content: "\F0D8"; }

.ri-arrow-right-line:before {
  content: "\EA6C"; }

.ri-shopping-bag-line:before {
  content: "\F118"; }

.ri-more-fill:before {
  content: "\EF78"; }

.ri-home-gear-fill:before {
  content: "\EE27"; }

.ri-phone-fill:before {
  content: "\EFE9"; }

.ri-menu-2-line:before {
  content: "\EF32"; }

.ri-calendar-line:before {
  content: "\EB27"; }

.ri-add-line:before {
  content: "\EA13"; }

.ri-subtract-fill:before {
  content: "\F1AE"; }

.ri-grid-fill:before {
  content: "\EDDE"; }

.ri-arrow-down-s-fill:before {
  content: "\EA4D"; }

.mdi-menu-down:before {
  content: '\f35d'; }

.ri-arrow-up-s-fill:before {
  content: "\EA77"; }

.ri-menu-add-line:before {
  content: "\EF3A"; }

.ri-eye-line:before {
  content: "\ECB5"; }

.ri-eye-off-line:before {
  content: "\ECB7"; }

.col,
[class*="col-"] {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.container-fluid {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }
  .container-13 {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto; }

@media (min-width: 1200px) {
  .container {
    max-width: calc(1206 / 1366 * 100%); }
  .container-13 {
    max-width: calc(1186 / 1366 * 100%); } }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

@media (max-width: 767px) {
  .row {
    margin-right: -10px;
    margin-left: -10px; }
    .row > .col,
    .row > [class*="col-"] {
      padding-right: 10px;
      padding-left: 10px; } }

*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 8px; }
  @media (min-width: 768px) {
    html {
      font-size: calc(0.33445vw + 5.43144px); } }
  @media (min-width: 1366px) {
    html {
      font-size: 10px; } }
  @media (min-width: 1366px) {
    html {
      font-size: 10px; } }

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: hidden;
  position: relative; }

a {
  text-decoration: none; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

button {
  cursor: pointer; }

input,
textarea,
button {
  outline: none;
  box-shadow: none; }

#content {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  #content::-webkit-scrollbar {
    width: 0; }

.slick-slide:active,
.slick-slide:focus,
.slick-arrow:hover,
.slick-arrow:focus {
  border: 0;
  outline: 0; }

@media (max-width: 767px) {
  .hidden-mobile {
    display: none; } }

@media (min-width: 768px) {
  .hidden-desktop {
    display: none; } }

.flex {
  display: flex; }

.align-center {
  align-items: center;
  display: flex; }

.flex-end {
  justify-content: flex-end; }

.flex-start {
  justify-content: flex-start; }

.justify-center {
  justify-content: center;
  display: flex; }

.row-reserve {
  flex-direction: row-reverse !important; }

.columns-reserve {
  flex-direction: column-reverse !important; }

.img-white {
  filter: brightness(0) invert(1); }

.fake-bold {
  text-stroke: 1px currentColor;
  -webkit-text-stroke: 1px currentColor; }

.center {
  text-align: center; }

.w-100 {
  width: 100%; }

.mw-100 {
  max-width: 100%; }

.m-a-0 {
  margin: 0rem 0rem !important; }

.m-t-0 {
  margin-top: 0rem !important; }

.m-r-0 {
  margin-right: 0rem !important; }

.m-b-0 {
  margin-bottom: 0rem !important; }

.m-l-0 {
  margin-left: 0rem !important; }

.m-x-0 {
  margin-right: 0rem !important;
  margin-left: 0rem !important; }

.m-y-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.m-a-1 {
  margin: 1rem 1rem !important; }

.m-t-1 {
  margin-top: 1rem !important; }

.m-r-1 {
  margin-right: 1rem !important; }

.m-b-1 {
  margin-bottom: 1rem !important; }

.m-l-1 {
  margin-left: 1rem !important; }

.m-x-1 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.m-y-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.m-a-2 {
  margin: 2rem 2rem !important; }

.m-t-2 {
  margin-top: 2rem !important; }

.m-r-2 {
  margin-right: 2rem !important; }

.m-b-2 {
  margin-bottom: 2rem !important; }

.m-l-2 {
  margin-left: 2rem !important; }

.m-x-2 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.m-y-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.m-a-3 {
  margin: 3rem 3rem !important; }

.m-t-3 {
  margin-top: 3rem !important; }

.m-r-3 {
  margin-right: 3rem !important; }

.m-b-3 {
  margin-bottom: 3rem !important; }

.m-l-3 {
  margin-left: 3rem !important; }

.m-x-3 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.m-y-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.m-a-4 {
  margin: 4rem 4rem !important; }

.m-t-4 {
  margin-top: 4rem !important; }

.m-r-4 {
  margin-right: 4rem !important; }

.m-b-4 {
  margin-bottom: 4rem !important; }

.m-l-4 {
  margin-left: 4rem !important; }

.m-x-4 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.m-y-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.m-a-5 {
  margin: 5rem 5rem !important; }

.m-t-5 {
  margin-top: 5rem !important; }

.m-r-5 {
  margin-right: 5rem !important; }

.m-b-5 {
  margin-bottom: 5rem !important; }

.m-l-5 {
  margin-left: 5rem !important; }

.m-x-5 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.m-y-5 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.m-a-6 {
  margin: 6rem 6rem !important; }

.m-t-6 {
  margin-top: 6rem !important; }

.m-r-6 {
  margin-right: 6rem !important; }

.m-b-6 {
  margin-bottom: 6rem !important; }

.m-l-6 {
  margin-left: 6rem !important; }

.m-x-6 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.m-y-6 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.m-a-7 {
  margin: 7rem 7rem !important; }

.m-t-7 {
  margin-top: 7rem !important; }

.m-r-7 {
  margin-right: 7rem !important; }

.m-b-7 {
  margin-bottom: 7rem !important; }

.m-l-7 {
  margin-left: 7rem !important; }

.m-x-7 {
  margin-right: 7rem !important;
  margin-left: 7rem !important; }

.m-y-7 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.m-a-8 {
  margin: 8rem 8rem !important; }

.m-t-8 {
  margin-top: 8rem !important; }

.m-r-8 {
  margin-right: 8rem !important; }

.m-b-8 {
  margin-bottom: 8rem !important; }

.m-l-8 {
  margin-left: 8rem !important; }

.m-x-8 {
  margin-right: 8rem !important;
  margin-left: 8rem !important; }

.m-y-8 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important; }

.m-a-9 {
  margin: 9rem 9rem !important; }

.m-t-9 {
  margin-top: 9rem !important; }

.m-r-9 {
  margin-right: 9rem !important; }

.m-b-9 {
  margin-bottom: 9rem !important; }

.m-l-9 {
  margin-left: 9rem !important; }

.m-x-9 {
  margin-right: 9rem !important;
  margin-left: 9rem !important; }

.m-y-9 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important; }

.m-a-10 {
  margin: 10rem 10rem !important; }

.m-t-10 {
  margin-top: 10rem !important; }

.m-r-10 {
  margin-right: 10rem !important; }

.m-b-10 {
  margin-bottom: 10rem !important; }

.m-l-10 {
  margin-left: 10rem !important; }

.m-x-10 {
  margin-right: 10rem !important;
  margin-left: 10rem !important; }

.m-y-10 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important; }

.m-a-11 {
  margin: 11rem 11rem !important; }

.m-t-11 {
  margin-top: 11rem !important; }

.m-r-11 {
  margin-right: 11rem !important; }

.m-b-11 {
  margin-bottom: 11rem !important; }

.m-l-11 {
  margin-left: 11rem !important; }

.m-x-11 {
  margin-right: 11rem !important;
  margin-left: 11rem !important; }

.m-y-11 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important; }

.m-a-12 {
  margin: 12rem 12rem !important; }

.m-t-12 {
  margin-top: 12rem !important; }

.m-r-12 {
  margin-right: 12rem !important; }

.m-b-12 {
  margin-bottom: 12rem !important; }

.m-l-12 {
  margin-left: 12rem !important; }

.m-x-12 {
  margin-right: 12rem !important;
  margin-left: 12rem !important; }

.m-y-12 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important; }

.m-a-13 {
  margin: 13rem 13rem !important; }

.m-t-13 {
  margin-top: 13rem !important; }

.m-r-13 {
  margin-right: 13rem !important; }

.m-b-13 {
  margin-bottom: 13rem !important; }

.m-l-13 {
  margin-left: 13rem !important; }

.m-x-13 {
  margin-right: 13rem !important;
  margin-left: 13rem !important; }

.m-y-13 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important; }

.m-a-14 {
  margin: 14rem 14rem !important; }

.m-t-14 {
  margin-top: 14rem !important; }

.m-r-14 {
  margin-right: 14rem !important; }

.m-b-14 {
  margin-bottom: 14rem !important; }

.m-l-14 {
  margin-left: 14rem !important; }

.m-x-14 {
  margin-right: 14rem !important;
  margin-left: 14rem !important; }

.m-y-14 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important; }

.m-a-15 {
  margin: 15rem 15rem !important; }

.m-t-15 {
  margin-top: 15rem !important; }

.m-r-15 {
  margin-right: 15rem !important; }

.m-b-15 {
  margin-bottom: 15rem !important; }

.m-l-15 {
  margin-left: 15rem !important; }

.m-x-15 {
  margin-right: 15rem !important;
  margin-left: 15rem !important; }

.m-y-15 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important; }

.m-a-16 {
  margin: 16rem 16rem !important; }

.m-t-16 {
  margin-top: 16rem !important; }

.m-r-16 {
  margin-right: 16rem !important; }

.m-b-16 {
  margin-bottom: 16rem !important; }

.m-l-16 {
  margin-left: 16rem !important; }

.m-x-16 {
  margin-right: 16rem !important;
  margin-left: 16rem !important; }

.m-y-16 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important; }

.m-a-17 {
  margin: 17rem 17rem !important; }

.m-t-17 {
  margin-top: 17rem !important; }

.m-r-17 {
  margin-right: 17rem !important; }

.m-b-17 {
  margin-bottom: 17rem !important; }

.m-l-17 {
  margin-left: 17rem !important; }

.m-x-17 {
  margin-right: 17rem !important;
  margin-left: 17rem !important; }

.m-y-17 {
  margin-top: 17rem !important;
  margin-bottom: 17rem !important; }

.m-a-18 {
  margin: 18rem 18rem !important; }

.m-t-18 {
  margin-top: 18rem !important; }

.m-r-18 {
  margin-right: 18rem !important; }

.m-b-18 {
  margin-bottom: 18rem !important; }

.m-l-18 {
  margin-left: 18rem !important; }

.m-x-18 {
  margin-right: 18rem !important;
  margin-left: 18rem !important; }

.m-y-18 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important; }

.m-a-19 {
  margin: 19rem 19rem !important; }

.m-t-19 {
  margin-top: 19rem !important; }

.m-r-19 {
  margin-right: 19rem !important; }

.m-b-19 {
  margin-bottom: 19rem !important; }

.m-l-19 {
  margin-left: 19rem !important; }

.m-x-19 {
  margin-right: 19rem !important;
  margin-left: 19rem !important; }

.m-y-19 {
  margin-top: 19rem !important;
  margin-bottom: 19rem !important; }

.m-a-20 {
  margin: 20rem 20rem !important; }

.m-t-20 {
  margin-top: 20rem !important; }

.m-r-20 {
  margin-right: 20rem !important; }

.m-b-20 {
  margin-bottom: 20rem !important; }

.m-l-20 {
  margin-left: 20rem !important; }

.m-x-20 {
  margin-right: 20rem !important;
  margin-left: 20rem !important; }

.m-y-20 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important; }

.m-a-21 {
  margin: 21rem 21rem !important; }

.m-t-21 {
  margin-top: 21rem !important; }

.m-r-21 {
  margin-right: 21rem !important; }

.m-b-21 {
  margin-bottom: 21rem !important; }

.m-l-21 {
  margin-left: 21rem !important; }

.m-x-21 {
  margin-right: 21rem !important;
  margin-left: 21rem !important; }

.m-y-21 {
  margin-top: 21rem !important;
  margin-bottom: 21rem !important; }

.m-a-22 {
  margin: 22rem 22rem !important; }

.m-t-22 {
  margin-top: 22rem !important; }

.m-r-22 {
  margin-right: 22rem !important; }

.m-b-22 {
  margin-bottom: 22rem !important; }

.m-l-22 {
  margin-left: 22rem !important; }

.m-x-22 {
  margin-right: 22rem !important;
  margin-left: 22rem !important; }

.m-y-22 {
  margin-top: 22rem !important;
  margin-bottom: 22rem !important; }

.m-a-23 {
  margin: 23rem 23rem !important; }

.m-t-23 {
  margin-top: 23rem !important; }

.m-r-23 {
  margin-right: 23rem !important; }

.m-b-23 {
  margin-bottom: 23rem !important; }

.m-l-23 {
  margin-left: 23rem !important; }

.m-x-23 {
  margin-right: 23rem !important;
  margin-left: 23rem !important; }

.m-y-23 {
  margin-top: 23rem !important;
  margin-bottom: 23rem !important; }

.m-a-24 {
  margin: 24rem 24rem !important; }

.m-t-24 {
  margin-top: 24rem !important; }

.m-r-24 {
  margin-right: 24rem !important; }

.m-b-24 {
  margin-bottom: 24rem !important; }

.m-l-24 {
  margin-left: 24rem !important; }

.m-x-24 {
  margin-right: 24rem !important;
  margin-left: 24rem !important; }

.m-y-24 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important; }

.m-a-25 {
  margin: 25rem 25rem !important; }

.m-t-25 {
  margin-top: 25rem !important; }

.m-r-25 {
  margin-right: 25rem !important; }

.m-b-25 {
  margin-bottom: 25rem !important; }

.m-l-25 {
  margin-left: 25rem !important; }

.m-x-25 {
  margin-right: 25rem !important;
  margin-left: 25rem !important; }

.m-y-25 {
  margin-top: 25rem !important;
  margin-bottom: 25rem !important; }

.m-a-26 {
  margin: 26rem 26rem !important; }

.m-t-26 {
  margin-top: 26rem !important; }

.m-r-26 {
  margin-right: 26rem !important; }

.m-b-26 {
  margin-bottom: 26rem !important; }

.m-l-26 {
  margin-left: 26rem !important; }

.m-x-26 {
  margin-right: 26rem !important;
  margin-left: 26rem !important; }

.m-y-26 {
  margin-top: 26rem !important;
  margin-bottom: 26rem !important; }

.m-a-27 {
  margin: 27rem 27rem !important; }

.m-t-27 {
  margin-top: 27rem !important; }

.m-r-27 {
  margin-right: 27rem !important; }

.m-b-27 {
  margin-bottom: 27rem !important; }

.m-l-27 {
  margin-left: 27rem !important; }

.m-x-27 {
  margin-right: 27rem !important;
  margin-left: 27rem !important; }

.m-y-27 {
  margin-top: 27rem !important;
  margin-bottom: 27rem !important; }

.m-a-28 {
  margin: 28rem 28rem !important; }

.m-t-28 {
  margin-top: 28rem !important; }

.m-r-28 {
  margin-right: 28rem !important; }

.m-b-28 {
  margin-bottom: 28rem !important; }

.m-l-28 {
  margin-left: 28rem !important; }

.m-x-28 {
  margin-right: 28rem !important;
  margin-left: 28rem !important; }

.m-y-28 {
  margin-top: 28rem !important;
  margin-bottom: 28rem !important; }

.m-a-29 {
  margin: 29rem 29rem !important; }

.m-t-29 {
  margin-top: 29rem !important; }

.m-r-29 {
  margin-right: 29rem !important; }

.m-b-29 {
  margin-bottom: 29rem !important; }

.m-l-29 {
  margin-left: 29rem !important; }

.m-x-29 {
  margin-right: 29rem !important;
  margin-left: 29rem !important; }

.m-y-29 {
  margin-top: 29rem !important;
  margin-bottom: 29rem !important; }

.m-a-30 {
  margin: 30rem 30rem !important; }

.m-t-30 {
  margin-top: 30rem !important; }

.m-r-30 {
  margin-right: 30rem !important; }

.m-b-30 {
  margin-bottom: 30rem !important; }

.m-l-30 {
  margin-left: 30rem !important; }

.m-x-30 {
  margin-right: 30rem !important;
  margin-left: 30rem !important; }

.m-y-30 {
  margin-top: 30rem !important;
  margin-bottom: 30rem !important; }

.m-a-31 {
  margin: 31rem 31rem !important; }

.m-t-31 {
  margin-top: 31rem !important; }

.m-r-31 {
  margin-right: 31rem !important; }

.m-b-31 {
  margin-bottom: 31rem !important; }

.m-l-31 {
  margin-left: 31rem !important; }

.m-x-31 {
  margin-right: 31rem !important;
  margin-left: 31rem !important; }

.m-y-31 {
  margin-top: 31rem !important;
  margin-bottom: 31rem !important; }

.m-a-32 {
  margin: 32rem 32rem !important; }

.m-t-32 {
  margin-top: 32rem !important; }

.m-r-32 {
  margin-right: 32rem !important; }

.m-b-32 {
  margin-bottom: 32rem !important; }

.m-l-32 {
  margin-left: 32rem !important; }

.m-x-32 {
  margin-right: 32rem !important;
  margin-left: 32rem !important; }

.m-y-32 {
  margin-top: 32rem !important;
  margin-bottom: 32rem !important; }

.m-a-33 {
  margin: 33rem 33rem !important; }

.m-t-33 {
  margin-top: 33rem !important; }

.m-r-33 {
  margin-right: 33rem !important; }

.m-b-33 {
  margin-bottom: 33rem !important; }

.m-l-33 {
  margin-left: 33rem !important; }

.m-x-33 {
  margin-right: 33rem !important;
  margin-left: 33rem !important; }

.m-y-33 {
  margin-top: 33rem !important;
  margin-bottom: 33rem !important; }

.m-a-34 {
  margin: 34rem 34rem !important; }

.m-t-34 {
  margin-top: 34rem !important; }

.m-r-34 {
  margin-right: 34rem !important; }

.m-b-34 {
  margin-bottom: 34rem !important; }

.m-l-34 {
  margin-left: 34rem !important; }

.m-x-34 {
  margin-right: 34rem !important;
  margin-left: 34rem !important; }

.m-y-34 {
  margin-top: 34rem !important;
  margin-bottom: 34rem !important; }

.m-a-35 {
  margin: 35rem 35rem !important; }

.m-t-35 {
  margin-top: 35rem !important; }

.m-r-35 {
  margin-right: 35rem !important; }

.m-b-35 {
  margin-bottom: 35rem !important; }

.m-l-35 {
  margin-left: 35rem !important; }

.m-x-35 {
  margin-right: 35rem !important;
  margin-left: 35rem !important; }

.m-y-35 {
  margin-top: 35rem !important;
  margin-bottom: 35rem !important; }

.m-a-36 {
  margin: 36rem 36rem !important; }

.m-t-36 {
  margin-top: 36rem !important; }

.m-r-36 {
  margin-right: 36rem !important; }

.m-b-36 {
  margin-bottom: 36rem !important; }

.m-l-36 {
  margin-left: 36rem !important; }

.m-x-36 {
  margin-right: 36rem !important;
  margin-left: 36rem !important; }

.m-y-36 {
  margin-top: 36rem !important;
  margin-bottom: 36rem !important; }

.m-a-37 {
  margin: 37rem 37rem !important; }

.m-t-37 {
  margin-top: 37rem !important; }

.m-r-37 {
  margin-right: 37rem !important; }

.m-b-37 {
  margin-bottom: 37rem !important; }

.m-l-37 {
  margin-left: 37rem !important; }

.m-x-37 {
  margin-right: 37rem !important;
  margin-left: 37rem !important; }

.m-y-37 {
  margin-top: 37rem !important;
  margin-bottom: 37rem !important; }

.m-a-38 {
  margin: 38rem 38rem !important; }

.m-t-38 {
  margin-top: 38rem !important; }

.m-r-38 {
  margin-right: 38rem !important; }

.m-b-38 {
  margin-bottom: 38rem !important; }

.m-l-38 {
  margin-left: 38rem !important; }

.m-x-38 {
  margin-right: 38rem !important;
  margin-left: 38rem !important; }

.m-y-38 {
  margin-top: 38rem !important;
  margin-bottom: 38rem !important; }

.m-a-39 {
  margin: 39rem 39rem !important; }

.m-t-39 {
  margin-top: 39rem !important; }

.m-r-39 {
  margin-right: 39rem !important; }

.m-b-39 {
  margin-bottom: 39rem !important; }

.m-l-39 {
  margin-left: 39rem !important; }

.m-x-39 {
  margin-right: 39rem !important;
  margin-left: 39rem !important; }

.m-y-39 {
  margin-top: 39rem !important;
  margin-bottom: 39rem !important; }

.m-a-40 {
  margin: 40rem 40rem !important; }

.m-t-40 {
  margin-top: 40rem !important; }

.m-r-40 {
  margin-right: 40rem !important; }

.m-b-40 {
  margin-bottom: 40rem !important; }

.m-l-40 {
  margin-left: 40rem !important; }

.m-x-40 {
  margin-right: 40rem !important;
  margin-left: 40rem !important; }

.m-y-40 {
  margin-top: 40rem !important;
  margin-bottom: 40rem !important; }

.m-a-41 {
  margin: 41rem 41rem !important; }

.m-t-41 {
  margin-top: 41rem !important; }

.m-r-41 {
  margin-right: 41rem !important; }

.m-b-41 {
  margin-bottom: 41rem !important; }

.m-l-41 {
  margin-left: 41rem !important; }

.m-x-41 {
  margin-right: 41rem !important;
  margin-left: 41rem !important; }

.m-y-41 {
  margin-top: 41rem !important;
  margin-bottom: 41rem !important; }

.m-a-42 {
  margin: 42rem 42rem !important; }

.m-t-42 {
  margin-top: 42rem !important; }

.m-r-42 {
  margin-right: 42rem !important; }

.m-b-42 {
  margin-bottom: 42rem !important; }

.m-l-42 {
  margin-left: 42rem !important; }

.m-x-42 {
  margin-right: 42rem !important;
  margin-left: 42rem !important; }

.m-y-42 {
  margin-top: 42rem !important;
  margin-bottom: 42rem !important; }

.m-a-43 {
  margin: 43rem 43rem !important; }

.m-t-43 {
  margin-top: 43rem !important; }

.m-r-43 {
  margin-right: 43rem !important; }

.m-b-43 {
  margin-bottom: 43rem !important; }

.m-l-43 {
  margin-left: 43rem !important; }

.m-x-43 {
  margin-right: 43rem !important;
  margin-left: 43rem !important; }

.m-y-43 {
  margin-top: 43rem !important;
  margin-bottom: 43rem !important; }

.m-a-44 {
  margin: 44rem 44rem !important; }

.m-t-44 {
  margin-top: 44rem !important; }

.m-r-44 {
  margin-right: 44rem !important; }

.m-b-44 {
  margin-bottom: 44rem !important; }

.m-l-44 {
  margin-left: 44rem !important; }

.m-x-44 {
  margin-right: 44rem !important;
  margin-left: 44rem !important; }

.m-y-44 {
  margin-top: 44rem !important;
  margin-bottom: 44rem !important; }

.m-a-45 {
  margin: 45rem 45rem !important; }

.m-t-45 {
  margin-top: 45rem !important; }

.m-r-45 {
  margin-right: 45rem !important; }

.m-b-45 {
  margin-bottom: 45rem !important; }

.m-l-45 {
  margin-left: 45rem !important; }

.m-x-45 {
  margin-right: 45rem !important;
  margin-left: 45rem !important; }

.m-y-45 {
  margin-top: 45rem !important;
  margin-bottom: 45rem !important; }

.m-a-46 {
  margin: 46rem 46rem !important; }

.m-t-46 {
  margin-top: 46rem !important; }

.m-r-46 {
  margin-right: 46rem !important; }

.m-b-46 {
  margin-bottom: 46rem !important; }

.m-l-46 {
  margin-left: 46rem !important; }

.m-x-46 {
  margin-right: 46rem !important;
  margin-left: 46rem !important; }

.m-y-46 {
  margin-top: 46rem !important;
  margin-bottom: 46rem !important; }

.m-a-47 {
  margin: 47rem 47rem !important; }

.m-t-47 {
  margin-top: 47rem !important; }

.m-r-47 {
  margin-right: 47rem !important; }

.m-b-47 {
  margin-bottom: 47rem !important; }

.m-l-47 {
  margin-left: 47rem !important; }

.m-x-47 {
  margin-right: 47rem !important;
  margin-left: 47rem !important; }

.m-y-47 {
  margin-top: 47rem !important;
  margin-bottom: 47rem !important; }

.m-a-48 {
  margin: 48rem 48rem !important; }

.m-t-48 {
  margin-top: 48rem !important; }

.m-r-48 {
  margin-right: 48rem !important; }

.m-b-48 {
  margin-bottom: 48rem !important; }

.m-l-48 {
  margin-left: 48rem !important; }

.m-x-48 {
  margin-right: 48rem !important;
  margin-left: 48rem !important; }

.m-y-48 {
  margin-top: 48rem !important;
  margin-bottom: 48rem !important; }

.m-a-49 {
  margin: 49rem 49rem !important; }

.m-t-49 {
  margin-top: 49rem !important; }

.m-r-49 {
  margin-right: 49rem !important; }

.m-b-49 {
  margin-bottom: 49rem !important; }

.m-l-49 {
  margin-left: 49rem !important; }

.m-x-49 {
  margin-right: 49rem !important;
  margin-left: 49rem !important; }

.m-y-49 {
  margin-top: 49rem !important;
  margin-bottom: 49rem !important; }

.m-a-50 {
  margin: 50rem 50rem !important; }

.m-t-50 {
  margin-top: 50rem !important; }

.m-r-50 {
  margin-right: 50rem !important; }

.m-b-50 {
  margin-bottom: 50rem !important; }

.m-l-50 {
  margin-left: 50rem !important; }

.m-x-50 {
  margin-right: 50rem !important;
  margin-left: 50rem !important; }

.m-y-50 {
  margin-top: 50rem !important;
  margin-bottom: 50rem !important; }

.m-a-51 {
  margin: 51rem 51rem !important; }

.m-t-51 {
  margin-top: 51rem !important; }

.m-r-51 {
  margin-right: 51rem !important; }

.m-b-51 {
  margin-bottom: 51rem !important; }

.m-l-51 {
  margin-left: 51rem !important; }

.m-x-51 {
  margin-right: 51rem !important;
  margin-left: 51rem !important; }

.m-y-51 {
  margin-top: 51rem !important;
  margin-bottom: 51rem !important; }

.m-a-52 {
  margin: 52rem 52rem !important; }

.m-t-52 {
  margin-top: 52rem !important; }

.m-r-52 {
  margin-right: 52rem !important; }

.m-b-52 {
  margin-bottom: 52rem !important; }

.m-l-52 {
  margin-left: 52rem !important; }

.m-x-52 {
  margin-right: 52rem !important;
  margin-left: 52rem !important; }

.m-y-52 {
  margin-top: 52rem !important;
  margin-bottom: 52rem !important; }

.m-a-53 {
  margin: 53rem 53rem !important; }

.m-t-53 {
  margin-top: 53rem !important; }

.m-r-53 {
  margin-right: 53rem !important; }

.m-b-53 {
  margin-bottom: 53rem !important; }

.m-l-53 {
  margin-left: 53rem !important; }

.m-x-53 {
  margin-right: 53rem !important;
  margin-left: 53rem !important; }

.m-y-53 {
  margin-top: 53rem !important;
  margin-bottom: 53rem !important; }

.m-a-54 {
  margin: 54rem 54rem !important; }

.m-t-54 {
  margin-top: 54rem !important; }

.m-r-54 {
  margin-right: 54rem !important; }

.m-b-54 {
  margin-bottom: 54rem !important; }

.m-l-54 {
  margin-left: 54rem !important; }

.m-x-54 {
  margin-right: 54rem !important;
  margin-left: 54rem !important; }

.m-y-54 {
  margin-top: 54rem !important;
  margin-bottom: 54rem !important; }

.m-a-55 {
  margin: 55rem 55rem !important; }

.m-t-55 {
  margin-top: 55rem !important; }

.m-r-55 {
  margin-right: 55rem !important; }

.m-b-55 {
  margin-bottom: 55rem !important; }

.m-l-55 {
  margin-left: 55rem !important; }

.m-x-55 {
  margin-right: 55rem !important;
  margin-left: 55rem !important; }

.m-y-55 {
  margin-top: 55rem !important;
  margin-bottom: 55rem !important; }

.m-a-56 {
  margin: 56rem 56rem !important; }

.m-t-56 {
  margin-top: 56rem !important; }

.m-r-56 {
  margin-right: 56rem !important; }

.m-b-56 {
  margin-bottom: 56rem !important; }

.m-l-56 {
  margin-left: 56rem !important; }

.m-x-56 {
  margin-right: 56rem !important;
  margin-left: 56rem !important; }

.m-y-56 {
  margin-top: 56rem !important;
  margin-bottom: 56rem !important; }

.m-a-57 {
  margin: 57rem 57rem !important; }

.m-t-57 {
  margin-top: 57rem !important; }

.m-r-57 {
  margin-right: 57rem !important; }

.m-b-57 {
  margin-bottom: 57rem !important; }

.m-l-57 {
  margin-left: 57rem !important; }

.m-x-57 {
  margin-right: 57rem !important;
  margin-left: 57rem !important; }

.m-y-57 {
  margin-top: 57rem !important;
  margin-bottom: 57rem !important; }

.m-a-58 {
  margin: 58rem 58rem !important; }

.m-t-58 {
  margin-top: 58rem !important; }

.m-r-58 {
  margin-right: 58rem !important; }

.m-b-58 {
  margin-bottom: 58rem !important; }

.m-l-58 {
  margin-left: 58rem !important; }

.m-x-58 {
  margin-right: 58rem !important;
  margin-left: 58rem !important; }

.m-y-58 {
  margin-top: 58rem !important;
  margin-bottom: 58rem !important; }

.m-a-59 {
  margin: 59rem 59rem !important; }

.m-t-59 {
  margin-top: 59rem !important; }

.m-r-59 {
  margin-right: 59rem !important; }

.m-b-59 {
  margin-bottom: 59rem !important; }

.m-l-59 {
  margin-left: 59rem !important; }

.m-x-59 {
  margin-right: 59rem !important;
  margin-left: 59rem !important; }

.m-y-59 {
  margin-top: 59rem !important;
  margin-bottom: 59rem !important; }

.m-a-60 {
  margin: 60rem 60rem !important; }

.m-t-60 {
  margin-top: 60rem !important; }

.m-r-60 {
  margin-right: 60rem !important; }

.m-b-60 {
  margin-bottom: 60rem !important; }

.m-l-60 {
  margin-left: 60rem !important; }

.m-x-60 {
  margin-right: 60rem !important;
  margin-left: 60rem !important; }

.m-y-60 {
  margin-top: 60rem !important;
  margin-bottom: 60rem !important; }

.m-a-61 {
  margin: 61rem 61rem !important; }

.m-t-61 {
  margin-top: 61rem !important; }

.m-r-61 {
  margin-right: 61rem !important; }

.m-b-61 {
  margin-bottom: 61rem !important; }

.m-l-61 {
  margin-left: 61rem !important; }

.m-x-61 {
  margin-right: 61rem !important;
  margin-left: 61rem !important; }

.m-y-61 {
  margin-top: 61rem !important;
  margin-bottom: 61rem !important; }

.m-a-62 {
  margin: 62rem 62rem !important; }

.m-t-62 {
  margin-top: 62rem !important; }

.m-r-62 {
  margin-right: 62rem !important; }

.m-b-62 {
  margin-bottom: 62rem !important; }

.m-l-62 {
  margin-left: 62rem !important; }

.m-x-62 {
  margin-right: 62rem !important;
  margin-left: 62rem !important; }

.m-y-62 {
  margin-top: 62rem !important;
  margin-bottom: 62rem !important; }

.m-a-63 {
  margin: 63rem 63rem !important; }

.m-t-63 {
  margin-top: 63rem !important; }

.m-r-63 {
  margin-right: 63rem !important; }

.m-b-63 {
  margin-bottom: 63rem !important; }

.m-l-63 {
  margin-left: 63rem !important; }

.m-x-63 {
  margin-right: 63rem !important;
  margin-left: 63rem !important; }

.m-y-63 {
  margin-top: 63rem !important;
  margin-bottom: 63rem !important; }

.m-a-64 {
  margin: 64rem 64rem !important; }

.m-t-64 {
  margin-top: 64rem !important; }

.m-r-64 {
  margin-right: 64rem !important; }

.m-b-64 {
  margin-bottom: 64rem !important; }

.m-l-64 {
  margin-left: 64rem !important; }

.m-x-64 {
  margin-right: 64rem !important;
  margin-left: 64rem !important; }

.m-y-64 {
  margin-top: 64rem !important;
  margin-bottom: 64rem !important; }

.m-a-65 {
  margin: 65rem 65rem !important; }

.m-t-65 {
  margin-top: 65rem !important; }

.m-r-65 {
  margin-right: 65rem !important; }

.m-b-65 {
  margin-bottom: 65rem !important; }

.m-l-65 {
  margin-left: 65rem !important; }

.m-x-65 {
  margin-right: 65rem !important;
  margin-left: 65rem !important; }

.m-y-65 {
  margin-top: 65rem !important;
  margin-bottom: 65rem !important; }

.m-a-66 {
  margin: 66rem 66rem !important; }

.m-t-66 {
  margin-top: 66rem !important; }

.m-r-66 {
  margin-right: 66rem !important; }

.m-b-66 {
  margin-bottom: 66rem !important; }

.m-l-66 {
  margin-left: 66rem !important; }

.m-x-66 {
  margin-right: 66rem !important;
  margin-left: 66rem !important; }

.m-y-66 {
  margin-top: 66rem !important;
  margin-bottom: 66rem !important; }

.m-a-67 {
  margin: 67rem 67rem !important; }

.m-t-67 {
  margin-top: 67rem !important; }

.m-r-67 {
  margin-right: 67rem !important; }

.m-b-67 {
  margin-bottom: 67rem !important; }

.m-l-67 {
  margin-left: 67rem !important; }

.m-x-67 {
  margin-right: 67rem !important;
  margin-left: 67rem !important; }

.m-y-67 {
  margin-top: 67rem !important;
  margin-bottom: 67rem !important; }

.m-a-68 {
  margin: 68rem 68rem !important; }

.m-t-68 {
  margin-top: 68rem !important; }

.m-r-68 {
  margin-right: 68rem !important; }

.m-b-68 {
  margin-bottom: 68rem !important; }

.m-l-68 {
  margin-left: 68rem !important; }

.m-x-68 {
  margin-right: 68rem !important;
  margin-left: 68rem !important; }

.m-y-68 {
  margin-top: 68rem !important;
  margin-bottom: 68rem !important; }

.m-a-69 {
  margin: 69rem 69rem !important; }

.m-t-69 {
  margin-top: 69rem !important; }

.m-r-69 {
  margin-right: 69rem !important; }

.m-b-69 {
  margin-bottom: 69rem !important; }

.m-l-69 {
  margin-left: 69rem !important; }

.m-x-69 {
  margin-right: 69rem !important;
  margin-left: 69rem !important; }

.m-y-69 {
  margin-top: 69rem !important;
  margin-bottom: 69rem !important; }

.m-a-70 {
  margin: 70rem 70rem !important; }

.m-t-70 {
  margin-top: 70rem !important; }

.m-r-70 {
  margin-right: 70rem !important; }

.m-b-70 {
  margin-bottom: 70rem !important; }

.m-l-70 {
  margin-left: 70rem !important; }

.m-x-70 {
  margin-right: 70rem !important;
  margin-left: 70rem !important; }

.m-y-70 {
  margin-top: 70rem !important;
  margin-bottom: 70rem !important; }

.m-a-71 {
  margin: 71rem 71rem !important; }

.m-t-71 {
  margin-top: 71rem !important; }

.m-r-71 {
  margin-right: 71rem !important; }

.m-b-71 {
  margin-bottom: 71rem !important; }

.m-l-71 {
  margin-left: 71rem !important; }

.m-x-71 {
  margin-right: 71rem !important;
  margin-left: 71rem !important; }

.m-y-71 {
  margin-top: 71rem !important;
  margin-bottom: 71rem !important; }

.m-a-72 {
  margin: 72rem 72rem !important; }

.m-t-72 {
  margin-top: 72rem !important; }

.m-r-72 {
  margin-right: 72rem !important; }

.m-b-72 {
  margin-bottom: 72rem !important; }

.m-l-72 {
  margin-left: 72rem !important; }

.m-x-72 {
  margin-right: 72rem !important;
  margin-left: 72rem !important; }

.m-y-72 {
  margin-top: 72rem !important;
  margin-bottom: 72rem !important; }

.m-a-73 {
  margin: 73rem 73rem !important; }

.m-t-73 {
  margin-top: 73rem !important; }

.m-r-73 {
  margin-right: 73rem !important; }

.m-b-73 {
  margin-bottom: 73rem !important; }

.m-l-73 {
  margin-left: 73rem !important; }

.m-x-73 {
  margin-right: 73rem !important;
  margin-left: 73rem !important; }

.m-y-73 {
  margin-top: 73rem !important;
  margin-bottom: 73rem !important; }

.m-a-74 {
  margin: 74rem 74rem !important; }

.m-t-74 {
  margin-top: 74rem !important; }

.m-r-74 {
  margin-right: 74rem !important; }

.m-b-74 {
  margin-bottom: 74rem !important; }

.m-l-74 {
  margin-left: 74rem !important; }

.m-x-74 {
  margin-right: 74rem !important;
  margin-left: 74rem !important; }

.m-y-74 {
  margin-top: 74rem !important;
  margin-bottom: 74rem !important; }

.m-a-75 {
  margin: 75rem 75rem !important; }

.m-t-75 {
  margin-top: 75rem !important; }

.m-r-75 {
  margin-right: 75rem !important; }

.m-b-75 {
  margin-bottom: 75rem !important; }

.m-l-75 {
  margin-left: 75rem !important; }

.m-x-75 {
  margin-right: 75rem !important;
  margin-left: 75rem !important; }

.m-y-75 {
  margin-top: 75rem !important;
  margin-bottom: 75rem !important; }

.m-a-76 {
  margin: 76rem 76rem !important; }

.m-t-76 {
  margin-top: 76rem !important; }

.m-r-76 {
  margin-right: 76rem !important; }

.m-b-76 {
  margin-bottom: 76rem !important; }

.m-l-76 {
  margin-left: 76rem !important; }

.m-x-76 {
  margin-right: 76rem !important;
  margin-left: 76rem !important; }

.m-y-76 {
  margin-top: 76rem !important;
  margin-bottom: 76rem !important; }

.m-a-77 {
  margin: 77rem 77rem !important; }

.m-t-77 {
  margin-top: 77rem !important; }

.m-r-77 {
  margin-right: 77rem !important; }

.m-b-77 {
  margin-bottom: 77rem !important; }

.m-l-77 {
  margin-left: 77rem !important; }

.m-x-77 {
  margin-right: 77rem !important;
  margin-left: 77rem !important; }

.m-y-77 {
  margin-top: 77rem !important;
  margin-bottom: 77rem !important; }

.m-a-78 {
  margin: 78rem 78rem !important; }

.m-t-78 {
  margin-top: 78rem !important; }

.m-r-78 {
  margin-right: 78rem !important; }

.m-b-78 {
  margin-bottom: 78rem !important; }

.m-l-78 {
  margin-left: 78rem !important; }

.m-x-78 {
  margin-right: 78rem !important;
  margin-left: 78rem !important; }

.m-y-78 {
  margin-top: 78rem !important;
  margin-bottom: 78rem !important; }

.m-a-79 {
  margin: 79rem 79rem !important; }

.m-t-79 {
  margin-top: 79rem !important; }

.m-r-79 {
  margin-right: 79rem !important; }

.m-b-79 {
  margin-bottom: 79rem !important; }

.m-l-79 {
  margin-left: 79rem !important; }

.m-x-79 {
  margin-right: 79rem !important;
  margin-left: 79rem !important; }

.m-y-79 {
  margin-top: 79rem !important;
  margin-bottom: 79rem !important; }

.m-a-80 {
  margin: 80rem 80rem !important; }

.m-t-80 {
  margin-top: 80rem !important; }

.m-r-80 {
  margin-right: 80rem !important; }

.m-b-80 {
  margin-bottom: 80rem !important; }

.m-l-80 {
  margin-left: 80rem !important; }

.m-x-80 {
  margin-right: 80rem !important;
  margin-left: 80rem !important; }

.m-y-80 {
  margin-top: 80rem !important;
  margin-bottom: 80rem !important; }

.m-a-81 {
  margin: 81rem 81rem !important; }

.m-t-81 {
  margin-top: 81rem !important; }

.m-r-81 {
  margin-right: 81rem !important; }

.m-b-81 {
  margin-bottom: 81rem !important; }

.m-l-81 {
  margin-left: 81rem !important; }

.m-x-81 {
  margin-right: 81rem !important;
  margin-left: 81rem !important; }

.m-y-81 {
  margin-top: 81rem !important;
  margin-bottom: 81rem !important; }

.m-a-82 {
  margin: 82rem 82rem !important; }

.m-t-82 {
  margin-top: 82rem !important; }

.m-r-82 {
  margin-right: 82rem !important; }

.m-b-82 {
  margin-bottom: 82rem !important; }

.m-l-82 {
  margin-left: 82rem !important; }

.m-x-82 {
  margin-right: 82rem !important;
  margin-left: 82rem !important; }

.m-y-82 {
  margin-top: 82rem !important;
  margin-bottom: 82rem !important; }

.m-a-83 {
  margin: 83rem 83rem !important; }

.m-t-83 {
  margin-top: 83rem !important; }

.m-r-83 {
  margin-right: 83rem !important; }

.m-b-83 {
  margin-bottom: 83rem !important; }

.m-l-83 {
  margin-left: 83rem !important; }

.m-x-83 {
  margin-right: 83rem !important;
  margin-left: 83rem !important; }

.m-y-83 {
  margin-top: 83rem !important;
  margin-bottom: 83rem !important; }

.m-a-84 {
  margin: 84rem 84rem !important; }

.m-t-84 {
  margin-top: 84rem !important; }

.m-r-84 {
  margin-right: 84rem !important; }

.m-b-84 {
  margin-bottom: 84rem !important; }

.m-l-84 {
  margin-left: 84rem !important; }

.m-x-84 {
  margin-right: 84rem !important;
  margin-left: 84rem !important; }

.m-y-84 {
  margin-top: 84rem !important;
  margin-bottom: 84rem !important; }

.m-a-85 {
  margin: 85rem 85rem !important; }

.m-t-85 {
  margin-top: 85rem !important; }

.m-r-85 {
  margin-right: 85rem !important; }

.m-b-85 {
  margin-bottom: 85rem !important; }

.m-l-85 {
  margin-left: 85rem !important; }

.m-x-85 {
  margin-right: 85rem !important;
  margin-left: 85rem !important; }

.m-y-85 {
  margin-top: 85rem !important;
  margin-bottom: 85rem !important; }

.m-a-86 {
  margin: 86rem 86rem !important; }

.m-t-86 {
  margin-top: 86rem !important; }

.m-r-86 {
  margin-right: 86rem !important; }

.m-b-86 {
  margin-bottom: 86rem !important; }

.m-l-86 {
  margin-left: 86rem !important; }

.m-x-86 {
  margin-right: 86rem !important;
  margin-left: 86rem !important; }

.m-y-86 {
  margin-top: 86rem !important;
  margin-bottom: 86rem !important; }

.m-a-87 {
  margin: 87rem 87rem !important; }

.m-t-87 {
  margin-top: 87rem !important; }

.m-r-87 {
  margin-right: 87rem !important; }

.m-b-87 {
  margin-bottom: 87rem !important; }

.m-l-87 {
  margin-left: 87rem !important; }

.m-x-87 {
  margin-right: 87rem !important;
  margin-left: 87rem !important; }

.m-y-87 {
  margin-top: 87rem !important;
  margin-bottom: 87rem !important; }

.m-a-88 {
  margin: 88rem 88rem !important; }

.m-t-88 {
  margin-top: 88rem !important; }

.m-r-88 {
  margin-right: 88rem !important; }

.m-b-88 {
  margin-bottom: 88rem !important; }

.m-l-88 {
  margin-left: 88rem !important; }

.m-x-88 {
  margin-right: 88rem !important;
  margin-left: 88rem !important; }

.m-y-88 {
  margin-top: 88rem !important;
  margin-bottom: 88rem !important; }

.m-a-89 {
  margin: 89rem 89rem !important; }

.m-t-89 {
  margin-top: 89rem !important; }

.m-r-89 {
  margin-right: 89rem !important; }

.m-b-89 {
  margin-bottom: 89rem !important; }

.m-l-89 {
  margin-left: 89rem !important; }

.m-x-89 {
  margin-right: 89rem !important;
  margin-left: 89rem !important; }

.m-y-89 {
  margin-top: 89rem !important;
  margin-bottom: 89rem !important; }

.m-a-90 {
  margin: 90rem 90rem !important; }

.m-t-90 {
  margin-top: 90rem !important; }

.m-r-90 {
  margin-right: 90rem !important; }

.m-b-90 {
  margin-bottom: 90rem !important; }

.m-l-90 {
  margin-left: 90rem !important; }

.m-x-90 {
  margin-right: 90rem !important;
  margin-left: 90rem !important; }

.m-y-90 {
  margin-top: 90rem !important;
  margin-bottom: 90rem !important; }

.m-a-91 {
  margin: 91rem 91rem !important; }

.m-t-91 {
  margin-top: 91rem !important; }

.m-r-91 {
  margin-right: 91rem !important; }

.m-b-91 {
  margin-bottom: 91rem !important; }

.m-l-91 {
  margin-left: 91rem !important; }

.m-x-91 {
  margin-right: 91rem !important;
  margin-left: 91rem !important; }

.m-y-91 {
  margin-top: 91rem !important;
  margin-bottom: 91rem !important; }

.m-a-92 {
  margin: 92rem 92rem !important; }

.m-t-92 {
  margin-top: 92rem !important; }

.m-r-92 {
  margin-right: 92rem !important; }

.m-b-92 {
  margin-bottom: 92rem !important; }

.m-l-92 {
  margin-left: 92rem !important; }

.m-x-92 {
  margin-right: 92rem !important;
  margin-left: 92rem !important; }

.m-y-92 {
  margin-top: 92rem !important;
  margin-bottom: 92rem !important; }

.m-a-93 {
  margin: 93rem 93rem !important; }

.m-t-93 {
  margin-top: 93rem !important; }

.m-r-93 {
  margin-right: 93rem !important; }

.m-b-93 {
  margin-bottom: 93rem !important; }

.m-l-93 {
  margin-left: 93rem !important; }

.m-x-93 {
  margin-right: 93rem !important;
  margin-left: 93rem !important; }

.m-y-93 {
  margin-top: 93rem !important;
  margin-bottom: 93rem !important; }

.m-a-94 {
  margin: 94rem 94rem !important; }

.m-t-94 {
  margin-top: 94rem !important; }

.m-r-94 {
  margin-right: 94rem !important; }

.m-b-94 {
  margin-bottom: 94rem !important; }

.m-l-94 {
  margin-left: 94rem !important; }

.m-x-94 {
  margin-right: 94rem !important;
  margin-left: 94rem !important; }

.m-y-94 {
  margin-top: 94rem !important;
  margin-bottom: 94rem !important; }

.m-a-95 {
  margin: 95rem 95rem !important; }

.m-t-95 {
  margin-top: 95rem !important; }

.m-r-95 {
  margin-right: 95rem !important; }

.m-b-95 {
  margin-bottom: 95rem !important; }

.m-l-95 {
  margin-left: 95rem !important; }

.m-x-95 {
  margin-right: 95rem !important;
  margin-left: 95rem !important; }

.m-y-95 {
  margin-top: 95rem !important;
  margin-bottom: 95rem !important; }

.m-a-96 {
  margin: 96rem 96rem !important; }

.m-t-96 {
  margin-top: 96rem !important; }

.m-r-96 {
  margin-right: 96rem !important; }

.m-b-96 {
  margin-bottom: 96rem !important; }

.m-l-96 {
  margin-left: 96rem !important; }

.m-x-96 {
  margin-right: 96rem !important;
  margin-left: 96rem !important; }

.m-y-96 {
  margin-top: 96rem !important;
  margin-bottom: 96rem !important; }

.m-a-97 {
  margin: 97rem 97rem !important; }

.m-t-97 {
  margin-top: 97rem !important; }

.m-r-97 {
  margin-right: 97rem !important; }

.m-b-97 {
  margin-bottom: 97rem !important; }

.m-l-97 {
  margin-left: 97rem !important; }

.m-x-97 {
  margin-right: 97rem !important;
  margin-left: 97rem !important; }

.m-y-97 {
  margin-top: 97rem !important;
  margin-bottom: 97rem !important; }

.m-a-98 {
  margin: 98rem 98rem !important; }

.m-t-98 {
  margin-top: 98rem !important; }

.m-r-98 {
  margin-right: 98rem !important; }

.m-b-98 {
  margin-bottom: 98rem !important; }

.m-l-98 {
  margin-left: 98rem !important; }

.m-x-98 {
  margin-right: 98rem !important;
  margin-left: 98rem !important; }

.m-y-98 {
  margin-top: 98rem !important;
  margin-bottom: 98rem !important; }

.m-a-99 {
  margin: 99rem 99rem !important; }

.m-t-99 {
  margin-top: 99rem !important; }

.m-r-99 {
  margin-right: 99rem !important; }

.m-b-99 {
  margin-bottom: 99rem !important; }

.m-l-99 {
  margin-left: 99rem !important; }

.m-x-99 {
  margin-right: 99rem !important;
  margin-left: 99rem !important; }

.m-y-99 {
  margin-top: 99rem !important;
  margin-bottom: 99rem !important; }

.m-a-100 {
  margin: 100rem 100rem !important; }

.m-t-100 {
  margin-top: 100rem !important; }

.m-r-100 {
  margin-right: 100rem !important; }

.m-b-100 {
  margin-bottom: 100rem !important; }

.m-l-100 {
  margin-left: 100rem !important; }

.m-x-100 {
  margin-right: 100rem !important;
  margin-left: 100rem !important; }

.m-y-100 {
  margin-top: 100rem !important;
  margin-bottom: 100rem !important; }

.m-a-101 {
  margin: 101rem 101rem !important; }

.m-t-101 {
  margin-top: 101rem !important; }

.m-r-101 {
  margin-right: 101rem !important; }

.m-b-101 {
  margin-bottom: 101rem !important; }

.m-l-101 {
  margin-left: 101rem !important; }

.m-x-101 {
  margin-right: 101rem !important;
  margin-left: 101rem !important; }

.m-y-101 {
  margin-top: 101rem !important;
  margin-bottom: 101rem !important; }

.m-a-102 {
  margin: 102rem 102rem !important; }

.m-t-102 {
  margin-top: 102rem !important; }

.m-r-102 {
  margin-right: 102rem !important; }

.m-b-102 {
  margin-bottom: 102rem !important; }

.m-l-102 {
  margin-left: 102rem !important; }

.m-x-102 {
  margin-right: 102rem !important;
  margin-left: 102rem !important; }

.m-y-102 {
  margin-top: 102rem !important;
  margin-bottom: 102rem !important; }

.m-a-103 {
  margin: 103rem 103rem !important; }

.m-t-103 {
  margin-top: 103rem !important; }

.m-r-103 {
  margin-right: 103rem !important; }

.m-b-103 {
  margin-bottom: 103rem !important; }

.m-l-103 {
  margin-left: 103rem !important; }

.m-x-103 {
  margin-right: 103rem !important;
  margin-left: 103rem !important; }

.m-y-103 {
  margin-top: 103rem !important;
  margin-bottom: 103rem !important; }

.m-a-104 {
  margin: 104rem 104rem !important; }

.m-t-104 {
  margin-top: 104rem !important; }

.m-r-104 {
  margin-right: 104rem !important; }

.m-b-104 {
  margin-bottom: 104rem !important; }

.m-l-104 {
  margin-left: 104rem !important; }

.m-x-104 {
  margin-right: 104rem !important;
  margin-left: 104rem !important; }

.m-y-104 {
  margin-top: 104rem !important;
  margin-bottom: 104rem !important; }

.m-a-105 {
  margin: 105rem 105rem !important; }

.m-t-105 {
  margin-top: 105rem !important; }

.m-r-105 {
  margin-right: 105rem !important; }

.m-b-105 {
  margin-bottom: 105rem !important; }

.m-l-105 {
  margin-left: 105rem !important; }

.m-x-105 {
  margin-right: 105rem !important;
  margin-left: 105rem !important; }

.m-y-105 {
  margin-top: 105rem !important;
  margin-bottom: 105rem !important; }

.m-a-106 {
  margin: 106rem 106rem !important; }

.m-t-106 {
  margin-top: 106rem !important; }

.m-r-106 {
  margin-right: 106rem !important; }

.m-b-106 {
  margin-bottom: 106rem !important; }

.m-l-106 {
  margin-left: 106rem !important; }

.m-x-106 {
  margin-right: 106rem !important;
  margin-left: 106rem !important; }

.m-y-106 {
  margin-top: 106rem !important;
  margin-bottom: 106rem !important; }

.m-a-107 {
  margin: 107rem 107rem !important; }

.m-t-107 {
  margin-top: 107rem !important; }

.m-r-107 {
  margin-right: 107rem !important; }

.m-b-107 {
  margin-bottom: 107rem !important; }

.m-l-107 {
  margin-left: 107rem !important; }

.m-x-107 {
  margin-right: 107rem !important;
  margin-left: 107rem !important; }

.m-y-107 {
  margin-top: 107rem !important;
  margin-bottom: 107rem !important; }

.m-a-108 {
  margin: 108rem 108rem !important; }

.m-t-108 {
  margin-top: 108rem !important; }

.m-r-108 {
  margin-right: 108rem !important; }

.m-b-108 {
  margin-bottom: 108rem !important; }

.m-l-108 {
  margin-left: 108rem !important; }

.m-x-108 {
  margin-right: 108rem !important;
  margin-left: 108rem !important; }

.m-y-108 {
  margin-top: 108rem !important;
  margin-bottom: 108rem !important; }

.m-a-109 {
  margin: 109rem 109rem !important; }

.m-t-109 {
  margin-top: 109rem !important; }

.m-r-109 {
  margin-right: 109rem !important; }

.m-b-109 {
  margin-bottom: 109rem !important; }

.m-l-109 {
  margin-left: 109rem !important; }

.m-x-109 {
  margin-right: 109rem !important;
  margin-left: 109rem !important; }

.m-y-109 {
  margin-top: 109rem !important;
  margin-bottom: 109rem !important; }

.m-a-110 {
  margin: 110rem 110rem !important; }

.m-t-110 {
  margin-top: 110rem !important; }

.m-r-110 {
  margin-right: 110rem !important; }

.m-b-110 {
  margin-bottom: 110rem !important; }

.m-l-110 {
  margin-left: 110rem !important; }

.m-x-110 {
  margin-right: 110rem !important;
  margin-left: 110rem !important; }

.m-y-110 {
  margin-top: 110rem !important;
  margin-bottom: 110rem !important; }

.m-a-111 {
  margin: 111rem 111rem !important; }

.m-t-111 {
  margin-top: 111rem !important; }

.m-r-111 {
  margin-right: 111rem !important; }

.m-b-111 {
  margin-bottom: 111rem !important; }

.m-l-111 {
  margin-left: 111rem !important; }

.m-x-111 {
  margin-right: 111rem !important;
  margin-left: 111rem !important; }

.m-y-111 {
  margin-top: 111rem !important;
  margin-bottom: 111rem !important; }

.m-a-112 {
  margin: 112rem 112rem !important; }

.m-t-112 {
  margin-top: 112rem !important; }

.m-r-112 {
  margin-right: 112rem !important; }

.m-b-112 {
  margin-bottom: 112rem !important; }

.m-l-112 {
  margin-left: 112rem !important; }

.m-x-112 {
  margin-right: 112rem !important;
  margin-left: 112rem !important; }

.m-y-112 {
  margin-top: 112rem !important;
  margin-bottom: 112rem !important; }

.m-a-113 {
  margin: 113rem 113rem !important; }

.m-t-113 {
  margin-top: 113rem !important; }

.m-r-113 {
  margin-right: 113rem !important; }

.m-b-113 {
  margin-bottom: 113rem !important; }

.m-l-113 {
  margin-left: 113rem !important; }

.m-x-113 {
  margin-right: 113rem !important;
  margin-left: 113rem !important; }

.m-y-113 {
  margin-top: 113rem !important;
  margin-bottom: 113rem !important; }

.m-a-114 {
  margin: 114rem 114rem !important; }

.m-t-114 {
  margin-top: 114rem !important; }

.m-r-114 {
  margin-right: 114rem !important; }

.m-b-114 {
  margin-bottom: 114rem !important; }

.m-l-114 {
  margin-left: 114rem !important; }

.m-x-114 {
  margin-right: 114rem !important;
  margin-left: 114rem !important; }

.m-y-114 {
  margin-top: 114rem !important;
  margin-bottom: 114rem !important; }

.m-a-115 {
  margin: 115rem 115rem !important; }

.m-t-115 {
  margin-top: 115rem !important; }

.m-r-115 {
  margin-right: 115rem !important; }

.m-b-115 {
  margin-bottom: 115rem !important; }

.m-l-115 {
  margin-left: 115rem !important; }

.m-x-115 {
  margin-right: 115rem !important;
  margin-left: 115rem !important; }

.m-y-115 {
  margin-top: 115rem !important;
  margin-bottom: 115rem !important; }

.m-a-116 {
  margin: 116rem 116rem !important; }

.m-t-116 {
  margin-top: 116rem !important; }

.m-r-116 {
  margin-right: 116rem !important; }

.m-b-116 {
  margin-bottom: 116rem !important; }

.m-l-116 {
  margin-left: 116rem !important; }

.m-x-116 {
  margin-right: 116rem !important;
  margin-left: 116rem !important; }

.m-y-116 {
  margin-top: 116rem !important;
  margin-bottom: 116rem !important; }

.m-a-117 {
  margin: 117rem 117rem !important; }

.m-t-117 {
  margin-top: 117rem !important; }

.m-r-117 {
  margin-right: 117rem !important; }

.m-b-117 {
  margin-bottom: 117rem !important; }

.m-l-117 {
  margin-left: 117rem !important; }

.m-x-117 {
  margin-right: 117rem !important;
  margin-left: 117rem !important; }

.m-y-117 {
  margin-top: 117rem !important;
  margin-bottom: 117rem !important; }

.m-a-118 {
  margin: 118rem 118rem !important; }

.m-t-118 {
  margin-top: 118rem !important; }

.m-r-118 {
  margin-right: 118rem !important; }

.m-b-118 {
  margin-bottom: 118rem !important; }

.m-l-118 {
  margin-left: 118rem !important; }

.m-x-118 {
  margin-right: 118rem !important;
  margin-left: 118rem !important; }

.m-y-118 {
  margin-top: 118rem !important;
  margin-bottom: 118rem !important; }

.m-a-119 {
  margin: 119rem 119rem !important; }

.m-t-119 {
  margin-top: 119rem !important; }

.m-r-119 {
  margin-right: 119rem !important; }

.m-b-119 {
  margin-bottom: 119rem !important; }

.m-l-119 {
  margin-left: 119rem !important; }

.m-x-119 {
  margin-right: 119rem !important;
  margin-left: 119rem !important; }

.m-y-119 {
  margin-top: 119rem !important;
  margin-bottom: 119rem !important; }

.m-a-120 {
  margin: 120rem 120rem !important; }

.m-t-120 {
  margin-top: 120rem !important; }

.m-r-120 {
  margin-right: 120rem !important; }

.m-b-120 {
  margin-bottom: 120rem !important; }

.m-l-120 {
  margin-left: 120rem !important; }

.m-x-120 {
  margin-right: 120rem !important;
  margin-left: 120rem !important; }

.m-y-120 {
  margin-top: 120rem !important;
  margin-bottom: 120rem !important; }

.m-a-121 {
  margin: 121rem 121rem !important; }

.m-t-121 {
  margin-top: 121rem !important; }

.m-r-121 {
  margin-right: 121rem !important; }

.m-b-121 {
  margin-bottom: 121rem !important; }

.m-l-121 {
  margin-left: 121rem !important; }

.m-x-121 {
  margin-right: 121rem !important;
  margin-left: 121rem !important; }

.m-y-121 {
  margin-top: 121rem !important;
  margin-bottom: 121rem !important; }

.m-a-122 {
  margin: 122rem 122rem !important; }

.m-t-122 {
  margin-top: 122rem !important; }

.m-r-122 {
  margin-right: 122rem !important; }

.m-b-122 {
  margin-bottom: 122rem !important; }

.m-l-122 {
  margin-left: 122rem !important; }

.m-x-122 {
  margin-right: 122rem !important;
  margin-left: 122rem !important; }

.m-y-122 {
  margin-top: 122rem !important;
  margin-bottom: 122rem !important; }

.m-a-123 {
  margin: 123rem 123rem !important; }

.m-t-123 {
  margin-top: 123rem !important; }

.m-r-123 {
  margin-right: 123rem !important; }

.m-b-123 {
  margin-bottom: 123rem !important; }

.m-l-123 {
  margin-left: 123rem !important; }

.m-x-123 {
  margin-right: 123rem !important;
  margin-left: 123rem !important; }

.m-y-123 {
  margin-top: 123rem !important;
  margin-bottom: 123rem !important; }

.m-a-124 {
  margin: 124rem 124rem !important; }

.m-t-124 {
  margin-top: 124rem !important; }

.m-r-124 {
  margin-right: 124rem !important; }

.m-b-124 {
  margin-bottom: 124rem !important; }

.m-l-124 {
  margin-left: 124rem !important; }

.m-x-124 {
  margin-right: 124rem !important;
  margin-left: 124rem !important; }

.m-y-124 {
  margin-top: 124rem !important;
  margin-bottom: 124rem !important; }

.m-a-125 {
  margin: 125rem 125rem !important; }

.m-t-125 {
  margin-top: 125rem !important; }

.m-r-125 {
  margin-right: 125rem !important; }

.m-b-125 {
  margin-bottom: 125rem !important; }

.m-l-125 {
  margin-left: 125rem !important; }

.m-x-125 {
  margin-right: 125rem !important;
  margin-left: 125rem !important; }

.m-y-125 {
  margin-top: 125rem !important;
  margin-bottom: 125rem !important; }

.m-a-126 {
  margin: 126rem 126rem !important; }

.m-t-126 {
  margin-top: 126rem !important; }

.m-r-126 {
  margin-right: 126rem !important; }

.m-b-126 {
  margin-bottom: 126rem !important; }

.m-l-126 {
  margin-left: 126rem !important; }

.m-x-126 {
  margin-right: 126rem !important;
  margin-left: 126rem !important; }

.m-y-126 {
  margin-top: 126rem !important;
  margin-bottom: 126rem !important; }

.m-a-127 {
  margin: 127rem 127rem !important; }

.m-t-127 {
  margin-top: 127rem !important; }

.m-r-127 {
  margin-right: 127rem !important; }

.m-b-127 {
  margin-bottom: 127rem !important; }

.m-l-127 {
  margin-left: 127rem !important; }

.m-x-127 {
  margin-right: 127rem !important;
  margin-left: 127rem !important; }

.m-y-127 {
  margin-top: 127rem !important;
  margin-bottom: 127rem !important; }

.m-a-128 {
  margin: 128rem 128rem !important; }

.m-t-128 {
  margin-top: 128rem !important; }

.m-r-128 {
  margin-right: 128rem !important; }

.m-b-128 {
  margin-bottom: 128rem !important; }

.m-l-128 {
  margin-left: 128rem !important; }

.m-x-128 {
  margin-right: 128rem !important;
  margin-left: 128rem !important; }

.m-y-128 {
  margin-top: 128rem !important;
  margin-bottom: 128rem !important; }

.m-a-129 {
  margin: 129rem 129rem !important; }

.m-t-129 {
  margin-top: 129rem !important; }

.m-r-129 {
  margin-right: 129rem !important; }

.m-b-129 {
  margin-bottom: 129rem !important; }

.m-l-129 {
  margin-left: 129rem !important; }

.m-x-129 {
  margin-right: 129rem !important;
  margin-left: 129rem !important; }

.m-y-129 {
  margin-top: 129rem !important;
  margin-bottom: 129rem !important; }

.m-a-130 {
  margin: 130rem 130rem !important; }

.m-t-130 {
  margin-top: 130rem !important; }

.m-r-130 {
  margin-right: 130rem !important; }

.m-b-130 {
  margin-bottom: 130rem !important; }

.m-l-130 {
  margin-left: 130rem !important; }

.m-x-130 {
  margin-right: 130rem !important;
  margin-left: 130rem !important; }

.m-y-130 {
  margin-top: 130rem !important;
  margin-bottom: 130rem !important; }

.m-a-131 {
  margin: 131rem 131rem !important; }

.m-t-131 {
  margin-top: 131rem !important; }

.m-r-131 {
  margin-right: 131rem !important; }

.m-b-131 {
  margin-bottom: 131rem !important; }

.m-l-131 {
  margin-left: 131rem !important; }

.m-x-131 {
  margin-right: 131rem !important;
  margin-left: 131rem !important; }

.m-y-131 {
  margin-top: 131rem !important;
  margin-bottom: 131rem !important; }

.m-a-132 {
  margin: 132rem 132rem !important; }

.m-t-132 {
  margin-top: 132rem !important; }

.m-r-132 {
  margin-right: 132rem !important; }

.m-b-132 {
  margin-bottom: 132rem !important; }

.m-l-132 {
  margin-left: 132rem !important; }

.m-x-132 {
  margin-right: 132rem !important;
  margin-left: 132rem !important; }

.m-y-132 {
  margin-top: 132rem !important;
  margin-bottom: 132rem !important; }

.m-a-133 {
  margin: 133rem 133rem !important; }

.m-t-133 {
  margin-top: 133rem !important; }

.m-r-133 {
  margin-right: 133rem !important; }

.m-b-133 {
  margin-bottom: 133rem !important; }

.m-l-133 {
  margin-left: 133rem !important; }

.m-x-133 {
  margin-right: 133rem !important;
  margin-left: 133rem !important; }

.m-y-133 {
  margin-top: 133rem !important;
  margin-bottom: 133rem !important; }

.m-a-134 {
  margin: 134rem 134rem !important; }

.m-t-134 {
  margin-top: 134rem !important; }

.m-r-134 {
  margin-right: 134rem !important; }

.m-b-134 {
  margin-bottom: 134rem !important; }

.m-l-134 {
  margin-left: 134rem !important; }

.m-x-134 {
  margin-right: 134rem !important;
  margin-left: 134rem !important; }

.m-y-134 {
  margin-top: 134rem !important;
  margin-bottom: 134rem !important; }

.m-a-135 {
  margin: 135rem 135rem !important; }

.m-t-135 {
  margin-top: 135rem !important; }

.m-r-135 {
  margin-right: 135rem !important; }

.m-b-135 {
  margin-bottom: 135rem !important; }

.m-l-135 {
  margin-left: 135rem !important; }

.m-x-135 {
  margin-right: 135rem !important;
  margin-left: 135rem !important; }

.m-y-135 {
  margin-top: 135rem !important;
  margin-bottom: 135rem !important; }

.m-a-136 {
  margin: 136rem 136rem !important; }

.m-t-136 {
  margin-top: 136rem !important; }

.m-r-136 {
  margin-right: 136rem !important; }

.m-b-136 {
  margin-bottom: 136rem !important; }

.m-l-136 {
  margin-left: 136rem !important; }

.m-x-136 {
  margin-right: 136rem !important;
  margin-left: 136rem !important; }

.m-y-136 {
  margin-top: 136rem !important;
  margin-bottom: 136rem !important; }

.m-a-137 {
  margin: 137rem 137rem !important; }

.m-t-137 {
  margin-top: 137rem !important; }

.m-r-137 {
  margin-right: 137rem !important; }

.m-b-137 {
  margin-bottom: 137rem !important; }

.m-l-137 {
  margin-left: 137rem !important; }

.m-x-137 {
  margin-right: 137rem !important;
  margin-left: 137rem !important; }

.m-y-137 {
  margin-top: 137rem !important;
  margin-bottom: 137rem !important; }

.m-a-138 {
  margin: 138rem 138rem !important; }

.m-t-138 {
  margin-top: 138rem !important; }

.m-r-138 {
  margin-right: 138rem !important; }

.m-b-138 {
  margin-bottom: 138rem !important; }

.m-l-138 {
  margin-left: 138rem !important; }

.m-x-138 {
  margin-right: 138rem !important;
  margin-left: 138rem !important; }

.m-y-138 {
  margin-top: 138rem !important;
  margin-bottom: 138rem !important; }

.m-a-139 {
  margin: 139rem 139rem !important; }

.m-t-139 {
  margin-top: 139rem !important; }

.m-r-139 {
  margin-right: 139rem !important; }

.m-b-139 {
  margin-bottom: 139rem !important; }

.m-l-139 {
  margin-left: 139rem !important; }

.m-x-139 {
  margin-right: 139rem !important;
  margin-left: 139rem !important; }

.m-y-139 {
  margin-top: 139rem !important;
  margin-bottom: 139rem !important; }

.m-a-140 {
  margin: 140rem 140rem !important; }

.m-t-140 {
  margin-top: 140rem !important; }

.m-r-140 {
  margin-right: 140rem !important; }

.m-b-140 {
  margin-bottom: 140rem !important; }

.m-l-140 {
  margin-left: 140rem !important; }

.m-x-140 {
  margin-right: 140rem !important;
  margin-left: 140rem !important; }

.m-y-140 {
  margin-top: 140rem !important;
  margin-bottom: 140rem !important; }

.m-a-141 {
  margin: 141rem 141rem !important; }

.m-t-141 {
  margin-top: 141rem !important; }

.m-r-141 {
  margin-right: 141rem !important; }

.m-b-141 {
  margin-bottom: 141rem !important; }

.m-l-141 {
  margin-left: 141rem !important; }

.m-x-141 {
  margin-right: 141rem !important;
  margin-left: 141rem !important; }

.m-y-141 {
  margin-top: 141rem !important;
  margin-bottom: 141rem !important; }

.m-a-142 {
  margin: 142rem 142rem !important; }

.m-t-142 {
  margin-top: 142rem !important; }

.m-r-142 {
  margin-right: 142rem !important; }

.m-b-142 {
  margin-bottom: 142rem !important; }

.m-l-142 {
  margin-left: 142rem !important; }

.m-x-142 {
  margin-right: 142rem !important;
  margin-left: 142rem !important; }

.m-y-142 {
  margin-top: 142rem !important;
  margin-bottom: 142rem !important; }

.m-a-143 {
  margin: 143rem 143rem !important; }

.m-t-143 {
  margin-top: 143rem !important; }

.m-r-143 {
  margin-right: 143rem !important; }

.m-b-143 {
  margin-bottom: 143rem !important; }

.m-l-143 {
  margin-left: 143rem !important; }

.m-x-143 {
  margin-right: 143rem !important;
  margin-left: 143rem !important; }

.m-y-143 {
  margin-top: 143rem !important;
  margin-bottom: 143rem !important; }

.m-a-144 {
  margin: 144rem 144rem !important; }

.m-t-144 {
  margin-top: 144rem !important; }

.m-r-144 {
  margin-right: 144rem !important; }

.m-b-144 {
  margin-bottom: 144rem !important; }

.m-l-144 {
  margin-left: 144rem !important; }

.m-x-144 {
  margin-right: 144rem !important;
  margin-left: 144rem !important; }

.m-y-144 {
  margin-top: 144rem !important;
  margin-bottom: 144rem !important; }

.m-a-145 {
  margin: 145rem 145rem !important; }

.m-t-145 {
  margin-top: 145rem !important; }

.m-r-145 {
  margin-right: 145rem !important; }

.m-b-145 {
  margin-bottom: 145rem !important; }

.m-l-145 {
  margin-left: 145rem !important; }

.m-x-145 {
  margin-right: 145rem !important;
  margin-left: 145rem !important; }

.m-y-145 {
  margin-top: 145rem !important;
  margin-bottom: 145rem !important; }

.m-a-146 {
  margin: 146rem 146rem !important; }

.m-t-146 {
  margin-top: 146rem !important; }

.m-r-146 {
  margin-right: 146rem !important; }

.m-b-146 {
  margin-bottom: 146rem !important; }

.m-l-146 {
  margin-left: 146rem !important; }

.m-x-146 {
  margin-right: 146rem !important;
  margin-left: 146rem !important; }

.m-y-146 {
  margin-top: 146rem !important;
  margin-bottom: 146rem !important; }

.m-a-147 {
  margin: 147rem 147rem !important; }

.m-t-147 {
  margin-top: 147rem !important; }

.m-r-147 {
  margin-right: 147rem !important; }

.m-b-147 {
  margin-bottom: 147rem !important; }

.m-l-147 {
  margin-left: 147rem !important; }

.m-x-147 {
  margin-right: 147rem !important;
  margin-left: 147rem !important; }

.m-y-147 {
  margin-top: 147rem !important;
  margin-bottom: 147rem !important; }

.m-a-148 {
  margin: 148rem 148rem !important; }

.m-t-148 {
  margin-top: 148rem !important; }

.m-r-148 {
  margin-right: 148rem !important; }

.m-b-148 {
  margin-bottom: 148rem !important; }

.m-l-148 {
  margin-left: 148rem !important; }

.m-x-148 {
  margin-right: 148rem !important;
  margin-left: 148rem !important; }

.m-y-148 {
  margin-top: 148rem !important;
  margin-bottom: 148rem !important; }

.m-a-149 {
  margin: 149rem 149rem !important; }

.m-t-149 {
  margin-top: 149rem !important; }

.m-r-149 {
  margin-right: 149rem !important; }

.m-b-149 {
  margin-bottom: 149rem !important; }

.m-l-149 {
  margin-left: 149rem !important; }

.m-x-149 {
  margin-right: 149rem !important;
  margin-left: 149rem !important; }

.m-y-149 {
  margin-top: 149rem !important;
  margin-bottom: 149rem !important; }

.m-a-150 {
  margin: 150rem 150rem !important; }

.m-t-150 {
  margin-top: 150rem !important; }

.m-r-150 {
  margin-right: 150rem !important; }

.m-b-150 {
  margin-bottom: 150rem !important; }

.m-l-150 {
  margin-left: 150rem !important; }

.m-x-150 {
  margin-right: 150rem !important;
  margin-left: 150rem !important; }

.m-y-150 {
  margin-top: 150rem !important;
  margin-bottom: 150rem !important; }

.m-a-151 {
  margin: 151rem 151rem !important; }

.m-t-151 {
  margin-top: 151rem !important; }

.m-r-151 {
  margin-right: 151rem !important; }

.m-b-151 {
  margin-bottom: 151rem !important; }

.m-l-151 {
  margin-left: 151rem !important; }

.m-x-151 {
  margin-right: 151rem !important;
  margin-left: 151rem !important; }

.m-y-151 {
  margin-top: 151rem !important;
  margin-bottom: 151rem !important; }

.m-a-152 {
  margin: 152rem 152rem !important; }

.m-t-152 {
  margin-top: 152rem !important; }

.m-r-152 {
  margin-right: 152rem !important; }

.m-b-152 {
  margin-bottom: 152rem !important; }

.m-l-152 {
  margin-left: 152rem !important; }

.m-x-152 {
  margin-right: 152rem !important;
  margin-left: 152rem !important; }

.m-y-152 {
  margin-top: 152rem !important;
  margin-bottom: 152rem !important; }

.m-a-153 {
  margin: 153rem 153rem !important; }

.m-t-153 {
  margin-top: 153rem !important; }

.m-r-153 {
  margin-right: 153rem !important; }

.m-b-153 {
  margin-bottom: 153rem !important; }

.m-l-153 {
  margin-left: 153rem !important; }

.m-x-153 {
  margin-right: 153rem !important;
  margin-left: 153rem !important; }

.m-y-153 {
  margin-top: 153rem !important;
  margin-bottom: 153rem !important; }

.m-a-154 {
  margin: 154rem 154rem !important; }

.m-t-154 {
  margin-top: 154rem !important; }

.m-r-154 {
  margin-right: 154rem !important; }

.m-b-154 {
  margin-bottom: 154rem !important; }

.m-l-154 {
  margin-left: 154rem !important; }

.m-x-154 {
  margin-right: 154rem !important;
  margin-left: 154rem !important; }

.m-y-154 {
  margin-top: 154rem !important;
  margin-bottom: 154rem !important; }

.m-a-155 {
  margin: 155rem 155rem !important; }

.m-t-155 {
  margin-top: 155rem !important; }

.m-r-155 {
  margin-right: 155rem !important; }

.m-b-155 {
  margin-bottom: 155rem !important; }

.m-l-155 {
  margin-left: 155rem !important; }

.m-x-155 {
  margin-right: 155rem !important;
  margin-left: 155rem !important; }

.m-y-155 {
  margin-top: 155rem !important;
  margin-bottom: 155rem !important; }

.m-a-156 {
  margin: 156rem 156rem !important; }

.m-t-156 {
  margin-top: 156rem !important; }

.m-r-156 {
  margin-right: 156rem !important; }

.m-b-156 {
  margin-bottom: 156rem !important; }

.m-l-156 {
  margin-left: 156rem !important; }

.m-x-156 {
  margin-right: 156rem !important;
  margin-left: 156rem !important; }

.m-y-156 {
  margin-top: 156rem !important;
  margin-bottom: 156rem !important; }

.m-a-157 {
  margin: 157rem 157rem !important; }

.m-t-157 {
  margin-top: 157rem !important; }

.m-r-157 {
  margin-right: 157rem !important; }

.m-b-157 {
  margin-bottom: 157rem !important; }

.m-l-157 {
  margin-left: 157rem !important; }

.m-x-157 {
  margin-right: 157rem !important;
  margin-left: 157rem !important; }

.m-y-157 {
  margin-top: 157rem !important;
  margin-bottom: 157rem !important; }

.m-a-158 {
  margin: 158rem 158rem !important; }

.m-t-158 {
  margin-top: 158rem !important; }

.m-r-158 {
  margin-right: 158rem !important; }

.m-b-158 {
  margin-bottom: 158rem !important; }

.m-l-158 {
  margin-left: 158rem !important; }

.m-x-158 {
  margin-right: 158rem !important;
  margin-left: 158rem !important; }

.m-y-158 {
  margin-top: 158rem !important;
  margin-bottom: 158rem !important; }

.m-a-159 {
  margin: 159rem 159rem !important; }

.m-t-159 {
  margin-top: 159rem !important; }

.m-r-159 {
  margin-right: 159rem !important; }

.m-b-159 {
  margin-bottom: 159rem !important; }

.m-l-159 {
  margin-left: 159rem !important; }

.m-x-159 {
  margin-right: 159rem !important;
  margin-left: 159rem !important; }

.m-y-159 {
  margin-top: 159rem !important;
  margin-bottom: 159rem !important; }

.m-a-160 {
  margin: 160rem 160rem !important; }

.m-t-160 {
  margin-top: 160rem !important; }

.m-r-160 {
  margin-right: 160rem !important; }

.m-b-160 {
  margin-bottom: 160rem !important; }

.m-l-160 {
  margin-left: 160rem !important; }

.m-x-160 {
  margin-right: 160rem !important;
  margin-left: 160rem !important; }

.m-y-160 {
  margin-top: 160rem !important;
  margin-bottom: 160rem !important; }

.m-a-161 {
  margin: 161rem 161rem !important; }

.m-t-161 {
  margin-top: 161rem !important; }

.m-r-161 {
  margin-right: 161rem !important; }

.m-b-161 {
  margin-bottom: 161rem !important; }

.m-l-161 {
  margin-left: 161rem !important; }

.m-x-161 {
  margin-right: 161rem !important;
  margin-left: 161rem !important; }

.m-y-161 {
  margin-top: 161rem !important;
  margin-bottom: 161rem !important; }

.m-a-162 {
  margin: 162rem 162rem !important; }

.m-t-162 {
  margin-top: 162rem !important; }

.m-r-162 {
  margin-right: 162rem !important; }

.m-b-162 {
  margin-bottom: 162rem !important; }

.m-l-162 {
  margin-left: 162rem !important; }

.m-x-162 {
  margin-right: 162rem !important;
  margin-left: 162rem !important; }

.m-y-162 {
  margin-top: 162rem !important;
  margin-bottom: 162rem !important; }

.m-a-163 {
  margin: 163rem 163rem !important; }

.m-t-163 {
  margin-top: 163rem !important; }

.m-r-163 {
  margin-right: 163rem !important; }

.m-b-163 {
  margin-bottom: 163rem !important; }

.m-l-163 {
  margin-left: 163rem !important; }

.m-x-163 {
  margin-right: 163rem !important;
  margin-left: 163rem !important; }

.m-y-163 {
  margin-top: 163rem !important;
  margin-bottom: 163rem !important; }

.m-a-164 {
  margin: 164rem 164rem !important; }

.m-t-164 {
  margin-top: 164rem !important; }

.m-r-164 {
  margin-right: 164rem !important; }

.m-b-164 {
  margin-bottom: 164rem !important; }

.m-l-164 {
  margin-left: 164rem !important; }

.m-x-164 {
  margin-right: 164rem !important;
  margin-left: 164rem !important; }

.m-y-164 {
  margin-top: 164rem !important;
  margin-bottom: 164rem !important; }

.m-a-165 {
  margin: 165rem 165rem !important; }

.m-t-165 {
  margin-top: 165rem !important; }

.m-r-165 {
  margin-right: 165rem !important; }

.m-b-165 {
  margin-bottom: 165rem !important; }

.m-l-165 {
  margin-left: 165rem !important; }

.m-x-165 {
  margin-right: 165rem !important;
  margin-left: 165rem !important; }

.m-y-165 {
  margin-top: 165rem !important;
  margin-bottom: 165rem !important; }

.m-a-166 {
  margin: 166rem 166rem !important; }

.m-t-166 {
  margin-top: 166rem !important; }

.m-r-166 {
  margin-right: 166rem !important; }

.m-b-166 {
  margin-bottom: 166rem !important; }

.m-l-166 {
  margin-left: 166rem !important; }

.m-x-166 {
  margin-right: 166rem !important;
  margin-left: 166rem !important; }

.m-y-166 {
  margin-top: 166rem !important;
  margin-bottom: 166rem !important; }

.m-a-167 {
  margin: 167rem 167rem !important; }

.m-t-167 {
  margin-top: 167rem !important; }

.m-r-167 {
  margin-right: 167rem !important; }

.m-b-167 {
  margin-bottom: 167rem !important; }

.m-l-167 {
  margin-left: 167rem !important; }

.m-x-167 {
  margin-right: 167rem !important;
  margin-left: 167rem !important; }

.m-y-167 {
  margin-top: 167rem !important;
  margin-bottom: 167rem !important; }

.m-a-168 {
  margin: 168rem 168rem !important; }

.m-t-168 {
  margin-top: 168rem !important; }

.m-r-168 {
  margin-right: 168rem !important; }

.m-b-168 {
  margin-bottom: 168rem !important; }

.m-l-168 {
  margin-left: 168rem !important; }

.m-x-168 {
  margin-right: 168rem !important;
  margin-left: 168rem !important; }

.m-y-168 {
  margin-top: 168rem !important;
  margin-bottom: 168rem !important; }

.m-a-169 {
  margin: 169rem 169rem !important; }

.m-t-169 {
  margin-top: 169rem !important; }

.m-r-169 {
  margin-right: 169rem !important; }

.m-b-169 {
  margin-bottom: 169rem !important; }

.m-l-169 {
  margin-left: 169rem !important; }

.m-x-169 {
  margin-right: 169rem !important;
  margin-left: 169rem !important; }

.m-y-169 {
  margin-top: 169rem !important;
  margin-bottom: 169rem !important; }

.m-a-170 {
  margin: 170rem 170rem !important; }

.m-t-170 {
  margin-top: 170rem !important; }

.m-r-170 {
  margin-right: 170rem !important; }

.m-b-170 {
  margin-bottom: 170rem !important; }

.m-l-170 {
  margin-left: 170rem !important; }

.m-x-170 {
  margin-right: 170rem !important;
  margin-left: 170rem !important; }

.m-y-170 {
  margin-top: 170rem !important;
  margin-bottom: 170rem !important; }

.m-a-171 {
  margin: 171rem 171rem !important; }

.m-t-171 {
  margin-top: 171rem !important; }

.m-r-171 {
  margin-right: 171rem !important; }

.m-b-171 {
  margin-bottom: 171rem !important; }

.m-l-171 {
  margin-left: 171rem !important; }

.m-x-171 {
  margin-right: 171rem !important;
  margin-left: 171rem !important; }

.m-y-171 {
  margin-top: 171rem !important;
  margin-bottom: 171rem !important; }

.m-a-172 {
  margin: 172rem 172rem !important; }

.m-t-172 {
  margin-top: 172rem !important; }

.m-r-172 {
  margin-right: 172rem !important; }

.m-b-172 {
  margin-bottom: 172rem !important; }

.m-l-172 {
  margin-left: 172rem !important; }

.m-x-172 {
  margin-right: 172rem !important;
  margin-left: 172rem !important; }

.m-y-172 {
  margin-top: 172rem !important;
  margin-bottom: 172rem !important; }

.m-a-173 {
  margin: 173rem 173rem !important; }

.m-t-173 {
  margin-top: 173rem !important; }

.m-r-173 {
  margin-right: 173rem !important; }

.m-b-173 {
  margin-bottom: 173rem !important; }

.m-l-173 {
  margin-left: 173rem !important; }

.m-x-173 {
  margin-right: 173rem !important;
  margin-left: 173rem !important; }

.m-y-173 {
  margin-top: 173rem !important;
  margin-bottom: 173rem !important; }

.m-a-174 {
  margin: 174rem 174rem !important; }

.m-t-174 {
  margin-top: 174rem !important; }

.m-r-174 {
  margin-right: 174rem !important; }

.m-b-174 {
  margin-bottom: 174rem !important; }

.m-l-174 {
  margin-left: 174rem !important; }

.m-x-174 {
  margin-right: 174rem !important;
  margin-left: 174rem !important; }

.m-y-174 {
  margin-top: 174rem !important;
  margin-bottom: 174rem !important; }

.m-a-175 {
  margin: 175rem 175rem !important; }

.m-t-175 {
  margin-top: 175rem !important; }

.m-r-175 {
  margin-right: 175rem !important; }

.m-b-175 {
  margin-bottom: 175rem !important; }

.m-l-175 {
  margin-left: 175rem !important; }

.m-x-175 {
  margin-right: 175rem !important;
  margin-left: 175rem !important; }

.m-y-175 {
  margin-top: 175rem !important;
  margin-bottom: 175rem !important; }

.m-a-176 {
  margin: 176rem 176rem !important; }

.m-t-176 {
  margin-top: 176rem !important; }

.m-r-176 {
  margin-right: 176rem !important; }

.m-b-176 {
  margin-bottom: 176rem !important; }

.m-l-176 {
  margin-left: 176rem !important; }

.m-x-176 {
  margin-right: 176rem !important;
  margin-left: 176rem !important; }

.m-y-176 {
  margin-top: 176rem !important;
  margin-bottom: 176rem !important; }

.m-a-177 {
  margin: 177rem 177rem !important; }

.m-t-177 {
  margin-top: 177rem !important; }

.m-r-177 {
  margin-right: 177rem !important; }

.m-b-177 {
  margin-bottom: 177rem !important; }

.m-l-177 {
  margin-left: 177rem !important; }

.m-x-177 {
  margin-right: 177rem !important;
  margin-left: 177rem !important; }

.m-y-177 {
  margin-top: 177rem !important;
  margin-bottom: 177rem !important; }

.m-a-178 {
  margin: 178rem 178rem !important; }

.m-t-178 {
  margin-top: 178rem !important; }

.m-r-178 {
  margin-right: 178rem !important; }

.m-b-178 {
  margin-bottom: 178rem !important; }

.m-l-178 {
  margin-left: 178rem !important; }

.m-x-178 {
  margin-right: 178rem !important;
  margin-left: 178rem !important; }

.m-y-178 {
  margin-top: 178rem !important;
  margin-bottom: 178rem !important; }

.m-a-179 {
  margin: 179rem 179rem !important; }

.m-t-179 {
  margin-top: 179rem !important; }

.m-r-179 {
  margin-right: 179rem !important; }

.m-b-179 {
  margin-bottom: 179rem !important; }

.m-l-179 {
  margin-left: 179rem !important; }

.m-x-179 {
  margin-right: 179rem !important;
  margin-left: 179rem !important; }

.m-y-179 {
  margin-top: 179rem !important;
  margin-bottom: 179rem !important; }

.m-a-180 {
  margin: 180rem 180rem !important; }

.m-t-180 {
  margin-top: 180rem !important; }

.m-r-180 {
  margin-right: 180rem !important; }

.m-b-180 {
  margin-bottom: 180rem !important; }

.m-l-180 {
  margin-left: 180rem !important; }

.m-x-180 {
  margin-right: 180rem !important;
  margin-left: 180rem !important; }

.m-y-180 {
  margin-top: 180rem !important;
  margin-bottom: 180rem !important; }

.m-a-181 {
  margin: 181rem 181rem !important; }

.m-t-181 {
  margin-top: 181rem !important; }

.m-r-181 {
  margin-right: 181rem !important; }

.m-b-181 {
  margin-bottom: 181rem !important; }

.m-l-181 {
  margin-left: 181rem !important; }

.m-x-181 {
  margin-right: 181rem !important;
  margin-left: 181rem !important; }

.m-y-181 {
  margin-top: 181rem !important;
  margin-bottom: 181rem !important; }

.m-a-182 {
  margin: 182rem 182rem !important; }

.m-t-182 {
  margin-top: 182rem !important; }

.m-r-182 {
  margin-right: 182rem !important; }

.m-b-182 {
  margin-bottom: 182rem !important; }

.m-l-182 {
  margin-left: 182rem !important; }

.m-x-182 {
  margin-right: 182rem !important;
  margin-left: 182rem !important; }

.m-y-182 {
  margin-top: 182rem !important;
  margin-bottom: 182rem !important; }

.m-a-183 {
  margin: 183rem 183rem !important; }

.m-t-183 {
  margin-top: 183rem !important; }

.m-r-183 {
  margin-right: 183rem !important; }

.m-b-183 {
  margin-bottom: 183rem !important; }

.m-l-183 {
  margin-left: 183rem !important; }

.m-x-183 {
  margin-right: 183rem !important;
  margin-left: 183rem !important; }

.m-y-183 {
  margin-top: 183rem !important;
  margin-bottom: 183rem !important; }

.m-a-184 {
  margin: 184rem 184rem !important; }

.m-t-184 {
  margin-top: 184rem !important; }

.m-r-184 {
  margin-right: 184rem !important; }

.m-b-184 {
  margin-bottom: 184rem !important; }

.m-l-184 {
  margin-left: 184rem !important; }

.m-x-184 {
  margin-right: 184rem !important;
  margin-left: 184rem !important; }

.m-y-184 {
  margin-top: 184rem !important;
  margin-bottom: 184rem !important; }

.m-a-185 {
  margin: 185rem 185rem !important; }

.m-t-185 {
  margin-top: 185rem !important; }

.m-r-185 {
  margin-right: 185rem !important; }

.m-b-185 {
  margin-bottom: 185rem !important; }

.m-l-185 {
  margin-left: 185rem !important; }

.m-x-185 {
  margin-right: 185rem !important;
  margin-left: 185rem !important; }

.m-y-185 {
  margin-top: 185rem !important;
  margin-bottom: 185rem !important; }

.m-a-186 {
  margin: 186rem 186rem !important; }

.m-t-186 {
  margin-top: 186rem !important; }

.m-r-186 {
  margin-right: 186rem !important; }

.m-b-186 {
  margin-bottom: 186rem !important; }

.m-l-186 {
  margin-left: 186rem !important; }

.m-x-186 {
  margin-right: 186rem !important;
  margin-left: 186rem !important; }

.m-y-186 {
  margin-top: 186rem !important;
  margin-bottom: 186rem !important; }

.m-a-187 {
  margin: 187rem 187rem !important; }

.m-t-187 {
  margin-top: 187rem !important; }

.m-r-187 {
  margin-right: 187rem !important; }

.m-b-187 {
  margin-bottom: 187rem !important; }

.m-l-187 {
  margin-left: 187rem !important; }

.m-x-187 {
  margin-right: 187rem !important;
  margin-left: 187rem !important; }

.m-y-187 {
  margin-top: 187rem !important;
  margin-bottom: 187rem !important; }

.m-a-188 {
  margin: 188rem 188rem !important; }

.m-t-188 {
  margin-top: 188rem !important; }

.m-r-188 {
  margin-right: 188rem !important; }

.m-b-188 {
  margin-bottom: 188rem !important; }

.m-l-188 {
  margin-left: 188rem !important; }

.m-x-188 {
  margin-right: 188rem !important;
  margin-left: 188rem !important; }

.m-y-188 {
  margin-top: 188rem !important;
  margin-bottom: 188rem !important; }

.m-a-189 {
  margin: 189rem 189rem !important; }

.m-t-189 {
  margin-top: 189rem !important; }

.m-r-189 {
  margin-right: 189rem !important; }

.m-b-189 {
  margin-bottom: 189rem !important; }

.m-l-189 {
  margin-left: 189rem !important; }

.m-x-189 {
  margin-right: 189rem !important;
  margin-left: 189rem !important; }

.m-y-189 {
  margin-top: 189rem !important;
  margin-bottom: 189rem !important; }

.m-a-190 {
  margin: 190rem 190rem !important; }

.m-t-190 {
  margin-top: 190rem !important; }

.m-r-190 {
  margin-right: 190rem !important; }

.m-b-190 {
  margin-bottom: 190rem !important; }

.m-l-190 {
  margin-left: 190rem !important; }

.m-x-190 {
  margin-right: 190rem !important;
  margin-left: 190rem !important; }

.m-y-190 {
  margin-top: 190rem !important;
  margin-bottom: 190rem !important; }

.m-a-191 {
  margin: 191rem 191rem !important; }

.m-t-191 {
  margin-top: 191rem !important; }

.m-r-191 {
  margin-right: 191rem !important; }

.m-b-191 {
  margin-bottom: 191rem !important; }

.m-l-191 {
  margin-left: 191rem !important; }

.m-x-191 {
  margin-right: 191rem !important;
  margin-left: 191rem !important; }

.m-y-191 {
  margin-top: 191rem !important;
  margin-bottom: 191rem !important; }

.m-a-192 {
  margin: 192rem 192rem !important; }

.m-t-192 {
  margin-top: 192rem !important; }

.m-r-192 {
  margin-right: 192rem !important; }

.m-b-192 {
  margin-bottom: 192rem !important; }

.m-l-192 {
  margin-left: 192rem !important; }

.m-x-192 {
  margin-right: 192rem !important;
  margin-left: 192rem !important; }

.m-y-192 {
  margin-top: 192rem !important;
  margin-bottom: 192rem !important; }

.m-a-193 {
  margin: 193rem 193rem !important; }

.m-t-193 {
  margin-top: 193rem !important; }

.m-r-193 {
  margin-right: 193rem !important; }

.m-b-193 {
  margin-bottom: 193rem !important; }

.m-l-193 {
  margin-left: 193rem !important; }

.m-x-193 {
  margin-right: 193rem !important;
  margin-left: 193rem !important; }

.m-y-193 {
  margin-top: 193rem !important;
  margin-bottom: 193rem !important; }

.m-a-194 {
  margin: 194rem 194rem !important; }

.m-t-194 {
  margin-top: 194rem !important; }

.m-r-194 {
  margin-right: 194rem !important; }

.m-b-194 {
  margin-bottom: 194rem !important; }

.m-l-194 {
  margin-left: 194rem !important; }

.m-x-194 {
  margin-right: 194rem !important;
  margin-left: 194rem !important; }

.m-y-194 {
  margin-top: 194rem !important;
  margin-bottom: 194rem !important; }

.m-a-195 {
  margin: 195rem 195rem !important; }

.m-t-195 {
  margin-top: 195rem !important; }

.m-r-195 {
  margin-right: 195rem !important; }

.m-b-195 {
  margin-bottom: 195rem !important; }

.m-l-195 {
  margin-left: 195rem !important; }

.m-x-195 {
  margin-right: 195rem !important;
  margin-left: 195rem !important; }

.m-y-195 {
  margin-top: 195rem !important;
  margin-bottom: 195rem !important; }

.m-a-196 {
  margin: 196rem 196rem !important; }

.m-t-196 {
  margin-top: 196rem !important; }

.m-r-196 {
  margin-right: 196rem !important; }

.m-b-196 {
  margin-bottom: 196rem !important; }

.m-l-196 {
  margin-left: 196rem !important; }

.m-x-196 {
  margin-right: 196rem !important;
  margin-left: 196rem !important; }

.m-y-196 {
  margin-top: 196rem !important;
  margin-bottom: 196rem !important; }

.m-a-197 {
  margin: 197rem 197rem !important; }

.m-t-197 {
  margin-top: 197rem !important; }

.m-r-197 {
  margin-right: 197rem !important; }

.m-b-197 {
  margin-bottom: 197rem !important; }

.m-l-197 {
  margin-left: 197rem !important; }

.m-x-197 {
  margin-right: 197rem !important;
  margin-left: 197rem !important; }

.m-y-197 {
  margin-top: 197rem !important;
  margin-bottom: 197rem !important; }

.m-a-198 {
  margin: 198rem 198rem !important; }

.m-t-198 {
  margin-top: 198rem !important; }

.m-r-198 {
  margin-right: 198rem !important; }

.m-b-198 {
  margin-bottom: 198rem !important; }

.m-l-198 {
  margin-left: 198rem !important; }

.m-x-198 {
  margin-right: 198rem !important;
  margin-left: 198rem !important; }

.m-y-198 {
  margin-top: 198rem !important;
  margin-bottom: 198rem !important; }

.m-a-199 {
  margin: 199rem 199rem !important; }

.m-t-199 {
  margin-top: 199rem !important; }

.m-r-199 {
  margin-right: 199rem !important; }

.m-b-199 {
  margin-bottom: 199rem !important; }

.m-l-199 {
  margin-left: 199rem !important; }

.m-x-199 {
  margin-right: 199rem !important;
  margin-left: 199rem !important; }

.m-y-199 {
  margin-top: 199rem !important;
  margin-bottom: 199rem !important; }

.m-a-200 {
  margin: 200rem 200rem !important; }

.m-t-200 {
  margin-top: 200rem !important; }

.m-r-200 {
  margin-right: 200rem !important; }

.m-b-200 {
  margin-bottom: 200rem !important; }

.m-l-200 {
  margin-left: 200rem !important; }

.m-x-200 {
  margin-right: 200rem !important;
  margin-left: 200rem !important; }

.m-y-200 {
  margin-top: 200rem !important;
  margin-bottom: 200rem !important; }

.p-a-0 {
  padding: 0rem 0rem !important; }

.p-t-0 {
  padding-top: 0rem !important; }

.p-r-0 {
  padding-right: 0rem !important; }

.p-b-0 {
  padding-bottom: 0rem !important; }

.p-l-0 {
  padding-left: 0rem !important; }

.p-x-0 {
  padding-right: 0rem !important;
  padding-left: 0rem !important; }

.p-y-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.p-a-1 {
  padding: 1rem 1rem !important; }

.p-t-1 {
  padding-top: 1rem !important; }

.p-r-1 {
  padding-right: 1rem !important; }

.p-b-1 {
  padding-bottom: 1rem !important; }

.p-l-1 {
  padding-left: 1rem !important; }

.p-x-1 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.p-y-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.p-a-2 {
  padding: 2rem 2rem !important; }

.p-t-2 {
  padding-top: 2rem !important; }

.p-r-2 {
  padding-right: 2rem !important; }

.p-b-2 {
  padding-bottom: 2rem !important; }

.p-l-2 {
  padding-left: 2rem !important; }

.p-x-2 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.p-y-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.p-a-3 {
  padding: 3rem 3rem !important; }

.p-t-3 {
  padding-top: 3rem !important; }

.p-r-3 {
  padding-right: 3rem !important; }

.p-b-3 {
  padding-bottom: 3rem !important; }

.p-l-3 {
  padding-left: 3rem !important; }

.p-x-3 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.p-y-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.p-a-4 {
  padding: 4rem 4rem !important; }

.p-t-4 {
  padding-top: 4rem !important; }

.p-r-4 {
  padding-right: 4rem !important; }

.p-b-4 {
  padding-bottom: 4rem !important; }

.p-l-4 {
  padding-left: 4rem !important; }

.p-x-4 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.p-y-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.p-a-5 {
  padding: 5rem 5rem !important; }

.p-t-5 {
  padding-top: 5rem !important; }

.p-r-5 {
  padding-right: 5rem !important; }

.p-b-5 {
  padding-bottom: 5rem !important; }

.p-l-5 {
  padding-left: 5rem !important; }

.p-x-5 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.p-y-5 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.p-a-6 {
  padding: 6rem 6rem !important; }

.p-t-6 {
  padding-top: 6rem !important; }

.p-r-6 {
  padding-right: 6rem !important; }

.p-b-6 {
  padding-bottom: 6rem !important; }

.p-l-6 {
  padding-left: 6rem !important; }

.p-x-6 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.p-y-6 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.p-a-7 {
  padding: 7rem 7rem !important; }

.p-t-7 {
  padding-top: 7rem !important; }

.p-r-7 {
  padding-right: 7rem !important; }

.p-b-7 {
  padding-bottom: 7rem !important; }

.p-l-7 {
  padding-left: 7rem !important; }

.p-x-7 {
  padding-right: 7rem !important;
  padding-left: 7rem !important; }

.p-y-7 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.p-a-8 {
  padding: 8rem 8rem !important; }

.p-t-8 {
  padding-top: 8rem !important; }

.p-r-8 {
  padding-right: 8rem !important; }

.p-b-8 {
  padding-bottom: 8rem !important; }

.p-l-8 {
  padding-left: 8rem !important; }

.p-x-8 {
  padding-right: 8rem !important;
  padding-left: 8rem !important; }

.p-y-8 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important; }

.p-a-9 {
  padding: 9rem 9rem !important; }

.p-t-9 {
  padding-top: 9rem !important; }

.p-r-9 {
  padding-right: 9rem !important; }

.p-b-9 {
  padding-bottom: 9rem !important; }

.p-l-9 {
  padding-left: 9rem !important; }

.p-x-9 {
  padding-right: 9rem !important;
  padding-left: 9rem !important; }

.p-y-9 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important; }

.p-a-10 {
  padding: 10rem 10rem !important; }

.p-t-10 {
  padding-top: 10rem !important; }

.p-r-10 {
  padding-right: 10rem !important; }

.p-b-10 {
  padding-bottom: 10rem !important; }

.p-l-10 {
  padding-left: 10rem !important; }

.p-x-10 {
  padding-right: 10rem !important;
  padding-left: 10rem !important; }

.p-y-10 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important; }

.p-a-11 {
  padding: 11rem 11rem !important; }

.p-t-11 {
  padding-top: 11rem !important; }

.p-r-11 {
  padding-right: 11rem !important; }

.p-b-11 {
  padding-bottom: 11rem !important; }

.p-l-11 {
  padding-left: 11rem !important; }

.p-x-11 {
  padding-right: 11rem !important;
  padding-left: 11rem !important; }

.p-y-11 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important; }

.p-a-12 {
  padding: 12rem 12rem !important; }

.p-t-12 {
  padding-top: 12rem !important; }

.p-r-12 {
  padding-right: 12rem !important; }

.p-b-12 {
  padding-bottom: 12rem !important; }

.p-l-12 {
  padding-left: 12rem !important; }

.p-x-12 {
  padding-right: 12rem !important;
  padding-left: 12rem !important; }

.p-y-12 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important; }

.p-a-13 {
  padding: 13rem 13rem !important; }

.p-t-13 {
  padding-top: 13rem !important; }

.p-r-13 {
  padding-right: 13rem !important; }

.p-b-13 {
  padding-bottom: 13rem !important; }

.p-l-13 {
  padding-left: 13rem !important; }

.p-x-13 {
  padding-right: 13rem !important;
  padding-left: 13rem !important; }

.p-y-13 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important; }

.p-a-14 {
  padding: 14rem 14rem !important; }

.p-t-14 {
  padding-top: 14rem !important; }

.p-r-14 {
  padding-right: 14rem !important; }

.p-b-14 {
  padding-bottom: 14rem !important; }

.p-l-14 {
  padding-left: 14rem !important; }

.p-x-14 {
  padding-right: 14rem !important;
  padding-left: 14rem !important; }

.p-y-14 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important; }

.p-a-15 {
  padding: 15rem 15rem !important; }

.p-t-15 {
  padding-top: 15rem !important; }

.p-r-15 {
  padding-right: 15rem !important; }

.p-b-15 {
  padding-bottom: 15rem !important; }

.p-l-15 {
  padding-left: 15rem !important; }

.p-x-15 {
  padding-right: 15rem !important;
  padding-left: 15rem !important; }

.p-y-15 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important; }

.p-a-16 {
  padding: 16rem 16rem !important; }

.p-t-16 {
  padding-top: 16rem !important; }

.p-r-16 {
  padding-right: 16rem !important; }

.p-b-16 {
  padding-bottom: 16rem !important; }

.p-l-16 {
  padding-left: 16rem !important; }

.p-x-16 {
  padding-right: 16rem !important;
  padding-left: 16rem !important; }

.p-y-16 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important; }

.p-a-17 {
  padding: 17rem 17rem !important; }

.p-t-17 {
  padding-top: 17rem !important; }

.p-r-17 {
  padding-right: 17rem !important; }

.p-b-17 {
  padding-bottom: 17rem !important; }

.p-l-17 {
  padding-left: 17rem !important; }

.p-x-17 {
  padding-right: 17rem !important;
  padding-left: 17rem !important; }

.p-y-17 {
  padding-top: 17rem !important;
  padding-bottom: 17rem !important; }

.p-a-18 {
  padding: 18rem 18rem !important; }

.p-t-18 {
  padding-top: 18rem !important; }

.p-r-18 {
  padding-right: 18rem !important; }

.p-b-18 {
  padding-bottom: 18rem !important; }

.p-l-18 {
  padding-left: 18rem !important; }

.p-x-18 {
  padding-right: 18rem !important;
  padding-left: 18rem !important; }

.p-y-18 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important; }

.p-a-19 {
  padding: 19rem 19rem !important; }

.p-t-19 {
  padding-top: 19rem !important; }

.p-r-19 {
  padding-right: 19rem !important; }

.p-b-19 {
  padding-bottom: 19rem !important; }

.p-l-19 {
  padding-left: 19rem !important; }

.p-x-19 {
  padding-right: 19rem !important;
  padding-left: 19rem !important; }

.p-y-19 {
  padding-top: 19rem !important;
  padding-bottom: 19rem !important; }

.p-a-20 {
  padding: 20rem 20rem !important; }

.p-t-20 {
  padding-top: 20rem !important; }

.p-r-20 {
  padding-right: 20rem !important; }

.p-b-20 {
  padding-bottom: 20rem !important; }

.p-l-20 {
  padding-left: 20rem !important; }

.p-x-20 {
  padding-right: 20rem !important;
  padding-left: 20rem !important; }

.p-y-20 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important; }

.p-a-21 {
  padding: 21rem 21rem !important; }

.p-t-21 {
  padding-top: 21rem !important; }

.p-r-21 {
  padding-right: 21rem !important; }

.p-b-21 {
  padding-bottom: 21rem !important; }

.p-l-21 {
  padding-left: 21rem !important; }

.p-x-21 {
  padding-right: 21rem !important;
  padding-left: 21rem !important; }

.p-y-21 {
  padding-top: 21rem !important;
  padding-bottom: 21rem !important; }

.p-a-22 {
  padding: 22rem 22rem !important; }

.p-t-22 {
  padding-top: 22rem !important; }

.p-r-22 {
  padding-right: 22rem !important; }

.p-b-22 {
  padding-bottom: 22rem !important; }

.p-l-22 {
  padding-left: 22rem !important; }

.p-x-22 {
  padding-right: 22rem !important;
  padding-left: 22rem !important; }

.p-y-22 {
  padding-top: 22rem !important;
  padding-bottom: 22rem !important; }

.p-a-23 {
  padding: 23rem 23rem !important; }

.p-t-23 {
  padding-top: 23rem !important; }

.p-r-23 {
  padding-right: 23rem !important; }

.p-b-23 {
  padding-bottom: 23rem !important; }

.p-l-23 {
  padding-left: 23rem !important; }

.p-x-23 {
  padding-right: 23rem !important;
  padding-left: 23rem !important; }

.p-y-23 {
  padding-top: 23rem !important;
  padding-bottom: 23rem !important; }

.p-a-24 {
  padding: 24rem 24rem !important; }

.p-t-24 {
  padding-top: 24rem !important; }

.p-r-24 {
  padding-right: 24rem !important; }

.p-b-24 {
  padding-bottom: 24rem !important; }

.p-l-24 {
  padding-left: 24rem !important; }

.p-x-24 {
  padding-right: 24rem !important;
  padding-left: 24rem !important; }

.p-y-24 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important; }

.p-a-25 {
  padding: 25rem 25rem !important; }

.p-t-25 {
  padding-top: 25rem !important; }

.p-r-25 {
  padding-right: 25rem !important; }

.p-b-25 {
  padding-bottom: 25rem !important; }

.p-l-25 {
  padding-left: 25rem !important; }

.p-x-25 {
  padding-right: 25rem !important;
  padding-left: 25rem !important; }

.p-y-25 {
  padding-top: 25rem !important;
  padding-bottom: 25rem !important; }

.p-a-26 {
  padding: 26rem 26rem !important; }

.p-t-26 {
  padding-top: 26rem !important; }

.p-r-26 {
  padding-right: 26rem !important; }

.p-b-26 {
  padding-bottom: 26rem !important; }

.p-l-26 {
  padding-left: 26rem !important; }

.p-x-26 {
  padding-right: 26rem !important;
  padding-left: 26rem !important; }

.p-y-26 {
  padding-top: 26rem !important;
  padding-bottom: 26rem !important; }

.p-a-27 {
  padding: 27rem 27rem !important; }

.p-t-27 {
  padding-top: 27rem !important; }

.p-r-27 {
  padding-right: 27rem !important; }

.p-b-27 {
  padding-bottom: 27rem !important; }

.p-l-27 {
  padding-left: 27rem !important; }

.p-x-27 {
  padding-right: 27rem !important;
  padding-left: 27rem !important; }

.p-y-27 {
  padding-top: 27rem !important;
  padding-bottom: 27rem !important; }

.p-a-28 {
  padding: 28rem 28rem !important; }

.p-t-28 {
  padding-top: 28rem !important; }

.p-r-28 {
  padding-right: 28rem !important; }

.p-b-28 {
  padding-bottom: 28rem !important; }

.p-l-28 {
  padding-left: 28rem !important; }

.p-x-28 {
  padding-right: 28rem !important;
  padding-left: 28rem !important; }

.p-y-28 {
  padding-top: 28rem !important;
  padding-bottom: 28rem !important; }

.p-a-29 {
  padding: 29rem 29rem !important; }

.p-t-29 {
  padding-top: 29rem !important; }

.p-r-29 {
  padding-right: 29rem !important; }

.p-b-29 {
  padding-bottom: 29rem !important; }

.p-l-29 {
  padding-left: 29rem !important; }

.p-x-29 {
  padding-right: 29rem !important;
  padding-left: 29rem !important; }

.p-y-29 {
  padding-top: 29rem !important;
  padding-bottom: 29rem !important; }

.p-a-30 {
  padding: 30rem 30rem !important; }

.p-t-30 {
  padding-top: 30rem !important; }

.p-r-30 {
  padding-right: 30rem !important; }

.p-b-30 {
  padding-bottom: 30rem !important; }

.p-l-30 {
  padding-left: 30rem !important; }

.p-x-30 {
  padding-right: 30rem !important;
  padding-left: 30rem !important; }

.p-y-30 {
  padding-top: 30rem !important;
  padding-bottom: 30rem !important; }

.p-a-31 {
  padding: 31rem 31rem !important; }

.p-t-31 {
  padding-top: 31rem !important; }

.p-r-31 {
  padding-right: 31rem !important; }

.p-b-31 {
  padding-bottom: 31rem !important; }

.p-l-31 {
  padding-left: 31rem !important; }

.p-x-31 {
  padding-right: 31rem !important;
  padding-left: 31rem !important; }

.p-y-31 {
  padding-top: 31rem !important;
  padding-bottom: 31rem !important; }

.p-a-32 {
  padding: 32rem 32rem !important; }

.p-t-32 {
  padding-top: 32rem !important; }

.p-r-32 {
  padding-right: 32rem !important; }

.p-b-32 {
  padding-bottom: 32rem !important; }

.p-l-32 {
  padding-left: 32rem !important; }

.p-x-32 {
  padding-right: 32rem !important;
  padding-left: 32rem !important; }

.p-y-32 {
  padding-top: 32rem !important;
  padding-bottom: 32rem !important; }

.p-a-33 {
  padding: 33rem 33rem !important; }

.p-t-33 {
  padding-top: 33rem !important; }

.p-r-33 {
  padding-right: 33rem !important; }

.p-b-33 {
  padding-bottom: 33rem !important; }

.p-l-33 {
  padding-left: 33rem !important; }

.p-x-33 {
  padding-right: 33rem !important;
  padding-left: 33rem !important; }

.p-y-33 {
  padding-top: 33rem !important;
  padding-bottom: 33rem !important; }

.p-a-34 {
  padding: 34rem 34rem !important; }

.p-t-34 {
  padding-top: 34rem !important; }

.p-r-34 {
  padding-right: 34rem !important; }

.p-b-34 {
  padding-bottom: 34rem !important; }

.p-l-34 {
  padding-left: 34rem !important; }

.p-x-34 {
  padding-right: 34rem !important;
  padding-left: 34rem !important; }

.p-y-34 {
  padding-top: 34rem !important;
  padding-bottom: 34rem !important; }

.p-a-35 {
  padding: 35rem 35rem !important; }

.p-t-35 {
  padding-top: 35rem !important; }

.p-r-35 {
  padding-right: 35rem !important; }

.p-b-35 {
  padding-bottom: 35rem !important; }

.p-l-35 {
  padding-left: 35rem !important; }

.p-x-35 {
  padding-right: 35rem !important;
  padding-left: 35rem !important; }

.p-y-35 {
  padding-top: 35rem !important;
  padding-bottom: 35rem !important; }

.p-a-36 {
  padding: 36rem 36rem !important; }

.p-t-36 {
  padding-top: 36rem !important; }

.p-r-36 {
  padding-right: 36rem !important; }

.p-b-36 {
  padding-bottom: 36rem !important; }

.p-l-36 {
  padding-left: 36rem !important; }

.p-x-36 {
  padding-right: 36rem !important;
  padding-left: 36rem !important; }

.p-y-36 {
  padding-top: 36rem !important;
  padding-bottom: 36rem !important; }

.p-a-37 {
  padding: 37rem 37rem !important; }

.p-t-37 {
  padding-top: 37rem !important; }

.p-r-37 {
  padding-right: 37rem !important; }

.p-b-37 {
  padding-bottom: 37rem !important; }

.p-l-37 {
  padding-left: 37rem !important; }

.p-x-37 {
  padding-right: 37rem !important;
  padding-left: 37rem !important; }

.p-y-37 {
  padding-top: 37rem !important;
  padding-bottom: 37rem !important; }

.p-a-38 {
  padding: 38rem 38rem !important; }

.p-t-38 {
  padding-top: 38rem !important; }

.p-r-38 {
  padding-right: 38rem !important; }

.p-b-38 {
  padding-bottom: 38rem !important; }

.p-l-38 {
  padding-left: 38rem !important; }

.p-x-38 {
  padding-right: 38rem !important;
  padding-left: 38rem !important; }

.p-y-38 {
  padding-top: 38rem !important;
  padding-bottom: 38rem !important; }

.p-a-39 {
  padding: 39rem 39rem !important; }

.p-t-39 {
  padding-top: 39rem !important; }

.p-r-39 {
  padding-right: 39rem !important; }

.p-b-39 {
  padding-bottom: 39rem !important; }

.p-l-39 {
  padding-left: 39rem !important; }

.p-x-39 {
  padding-right: 39rem !important;
  padding-left: 39rem !important; }

.p-y-39 {
  padding-top: 39rem !important;
  padding-bottom: 39rem !important; }

.p-a-40 {
  padding: 40rem 40rem !important; }

.p-t-40 {
  padding-top: 40rem !important; }

.p-r-40 {
  padding-right: 40rem !important; }

.p-b-40 {
  padding-bottom: 40rem !important; }

.p-l-40 {
  padding-left: 40rem !important; }

.p-x-40 {
  padding-right: 40rem !important;
  padding-left: 40rem !important; }

.p-y-40 {
  padding-top: 40rem !important;
  padding-bottom: 40rem !important; }

.p-a-41 {
  padding: 41rem 41rem !important; }

.p-t-41 {
  padding-top: 41rem !important; }

.p-r-41 {
  padding-right: 41rem !important; }

.p-b-41 {
  padding-bottom: 41rem !important; }

.p-l-41 {
  padding-left: 41rem !important; }

.p-x-41 {
  padding-right: 41rem !important;
  padding-left: 41rem !important; }

.p-y-41 {
  padding-top: 41rem !important;
  padding-bottom: 41rem !important; }

.p-a-42 {
  padding: 42rem 42rem !important; }

.p-t-42 {
  padding-top: 42rem !important; }

.p-r-42 {
  padding-right: 42rem !important; }

.p-b-42 {
  padding-bottom: 42rem !important; }

.p-l-42 {
  padding-left: 42rem !important; }

.p-x-42 {
  padding-right: 42rem !important;
  padding-left: 42rem !important; }

.p-y-42 {
  padding-top: 42rem !important;
  padding-bottom: 42rem !important; }

.p-a-43 {
  padding: 43rem 43rem !important; }

.p-t-43 {
  padding-top: 43rem !important; }

.p-r-43 {
  padding-right: 43rem !important; }

.p-b-43 {
  padding-bottom: 43rem !important; }

.p-l-43 {
  padding-left: 43rem !important; }

.p-x-43 {
  padding-right: 43rem !important;
  padding-left: 43rem !important; }

.p-y-43 {
  padding-top: 43rem !important;
  padding-bottom: 43rem !important; }

.p-a-44 {
  padding: 44rem 44rem !important; }

.p-t-44 {
  padding-top: 44rem !important; }

.p-r-44 {
  padding-right: 44rem !important; }

.p-b-44 {
  padding-bottom: 44rem !important; }

.p-l-44 {
  padding-left: 44rem !important; }

.p-x-44 {
  padding-right: 44rem !important;
  padding-left: 44rem !important; }

.p-y-44 {
  padding-top: 44rem !important;
  padding-bottom: 44rem !important; }

.p-a-45 {
  padding: 45rem 45rem !important; }

.p-t-45 {
  padding-top: 45rem !important; }

.p-r-45 {
  padding-right: 45rem !important; }

.p-b-45 {
  padding-bottom: 45rem !important; }

.p-l-45 {
  padding-left: 45rem !important; }

.p-x-45 {
  padding-right: 45rem !important;
  padding-left: 45rem !important; }

.p-y-45 {
  padding-top: 45rem !important;
  padding-bottom: 45rem !important; }

.p-a-46 {
  padding: 46rem 46rem !important; }

.p-t-46 {
  padding-top: 46rem !important; }

.p-r-46 {
  padding-right: 46rem !important; }

.p-b-46 {
  padding-bottom: 46rem !important; }

.p-l-46 {
  padding-left: 46rem !important; }

.p-x-46 {
  padding-right: 46rem !important;
  padding-left: 46rem !important; }

.p-y-46 {
  padding-top: 46rem !important;
  padding-bottom: 46rem !important; }

.p-a-47 {
  padding: 47rem 47rem !important; }

.p-t-47 {
  padding-top: 47rem !important; }

.p-r-47 {
  padding-right: 47rem !important; }

.p-b-47 {
  padding-bottom: 47rem !important; }

.p-l-47 {
  padding-left: 47rem !important; }

.p-x-47 {
  padding-right: 47rem !important;
  padding-left: 47rem !important; }

.p-y-47 {
  padding-top: 47rem !important;
  padding-bottom: 47rem !important; }

.p-a-48 {
  padding: 48rem 48rem !important; }

.p-t-48 {
  padding-top: 48rem !important; }

.p-r-48 {
  padding-right: 48rem !important; }

.p-b-48 {
  padding-bottom: 48rem !important; }

.p-l-48 {
  padding-left: 48rem !important; }

.p-x-48 {
  padding-right: 48rem !important;
  padding-left: 48rem !important; }

.p-y-48 {
  padding-top: 48rem !important;
  padding-bottom: 48rem !important; }

.p-a-49 {
  padding: 49rem 49rem !important; }

.p-t-49 {
  padding-top: 49rem !important; }

.p-r-49 {
  padding-right: 49rem !important; }

.p-b-49 {
  padding-bottom: 49rem !important; }

.p-l-49 {
  padding-left: 49rem !important; }

.p-x-49 {
  padding-right: 49rem !important;
  padding-left: 49rem !important; }

.p-y-49 {
  padding-top: 49rem !important;
  padding-bottom: 49rem !important; }

.p-a-50 {
  padding: 50rem 50rem !important; }

.p-t-50 {
  padding-top: 50rem !important; }

.p-r-50 {
  padding-right: 50rem !important; }

.p-b-50 {
  padding-bottom: 50rem !important; }

.p-l-50 {
  padding-left: 50rem !important; }

.p-x-50 {
  padding-right: 50rem !important;
  padding-left: 50rem !important; }

.p-y-50 {
  padding-top: 50rem !important;
  padding-bottom: 50rem !important; }

.p-a-51 {
  padding: 51rem 51rem !important; }

.p-t-51 {
  padding-top: 51rem !important; }

.p-r-51 {
  padding-right: 51rem !important; }

.p-b-51 {
  padding-bottom: 51rem !important; }

.p-l-51 {
  padding-left: 51rem !important; }

.p-x-51 {
  padding-right: 51rem !important;
  padding-left: 51rem !important; }

.p-y-51 {
  padding-top: 51rem !important;
  padding-bottom: 51rem !important; }

.p-a-52 {
  padding: 52rem 52rem !important; }

.p-t-52 {
  padding-top: 52rem !important; }

.p-r-52 {
  padding-right: 52rem !important; }

.p-b-52 {
  padding-bottom: 52rem !important; }

.p-l-52 {
  padding-left: 52rem !important; }

.p-x-52 {
  padding-right: 52rem !important;
  padding-left: 52rem !important; }

.p-y-52 {
  padding-top: 52rem !important;
  padding-bottom: 52rem !important; }

.p-a-53 {
  padding: 53rem 53rem !important; }

.p-t-53 {
  padding-top: 53rem !important; }

.p-r-53 {
  padding-right: 53rem !important; }

.p-b-53 {
  padding-bottom: 53rem !important; }

.p-l-53 {
  padding-left: 53rem !important; }

.p-x-53 {
  padding-right: 53rem !important;
  padding-left: 53rem !important; }

.p-y-53 {
  padding-top: 53rem !important;
  padding-bottom: 53rem !important; }

.p-a-54 {
  padding: 54rem 54rem !important; }

.p-t-54 {
  padding-top: 54rem !important; }

.p-r-54 {
  padding-right: 54rem !important; }

.p-b-54 {
  padding-bottom: 54rem !important; }

.p-l-54 {
  padding-left: 54rem !important; }

.p-x-54 {
  padding-right: 54rem !important;
  padding-left: 54rem !important; }

.p-y-54 {
  padding-top: 54rem !important;
  padding-bottom: 54rem !important; }

.p-a-55 {
  padding: 55rem 55rem !important; }

.p-t-55 {
  padding-top: 55rem !important; }

.p-r-55 {
  padding-right: 55rem !important; }

.p-b-55 {
  padding-bottom: 55rem !important; }

.p-l-55 {
  padding-left: 55rem !important; }

.p-x-55 {
  padding-right: 55rem !important;
  padding-left: 55rem !important; }

.p-y-55 {
  padding-top: 55rem !important;
  padding-bottom: 55rem !important; }

.p-a-56 {
  padding: 56rem 56rem !important; }

.p-t-56 {
  padding-top: 56rem !important; }

.p-r-56 {
  padding-right: 56rem !important; }

.p-b-56 {
  padding-bottom: 56rem !important; }

.p-l-56 {
  padding-left: 56rem !important; }

.p-x-56 {
  padding-right: 56rem !important;
  padding-left: 56rem !important; }

.p-y-56 {
  padding-top: 56rem !important;
  padding-bottom: 56rem !important; }

.p-a-57 {
  padding: 57rem 57rem !important; }

.p-t-57 {
  padding-top: 57rem !important; }

.p-r-57 {
  padding-right: 57rem !important; }

.p-b-57 {
  padding-bottom: 57rem !important; }

.p-l-57 {
  padding-left: 57rem !important; }

.p-x-57 {
  padding-right: 57rem !important;
  padding-left: 57rem !important; }

.p-y-57 {
  padding-top: 57rem !important;
  padding-bottom: 57rem !important; }

.p-a-58 {
  padding: 58rem 58rem !important; }

.p-t-58 {
  padding-top: 58rem !important; }

.p-r-58 {
  padding-right: 58rem !important; }

.p-b-58 {
  padding-bottom: 58rem !important; }

.p-l-58 {
  padding-left: 58rem !important; }

.p-x-58 {
  padding-right: 58rem !important;
  padding-left: 58rem !important; }

.p-y-58 {
  padding-top: 58rem !important;
  padding-bottom: 58rem !important; }

.p-a-59 {
  padding: 59rem 59rem !important; }

.p-t-59 {
  padding-top: 59rem !important; }

.p-r-59 {
  padding-right: 59rem !important; }

.p-b-59 {
  padding-bottom: 59rem !important; }

.p-l-59 {
  padding-left: 59rem !important; }

.p-x-59 {
  padding-right: 59rem !important;
  padding-left: 59rem !important; }

.p-y-59 {
  padding-top: 59rem !important;
  padding-bottom: 59rem !important; }

.p-a-60 {
  padding: 60rem 60rem !important; }

.p-t-60 {
  padding-top: 60rem !important; }

.p-r-60 {
  padding-right: 60rem !important; }

.p-b-60 {
  padding-bottom: 60rem !important; }

.p-l-60 {
  padding-left: 60rem !important; }

.p-x-60 {
  padding-right: 60rem !important;
  padding-left: 60rem !important; }

.p-y-60 {
  padding-top: 60rem !important;
  padding-bottom: 60rem !important; }

.p-a-61 {
  padding: 61rem 61rem !important; }

.p-t-61 {
  padding-top: 61rem !important; }

.p-r-61 {
  padding-right: 61rem !important; }

.p-b-61 {
  padding-bottom: 61rem !important; }

.p-l-61 {
  padding-left: 61rem !important; }

.p-x-61 {
  padding-right: 61rem !important;
  padding-left: 61rem !important; }

.p-y-61 {
  padding-top: 61rem !important;
  padding-bottom: 61rem !important; }

.p-a-62 {
  padding: 62rem 62rem !important; }

.p-t-62 {
  padding-top: 62rem !important; }

.p-r-62 {
  padding-right: 62rem !important; }

.p-b-62 {
  padding-bottom: 62rem !important; }

.p-l-62 {
  padding-left: 62rem !important; }

.p-x-62 {
  padding-right: 62rem !important;
  padding-left: 62rem !important; }

.p-y-62 {
  padding-top: 62rem !important;
  padding-bottom: 62rem !important; }

.p-a-63 {
  padding: 63rem 63rem !important; }

.p-t-63 {
  padding-top: 63rem !important; }

.p-r-63 {
  padding-right: 63rem !important; }

.p-b-63 {
  padding-bottom: 63rem !important; }

.p-l-63 {
  padding-left: 63rem !important; }

.p-x-63 {
  padding-right: 63rem !important;
  padding-left: 63rem !important; }

.p-y-63 {
  padding-top: 63rem !important;
  padding-bottom: 63rem !important; }

.p-a-64 {
  padding: 64rem 64rem !important; }

.p-t-64 {
  padding-top: 64rem !important; }

.p-r-64 {
  padding-right: 64rem !important; }

.p-b-64 {
  padding-bottom: 64rem !important; }

.p-l-64 {
  padding-left: 64rem !important; }

.p-x-64 {
  padding-right: 64rem !important;
  padding-left: 64rem !important; }

.p-y-64 {
  padding-top: 64rem !important;
  padding-bottom: 64rem !important; }

.p-a-65 {
  padding: 65rem 65rem !important; }

.p-t-65 {
  padding-top: 65rem !important; }

.p-r-65 {
  padding-right: 65rem !important; }

.p-b-65 {
  padding-bottom: 65rem !important; }

.p-l-65 {
  padding-left: 65rem !important; }

.p-x-65 {
  padding-right: 65rem !important;
  padding-left: 65rem !important; }

.p-y-65 {
  padding-top: 65rem !important;
  padding-bottom: 65rem !important; }

.p-a-66 {
  padding: 66rem 66rem !important; }

.p-t-66 {
  padding-top: 66rem !important; }

.p-r-66 {
  padding-right: 66rem !important; }

.p-b-66 {
  padding-bottom: 66rem !important; }

.p-l-66 {
  padding-left: 66rem !important; }

.p-x-66 {
  padding-right: 66rem !important;
  padding-left: 66rem !important; }

.p-y-66 {
  padding-top: 66rem !important;
  padding-bottom: 66rem !important; }

.p-a-67 {
  padding: 67rem 67rem !important; }

.p-t-67 {
  padding-top: 67rem !important; }

.p-r-67 {
  padding-right: 67rem !important; }

.p-b-67 {
  padding-bottom: 67rem !important; }

.p-l-67 {
  padding-left: 67rem !important; }

.p-x-67 {
  padding-right: 67rem !important;
  padding-left: 67rem !important; }

.p-y-67 {
  padding-top: 67rem !important;
  padding-bottom: 67rem !important; }

.p-a-68 {
  padding: 68rem 68rem !important; }

.p-t-68 {
  padding-top: 68rem !important; }

.p-r-68 {
  padding-right: 68rem !important; }

.p-b-68 {
  padding-bottom: 68rem !important; }

.p-l-68 {
  padding-left: 68rem !important; }

.p-x-68 {
  padding-right: 68rem !important;
  padding-left: 68rem !important; }

.p-y-68 {
  padding-top: 68rem !important;
  padding-bottom: 68rem !important; }

.p-a-69 {
  padding: 69rem 69rem !important; }

.p-t-69 {
  padding-top: 69rem !important; }

.p-r-69 {
  padding-right: 69rem !important; }

.p-b-69 {
  padding-bottom: 69rem !important; }

.p-l-69 {
  padding-left: 69rem !important; }

.p-x-69 {
  padding-right: 69rem !important;
  padding-left: 69rem !important; }

.p-y-69 {
  padding-top: 69rem !important;
  padding-bottom: 69rem !important; }

.p-a-70 {
  padding: 70rem 70rem !important; }

.p-t-70 {
  padding-top: 70rem !important; }

.p-r-70 {
  padding-right: 70rem !important; }

.p-b-70 {
  padding-bottom: 70rem !important; }

.p-l-70 {
  padding-left: 70rem !important; }

.p-x-70 {
  padding-right: 70rem !important;
  padding-left: 70rem !important; }

.p-y-70 {
  padding-top: 70rem !important;
  padding-bottom: 70rem !important; }

.p-a-71 {
  padding: 71rem 71rem !important; }

.p-t-71 {
  padding-top: 71rem !important; }

.p-r-71 {
  padding-right: 71rem !important; }

.p-b-71 {
  padding-bottom: 71rem !important; }

.p-l-71 {
  padding-left: 71rem !important; }

.p-x-71 {
  padding-right: 71rem !important;
  padding-left: 71rem !important; }

.p-y-71 {
  padding-top: 71rem !important;
  padding-bottom: 71rem !important; }

.p-a-72 {
  padding: 72rem 72rem !important; }

.p-t-72 {
  padding-top: 72rem !important; }

.p-r-72 {
  padding-right: 72rem !important; }

.p-b-72 {
  padding-bottom: 72rem !important; }

.p-l-72 {
  padding-left: 72rem !important; }

.p-x-72 {
  padding-right: 72rem !important;
  padding-left: 72rem !important; }

.p-y-72 {
  padding-top: 72rem !important;
  padding-bottom: 72rem !important; }

.p-a-73 {
  padding: 73rem 73rem !important; }

.p-t-73 {
  padding-top: 73rem !important; }

.p-r-73 {
  padding-right: 73rem !important; }

.p-b-73 {
  padding-bottom: 73rem !important; }

.p-l-73 {
  padding-left: 73rem !important; }

.p-x-73 {
  padding-right: 73rem !important;
  padding-left: 73rem !important; }

.p-y-73 {
  padding-top: 73rem !important;
  padding-bottom: 73rem !important; }

.p-a-74 {
  padding: 74rem 74rem !important; }

.p-t-74 {
  padding-top: 74rem !important; }

.p-r-74 {
  padding-right: 74rem !important; }

.p-b-74 {
  padding-bottom: 74rem !important; }

.p-l-74 {
  padding-left: 74rem !important; }

.p-x-74 {
  padding-right: 74rem !important;
  padding-left: 74rem !important; }

.p-y-74 {
  padding-top: 74rem !important;
  padding-bottom: 74rem !important; }

.p-a-75 {
  padding: 75rem 75rem !important; }

.p-t-75 {
  padding-top: 75rem !important; }

.p-r-75 {
  padding-right: 75rem !important; }

.p-b-75 {
  padding-bottom: 75rem !important; }

.p-l-75 {
  padding-left: 75rem !important; }

.p-x-75 {
  padding-right: 75rem !important;
  padding-left: 75rem !important; }

.p-y-75 {
  padding-top: 75rem !important;
  padding-bottom: 75rem !important; }

.p-a-76 {
  padding: 76rem 76rem !important; }

.p-t-76 {
  padding-top: 76rem !important; }

.p-r-76 {
  padding-right: 76rem !important; }

.p-b-76 {
  padding-bottom: 76rem !important; }

.p-l-76 {
  padding-left: 76rem !important; }

.p-x-76 {
  padding-right: 76rem !important;
  padding-left: 76rem !important; }

.p-y-76 {
  padding-top: 76rem !important;
  padding-bottom: 76rem !important; }

.p-a-77 {
  padding: 77rem 77rem !important; }

.p-t-77 {
  padding-top: 77rem !important; }

.p-r-77 {
  padding-right: 77rem !important; }

.p-b-77 {
  padding-bottom: 77rem !important; }

.p-l-77 {
  padding-left: 77rem !important; }

.p-x-77 {
  padding-right: 77rem !important;
  padding-left: 77rem !important; }

.p-y-77 {
  padding-top: 77rem !important;
  padding-bottom: 77rem !important; }

.p-a-78 {
  padding: 78rem 78rem !important; }

.p-t-78 {
  padding-top: 78rem !important; }

.p-r-78 {
  padding-right: 78rem !important; }

.p-b-78 {
  padding-bottom: 78rem !important; }

.p-l-78 {
  padding-left: 78rem !important; }

.p-x-78 {
  padding-right: 78rem !important;
  padding-left: 78rem !important; }

.p-y-78 {
  padding-top: 78rem !important;
  padding-bottom: 78rem !important; }

.p-a-79 {
  padding: 79rem 79rem !important; }

.p-t-79 {
  padding-top: 79rem !important; }

.p-r-79 {
  padding-right: 79rem !important; }

.p-b-79 {
  padding-bottom: 79rem !important; }

.p-l-79 {
  padding-left: 79rem !important; }

.p-x-79 {
  padding-right: 79rem !important;
  padding-left: 79rem !important; }

.p-y-79 {
  padding-top: 79rem !important;
  padding-bottom: 79rem !important; }

.p-a-80 {
  padding: 80rem 80rem !important; }

.p-t-80 {
  padding-top: 80rem !important; }

.p-r-80 {
  padding-right: 80rem !important; }

.p-b-80 {
  padding-bottom: 80rem !important; }

.p-l-80 {
  padding-left: 80rem !important; }

.p-x-80 {
  padding-right: 80rem !important;
  padding-left: 80rem !important; }

.p-y-80 {
  padding-top: 80rem !important;
  padding-bottom: 80rem !important; }

.p-a-81 {
  padding: 81rem 81rem !important; }

.p-t-81 {
  padding-top: 81rem !important; }

.p-r-81 {
  padding-right: 81rem !important; }

.p-b-81 {
  padding-bottom: 81rem !important; }

.p-l-81 {
  padding-left: 81rem !important; }

.p-x-81 {
  padding-right: 81rem !important;
  padding-left: 81rem !important; }

.p-y-81 {
  padding-top: 81rem !important;
  padding-bottom: 81rem !important; }

.p-a-82 {
  padding: 82rem 82rem !important; }

.p-t-82 {
  padding-top: 82rem !important; }

.p-r-82 {
  padding-right: 82rem !important; }

.p-b-82 {
  padding-bottom: 82rem !important; }

.p-l-82 {
  padding-left: 82rem !important; }

.p-x-82 {
  padding-right: 82rem !important;
  padding-left: 82rem !important; }

.p-y-82 {
  padding-top: 82rem !important;
  padding-bottom: 82rem !important; }

.p-a-83 {
  padding: 83rem 83rem !important; }

.p-t-83 {
  padding-top: 83rem !important; }

.p-r-83 {
  padding-right: 83rem !important; }

.p-b-83 {
  padding-bottom: 83rem !important; }

.p-l-83 {
  padding-left: 83rem !important; }

.p-x-83 {
  padding-right: 83rem !important;
  padding-left: 83rem !important; }

.p-y-83 {
  padding-top: 83rem !important;
  padding-bottom: 83rem !important; }

.p-a-84 {
  padding: 84rem 84rem !important; }

.p-t-84 {
  padding-top: 84rem !important; }

.p-r-84 {
  padding-right: 84rem !important; }

.p-b-84 {
  padding-bottom: 84rem !important; }

.p-l-84 {
  padding-left: 84rem !important; }

.p-x-84 {
  padding-right: 84rem !important;
  padding-left: 84rem !important; }

.p-y-84 {
  padding-top: 84rem !important;
  padding-bottom: 84rem !important; }

.p-a-85 {
  padding: 85rem 85rem !important; }

.p-t-85 {
  padding-top: 85rem !important; }

.p-r-85 {
  padding-right: 85rem !important; }

.p-b-85 {
  padding-bottom: 85rem !important; }

.p-l-85 {
  padding-left: 85rem !important; }

.p-x-85 {
  padding-right: 85rem !important;
  padding-left: 85rem !important; }

.p-y-85 {
  padding-top: 85rem !important;
  padding-bottom: 85rem !important; }

.p-a-86 {
  padding: 86rem 86rem !important; }

.p-t-86 {
  padding-top: 86rem !important; }

.p-r-86 {
  padding-right: 86rem !important; }

.p-b-86 {
  padding-bottom: 86rem !important; }

.p-l-86 {
  padding-left: 86rem !important; }

.p-x-86 {
  padding-right: 86rem !important;
  padding-left: 86rem !important; }

.p-y-86 {
  padding-top: 86rem !important;
  padding-bottom: 86rem !important; }

.p-a-87 {
  padding: 87rem 87rem !important; }

.p-t-87 {
  padding-top: 87rem !important; }

.p-r-87 {
  padding-right: 87rem !important; }

.p-b-87 {
  padding-bottom: 87rem !important; }

.p-l-87 {
  padding-left: 87rem !important; }

.p-x-87 {
  padding-right: 87rem !important;
  padding-left: 87rem !important; }

.p-y-87 {
  padding-top: 87rem !important;
  padding-bottom: 87rem !important; }

.p-a-88 {
  padding: 88rem 88rem !important; }

.p-t-88 {
  padding-top: 88rem !important; }

.p-r-88 {
  padding-right: 88rem !important; }

.p-b-88 {
  padding-bottom: 88rem !important; }

.p-l-88 {
  padding-left: 88rem !important; }

.p-x-88 {
  padding-right: 88rem !important;
  padding-left: 88rem !important; }

.p-y-88 {
  padding-top: 88rem !important;
  padding-bottom: 88rem !important; }

.p-a-89 {
  padding: 89rem 89rem !important; }

.p-t-89 {
  padding-top: 89rem !important; }

.p-r-89 {
  padding-right: 89rem !important; }

.p-b-89 {
  padding-bottom: 89rem !important; }

.p-l-89 {
  padding-left: 89rem !important; }

.p-x-89 {
  padding-right: 89rem !important;
  padding-left: 89rem !important; }

.p-y-89 {
  padding-top: 89rem !important;
  padding-bottom: 89rem !important; }

.p-a-90 {
  padding: 90rem 90rem !important; }

.p-t-90 {
  padding-top: 90rem !important; }

.p-r-90 {
  padding-right: 90rem !important; }

.p-b-90 {
  padding-bottom: 90rem !important; }

.p-l-90 {
  padding-left: 90rem !important; }

.p-x-90 {
  padding-right: 90rem !important;
  padding-left: 90rem !important; }

.p-y-90 {
  padding-top: 90rem !important;
  padding-bottom: 90rem !important; }

.p-a-91 {
  padding: 91rem 91rem !important; }

.p-t-91 {
  padding-top: 91rem !important; }

.p-r-91 {
  padding-right: 91rem !important; }

.p-b-91 {
  padding-bottom: 91rem !important; }

.p-l-91 {
  padding-left: 91rem !important; }

.p-x-91 {
  padding-right: 91rem !important;
  padding-left: 91rem !important; }

.p-y-91 {
  padding-top: 91rem !important;
  padding-bottom: 91rem !important; }

.p-a-92 {
  padding: 92rem 92rem !important; }

.p-t-92 {
  padding-top: 92rem !important; }

.p-r-92 {
  padding-right: 92rem !important; }

.p-b-92 {
  padding-bottom: 92rem !important; }

.p-l-92 {
  padding-left: 92rem !important; }

.p-x-92 {
  padding-right: 92rem !important;
  padding-left: 92rem !important; }

.p-y-92 {
  padding-top: 92rem !important;
  padding-bottom: 92rem !important; }

.p-a-93 {
  padding: 93rem 93rem !important; }

.p-t-93 {
  padding-top: 93rem !important; }

.p-r-93 {
  padding-right: 93rem !important; }

.p-b-93 {
  padding-bottom: 93rem !important; }

.p-l-93 {
  padding-left: 93rem !important; }

.p-x-93 {
  padding-right: 93rem !important;
  padding-left: 93rem !important; }

.p-y-93 {
  padding-top: 93rem !important;
  padding-bottom: 93rem !important; }

.p-a-94 {
  padding: 94rem 94rem !important; }

.p-t-94 {
  padding-top: 94rem !important; }

.p-r-94 {
  padding-right: 94rem !important; }

.p-b-94 {
  padding-bottom: 94rem !important; }

.p-l-94 {
  padding-left: 94rem !important; }

.p-x-94 {
  padding-right: 94rem !important;
  padding-left: 94rem !important; }

.p-y-94 {
  padding-top: 94rem !important;
  padding-bottom: 94rem !important; }

.p-a-95 {
  padding: 95rem 95rem !important; }

.p-t-95 {
  padding-top: 95rem !important; }

.p-r-95 {
  padding-right: 95rem !important; }

.p-b-95 {
  padding-bottom: 95rem !important; }

.p-l-95 {
  padding-left: 95rem !important; }

.p-x-95 {
  padding-right: 95rem !important;
  padding-left: 95rem !important; }

.p-y-95 {
  padding-top: 95rem !important;
  padding-bottom: 95rem !important; }

.p-a-96 {
  padding: 96rem 96rem !important; }

.p-t-96 {
  padding-top: 96rem !important; }

.p-r-96 {
  padding-right: 96rem !important; }

.p-b-96 {
  padding-bottom: 96rem !important; }

.p-l-96 {
  padding-left: 96rem !important; }

.p-x-96 {
  padding-right: 96rem !important;
  padding-left: 96rem !important; }

.p-y-96 {
  padding-top: 96rem !important;
  padding-bottom: 96rem !important; }

.p-a-97 {
  padding: 97rem 97rem !important; }

.p-t-97 {
  padding-top: 97rem !important; }

.p-r-97 {
  padding-right: 97rem !important; }

.p-b-97 {
  padding-bottom: 97rem !important; }

.p-l-97 {
  padding-left: 97rem !important; }

.p-x-97 {
  padding-right: 97rem !important;
  padding-left: 97rem !important; }

.p-y-97 {
  padding-top: 97rem !important;
  padding-bottom: 97rem !important; }

.p-a-98 {
  padding: 98rem 98rem !important; }

.p-t-98 {
  padding-top: 98rem !important; }

.p-r-98 {
  padding-right: 98rem !important; }

.p-b-98 {
  padding-bottom: 98rem !important; }

.p-l-98 {
  padding-left: 98rem !important; }

.p-x-98 {
  padding-right: 98rem !important;
  padding-left: 98rem !important; }

.p-y-98 {
  padding-top: 98rem !important;
  padding-bottom: 98rem !important; }

.p-a-99 {
  padding: 99rem 99rem !important; }

.p-t-99 {
  padding-top: 99rem !important; }

.p-r-99 {
  padding-right: 99rem !important; }

.p-b-99 {
  padding-bottom: 99rem !important; }

.p-l-99 {
  padding-left: 99rem !important; }

.p-x-99 {
  padding-right: 99rem !important;
  padding-left: 99rem !important; }

.p-y-99 {
  padding-top: 99rem !important;
  padding-bottom: 99rem !important; }

.p-a-100 {
  padding: 100rem 100rem !important; }

.p-t-100 {
  padding-top: 100rem !important; }

.p-r-100 {
  padding-right: 100rem !important; }

.p-b-100 {
  padding-bottom: 100rem !important; }

.p-l-100 {
  padding-left: 100rem !important; }

.p-x-100 {
  padding-right: 100rem !important;
  padding-left: 100rem !important; }

.p-y-100 {
  padding-top: 100rem !important;
  padding-bottom: 100rem !important; }

.p-a-101 {
  padding: 101rem 101rem !important; }

.p-t-101 {
  padding-top: 101rem !important; }

.p-r-101 {
  padding-right: 101rem !important; }

.p-b-101 {
  padding-bottom: 101rem !important; }

.p-l-101 {
  padding-left: 101rem !important; }

.p-x-101 {
  padding-right: 101rem !important;
  padding-left: 101rem !important; }

.p-y-101 {
  padding-top: 101rem !important;
  padding-bottom: 101rem !important; }

.p-a-102 {
  padding: 102rem 102rem !important; }

.p-t-102 {
  padding-top: 102rem !important; }

.p-r-102 {
  padding-right: 102rem !important; }

.p-b-102 {
  padding-bottom: 102rem !important; }

.p-l-102 {
  padding-left: 102rem !important; }

.p-x-102 {
  padding-right: 102rem !important;
  padding-left: 102rem !important; }

.p-y-102 {
  padding-top: 102rem !important;
  padding-bottom: 102rem !important; }

.p-a-103 {
  padding: 103rem 103rem !important; }

.p-t-103 {
  padding-top: 103rem !important; }

.p-r-103 {
  padding-right: 103rem !important; }

.p-b-103 {
  padding-bottom: 103rem !important; }

.p-l-103 {
  padding-left: 103rem !important; }

.p-x-103 {
  padding-right: 103rem !important;
  padding-left: 103rem !important; }

.p-y-103 {
  padding-top: 103rem !important;
  padding-bottom: 103rem !important; }

.p-a-104 {
  padding: 104rem 104rem !important; }

.p-t-104 {
  padding-top: 104rem !important; }

.p-r-104 {
  padding-right: 104rem !important; }

.p-b-104 {
  padding-bottom: 104rem !important; }

.p-l-104 {
  padding-left: 104rem !important; }

.p-x-104 {
  padding-right: 104rem !important;
  padding-left: 104rem !important; }

.p-y-104 {
  padding-top: 104rem !important;
  padding-bottom: 104rem !important; }

.p-a-105 {
  padding: 105rem 105rem !important; }

.p-t-105 {
  padding-top: 105rem !important; }

.p-r-105 {
  padding-right: 105rem !important; }

.p-b-105 {
  padding-bottom: 105rem !important; }

.p-l-105 {
  padding-left: 105rem !important; }

.p-x-105 {
  padding-right: 105rem !important;
  padding-left: 105rem !important; }

.p-y-105 {
  padding-top: 105rem !important;
  padding-bottom: 105rem !important; }

.p-a-106 {
  padding: 106rem 106rem !important; }

.p-t-106 {
  padding-top: 106rem !important; }

.p-r-106 {
  padding-right: 106rem !important; }

.p-b-106 {
  padding-bottom: 106rem !important; }

.p-l-106 {
  padding-left: 106rem !important; }

.p-x-106 {
  padding-right: 106rem !important;
  padding-left: 106rem !important; }

.p-y-106 {
  padding-top: 106rem !important;
  padding-bottom: 106rem !important; }

.p-a-107 {
  padding: 107rem 107rem !important; }

.p-t-107 {
  padding-top: 107rem !important; }

.p-r-107 {
  padding-right: 107rem !important; }

.p-b-107 {
  padding-bottom: 107rem !important; }

.p-l-107 {
  padding-left: 107rem !important; }

.p-x-107 {
  padding-right: 107rem !important;
  padding-left: 107rem !important; }

.p-y-107 {
  padding-top: 107rem !important;
  padding-bottom: 107rem !important; }

.p-a-108 {
  padding: 108rem 108rem !important; }

.p-t-108 {
  padding-top: 108rem !important; }

.p-r-108 {
  padding-right: 108rem !important; }

.p-b-108 {
  padding-bottom: 108rem !important; }

.p-l-108 {
  padding-left: 108rem !important; }

.p-x-108 {
  padding-right: 108rem !important;
  padding-left: 108rem !important; }

.p-y-108 {
  padding-top: 108rem !important;
  padding-bottom: 108rem !important; }

.p-a-109 {
  padding: 109rem 109rem !important; }

.p-t-109 {
  padding-top: 109rem !important; }

.p-r-109 {
  padding-right: 109rem !important; }

.p-b-109 {
  padding-bottom: 109rem !important; }

.p-l-109 {
  padding-left: 109rem !important; }

.p-x-109 {
  padding-right: 109rem !important;
  padding-left: 109rem !important; }

.p-y-109 {
  padding-top: 109rem !important;
  padding-bottom: 109rem !important; }

.p-a-110 {
  padding: 110rem 110rem !important; }

.p-t-110 {
  padding-top: 110rem !important; }

.p-r-110 {
  padding-right: 110rem !important; }

.p-b-110 {
  padding-bottom: 110rem !important; }

.p-l-110 {
  padding-left: 110rem !important; }

.p-x-110 {
  padding-right: 110rem !important;
  padding-left: 110rem !important; }

.p-y-110 {
  padding-top: 110rem !important;
  padding-bottom: 110rem !important; }

.p-a-111 {
  padding: 111rem 111rem !important; }

.p-t-111 {
  padding-top: 111rem !important; }

.p-r-111 {
  padding-right: 111rem !important; }

.p-b-111 {
  padding-bottom: 111rem !important; }

.p-l-111 {
  padding-left: 111rem !important; }

.p-x-111 {
  padding-right: 111rem !important;
  padding-left: 111rem !important; }

.p-y-111 {
  padding-top: 111rem !important;
  padding-bottom: 111rem !important; }

.p-a-112 {
  padding: 112rem 112rem !important; }

.p-t-112 {
  padding-top: 112rem !important; }

.p-r-112 {
  padding-right: 112rem !important; }

.p-b-112 {
  padding-bottom: 112rem !important; }

.p-l-112 {
  padding-left: 112rem !important; }

.p-x-112 {
  padding-right: 112rem !important;
  padding-left: 112rem !important; }

.p-y-112 {
  padding-top: 112rem !important;
  padding-bottom: 112rem !important; }

.p-a-113 {
  padding: 113rem 113rem !important; }

.p-t-113 {
  padding-top: 113rem !important; }

.p-r-113 {
  padding-right: 113rem !important; }

.p-b-113 {
  padding-bottom: 113rem !important; }

.p-l-113 {
  padding-left: 113rem !important; }

.p-x-113 {
  padding-right: 113rem !important;
  padding-left: 113rem !important; }

.p-y-113 {
  padding-top: 113rem !important;
  padding-bottom: 113rem !important; }

.p-a-114 {
  padding: 114rem 114rem !important; }

.p-t-114 {
  padding-top: 114rem !important; }

.p-r-114 {
  padding-right: 114rem !important; }

.p-b-114 {
  padding-bottom: 114rem !important; }

.p-l-114 {
  padding-left: 114rem !important; }

.p-x-114 {
  padding-right: 114rem !important;
  padding-left: 114rem !important; }

.p-y-114 {
  padding-top: 114rem !important;
  padding-bottom: 114rem !important; }

.p-a-115 {
  padding: 115rem 115rem !important; }

.p-t-115 {
  padding-top: 115rem !important; }

.p-r-115 {
  padding-right: 115rem !important; }

.p-b-115 {
  padding-bottom: 115rem !important; }

.p-l-115 {
  padding-left: 115rem !important; }

.p-x-115 {
  padding-right: 115rem !important;
  padding-left: 115rem !important; }

.p-y-115 {
  padding-top: 115rem !important;
  padding-bottom: 115rem !important; }

.p-a-116 {
  padding: 116rem 116rem !important; }

.p-t-116 {
  padding-top: 116rem !important; }

.p-r-116 {
  padding-right: 116rem !important; }

.p-b-116 {
  padding-bottom: 116rem !important; }

.p-l-116 {
  padding-left: 116rem !important; }

.p-x-116 {
  padding-right: 116rem !important;
  padding-left: 116rem !important; }

.p-y-116 {
  padding-top: 116rem !important;
  padding-bottom: 116rem !important; }

.p-a-117 {
  padding: 117rem 117rem !important; }

.p-t-117 {
  padding-top: 117rem !important; }

.p-r-117 {
  padding-right: 117rem !important; }

.p-b-117 {
  padding-bottom: 117rem !important; }

.p-l-117 {
  padding-left: 117rem !important; }

.p-x-117 {
  padding-right: 117rem !important;
  padding-left: 117rem !important; }

.p-y-117 {
  padding-top: 117rem !important;
  padding-bottom: 117rem !important; }

.p-a-118 {
  padding: 118rem 118rem !important; }

.p-t-118 {
  padding-top: 118rem !important; }

.p-r-118 {
  padding-right: 118rem !important; }

.p-b-118 {
  padding-bottom: 118rem !important; }

.p-l-118 {
  padding-left: 118rem !important; }

.p-x-118 {
  padding-right: 118rem !important;
  padding-left: 118rem !important; }

.p-y-118 {
  padding-top: 118rem !important;
  padding-bottom: 118rem !important; }

.p-a-119 {
  padding: 119rem 119rem !important; }

.p-t-119 {
  padding-top: 119rem !important; }

.p-r-119 {
  padding-right: 119rem !important; }

.p-b-119 {
  padding-bottom: 119rem !important; }

.p-l-119 {
  padding-left: 119rem !important; }

.p-x-119 {
  padding-right: 119rem !important;
  padding-left: 119rem !important; }

.p-y-119 {
  padding-top: 119rem !important;
  padding-bottom: 119rem !important; }

.p-a-120 {
  padding: 120rem 120rem !important; }

.p-t-120 {
  padding-top: 120rem !important; }

.p-r-120 {
  padding-right: 120rem !important; }

.p-b-120 {
  padding-bottom: 120rem !important; }

.p-l-120 {
  padding-left: 120rem !important; }

.p-x-120 {
  padding-right: 120rem !important;
  padding-left: 120rem !important; }

.p-y-120 {
  padding-top: 120rem !important;
  padding-bottom: 120rem !important; }

.p-a-121 {
  padding: 121rem 121rem !important; }

.p-t-121 {
  padding-top: 121rem !important; }

.p-r-121 {
  padding-right: 121rem !important; }

.p-b-121 {
  padding-bottom: 121rem !important; }

.p-l-121 {
  padding-left: 121rem !important; }

.p-x-121 {
  padding-right: 121rem !important;
  padding-left: 121rem !important; }

.p-y-121 {
  padding-top: 121rem !important;
  padding-bottom: 121rem !important; }

.p-a-122 {
  padding: 122rem 122rem !important; }

.p-t-122 {
  padding-top: 122rem !important; }

.p-r-122 {
  padding-right: 122rem !important; }

.p-b-122 {
  padding-bottom: 122rem !important; }

.p-l-122 {
  padding-left: 122rem !important; }

.p-x-122 {
  padding-right: 122rem !important;
  padding-left: 122rem !important; }

.p-y-122 {
  padding-top: 122rem !important;
  padding-bottom: 122rem !important; }

.p-a-123 {
  padding: 123rem 123rem !important; }

.p-t-123 {
  padding-top: 123rem !important; }

.p-r-123 {
  padding-right: 123rem !important; }

.p-b-123 {
  padding-bottom: 123rem !important; }

.p-l-123 {
  padding-left: 123rem !important; }

.p-x-123 {
  padding-right: 123rem !important;
  padding-left: 123rem !important; }

.p-y-123 {
  padding-top: 123rem !important;
  padding-bottom: 123rem !important; }

.p-a-124 {
  padding: 124rem 124rem !important; }

.p-t-124 {
  padding-top: 124rem !important; }

.p-r-124 {
  padding-right: 124rem !important; }

.p-b-124 {
  padding-bottom: 124rem !important; }

.p-l-124 {
  padding-left: 124rem !important; }

.p-x-124 {
  padding-right: 124rem !important;
  padding-left: 124rem !important; }

.p-y-124 {
  padding-top: 124rem !important;
  padding-bottom: 124rem !important; }

.p-a-125 {
  padding: 125rem 125rem !important; }

.p-t-125 {
  padding-top: 125rem !important; }

.p-r-125 {
  padding-right: 125rem !important; }

.p-b-125 {
  padding-bottom: 125rem !important; }

.p-l-125 {
  padding-left: 125rem !important; }

.p-x-125 {
  padding-right: 125rem !important;
  padding-left: 125rem !important; }

.p-y-125 {
  padding-top: 125rem !important;
  padding-bottom: 125rem !important; }

.p-a-126 {
  padding: 126rem 126rem !important; }

.p-t-126 {
  padding-top: 126rem !important; }

.p-r-126 {
  padding-right: 126rem !important; }

.p-b-126 {
  padding-bottom: 126rem !important; }

.p-l-126 {
  padding-left: 126rem !important; }

.p-x-126 {
  padding-right: 126rem !important;
  padding-left: 126rem !important; }

.p-y-126 {
  padding-top: 126rem !important;
  padding-bottom: 126rem !important; }

.p-a-127 {
  padding: 127rem 127rem !important; }

.p-t-127 {
  padding-top: 127rem !important; }

.p-r-127 {
  padding-right: 127rem !important; }

.p-b-127 {
  padding-bottom: 127rem !important; }

.p-l-127 {
  padding-left: 127rem !important; }

.p-x-127 {
  padding-right: 127rem !important;
  padding-left: 127rem !important; }

.p-y-127 {
  padding-top: 127rem !important;
  padding-bottom: 127rem !important; }

.p-a-128 {
  padding: 128rem 128rem !important; }

.p-t-128 {
  padding-top: 128rem !important; }

.p-r-128 {
  padding-right: 128rem !important; }

.p-b-128 {
  padding-bottom: 128rem !important; }

.p-l-128 {
  padding-left: 128rem !important; }

.p-x-128 {
  padding-right: 128rem !important;
  padding-left: 128rem !important; }

.p-y-128 {
  padding-top: 128rem !important;
  padding-bottom: 128rem !important; }

.p-a-129 {
  padding: 129rem 129rem !important; }

.p-t-129 {
  padding-top: 129rem !important; }

.p-r-129 {
  padding-right: 129rem !important; }

.p-b-129 {
  padding-bottom: 129rem !important; }

.p-l-129 {
  padding-left: 129rem !important; }

.p-x-129 {
  padding-right: 129rem !important;
  padding-left: 129rem !important; }

.p-y-129 {
  padding-top: 129rem !important;
  padding-bottom: 129rem !important; }

.p-a-130 {
  padding: 130rem 130rem !important; }

.p-t-130 {
  padding-top: 130rem !important; }

.p-r-130 {
  padding-right: 130rem !important; }

.p-b-130 {
  padding-bottom: 130rem !important; }

.p-l-130 {
  padding-left: 130rem !important; }

.p-x-130 {
  padding-right: 130rem !important;
  padding-left: 130rem !important; }

.p-y-130 {
  padding-top: 130rem !important;
  padding-bottom: 130rem !important; }

.p-a-131 {
  padding: 131rem 131rem !important; }

.p-t-131 {
  padding-top: 131rem !important; }

.p-r-131 {
  padding-right: 131rem !important; }

.p-b-131 {
  padding-bottom: 131rem !important; }

.p-l-131 {
  padding-left: 131rem !important; }

.p-x-131 {
  padding-right: 131rem !important;
  padding-left: 131rem !important; }

.p-y-131 {
  padding-top: 131rem !important;
  padding-bottom: 131rem !important; }

.p-a-132 {
  padding: 132rem 132rem !important; }

.p-t-132 {
  padding-top: 132rem !important; }

.p-r-132 {
  padding-right: 132rem !important; }

.p-b-132 {
  padding-bottom: 132rem !important; }

.p-l-132 {
  padding-left: 132rem !important; }

.p-x-132 {
  padding-right: 132rem !important;
  padding-left: 132rem !important; }

.p-y-132 {
  padding-top: 132rem !important;
  padding-bottom: 132rem !important; }

.p-a-133 {
  padding: 133rem 133rem !important; }

.p-t-133 {
  padding-top: 133rem !important; }

.p-r-133 {
  padding-right: 133rem !important; }

.p-b-133 {
  padding-bottom: 133rem !important; }

.p-l-133 {
  padding-left: 133rem !important; }

.p-x-133 {
  padding-right: 133rem !important;
  padding-left: 133rem !important; }

.p-y-133 {
  padding-top: 133rem !important;
  padding-bottom: 133rem !important; }

.p-a-134 {
  padding: 134rem 134rem !important; }

.p-t-134 {
  padding-top: 134rem !important; }

.p-r-134 {
  padding-right: 134rem !important; }

.p-b-134 {
  padding-bottom: 134rem !important; }

.p-l-134 {
  padding-left: 134rem !important; }

.p-x-134 {
  padding-right: 134rem !important;
  padding-left: 134rem !important; }

.p-y-134 {
  padding-top: 134rem !important;
  padding-bottom: 134rem !important; }

.p-a-135 {
  padding: 135rem 135rem !important; }

.p-t-135 {
  padding-top: 135rem !important; }

.p-r-135 {
  padding-right: 135rem !important; }

.p-b-135 {
  padding-bottom: 135rem !important; }

.p-l-135 {
  padding-left: 135rem !important; }

.p-x-135 {
  padding-right: 135rem !important;
  padding-left: 135rem !important; }

.p-y-135 {
  padding-top: 135rem !important;
  padding-bottom: 135rem !important; }

.p-a-136 {
  padding: 136rem 136rem !important; }

.p-t-136 {
  padding-top: 136rem !important; }

.p-r-136 {
  padding-right: 136rem !important; }

.p-b-136 {
  padding-bottom: 136rem !important; }

.p-l-136 {
  padding-left: 136rem !important; }

.p-x-136 {
  padding-right: 136rem !important;
  padding-left: 136rem !important; }

.p-y-136 {
  padding-top: 136rem !important;
  padding-bottom: 136rem !important; }

.p-a-137 {
  padding: 137rem 137rem !important; }

.p-t-137 {
  padding-top: 137rem !important; }

.p-r-137 {
  padding-right: 137rem !important; }

.p-b-137 {
  padding-bottom: 137rem !important; }

.p-l-137 {
  padding-left: 137rem !important; }

.p-x-137 {
  padding-right: 137rem !important;
  padding-left: 137rem !important; }

.p-y-137 {
  padding-top: 137rem !important;
  padding-bottom: 137rem !important; }

.p-a-138 {
  padding: 138rem 138rem !important; }

.p-t-138 {
  padding-top: 138rem !important; }

.p-r-138 {
  padding-right: 138rem !important; }

.p-b-138 {
  padding-bottom: 138rem !important; }

.p-l-138 {
  padding-left: 138rem !important; }

.p-x-138 {
  padding-right: 138rem !important;
  padding-left: 138rem !important; }

.p-y-138 {
  padding-top: 138rem !important;
  padding-bottom: 138rem !important; }

.p-a-139 {
  padding: 139rem 139rem !important; }

.p-t-139 {
  padding-top: 139rem !important; }

.p-r-139 {
  padding-right: 139rem !important; }

.p-b-139 {
  padding-bottom: 139rem !important; }

.p-l-139 {
  padding-left: 139rem !important; }

.p-x-139 {
  padding-right: 139rem !important;
  padding-left: 139rem !important; }

.p-y-139 {
  padding-top: 139rem !important;
  padding-bottom: 139rem !important; }

.p-a-140 {
  padding: 140rem 140rem !important; }

.p-t-140 {
  padding-top: 140rem !important; }

.p-r-140 {
  padding-right: 140rem !important; }

.p-b-140 {
  padding-bottom: 140rem !important; }

.p-l-140 {
  padding-left: 140rem !important; }

.p-x-140 {
  padding-right: 140rem !important;
  padding-left: 140rem !important; }

.p-y-140 {
  padding-top: 140rem !important;
  padding-bottom: 140rem !important; }

.p-a-141 {
  padding: 141rem 141rem !important; }

.p-t-141 {
  padding-top: 141rem !important; }

.p-r-141 {
  padding-right: 141rem !important; }

.p-b-141 {
  padding-bottom: 141rem !important; }

.p-l-141 {
  padding-left: 141rem !important; }

.p-x-141 {
  padding-right: 141rem !important;
  padding-left: 141rem !important; }

.p-y-141 {
  padding-top: 141rem !important;
  padding-bottom: 141rem !important; }

.p-a-142 {
  padding: 142rem 142rem !important; }

.p-t-142 {
  padding-top: 142rem !important; }

.p-r-142 {
  padding-right: 142rem !important; }

.p-b-142 {
  padding-bottom: 142rem !important; }

.p-l-142 {
  padding-left: 142rem !important; }

.p-x-142 {
  padding-right: 142rem !important;
  padding-left: 142rem !important; }

.p-y-142 {
  padding-top: 142rem !important;
  padding-bottom: 142rem !important; }

.p-a-143 {
  padding: 143rem 143rem !important; }

.p-t-143 {
  padding-top: 143rem !important; }

.p-r-143 {
  padding-right: 143rem !important; }

.p-b-143 {
  padding-bottom: 143rem !important; }

.p-l-143 {
  padding-left: 143rem !important; }

.p-x-143 {
  padding-right: 143rem !important;
  padding-left: 143rem !important; }

.p-y-143 {
  padding-top: 143rem !important;
  padding-bottom: 143rem !important; }

.p-a-144 {
  padding: 144rem 144rem !important; }

.p-t-144 {
  padding-top: 144rem !important; }

.p-r-144 {
  padding-right: 144rem !important; }

.p-b-144 {
  padding-bottom: 144rem !important; }

.p-l-144 {
  padding-left: 144rem !important; }

.p-x-144 {
  padding-right: 144rem !important;
  padding-left: 144rem !important; }

.p-y-144 {
  padding-top: 144rem !important;
  padding-bottom: 144rem !important; }

.p-a-145 {
  padding: 145rem 145rem !important; }

.p-t-145 {
  padding-top: 145rem !important; }

.p-r-145 {
  padding-right: 145rem !important; }

.p-b-145 {
  padding-bottom: 145rem !important; }

.p-l-145 {
  padding-left: 145rem !important; }

.p-x-145 {
  padding-right: 145rem !important;
  padding-left: 145rem !important; }

.p-y-145 {
  padding-top: 145rem !important;
  padding-bottom: 145rem !important; }

.p-a-146 {
  padding: 146rem 146rem !important; }

.p-t-146 {
  padding-top: 146rem !important; }

.p-r-146 {
  padding-right: 146rem !important; }

.p-b-146 {
  padding-bottom: 146rem !important; }

.p-l-146 {
  padding-left: 146rem !important; }

.p-x-146 {
  padding-right: 146rem !important;
  padding-left: 146rem !important; }

.p-y-146 {
  padding-top: 146rem !important;
  padding-bottom: 146rem !important; }

.p-a-147 {
  padding: 147rem 147rem !important; }

.p-t-147 {
  padding-top: 147rem !important; }

.p-r-147 {
  padding-right: 147rem !important; }

.p-b-147 {
  padding-bottom: 147rem !important; }

.p-l-147 {
  padding-left: 147rem !important; }

.p-x-147 {
  padding-right: 147rem !important;
  padding-left: 147rem !important; }

.p-y-147 {
  padding-top: 147rem !important;
  padding-bottom: 147rem !important; }

.p-a-148 {
  padding: 148rem 148rem !important; }

.p-t-148 {
  padding-top: 148rem !important; }

.p-r-148 {
  padding-right: 148rem !important; }

.p-b-148 {
  padding-bottom: 148rem !important; }

.p-l-148 {
  padding-left: 148rem !important; }

.p-x-148 {
  padding-right: 148rem !important;
  padding-left: 148rem !important; }

.p-y-148 {
  padding-top: 148rem !important;
  padding-bottom: 148rem !important; }

.p-a-149 {
  padding: 149rem 149rem !important; }

.p-t-149 {
  padding-top: 149rem !important; }

.p-r-149 {
  padding-right: 149rem !important; }

.p-b-149 {
  padding-bottom: 149rem !important; }

.p-l-149 {
  padding-left: 149rem !important; }

.p-x-149 {
  padding-right: 149rem !important;
  padding-left: 149rem !important; }

.p-y-149 {
  padding-top: 149rem !important;
  padding-bottom: 149rem !important; }

.p-a-150 {
  padding: 150rem 150rem !important; }

.p-t-150 {
  padding-top: 150rem !important; }

.p-r-150 {
  padding-right: 150rem !important; }

.p-b-150 {
  padding-bottom: 150rem !important; }

.p-l-150 {
  padding-left: 150rem !important; }

.p-x-150 {
  padding-right: 150rem !important;
  padding-left: 150rem !important; }

.p-y-150 {
  padding-top: 150rem !important;
  padding-bottom: 150rem !important; }

.p-a-151 {
  padding: 151rem 151rem !important; }

.p-t-151 {
  padding-top: 151rem !important; }

.p-r-151 {
  padding-right: 151rem !important; }

.p-b-151 {
  padding-bottom: 151rem !important; }

.p-l-151 {
  padding-left: 151rem !important; }

.p-x-151 {
  padding-right: 151rem !important;
  padding-left: 151rem !important; }

.p-y-151 {
  padding-top: 151rem !important;
  padding-bottom: 151rem !important; }

.p-a-152 {
  padding: 152rem 152rem !important; }

.p-t-152 {
  padding-top: 152rem !important; }

.p-r-152 {
  padding-right: 152rem !important; }

.p-b-152 {
  padding-bottom: 152rem !important; }

.p-l-152 {
  padding-left: 152rem !important; }

.p-x-152 {
  padding-right: 152rem !important;
  padding-left: 152rem !important; }

.p-y-152 {
  padding-top: 152rem !important;
  padding-bottom: 152rem !important; }

.p-a-153 {
  padding: 153rem 153rem !important; }

.p-t-153 {
  padding-top: 153rem !important; }

.p-r-153 {
  padding-right: 153rem !important; }

.p-b-153 {
  padding-bottom: 153rem !important; }

.p-l-153 {
  padding-left: 153rem !important; }

.p-x-153 {
  padding-right: 153rem !important;
  padding-left: 153rem !important; }

.p-y-153 {
  padding-top: 153rem !important;
  padding-bottom: 153rem !important; }

.p-a-154 {
  padding: 154rem 154rem !important; }

.p-t-154 {
  padding-top: 154rem !important; }

.p-r-154 {
  padding-right: 154rem !important; }

.p-b-154 {
  padding-bottom: 154rem !important; }

.p-l-154 {
  padding-left: 154rem !important; }

.p-x-154 {
  padding-right: 154rem !important;
  padding-left: 154rem !important; }

.p-y-154 {
  padding-top: 154rem !important;
  padding-bottom: 154rem !important; }

.p-a-155 {
  padding: 155rem 155rem !important; }

.p-t-155 {
  padding-top: 155rem !important; }

.p-r-155 {
  padding-right: 155rem !important; }

.p-b-155 {
  padding-bottom: 155rem !important; }

.p-l-155 {
  padding-left: 155rem !important; }

.p-x-155 {
  padding-right: 155rem !important;
  padding-left: 155rem !important; }

.p-y-155 {
  padding-top: 155rem !important;
  padding-bottom: 155rem !important; }

.p-a-156 {
  padding: 156rem 156rem !important; }

.p-t-156 {
  padding-top: 156rem !important; }

.p-r-156 {
  padding-right: 156rem !important; }

.p-b-156 {
  padding-bottom: 156rem !important; }

.p-l-156 {
  padding-left: 156rem !important; }

.p-x-156 {
  padding-right: 156rem !important;
  padding-left: 156rem !important; }

.p-y-156 {
  padding-top: 156rem !important;
  padding-bottom: 156rem !important; }

.p-a-157 {
  padding: 157rem 157rem !important; }

.p-t-157 {
  padding-top: 157rem !important; }

.p-r-157 {
  padding-right: 157rem !important; }

.p-b-157 {
  padding-bottom: 157rem !important; }

.p-l-157 {
  padding-left: 157rem !important; }

.p-x-157 {
  padding-right: 157rem !important;
  padding-left: 157rem !important; }

.p-y-157 {
  padding-top: 157rem !important;
  padding-bottom: 157rem !important; }

.p-a-158 {
  padding: 158rem 158rem !important; }

.p-t-158 {
  padding-top: 158rem !important; }

.p-r-158 {
  padding-right: 158rem !important; }

.p-b-158 {
  padding-bottom: 158rem !important; }

.p-l-158 {
  padding-left: 158rem !important; }

.p-x-158 {
  padding-right: 158rem !important;
  padding-left: 158rem !important; }

.p-y-158 {
  padding-top: 158rem !important;
  padding-bottom: 158rem !important; }

.p-a-159 {
  padding: 159rem 159rem !important; }

.p-t-159 {
  padding-top: 159rem !important; }

.p-r-159 {
  padding-right: 159rem !important; }

.p-b-159 {
  padding-bottom: 159rem !important; }

.p-l-159 {
  padding-left: 159rem !important; }

.p-x-159 {
  padding-right: 159rem !important;
  padding-left: 159rem !important; }

.p-y-159 {
  padding-top: 159rem !important;
  padding-bottom: 159rem !important; }

.p-a-160 {
  padding: 160rem 160rem !important; }

.p-t-160 {
  padding-top: 160rem !important; }

.p-r-160 {
  padding-right: 160rem !important; }

.p-b-160 {
  padding-bottom: 160rem !important; }

.p-l-160 {
  padding-left: 160rem !important; }

.p-x-160 {
  padding-right: 160rem !important;
  padding-left: 160rem !important; }

.p-y-160 {
  padding-top: 160rem !important;
  padding-bottom: 160rem !important; }

.p-a-161 {
  padding: 161rem 161rem !important; }

.p-t-161 {
  padding-top: 161rem !important; }

.p-r-161 {
  padding-right: 161rem !important; }

.p-b-161 {
  padding-bottom: 161rem !important; }

.p-l-161 {
  padding-left: 161rem !important; }

.p-x-161 {
  padding-right: 161rem !important;
  padding-left: 161rem !important; }

.p-y-161 {
  padding-top: 161rem !important;
  padding-bottom: 161rem !important; }

.p-a-162 {
  padding: 162rem 162rem !important; }

.p-t-162 {
  padding-top: 162rem !important; }

.p-r-162 {
  padding-right: 162rem !important; }

.p-b-162 {
  padding-bottom: 162rem !important; }

.p-l-162 {
  padding-left: 162rem !important; }

.p-x-162 {
  padding-right: 162rem !important;
  padding-left: 162rem !important; }

.p-y-162 {
  padding-top: 162rem !important;
  padding-bottom: 162rem !important; }

.p-a-163 {
  padding: 163rem 163rem !important; }

.p-t-163 {
  padding-top: 163rem !important; }

.p-r-163 {
  padding-right: 163rem !important; }

.p-b-163 {
  padding-bottom: 163rem !important; }

.p-l-163 {
  padding-left: 163rem !important; }

.p-x-163 {
  padding-right: 163rem !important;
  padding-left: 163rem !important; }

.p-y-163 {
  padding-top: 163rem !important;
  padding-bottom: 163rem !important; }

.p-a-164 {
  padding: 164rem 164rem !important; }

.p-t-164 {
  padding-top: 164rem !important; }

.p-r-164 {
  padding-right: 164rem !important; }

.p-b-164 {
  padding-bottom: 164rem !important; }

.p-l-164 {
  padding-left: 164rem !important; }

.p-x-164 {
  padding-right: 164rem !important;
  padding-left: 164rem !important; }

.p-y-164 {
  padding-top: 164rem !important;
  padding-bottom: 164rem !important; }

.p-a-165 {
  padding: 165rem 165rem !important; }

.p-t-165 {
  padding-top: 165rem !important; }

.p-r-165 {
  padding-right: 165rem !important; }

.p-b-165 {
  padding-bottom: 165rem !important; }

.p-l-165 {
  padding-left: 165rem !important; }

.p-x-165 {
  padding-right: 165rem !important;
  padding-left: 165rem !important; }

.p-y-165 {
  padding-top: 165rem !important;
  padding-bottom: 165rem !important; }

.p-a-166 {
  padding: 166rem 166rem !important; }

.p-t-166 {
  padding-top: 166rem !important; }

.p-r-166 {
  padding-right: 166rem !important; }

.p-b-166 {
  padding-bottom: 166rem !important; }

.p-l-166 {
  padding-left: 166rem !important; }

.p-x-166 {
  padding-right: 166rem !important;
  padding-left: 166rem !important; }

.p-y-166 {
  padding-top: 166rem !important;
  padding-bottom: 166rem !important; }

.p-a-167 {
  padding: 167rem 167rem !important; }

.p-t-167 {
  padding-top: 167rem !important; }

.p-r-167 {
  padding-right: 167rem !important; }

.p-b-167 {
  padding-bottom: 167rem !important; }

.p-l-167 {
  padding-left: 167rem !important; }

.p-x-167 {
  padding-right: 167rem !important;
  padding-left: 167rem !important; }

.p-y-167 {
  padding-top: 167rem !important;
  padding-bottom: 167rem !important; }

.p-a-168 {
  padding: 168rem 168rem !important; }

.p-t-168 {
  padding-top: 168rem !important; }

.p-r-168 {
  padding-right: 168rem !important; }

.p-b-168 {
  padding-bottom: 168rem !important; }

.p-l-168 {
  padding-left: 168rem !important; }

.p-x-168 {
  padding-right: 168rem !important;
  padding-left: 168rem !important; }

.p-y-168 {
  padding-top: 168rem !important;
  padding-bottom: 168rem !important; }

.p-a-169 {
  padding: 169rem 169rem !important; }

.p-t-169 {
  padding-top: 169rem !important; }

.p-r-169 {
  padding-right: 169rem !important; }

.p-b-169 {
  padding-bottom: 169rem !important; }

.p-l-169 {
  padding-left: 169rem !important; }

.p-x-169 {
  padding-right: 169rem !important;
  padding-left: 169rem !important; }

.p-y-169 {
  padding-top: 169rem !important;
  padding-bottom: 169rem !important; }

.p-a-170 {
  padding: 170rem 170rem !important; }

.p-t-170 {
  padding-top: 170rem !important; }

.p-r-170 {
  padding-right: 170rem !important; }

.p-b-170 {
  padding-bottom: 170rem !important; }

.p-l-170 {
  padding-left: 170rem !important; }

.p-x-170 {
  padding-right: 170rem !important;
  padding-left: 170rem !important; }

.p-y-170 {
  padding-top: 170rem !important;
  padding-bottom: 170rem !important; }

.p-a-171 {
  padding: 171rem 171rem !important; }

.p-t-171 {
  padding-top: 171rem !important; }

.p-r-171 {
  padding-right: 171rem !important; }

.p-b-171 {
  padding-bottom: 171rem !important; }

.p-l-171 {
  padding-left: 171rem !important; }

.p-x-171 {
  padding-right: 171rem !important;
  padding-left: 171rem !important; }

.p-y-171 {
  padding-top: 171rem !important;
  padding-bottom: 171rem !important; }

.p-a-172 {
  padding: 172rem 172rem !important; }

.p-t-172 {
  padding-top: 172rem !important; }

.p-r-172 {
  padding-right: 172rem !important; }

.p-b-172 {
  padding-bottom: 172rem !important; }

.p-l-172 {
  padding-left: 172rem !important; }

.p-x-172 {
  padding-right: 172rem !important;
  padding-left: 172rem !important; }

.p-y-172 {
  padding-top: 172rem !important;
  padding-bottom: 172rem !important; }

.p-a-173 {
  padding: 173rem 173rem !important; }

.p-t-173 {
  padding-top: 173rem !important; }

.p-r-173 {
  padding-right: 173rem !important; }

.p-b-173 {
  padding-bottom: 173rem !important; }

.p-l-173 {
  padding-left: 173rem !important; }

.p-x-173 {
  padding-right: 173rem !important;
  padding-left: 173rem !important; }

.p-y-173 {
  padding-top: 173rem !important;
  padding-bottom: 173rem !important; }

.p-a-174 {
  padding: 174rem 174rem !important; }

.p-t-174 {
  padding-top: 174rem !important; }

.p-r-174 {
  padding-right: 174rem !important; }

.p-b-174 {
  padding-bottom: 174rem !important; }

.p-l-174 {
  padding-left: 174rem !important; }

.p-x-174 {
  padding-right: 174rem !important;
  padding-left: 174rem !important; }

.p-y-174 {
  padding-top: 174rem !important;
  padding-bottom: 174rem !important; }

.p-a-175 {
  padding: 175rem 175rem !important; }

.p-t-175 {
  padding-top: 175rem !important; }

.p-r-175 {
  padding-right: 175rem !important; }

.p-b-175 {
  padding-bottom: 175rem !important; }

.p-l-175 {
  padding-left: 175rem !important; }

.p-x-175 {
  padding-right: 175rem !important;
  padding-left: 175rem !important; }

.p-y-175 {
  padding-top: 175rem !important;
  padding-bottom: 175rem !important; }

.p-a-176 {
  padding: 176rem 176rem !important; }

.p-t-176 {
  padding-top: 176rem !important; }

.p-r-176 {
  padding-right: 176rem !important; }

.p-b-176 {
  padding-bottom: 176rem !important; }

.p-l-176 {
  padding-left: 176rem !important; }

.p-x-176 {
  padding-right: 176rem !important;
  padding-left: 176rem !important; }

.p-y-176 {
  padding-top: 176rem !important;
  padding-bottom: 176rem !important; }

.p-a-177 {
  padding: 177rem 177rem !important; }

.p-t-177 {
  padding-top: 177rem !important; }

.p-r-177 {
  padding-right: 177rem !important; }

.p-b-177 {
  padding-bottom: 177rem !important; }

.p-l-177 {
  padding-left: 177rem !important; }

.p-x-177 {
  padding-right: 177rem !important;
  padding-left: 177rem !important; }

.p-y-177 {
  padding-top: 177rem !important;
  padding-bottom: 177rem !important; }

.p-a-178 {
  padding: 178rem 178rem !important; }

.p-t-178 {
  padding-top: 178rem !important; }

.p-r-178 {
  padding-right: 178rem !important; }

.p-b-178 {
  padding-bottom: 178rem !important; }

.p-l-178 {
  padding-left: 178rem !important; }

.p-x-178 {
  padding-right: 178rem !important;
  padding-left: 178rem !important; }

.p-y-178 {
  padding-top: 178rem !important;
  padding-bottom: 178rem !important; }

.p-a-179 {
  padding: 179rem 179rem !important; }

.p-t-179 {
  padding-top: 179rem !important; }

.p-r-179 {
  padding-right: 179rem !important; }

.p-b-179 {
  padding-bottom: 179rem !important; }

.p-l-179 {
  padding-left: 179rem !important; }

.p-x-179 {
  padding-right: 179rem !important;
  padding-left: 179rem !important; }

.p-y-179 {
  padding-top: 179rem !important;
  padding-bottom: 179rem !important; }

.p-a-180 {
  padding: 180rem 180rem !important; }

.p-t-180 {
  padding-top: 180rem !important; }

.p-r-180 {
  padding-right: 180rem !important; }

.p-b-180 {
  padding-bottom: 180rem !important; }

.p-l-180 {
  padding-left: 180rem !important; }

.p-x-180 {
  padding-right: 180rem !important;
  padding-left: 180rem !important; }

.p-y-180 {
  padding-top: 180rem !important;
  padding-bottom: 180rem !important; }

.p-a-181 {
  padding: 181rem 181rem !important; }

.p-t-181 {
  padding-top: 181rem !important; }

.p-r-181 {
  padding-right: 181rem !important; }

.p-b-181 {
  padding-bottom: 181rem !important; }

.p-l-181 {
  padding-left: 181rem !important; }

.p-x-181 {
  padding-right: 181rem !important;
  padding-left: 181rem !important; }

.p-y-181 {
  padding-top: 181rem !important;
  padding-bottom: 181rem !important; }

.p-a-182 {
  padding: 182rem 182rem !important; }

.p-t-182 {
  padding-top: 182rem !important; }

.p-r-182 {
  padding-right: 182rem !important; }

.p-b-182 {
  padding-bottom: 182rem !important; }

.p-l-182 {
  padding-left: 182rem !important; }

.p-x-182 {
  padding-right: 182rem !important;
  padding-left: 182rem !important; }

.p-y-182 {
  padding-top: 182rem !important;
  padding-bottom: 182rem !important; }

.p-a-183 {
  padding: 183rem 183rem !important; }

.p-t-183 {
  padding-top: 183rem !important; }

.p-r-183 {
  padding-right: 183rem !important; }

.p-b-183 {
  padding-bottom: 183rem !important; }

.p-l-183 {
  padding-left: 183rem !important; }

.p-x-183 {
  padding-right: 183rem !important;
  padding-left: 183rem !important; }

.p-y-183 {
  padding-top: 183rem !important;
  padding-bottom: 183rem !important; }

.p-a-184 {
  padding: 184rem 184rem !important; }

.p-t-184 {
  padding-top: 184rem !important; }

.p-r-184 {
  padding-right: 184rem !important; }

.p-b-184 {
  padding-bottom: 184rem !important; }

.p-l-184 {
  padding-left: 184rem !important; }

.p-x-184 {
  padding-right: 184rem !important;
  padding-left: 184rem !important; }

.p-y-184 {
  padding-top: 184rem !important;
  padding-bottom: 184rem !important; }

.p-a-185 {
  padding: 185rem 185rem !important; }

.p-t-185 {
  padding-top: 185rem !important; }

.p-r-185 {
  padding-right: 185rem !important; }

.p-b-185 {
  padding-bottom: 185rem !important; }

.p-l-185 {
  padding-left: 185rem !important; }

.p-x-185 {
  padding-right: 185rem !important;
  padding-left: 185rem !important; }

.p-y-185 {
  padding-top: 185rem !important;
  padding-bottom: 185rem !important; }

.p-a-186 {
  padding: 186rem 186rem !important; }

.p-t-186 {
  padding-top: 186rem !important; }

.p-r-186 {
  padding-right: 186rem !important; }

.p-b-186 {
  padding-bottom: 186rem !important; }

.p-l-186 {
  padding-left: 186rem !important; }

.p-x-186 {
  padding-right: 186rem !important;
  padding-left: 186rem !important; }

.p-y-186 {
  padding-top: 186rem !important;
  padding-bottom: 186rem !important; }

.p-a-187 {
  padding: 187rem 187rem !important; }

.p-t-187 {
  padding-top: 187rem !important; }

.p-r-187 {
  padding-right: 187rem !important; }

.p-b-187 {
  padding-bottom: 187rem !important; }

.p-l-187 {
  padding-left: 187rem !important; }

.p-x-187 {
  padding-right: 187rem !important;
  padding-left: 187rem !important; }

.p-y-187 {
  padding-top: 187rem !important;
  padding-bottom: 187rem !important; }

.p-a-188 {
  padding: 188rem 188rem !important; }

.p-t-188 {
  padding-top: 188rem !important; }

.p-r-188 {
  padding-right: 188rem !important; }

.p-b-188 {
  padding-bottom: 188rem !important; }

.p-l-188 {
  padding-left: 188rem !important; }

.p-x-188 {
  padding-right: 188rem !important;
  padding-left: 188rem !important; }

.p-y-188 {
  padding-top: 188rem !important;
  padding-bottom: 188rem !important; }

.p-a-189 {
  padding: 189rem 189rem !important; }

.p-t-189 {
  padding-top: 189rem !important; }

.p-r-189 {
  padding-right: 189rem !important; }

.p-b-189 {
  padding-bottom: 189rem !important; }

.p-l-189 {
  padding-left: 189rem !important; }

.p-x-189 {
  padding-right: 189rem !important;
  padding-left: 189rem !important; }

.p-y-189 {
  padding-top: 189rem !important;
  padding-bottom: 189rem !important; }

.p-a-190 {
  padding: 190rem 190rem !important; }

.p-t-190 {
  padding-top: 190rem !important; }

.p-r-190 {
  padding-right: 190rem !important; }

.p-b-190 {
  padding-bottom: 190rem !important; }

.p-l-190 {
  padding-left: 190rem !important; }

.p-x-190 {
  padding-right: 190rem !important;
  padding-left: 190rem !important; }

.p-y-190 {
  padding-top: 190rem !important;
  padding-bottom: 190rem !important; }

.p-a-191 {
  padding: 191rem 191rem !important; }

.p-t-191 {
  padding-top: 191rem !important; }

.p-r-191 {
  padding-right: 191rem !important; }

.p-b-191 {
  padding-bottom: 191rem !important; }

.p-l-191 {
  padding-left: 191rem !important; }

.p-x-191 {
  padding-right: 191rem !important;
  padding-left: 191rem !important; }

.p-y-191 {
  padding-top: 191rem !important;
  padding-bottom: 191rem !important; }

.p-a-192 {
  padding: 192rem 192rem !important; }

.p-t-192 {
  padding-top: 192rem !important; }

.p-r-192 {
  padding-right: 192rem !important; }

.p-b-192 {
  padding-bottom: 192rem !important; }

.p-l-192 {
  padding-left: 192rem !important; }

.p-x-192 {
  padding-right: 192rem !important;
  padding-left: 192rem !important; }

.p-y-192 {
  padding-top: 192rem !important;
  padding-bottom: 192rem !important; }

.p-a-193 {
  padding: 193rem 193rem !important; }

.p-t-193 {
  padding-top: 193rem !important; }

.p-r-193 {
  padding-right: 193rem !important; }

.p-b-193 {
  padding-bottom: 193rem !important; }

.p-l-193 {
  padding-left: 193rem !important; }

.p-x-193 {
  padding-right: 193rem !important;
  padding-left: 193rem !important; }

.p-y-193 {
  padding-top: 193rem !important;
  padding-bottom: 193rem !important; }

.p-a-194 {
  padding: 194rem 194rem !important; }

.p-t-194 {
  padding-top: 194rem !important; }

.p-r-194 {
  padding-right: 194rem !important; }

.p-b-194 {
  padding-bottom: 194rem !important; }

.p-l-194 {
  padding-left: 194rem !important; }

.p-x-194 {
  padding-right: 194rem !important;
  padding-left: 194rem !important; }

.p-y-194 {
  padding-top: 194rem !important;
  padding-bottom: 194rem !important; }

.p-a-195 {
  padding: 195rem 195rem !important; }

.p-t-195 {
  padding-top: 195rem !important; }

.p-r-195 {
  padding-right: 195rem !important; }

.p-b-195 {
  padding-bottom: 195rem !important; }

.p-l-195 {
  padding-left: 195rem !important; }

.p-x-195 {
  padding-right: 195rem !important;
  padding-left: 195rem !important; }

.p-y-195 {
  padding-top: 195rem !important;
  padding-bottom: 195rem !important; }

.p-a-196 {
  padding: 196rem 196rem !important; }

.p-t-196 {
  padding-top: 196rem !important; }

.p-r-196 {
  padding-right: 196rem !important; }

.p-b-196 {
  padding-bottom: 196rem !important; }

.p-l-196 {
  padding-left: 196rem !important; }

.p-x-196 {
  padding-right: 196rem !important;
  padding-left: 196rem !important; }

.p-y-196 {
  padding-top: 196rem !important;
  padding-bottom: 196rem !important; }

.p-a-197 {
  padding: 197rem 197rem !important; }

.p-t-197 {
  padding-top: 197rem !important; }

.p-r-197 {
  padding-right: 197rem !important; }

.p-b-197 {
  padding-bottom: 197rem !important; }

.p-l-197 {
  padding-left: 197rem !important; }

.p-x-197 {
  padding-right: 197rem !important;
  padding-left: 197rem !important; }

.p-y-197 {
  padding-top: 197rem !important;
  padding-bottom: 197rem !important; }

.p-a-198 {
  padding: 198rem 198rem !important; }

.p-t-198 {
  padding-top: 198rem !important; }

.p-r-198 {
  padding-right: 198rem !important; }

.p-b-198 {
  padding-bottom: 198rem !important; }

.p-l-198 {
  padding-left: 198rem !important; }

.p-x-198 {
  padding-right: 198rem !important;
  padding-left: 198rem !important; }

.p-y-198 {
  padding-top: 198rem !important;
  padding-bottom: 198rem !important; }

.p-a-199 {
  padding: 199rem 199rem !important; }

.p-t-199 {
  padding-top: 199rem !important; }

.p-r-199 {
  padding-right: 199rem !important; }

.p-b-199 {
  padding-bottom: 199rem !important; }

.p-l-199 {
  padding-left: 199rem !important; }

.p-x-199 {
  padding-right: 199rem !important;
  padding-left: 199rem !important; }

.p-y-199 {
  padding-top: 199rem !important;
  padding-bottom: 199rem !important; }

.p-a-200 {
  padding: 200rem 200rem !important; }

.p-t-200 {
  padding-top: 200rem !important; }

.p-r-200 {
  padding-right: 200rem !important; }

.p-b-200 {
  padding-bottom: 200rem !important; }

.p-l-200 {
  padding-left: 200rem !important; }

.p-x-200 {
  padding-right: 200rem !important;
  padding-left: 200rem !important; }

.p-y-200 {
  padding-top: 200rem !important;
  padding-bottom: 200rem !important; }

.triangle {
  margin: 5px;
  display: inline-block;
  width: 0;
  height: 0; }
  .triangle--top {
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid #000; }
  .triangle--bottom {
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-top: 1rem solid #000; }
  .triangle--right {
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-left: 1rem solid #000; }
  .triangle--left {
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
    border-right: 1rem solid #000; }

.flex-ui {
  display: flex;
  flex-flow: wrap;
  margin-left: calc( -1* 15px);
  margin-bottom: calc( -1* 15px); }
  .flex-ui .flex-item {
    margin-left: 15px;
    margin-bottom: 15px;
    width: calc( 100% / 3 - 15px); }

.font-weight-400 {
  font-weight: normal !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.font-weight-600 {
  font-weight: 600 !important; }

.font-weight-700 {
  font-weight: 700 !important; }

.font-weight-800 {
  font-weight: 800 !important; }

.font-weight-900 {
  font-weight: 900 !important; }

.font-weight-200 {
  font-weight: 200 !important; }

.font-weight-100 {
  font-weight: 100 !important; }

.main-title {
  font-size: 4rem;
  letter-spacing: .4rem;
  text-transform: capitalize;
  font-weight: bold;
  color: #282828;
  margin-bottom: 5rem; }
  @media (max-width: 767px) {
    .main-title {
      font-size: 3rem;
      letter-spacing: .3rem; } }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.color-red {
  color: #CA3625 !important; }

.btn-position .swiper-next, .btn-position .swiper-prev {
  transform: none; }

.mb-15 {
  margin-bottom: 1.5rem !important; }

.m-0 {
  margin: 0 !important; }

.text-uppercase {
  text-transform: uppercase; }

a {
  outline: none !important; }

.text-center {
  text-align: center !important; }

.color-black {
  color: #000 !important; }

.color-white {
  color: #fff !important; }

.color-purple-bd {
  color: #BD56A0 !important; }

.color-purple-45 {
  color: #452A50 !important; }

.color-dark-70 {
  color: #707070 !important; }

.d-inline-flex {
  display: inline-flex; }

.border-none {
  border: none !important; }

.d-flex {
  display: flex; }

.text-align-left {
  text-align: left !important; }

.text-align-right {
  text-align: right !important; }

.italic {
  font-style: italic !important; }

.flex-end {
  justify-content: flex-end !important; }

.flex-start {
  justify-content: flex-start !important; }

.slick-disabled {
  pointer-events: none;
  cursor: grab; }

.fz-13 {
  font-size: 1.3rem; }

.d-block {
  display: block; }

.p-3 {
  padding: 3rem !important; }

.m-l-auto {
  margin-left: auto !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.ls-0 {
  letter-spacing: 0 !important; }

.w-100 {
  width: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.p-0 {
  padding: 0 !important; }

.btn {
  font-size: 1.5rem;
  letter-spacing: .03rem;
  height: 3.6rem;
  line-height: 3.6rem;
  box-sizing: border-box;
  padding: 0 2.5rem;
  display: inline-flex;
  min-width: 13.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: .3rem;
  box-shadow: none;
  text-transform: uppercase;
  border: none;
  margin-top: 2rem;
  transition: all .2s; }
  .btn .icon {
    width: 1.6rem;
    flex-shrink: 0;
    margin-right: 1rem; }
    .btn .icon img {
      width: 100%;
      height: auto;
      filter: brightness(0) invert(1); }
  .btn-white {
    background: #fff;
    color: #BD55A0; }
    .btn-white--brounded {
      border: 1px solid #fff;
      color: #fff;
      background: none; }
      .btn-white--brounded:hover {
        background: #fff;
        color: #BD55A0;
        transition: all .3s; }
  .btn-purple {
    background: #BD55A0;
    color: #fff; }
    .btn-purple--brounded {
      border: 1px solid #BD55A0;
      color: #BD55A0;
      background: none; }
      .btn-purple--brounded:hover {
        background: #BD55A0;
        color: #fff;
        transition: all .3s; }
  .btn-purple-45 {
    background: #452A50;
    color: #fff; }
    .btn-purple-45--brounded {
      border: 1px solid #452A50;
      color: #452A50;
      background: none; }
      .btn-purple-45--brounded:hover {
        background: #452A50;
        color: #fff;
        transition: all .3s; }
  .btn-blue {
    background: #0078BF;
    color: #fff; }
    .btn-blue--brounded {
      border: 1px solid #0078BF;
      color: #0078BF;
      background: none; }
      .btn-blue--brounded:hover {
        background: #0078BF;
        color: #fff;
        transition: all .3s; }
  .btn-green {
    background: #007885;
    color: #fff; }
    .btn-green--brounded {
      border: 1px solid #007885;
      color: #007885;
      background: none; }
      .btn-green--brounded:hover {
        background: #007885;
        color: #fff;
        transition: all .3s; }
  .btn-dark-light {
    background: #9F9F9F;
    color: #fff; }
    .btn-dark-light--brounded {
      border: 1px solid #9F9F9F;
      color: #9F9F9F;
      background: none; }
      .btn-dark-light--brounded:hover {
        background: #9F9F9F;
        color: #fff;
        transition: all .3s; }
  .btn-dark-70 {
    background: #707070;
    color: #fff; }
    .btn-dark-70--brounded {
      border: 1px solid #707070;
      color: #707070;
      background: none; }
      .btn-dark-70--brounded:hover {
        background: #707070;
        color: #fff;
        transition: all .3s; }
  .btn-red {
    background: #D33B27;
    color: #fff; }
    .btn-red--brounded {
      border: 1px solid #D33B27;
      color: #D33B27;
      background: none; }
      .btn-red--brounded:hover {
        background: #D33B27;
        color: #fff;
        transition: all .3s; }
  .btn:hover, .btn.active {
    box-shadow: 0.1rem 0.5rem 1rem rgba(0, 0, 0, 0.3); }

.text-center {
  text-align: center; }

.swiper-pagination {
  position: absolute;
  left: 50% !important;
  transform: translateX(-50%);
  display: flex;
  bottom: 7rem;
  z-index: 9;
  justify-content: center; }
  .swiper-pagination span {
    width: 10px;
    height: 10px;
    border: none;
    opacity: 1;
    border-radius: 5px;
    margin: 0 5px;
    background: #F5E5F0;
    position: static;
    outline: none;
    transition: all .2s; }
    .swiper-pagination span.swiper-pagination-bullet-active {
      background: #BD55A0;
      transition: all .2s; }

.slick-dots {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 1rem; }
  .slick-dots li {
    width: 10px;
    height: 10px;
    border: none;
    opacity: 1;
    border-radius: 5px;
    margin: 0 5px;
    background: #F5E5F0;
    position: static;
    outline: none;
    transition: all .2s; }
    .slick-dots li.slick-active {
      background: #BD55A0;
      transition: all .2s; }
  .slick-dots button {
    display: none; }

.swiper-prev, .swiper-next {
  z-index: 9;
  outline: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center; }
  .swiper-prev img, .swiper-next img {
    width: 2.5rem;
    height: auto; }
    @media (max-width: 991px) {
      .swiper-prev img, .swiper-next img {
        width: 2rem; } }

.swiper-prev {
  left: -5.6rem; }

.swiper-next {
  right: -5.6rem; }

@media (max-width: 1199.98px) {
  .swiper-prev {
    left: 15px; }
  .swiper-next {
    right: 15px; } }

.swiper-button-disabled {
  opacity: .4; }

.amount {
  display: inline-flex;
  align-items: stretch;
  border: 1px solid rgba(4, 36, 78, 0.1);
  overflow: hidden;
  background: #fff; }
  .amount .minus, .amount .plus {
    width: 5rem;
    height: 5rem;
    flex-shrink: 0;
    background: #0078BF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center; }
    .amount .minus img, .amount .plus img {
      width: 1.8rem; }
  .amount .input {
    text-align: center;
    border: none;
    width: 7.8rem;
    outline: none;
    background: none;
    color: #000000;
    font-size: 2rem;
    height: 5rem;
    padding: 0; }
  .amount ::-webkit-outer-spin-button, .amount ::-webkit-inner-spin-button {
    display: none; }

.pagi {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none; }
  .pagi-item {
    font-size: 1.8rem;
    color: #02234F;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0 !important;
    cursor: pointer;
    transition: .3s; }
    .pagi-item:not(:last-child) {
      margin-right: 1rem; }
  .pagi-item.is-disabled {
    cursor: not-allowed; }
  .pagi-item:hover, .pagi-item.is-active {
    color: #88D7B0;
    text-stroke: 1.5px currentColor;
    -webkit-text-stroke: 1.5px currentColor;
    transition: .3s; }
  .pagi img {
    width: 5px; }

.dropdown {
  min-width: 23.8rem;
  position: relative;
  width: fit-content;
  background: #F5F5F5; }
  .dropdown-caret {
    position: relative;
    margin-left: 5px;
    width: 3.6rem;
    height: 3.6rem;
    background: #E1E1E1;
    display: flex;
    justify-content: center;
    align-items: center; }
    .dropdown-caret img {
      width: 1.5rem;
      height: auto; }
  .dropdown-select {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    background: none;
    height: 3.6rem;
    line-height: 3.6rem;
    padding-left: 1.5rem;
    height: 100%; }
    .dropdown-select .dropdown-selected {
      color: #282828;
      font-weight: 300; }
  .dropdown-list {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% + .5rem);
    z-index: 9;
    border: none;
    background: #fff;
    display: none;
    padding: 0;
    list-style: none;
    border: 1px solid #E2E2E2;
    border-radius: .5rem; }
  .dropdown-item {
    z-index: 2;
    font-weight: 300;
    color: #282828;
    line-height: 4.8rem;
    padding: 0 1.5rem;
    text-transform: capitalize;
    cursor: pointer; }
    .dropdown-item.selected, .dropdown-item:hover {
      background: #F5E4F0; }
  .dropdown.open .dropdown-list {
    z-index: 9999 !important; }
    .dropdown.open .dropdown-list .dropdown-item {
      display: block; }

.custom-checkbox, .custom-radio {
  cursor: pointer; }
  .custom-checkbox label, .custom-radio label {
    font-size: 1.8rem;
    color: #282828;
    line-height: 2.5rem;
    text-transform: capitalize; }
  .custom-checkbox input, .custom-radio input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer; }
  .custom-checkbox.style-1 label, .custom-radio.style-1 label {
    position: relative;
    cursor: pointer;
    padding-left: 3.5rem; }
    .custom-checkbox.style-1 label:before, .custom-radio.style-1 label:before {
      content: '';
      -webkit-appearance: none;
      width: 2rem;
      height: 2rem;
      background: none;
      border: 1px solid #E1E1E1;
      display: inline-block;
      border-radius: 0.2rem;
      position: absolute;
      top: .2rem;
      left: 0;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 5px; }
  .custom-checkbox.style-1 input[type="radio"]:checked + label:after, .custom-radio.style-1 input[type="radio"]:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: .65rem;
    left: .7rem;
    width: .5rem;
    height: .8rem;
    border: solid #000000;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg); }
  .custom-checkbox.style-1 input[type="checkbox"]:checked + label:after, .custom-radio.style-1 input[type="checkbox"]:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: .65rem;
    left: .7rem;
    width: .5rem;
    height: .8rem;
    border: solid #000000;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg); }
  .custom-checkbox.style-2 label, .custom-radio.style-2 label {
    position: relative;
    cursor: pointer;
    padding-left: 3.5rem; }
    .custom-checkbox.style-2 label:before, .custom-radio.style-2 label:before {
      content: "";
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: none;
      border: 1px solid #E1E1E1;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      cursor: pointer; }
  .custom-checkbox.style-2 input[type="radio"]:focus + label:before, .custom-radio.style-2 input[type="radio"]:focus + label:before {
    border: 1px solid #707070;
    background: #707070;
    box-shadow: inset 0 0 0 .2rem #fff; }
  .custom-checkbox.style-2 input[type="radio"]:checked + label:before, .custom-radio.style-2 input[type="radio"]:checked + label:before {
    border: 1px solid #707070;
    background: #707070;
    box-shadow: inset 0 0 0 .2rem #fff; }
  .custom-checkbox.style-2 input[type="checkbox"]:focus + label:before, .custom-radio.style-2 input[type="checkbox"]:focus + label:before {
    border: 1px solid #707070;
    background: #707070;
    box-shadow: inset 0 0 0 .2rem #fff; }
  .custom-checkbox.style-2 input[type="checkbox"]:checked + label:before, .custom-radio.style-2 input[type="checkbox"]:checked + label:before {
    border: 1px solid #707070;
    background: #707070;
    box-shadow: inset 0 0 0 .2rem #fff; }

input, textarea {
  outline: none;
  font-size: 1.5rem;
  color: #282828;
  border-radius: .3rem;
  border: none;
  font-weight: 300;
  background: #F5F5F5;
  box-shadow: none;
  height: 3.6rem;
  width: 100%;
  padding: 0rem 1.5rem; }

textarea {
  resize: none;
  padding: 1.5rem;
  height: 13.3rem; }

.select-box {
  overflow: hidden;
  border: none;
  background: #F5F5F5;
  border: none;
  height: 3.6rem;
  border-radius: .3rem;
  position: relative; }
  .select-box:before {
    font-family: Linearicons-Free;
    content: "\e874";
    position: absolute;
    top: 50%;
    right: .5rem;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #707070; }

select {
  text-transform: capitalize;
  outline: none;
  width: 100%;
  outline: none;
  line-height: 3.6rem;
  padding: 0 1.5rem;
  font-weight: 300;
  font-size: 1.5rem;
  color: #282828;
  background: none;
  border: none;
  box-shadow: none;
  outline: none;
  -webkit-appearance: none; }

select:invalid {
  color: #707070; }

select option {
  color: #282828; }

button {
  outline: none !important;
  border: none;
  background: none;
  box-shadow: none; }

::-webkit-input-placeholder {
  color: #282828;
  text-transform: none; }

@media screen and (min-width: 768px) {
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    grid-gap: 0 1.7rem; } }

.form-group {
  margin-bottom: 1.5rem; }
  .form-group .input {
    position: relative; }
  .form-group label {
    font-size: 1.5rem;
    color: #707070;
    font-weight: 300;
    margin-bottom: .5rem; }
    .form-group label span {
      color: red; }

.range-price-slide .slider-box {
  width: 100%; }

.range-price-slide label {
  border: none;
  display: inline-block;
  vertical-align: top; }

.range-price-slide input {
  border: none;
  outline: none;
  width: 100%;
  background: none;
  font-size: 1.4rem;
  border: none !important;
  color: rgba(2, 12, 34, 0.8);
  margin-top: 1rem;
  width: fit-content;
  padding: 0;
  height: auto;
  line-height: 1;
  text-align: left; }

.range-price-slide .slider {
  background: rgba(2, 12, 34, 0.2);
  margin-top: 2rem;
  border: none;
  height: .4rem;
  border-radius: 0;
  margin-left: 1rem; }
  .range-price-slide .slider .ui-slider-range {
    background: #020C22;
    border: none; }
  .range-price-slide .slider .ui-slider-handle.ui-corner-all.ui-state-default {
    border-radius: 50%;
    background: #ED1B24;
    border: none;
    outline: none;
    top: 50%;
    height: 1rem;
    width: 1rem;
    transform: translateY(-50%); }

.ck-editor__editable_inline {
  min-height: 30rem; }

[data-aos="box"] {
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0); }
  [data-aos="box"].aos-animate {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    transition: 1s cubic-bezier(0.77, 0, 0.18, 1); }

[data-aos="box-reverse"] {
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%); }
  [data-aos="box-reverse"].aos-animate {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    transition: 1s cubic-bezier(0.77, 0, 0.18, 1); }

[data-aos="fadeup"] {
  transform: translateY(2rem); }
  [data-aos="fadeup"].aos-animate {
    transform: translateY(0rem); }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  50% {
    z-index: 2; }
  100% {
    transform: rotate(360deg);
    z-index: 0; } }

@keyframes rotate2 {
  0% {
    transform: rotate(0); }
  50% { }
  100% {
    transform: rotate(-360deg); } }

.page-about-2 {
  padding: 10rem 0;
  position: relative; }
  .page-about-2:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    z-index: -1;
    left: 0;
    background-repeat: no-repeat;
    background-image: url(../img/about/WhySupportCHARIS_BgBanner.jpg);
    background-position: center;
    background-size: cover; }
  .page-about-2 .content {
    box-shadow: 0px .3rem 1.2rem #00000029;
    border: 1px solid #F4F4F4;
    padding: 4rem 6rem;
    background: #fff; }
    .page-about-2 .content p:not(:last-child) {
      margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .page-about-2 .content {
        padding: 4rem 20px; } }

.page-about-1 .box-content p:not(:last-child) {
  margin-bottom: 2rem; }

.page-about-1 .box-content .btn {
  width: 100%; }

@media (max-width: 767px) {
  .page-about-1 .box-content {
    padding-top: 5rem; } }

.page-about-1 .video-gallery .video {
  display: block;
  position: relative;
  border-radius: .5rem;
  overflow: hidden; }
  .page-about-1 .video-gallery .video .video-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .page-about-1 .video-gallery .video .video-btn img {
      width: 100%;
      height: 100%;
      object-fit: contain; }

.page-about-1 .video-gallery .my-swiper .image {
  padding-top: 55.81395%;
  display: block;
  position: relative; }
  .page-about-1 .video-gallery .my-swiper .image img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; }

.page-about-1 .video-gallery .my-swiper .video .video-btn {
  width: 7rem;
  height: 7rem; }

.page-about-1 .video-gallery .my-swiper2 {
  margin-top: 20px; }
  @media (max-width: 575px) {
    .page-about-1 .video-gallery .my-swiper2 {
      margin-top: 15px; } }
  .page-about-1 .video-gallery .my-swiper2 .image {
    padding-top: 55.85586%;
    display: block;
    position: relative; }
    .page-about-1 .video-gallery .my-swiper2 .image img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .page-about-1 .video-gallery .my-swiper2 .video .video-btn {
    width: 4rem;
    height: 4rem; }

.page-intro-3 .items {
  margin-bottom: -40px; }
  .page-intro-3 .items .blog-card {
    margin-bottom: 40px; }

.main-banner {
  border-radius: .5rem;
  overflow: hidden;
  position: relative;
  padding: 5rem; }
  @media (max-width: 767px) {
    .main-banner {
      padding: 5rem 20px; } }
  .main-banner .title {
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    line-height: 1.2; }
  .main-banner .desc {
    color: #fff;
    max-width: 70rem;
    margin-top: 2rem; }

.btn-wrap .btn:not(:last-child) {
  margin-right: 2rem; }

.activities {
  padding: 4rem 0 10rem; }
  .activities .row-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .activities .row-wrapper .left-wrap {
      width: calc(20% -2rem); }
    .activities .row-wrapper .right-wrap {
      width: calc(80% -2rem); }
    @media (max-width: 767px) {
      .activities .row-wrapper .left-wrap, .activities .row-wrapper .right-wrap {
        width: 100%; } }
  .activities .left-wrap {
    margin-bottom: 4rem;
    position: relative; }
  .activities .mb-filter {
    margin-top: 0; }
    @media (min-width: 768px) {
      .activities .mb-filter {
        display: none; } }
  @media (max-width: 767px) {
    .activities .sidebar {
      display: none;
      position: absolute;
      left: -20px;
      width: 100vw;
      top: calc(100% + 2rem);
      background: #fff;
      padding: 0 20px;
      z-index: 50;
      padding-bottom: 3rem; } }
  .activities .sidebar .cate-txt {
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #0F5373;
    margin-bottom: 1rem;
    padding: 0 2rem;
    font-weight: 500; }
  .activities .sidebar .sidebar-item:not(:last-child) {
    margin-bottom: 3rem; }
  .activities .sidebar .sidebar-item .sidebar-text {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #452A50;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E1E1E1;
    justify-content: space-between; }
    .activities .sidebar .sidebar-item .sidebar-text .arrow {
      width: 1.3rem;
      height: 1rem;
      background-image: url(../img/icons/Arrow3_Purple.png);
      transform: scaleY(-1);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: all .2s; }
    .activities .sidebar .sidebar-item .sidebar-text.active .arrow {
      transform: scale(1);
      transition: all .2s; }
  .activities .sidebar .sidebar-item .menu {
    list-style: none;
    background: #F4F4F4;
    display: none;
    padding: .5rem 0;
    padding-top: 1.7rem; }
    .activities .sidebar .sidebar-item .menu .menu-item:not(:last-child) {
      margin-bottom: 1rem; }
    .activities .sidebar .sidebar-item .menu .menu-item .menu-link {
      font-size: 1.5rem;
      color: #282828;
      text-transform: capitalize;
      display: inline-block;
      transition: all .3s ease; }
    .activities .sidebar .sidebar-item .menu .menu-item:hover .menu-link, .activities .sidebar .sidebar-item .menu .menu-item.active .menu-link {
      color: #BD55A0;
      text-stroke: .1rem currentColor;
      -webkit-text-stroke: .1rem currentColor;
      transition: all .3s ease; }
  .activities .sidebar .sidebar-item .filter-list {
    list-style: none;
    margin-top: 1.5rem;
    display: none;
    overflow: hidden;
    overflow-y: auto;
    max-height: 25rem; }
    .activities .sidebar .sidebar-item .filter-list::-webkit-scrollbar {
      width: .3rem; }
    .activities .sidebar .sidebar-item .filter-list::-webkit-scrollbar-track {
      border-radius: 20px;
      background: transparent; }
    .activities .sidebar .sidebar-item .filter-list::-webkit-scrollbar-thumb {
      background: #007885;
      border-radius: 20px; }
    .activities .sidebar .sidebar-item .filter-list .filter-item label {
      font-size: 1.5rem; }
      .activities .sidebar .sidebar-item .filter-list .filter-item label:before {
        top: .2rem; }
    .activities .sidebar .sidebar-item .filter-list .filter-item:not(:last-child) {
      margin-bottom: 1rem; }
  .activities .sidebar .apply-filter {
    margin-top: 0;
    width: 100%; }
  .activities .list {
    margin: 0 -15px; }
    .activities .list .col, .activities .list [class*=col-] {
      padding-right: 15px;
      padding-left: 15px; }
    .activities .list .item {
      margin-bottom: 30px; }
  .activities .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem; }
    .activities .head .clear-all {
      color: #007885;
      text-transform: capitalize;
      text-decoration: underline; }
  .activities .showing {
    color: #707070;
    margin-bottom: 3rem; }

.fancybox-slide--html {
  padding: 20px !important; }

.fancybox-bg {
  background: rgba(40, 40, 40, 0.66); }

.popup-default {
  padding: 0 !important; }
  @media (min-width: 768px) {
    .popup-default {
      width: 35%; } }
  .popup-default .popup-head {
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 2rem;
    background: #452A50; }
  .popup-default .popup-body {
    padding: 2.5rem; }
  .popup-default .donate-amount {
    list-style: none;
    display: flex;
    flex-flow: wrap;
    margin: -1rem; }
    .popup-default .donate-amount li {
      padding: 1rem;
      width: 33.33%; }
      .popup-default .donate-amount li a {
        display: block;
        text-align: center;
        font-size: 1.5rem;
        letter-spacing: .03rem;
        height: 3.5rem;
        line-height: 3.5rem;
        box-sizing: border-box;
        padding: 0 2.5rem;
        transition: all .2s;
        border-radius: .3rem;
        border: 1px solid #E1E1E1;
        color: #9F9F9F;
        background: #F5F5F5; }
      .popup-default .donate-amount li:hover a, .popup-default .donate-amount li.active a {
        color: #fff;
        transition: all .2s;
        border: 1px solid #007885;
        background: #007885; }
    .popup-default .donate-amount .other-amount {
      width: 66.66%; }
    @media (max-width: 575px) {
      .popup-default .donate-amount li {
        width: 50%; }
      .popup-default .donate-amount .other-amount {
        width: 100%; } }
  .popup-default .learn-more {
    display: block;
    width: fit-content;
    text-decoration: underline;
    margin: 0 auto;
    margin-top: 2rem;
    transition: all .2s;
    color: #282828;
    font-weight: bold;
    text-transform: uppercase; }
    .popup-default .learn-more:hover {
      letter-spacing: .1rem;
      transition: all .2s; }
  .popup-default .bottom-wrap {
    margin-top: 3rem;
    border-top: 1px dashed #0000001B;
    padding-top: 3rem; }
  .popup-default .text {
    line-height: 1.6; }
  .popup-default .btn-wrap {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .popup-default .btn-wrap .btn {
      margin-right: 0 !important;
      width: calc(50% -1rem); }
    @media (max-width: 575px) {
      .popup-default .btn-wrap {
        flex-direction: column; }
        .popup-default .btn-wrap .btn {
          width: 100%; } }
  .popup-default .options {
    display: flex;
    border-bottom: 1px solid #0000001B; }
  .popup-default .option {
    width: 50%;
    cursor: pointer; }
    .popup-default .option label {
      font-size: 1.5rem;
      color: #9F9F9F;
      background: #fff;
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      cursor: pointer;
      padding: 2rem;
      font-weight: 600; }
    .popup-default .option input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer; }
    .popup-default .option input:checked + label {
      color: #BD55A0;
      background: #F5E5F0; }

.donate-haf-popup .btn {
  width: 100% !important; }

.fancybox-close-small {
  display: block !important;
  padding: 0px !important;
  width: 1.5rem !important;
  top: 2.5rem !important;
  right: 2rem !important;
  height: 1.5rem !important; }
  .fancybox-close-small svg {
    display: none !important; }
  .fancybox-close-small:before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../img/icons/Cart_Delete.png);
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(0) invert(1);
    background-size: contain; }

.add-to-basket-popup {
  text-align: center; }
  .add-to-basket-popup .icon {
    width: 12rem;
    margin: 0 auto; }
    .add-to-basket-popup .icon img {
      width: 100%;
      height: auto; }
  .add-to-basket-popup .text {
    margin-top: 3rem; }
  .add-to-basket-popup .btn-wrap {
    justify-content: center; }

.dashboard-banner {
  padding: 12.8rem 0 17.4rem;
  position: relative; }
  .dashboard-banner .heading {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: 4rem;
    letter-spacing: .4rem;
    text-transform: capitalize;
    font-weight: bold;
    color: #452A50;
    margin-bottom: 5rem;
    max-width: 67rem;
    margin: 0 auto; }
  .dashboard-banner:after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(90deg, #b05c9d 0%, rgba(0, 0, 0, 0) 64%);
    box-shadow: 0px 3px 12px #0000000F;
    opacity: 0.75; }

.account-dashboard {
  margin-top: -12.5rem;
  z-index: 2;
  position: relative; }
  .account-dashboard .my-tabs {
    box-shadow: 0 .3rem .6rem #00000029; }
    .account-dashboard .my-tabs .tabs-nav-wrap .tabs-nav {
      list-style: none;
      display: flex;
      align-items: center;
      flex-flow: wrap; }
      .account-dashboard .my-tabs .tabs-nav-wrap .tabs-nav li {
        width: 25%;
        font-size: 1.8rem;
        text-transform: capitalize;
        color: #9F9F9F;
        text-align: center;
        height: 7.5rem;
        display: inline-flex;
        background: #fff;
        justify-content: center;
        align-items: center;
        transition: all .2s;
        cursor: pointer;
        border-bottom: 1px solid #e1e1e1; }
        .account-dashboard .my-tabs .tabs-nav-wrap .tabs-nav li:not(:last-child) {
          border-right: 1px solid #E1E1E1; }
        .account-dashboard .my-tabs .tabs-nav-wrap .tabs-nav li:hover, .account-dashboard .my-tabs .tabs-nav-wrap .tabs-nav li.active {
          color: #fff;
          background: #452A50;
          border-color: #452A50;
          transition: all .2s; }
        @media (max-width: 767px) {
          .account-dashboard .my-tabs .tabs-nav-wrap .tabs-nav li {
            width: 50%;
            border-bottom: 1px solid #E1E1E1;
            border-right: 1px solid #E1E1E1; } }
  .account-dashboard .tab-content {
    display: none;
    background: #fff; }
    .account-dashboard .tab-content .tab-content-wrapper {
      padding: 2.5rem 4rem 4rem; }
      @media (max-width: 767px) {
        .account-dashboard .tab-content .tab-content-wrapper {
          padding: 2.5rem 20px 4rem; } }
    .account-dashboard .tab-content .box-top {
      background: #F7F5F4;
      padding: 2.5rem 4rem; }
      @media (max-width: 767px) {
        .account-dashboard .tab-content .box-top {
          padding: 2.5rem 20px; } }
      .account-dashboard .tab-content .box-top .step-progress {
        margin-top: auto; }
        @media (max-width: 767px) {
          .account-dashboard .tab-content .box-top .step-progress {
            margin-bottom: 3rem; } }
        .account-dashboard .tab-content .box-top .step-progress .step-result {
          text-align: left;
          color: #BD55A0;
          font-weight: bold;
          margin-bottom: 1rem; }
        .account-dashboard .tab-content .box-top .step-progress .bottom {
          margin-top: 1rem;
          font-weight: bold; }
        .account-dashboard .tab-content .box-top .step-progress .progress-bar {
          background-color: #E1E1E1;
          border-radius: 20px;
          position: relative;
          height: .5rem;
          width: 100%; }
          .account-dashboard .tab-content .box-top .step-progress .progress-bar .progress-done {
            background: #BD55A0;
            border-radius: 20px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 0;
            opacity: 0;
            transition: 1s ease 0.3s; }
    .account-dashboard .tab-content .steps-list {
      list-style: none; }
      .account-dashboard .tab-content .steps-list li {
        font-size: 1.8rem;
        position: relative;
        padding-left: 3rem;
        width: fit-content; }
        .account-dashboard .tab-content .steps-list li:not(:last-child) {
          margin-bottom: 1rem; }
        .account-dashboard .tab-content .steps-list li.done:before {
          background-image: url(../img/icons/check.png); }
        .account-dashboard .tab-content .steps-list li:before {
          content: '';
          position: absolute;
          top: .2rem;
          left: 0;
          width: 1.8rem;
          height: 1.8rem;
          background-image: url(../img/icons/check-1.png);
          background-position: center;
          background-size: contain; }
        .account-dashboard .tab-content .steps-list li.important {
          padding-right: 3rem; }
          .account-dashboard .tab-content .steps-list li.important:after {
            content: '';
            position: absolute;
            top: .2rem;
            right: 0;
            width: 1.8rem;
            height: 1.8rem;
            background-image: url(../img/icons/exclamation.png);
            background-position: center;
            background-size: contain; }
  .account-dashboard .accordions .accordion-item {
    background: #fff;
    box-shadow: 0px .3rem 1.2rem #0000000F;
    border-radius: .5rem; }
    .account-dashboard .accordions .accordion-item:not(:last-child) {
      margin-bottom: 2rem; }
    .account-dashboard .accordions .accordion-item .accordion-heading {
      padding-right: 3rem;
      font-weight: 600;
      font-size: 1.8rem;
      color: #452A50;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-shadow: 0px .3rem 1.2rem #0000000F;
      border-radius: .5rem; }
      @media (max-width: 767px) {
        .account-dashboard .accordions .accordion-item .accordion-heading {
          padding-right: 2rem;
          font-size: 1.6rem; } }
      .account-dashboard .accordions .accordion-item .accordion-heading .arrow {
        width: 1.4rem;
        height: .8rem;
        margin-left: auto;
        background-image: url(../img/icons/Arrow1_Grey.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: block;
        flex-shrink: 0; }
      .account-dashboard .accordions .accordion-item .accordion-heading .txt {
        display: block;
        margin-right: 2rem; }
      .account-dashboard .accordions .accordion-item .accordion-heading .number {
        margin-right: 2rem;
        width: 5rem;
        flex-shrink: 0;
        height: 5rem;
        border-radius: .5rem;
        background: #452A50;
        display: flex;
        box-shadow: 0px .3rem 1.2rem #0000000F;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-weight: bold; }
    .account-dashboard .accordions .accordion-item .accordion-content {
      display: none;
      padding: 4rem;
      background: #fff; }
      .account-dashboard .accordions .accordion-item .accordion-content p:not(:last-child) {
        margin-bottom: 1.5rem; }
      .account-dashboard .accordions .accordion-item .accordion-content .wrapper {
        display: flex;
        flex-flow: wrap; }
        .account-dashboard .accordions .accordion-item .accordion-content .wrapper .image {
          width: 26rem;
          flex-shrink: 0; }
          .account-dashboard .accordions .accordion-item .accordion-content .wrapper .image img {
            width: 100%;
            height: auto; }
        .account-dashboard .accordions .accordion-item .accordion-content .wrapper .content {
          width: 100%;
          flex: 1;
          padding-left: 5rem; }
        @media (max-width: 767px) {
          .account-dashboard .accordions .accordion-item .accordion-content .wrapper .image, .account-dashboard .accordions .accordion-item .accordion-content .wrapper .content {
            width: 100%; }
          .account-dashboard .accordions .accordion-item .accordion-content .wrapper .content {
            padding-left: 0;
            padding-top: 3rem; } }
      .account-dashboard .accordions .accordion-item .accordion-content .heading {
        font-size: 1.8rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 2rem; }
      .account-dashboard .accordions .accordion-item .accordion-content .list-content {
        list-style: none; }
        .account-dashboard .accordions .accordion-item .accordion-content .list-content li {
          color: #707070; }
          .account-dashboard .accordions .accordion-item .accordion-content .list-content li:not(:last-child) {
            margin-bottom: 1.5rem; }
          .account-dashboard .accordions .accordion-item .accordion-content .list-content li .text {
            display: inline-block;
            font-weight: 300;
            margin-left: .5rem; }
        .account-dashboard .accordions .accordion-item .accordion-content .list-content .clauses {
          text-decoration: underline;
          position: relative;
          padding-right: 3rem;
          width: fit-content; }
          .account-dashboard .accordions .accordion-item .accordion-content .list-content .clauses a {
            color: #BD56A0; }
          .account-dashboard .accordions .accordion-item .accordion-content .list-content .clauses:before {
            content: '';
            position: absolute;
            top: .2rem;
            right: 0;
            width: 1.8rem;
            height: 1.8rem;
            background-image: url(../img/icons/check.png);
            background-position: center;
            background-size: contain; }
      .account-dashboard .accordions .accordion-item .accordion-content .box:not(:last-child) {
        border-bottom: 1px solid #E1E1E1;
        padding-bottom: 4rem; }
      .account-dashboard .accordions .accordion-item .accordion-content .box:not(:first-child) {
        padding-top: 4rem; }
      .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav-wrap {
        border-bottom: 1px solid #E1E1E1;
        max-width: 92rem;
        overflow: hidden;
        overflow-x: auto; }
        .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav-wrap::-webkit-scrollbar {
          height: 0; }
      .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav {
        display: flex;
        list-style: none;
        align-items: center; }
        .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav li {
          font-size: 1.8rem;
          color: #707070;
          text-transform: capitalize;
          cursor: pointer;
          padding: 1rem 2.4rem;
          position: relative; }
          .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav li:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: .3rem;
            background: #452A50;
            width: 100%;
            opacity: 0;
            transition: all .2s;
            left: 0; }
          .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav li.active, .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav li:hover {
            color: #452A50;
            transition: all .2s;
            text-stroke: 1px currentColor;
            -webkit-text-stroke: 1px currentColor; }
            .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav li.active:after, .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tabs-nav li:hover:after {
              opacity: 1;
              transition: all .2s; }
      .account-dashboard .accordions .accordion-item .accordion-content .my-child-tabs .child-tab-content {
        padding-top: 4rem;
        display: none; }
      .account-dashboard .accordions .accordion-item .accordion-content .personal-declaration {
        display: flex;
        align-items: center; }
        .account-dashboard .accordions .accordion-item .accordion-content .personal-declaration .icon {
          display: block;
          width: 2rem;
          flex-shrink: 0; }
          .account-dashboard .accordions .accordion-item .accordion-content .personal-declaration .icon img {
            width: 100%;
            height: auto; }
        .account-dashboard .accordions .accordion-item .accordion-content .personal-declaration .txt {
          color: #BD55A0;
          text-transform: capitalize;
          padding-left: 2rem; }
      .account-dashboard .accordions .accordion-item .accordion-content .causes .list-item {
        display: flex;
        position: relative;
        flex-flow: wrap;
        z-index: 1;
        background: #fff;
        box-shadow: 0px 3px 12px #0000000F; }
      .account-dashboard .accordions .accordion-item .accordion-content .causes .item {
        width: calc(100% / 6);
        padding-top: calc(100% / 6);
        position: relative;
        text-align: center;
        transition: all .2s;
        border-bottom: 1px solid #F7F5F4;
        border-right: 1px solid #F7F5F4; }
        @media (max-width: 991px) {
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item {
            width: 25%;
            padding-top: 25%; } }
        @media (max-width: 575px) {
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item {
            width: 33.33%;
            padding-top: 33.33%; } }
        @media (max-width: 480px) {
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item {
            width: 50%;
            padding-top: 50%; } }
        .account-dashboard .accordions .accordion-item .accordion-content .causes .item .wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
          inset: 0;
          padding: 2rem; }
        .account-dashboard .accordions .accordion-item .accordion-content .causes .item .icon {
          height: 5rem;
          margin: 0 auto;
          transition: all .2s; }
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item .icon svg {
            height: 100%;
            width: auto; }
            .account-dashboard .accordions .accordion-item .accordion-content .causes .item .icon svg path {
              fill: #BD55A0;
              transition: all .2s; }
        @media (max-width: 767px) {
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item .icon {
            height: 4rem; }
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item .wrap {
            padding: 1rem; } }
        @media (max-width: 575px) {
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item .icon {
            height: 3rem; } }
        .account-dashboard .accordions .accordion-item .accordion-content .causes .item .title {
          color: #282828;
          font-weight: normal;
          margin-top: 1.5rem;
          text-transform: capitalize;
          font-size: 1.3rem;
          min-height: 3.6rem; }
        .account-dashboard .accordions .accordion-item .accordion-content .causes .item:hover {
          background: #F7F5F4;
          transition: all .2s; }
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item:hover .title {
            color: #007885;
            transition: all .2s;
            font-weight: bold; }
          .account-dashboard .accordions .accordion-item .accordion-content .causes .item:hover .icon {
            transform: scale(1.1);
            transition: all .2s; }
            .account-dashboard .accordions .accordion-item .accordion-content .causes .item:hover .icon svg path {
              fill: #007885;
              transition: all .2s; }
      .account-dashboard .accordions .accordion-item .accordion-content .organisations .items {
        display: flex;
        flex-flow: wrap;
        margin: -.76rem; }
        .account-dashboard .accordions .accordion-item .accordion-content .organisations .items .item {
          padding: .75rem; }
          .account-dashboard .accordions .accordion-item .accordion-content .organisations .items .item .image {
            height: 13rem;
            border: 1px solid #F5F5F5;
            width: auto;
            padding: 1rem; }
            .account-dashboard .accordions .accordion-item .accordion-content .organisations .items .item .image img {
              height: 100%;
              width: auto; }
      .account-dashboard .accordions .accordion-item .accordion-content .declarations-submitted {
        display: flex;
        align-items: center;
        position: relative;
        width: fit-content;
        padding-right: 3rem; }
        .account-dashboard .accordions .accordion-item .accordion-content .declarations-submitted:before {
          content: '';
          position: absolute;
          top: .2rem;
          right: 0;
          width: 1.8rem;
          height: 1.8rem;
          background-image: url(../img/icons/check.png);
          background-position: center;
          background-size: contain; }
        .account-dashboard .accordions .accordion-item .accordion-content .declarations-submitted .icon {
          display: block;
          width: 2rem;
          flex-shrink: 0; }
          .account-dashboard .accordions .accordion-item .accordion-content .declarations-submitted .icon img {
            width: 100%;
            height: auto; }
        .account-dashboard .accordions .accordion-item .accordion-content .declarations-submitted .txt {
          color: #BD55A0;
          text-transform: capitalize;
          padding-left: 2rem; }
    @media (max-width: 767px) {
      .account-dashboard .accordions .accordion-item .accordion-content {
        padding: 20px; } }
    .account-dashboard .accordions .accordion-item.active .accordion-heading .arrow {
      background-image: url(../img/icons/Arrow1_Grey-2.png); }
  .account-dashboard .donation-history-content, .account-dashboard .recurring-donations-content, .account-dashboard .activities-history-content {
    padding: 4rem 2.5rem; }
  .account-dashboard .filter-wrapper {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    margin-top: -2.5rem;
    margin-bottom: 3.5rem; }
    .account-dashboard .filter-wrapper .filter-item {
      margin-right: 2.5rem;
      margin-top: 2.5rem;
      display: inline-flex;
      align-items: center; }
      .account-dashboard .filter-wrapper .filter-item .txt {
        font-size: 1.5rem;
        color: #707070;
        text-transform: capitalize;
        margin-right: 1.5rem;
        font-weight: 300; }
      @media (max-width: 575px) {
        .account-dashboard .filter-wrapper .filter-item {
          display: flex;
          width: 100%; } }
    .account-dashboard .filter-wrapper .btn {
      margin-left: 1.5rem;
      margin-top: 2.5rem; }
      @media (max-width: 575px) {
        .account-dashboard .filter-wrapper .btn {
          margin-left: 0; } }
  .account-dashboard .table {
    width: 100%;
    border: 1px solid #DDDDDD; }
    .account-dashboard .table tbody tr {
      border-top: 1px solid #DDDDDD; }
      .account-dashboard .table tbody tr td:nth-child(1), .account-dashboard .table tbody tr td:nth-child(2) {
        color: #BD56A0; }
    .account-dashboard .table th {
      color: #000000;
      text-transform: capitalize;
      font-weight: bold; }
    .account-dashboard .table th, .account-dashboard .table td {
      padding: 1rem 2rem; }
    @media (max-width: 767px) {
      .account-dashboard .table thead {
        position: absolute;
        left: -9999px;
        overflow: hidden;
        width: 0px;
        height: 0px; }
      .account-dashboard .table tr, .account-dashboard .table td, .account-dashboard .table th, .account-dashboard .table thead, .account-dashboard .table tbody, .account-dashboard .table table {
        display: block; }
      .account-dashboard .table tbody tr {
        border-top: 0; }
        .account-dashboard .table tbody tr:not(:first-child) {
          border-top: 1px solid #DDDDDD; }
      .account-dashboard .table tbody td::before {
        display: inline-block;
        width: 19rem;
        flex: 0 0 19rem;
        min-width: 19rem;
        text-align: left;
        color: #000;
        font-weight: bold;
        text-transform: capitalize;
        content: attr(data-title); } }
  .account-dashboard .recurring-donations-content .table {
    width: calc(100% - 7.8rem); }
    .account-dashboard .recurring-donations-content .table .cancel-btn {
      position: absolute;
      left: calc(100% + 1.8rem);
      font-size: 1.3rem;
      border: 1px solid #6E6E6E;
      border-radius: .2rem;
      text-transform: uppercase;
      top: 1rem;
      padding: .3rem .6rem;
      transition: all .2s;
      color: #6E6E6E;
      cursor: pointer;
      display: inline-block;
      background: none; }
      .account-dashboard .recurring-donations-content .table .cancel-btn:hover {
        border: 1px solid #007885;
        transition: all .2s;
        color: #fff;
        background: #007885; }
    .account-dashboard .recurring-donations-content .table tr {
      position: relative; }
    .account-dashboard .recurring-donations-content .table th, .account-dashboard .recurring-donations-content .table td {
      padding: 1rem; }
    .account-dashboard .recurring-donations-content .table .arrow {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer; }
      .account-dashboard .recurring-donations-content .table .arrow img {
        flex-shrink: 0;
        width: 1.5rem;
        height: auto;
        transition: all .3s ease; }
      .account-dashboard .recurring-donations-content .table .arrow.active img {
        transform: scaleY(-1);
        transition: all .3s ease; }
    .account-dashboard .recurring-donations-content .table .more-content {
      display: none; }
    .account-dashboard .recurring-donations-content .table table tr {
      border-top: 0; }
    .account-dashboard .recurring-donations-content .table table th {
      color: #707070; }
    .account-dashboard .recurring-donations-content .table table thead {
      border-bottom: 1px solid #D9D8D8; }
    @media (max-width: 767px) {
      .account-dashboard .recurring-donations-content .table {
        width: 100%; }
        .account-dashboard .recurring-donations-content .table tbody {
          border-bottom: 1px solid #D9D8D8; }
        .account-dashboard .recurring-donations-content .table table tr {
          border: none !important; }
        .account-dashboard .recurring-donations-content .table .cancel-btn {
          position: static; }
        .account-dashboard .recurring-donations-content .table .arrow {
          width: 100%;
          justify-content: center;
          margin-top: 2rem;
          padding: 1.5rem;
          background: #007885;
          border-radius: .2rem; }
          .account-dashboard .recurring-donations-content .table .arrow img {
            filter: brightness(0) invert(1); }
        .account-dashboard .recurring-donations-content .table .more-content {
          border-top: 0; }
          .account-dashboard .recurring-donations-content .table .more-content tbody {
            border-bottom: 0; }
        .account-dashboard .recurring-donations-content .table .more-content > td {
          padding-top: 0; }
          .account-dashboard .recurring-donations-content .table .more-content > td:before {
            display: none; }
          .account-dashboard .recurring-donations-content .table .more-content > td:nth-child(1), .account-dashboard .recurring-donations-content .table .more-content > td:nth-child(3) {
            display: none; }
            .account-dashboard .recurring-donations-content .table .more-content > td:nth-child(1):before, .account-dashboard .recurring-donations-content .table .more-content > td:nth-child(3):before {
              display: none; } }
  .account-dashboard .recurring-donations-content table {
    width: 100%; }

.popup-default.cancel-popup {
  max-width: 49rem; }
  .popup-default.cancel-popup .popup-body {
    padding: 2.5rem 2.5rem 4.5rem;
    text-align: center; }
    .popup-default.cancel-popup .popup-body .txt {
      margin-bottom: 1.5rem; }
    .popup-default.cancel-popup .popup-body .btn-wrap {
      justify-content: center;
      gap: 0 1.5rem; }
      .popup-default.cancel-popup .popup-body .btn-wrap .btn {
        width: auto;
        min-width: 15.8rem; }

.head-btn {
  padding: 1.5rem 0;
  border-bottom: 1px solid #E1E1E1; }
  .head-btn ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;
    padding-right: 1rem; }
    .head-btn ul li {
      padding: 0 2rem; }
      .head-btn ul li:not(:last-child) {
        border-right: 1px solid #707070; }
      .head-btn ul li a {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #282828;
        text-decoration: underline;
        cursor: pointer; }

.main-tags {
  list-style: none;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin: -1rem; }
  .main-tags li {
    padding: 1rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
    .main-tags li .tag {
      font-size: 1.3rem;
      text-transform: capitalize;
      letter-spacing: .026rem;
      color: #fff;
      line-height: 2.5rem;
      padding: 0 2rem;
      background: #452A50;
      border-radius: .3rem;
      display: inline-block; }

.recommended-campaigns {
  padding-top: 10rem; }
  .recommended-campaigns .slider {
    position: relative; }
  .recommended-campaigns .swiper-slide {
    box-sizing: border-box;
    height: auto !important; }

.recommended-activities {
  padding: 10rem 0; }
  .recommended-activities .slider {
    position: relative; }
  .recommended-activities .swiper-slide {
    box-sizing: border-box;
    height: auto !important; }

.upload-box .box-img {
  padding-top: 88.46154%;
  display: block;
  position: relative; }
  .upload-box .box-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; }
  .upload-box .box-img img {
    height: 100% !important; }

.upload-box .upload-button {
  background: #E1E1E1;
  display: flex;
  border-radius: .2rem;
  padding: .5rem;
  width: 100%;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: center; }
  .upload-box .upload-button .icon {
    width: 1.5rem;
    margin-right: 1rem; }
    .upload-box .upload-button .icon img {
      width: 100%; }
  .upload-box .upload-button span {
    text-transform: capitalize; }

.upload-box .file-upload {
  padding: 0;
  border: 0;
  background: none;
  max-height: 0;
  overflow: hidden;
  height: auto;
  font-size: 1.3rem;
  color: #9F9F9F;
  font-style: italic;
  display: none; }

.upload-box .file-upload-name {
  font-size: 1.3rem;
  color: #9F9F9F;
  width: 100%;
  word-break: break-word;
  font-style: italic;
  margin-top: 1.5rem; }

.account-dashboard-sub {
  margin-top: -4.5rem;
  padding-bottom: 10rem; }
  .account-dashboard-sub .inner {
    position: relative;
    z-index: 2;
    background: #fff;
    box-shadow: 0px .3rem 1.2rem #0000000A;
    border: 1px solid #F4F4F4; }
  .account-dashboard-sub .full-content {
    color: #707070;
    padding: 4rem; }
    @media (max-width: 767px) {
      .account-dashboard-sub .full-content {
        padding: 4rem 20px; } }
    .account-dashboard-sub .full-content p {
      margin-bottom: 4rem; }
    .account-dashboard-sub .full-content ul {
      list-style: none; }
      .account-dashboard-sub .full-content ul li:not(:last-child) {
        margin-bottom: 3rem; }
      .account-dashboard-sub .full-content ul li .list {
        display: flex;
        flex-flow: wrap;
        margin-top: 1.5rem; }
        .account-dashboard-sub .full-content ul li .list .custom-checkbox:not(:last-child) {
          margin-right: 10rem; }
          @media (max-width: 767px) {
            .account-dashboard-sub .full-content ul li .list .custom-checkbox:not(:last-child) {
              margin-right: 5rem; } }
        .account-dashboard-sub .full-content ul li .list .custom-checkbox input[type="radio"]:focus + label:before {
          border: 1px solid #E1E1E1;
          background: #B05C9D;
          box-shadow: inset 0 0 0 .2rem #fff; }
        .account-dashboard-sub .full-content ul li .list .custom-checkbox input[type="radio"]:checked + label:before {
          border: 1px solid #E1E1E1;
          background: #B05C9D;
          box-shadow: inset 0 0 0 .2rem #fff; }
        .account-dashboard-sub .full-content ul li .list .custom-checkbox input[type="checkbox"]:focus + label:before {
          border: 1px solid #E1E1E1;
          background: #B05C9D;
          box-shadow: inset 0 0 0 .2rem #fff; }
        .account-dashboard-sub .full-content ul li .list .custom-checkbox input[type="checkbox"]:checked + label:before {
          border: 1px solid #E1E1E1;
          background: #B05C9D;
          box-shadow: inset 0 0 0 .2rem #fff; }
      .account-dashboard-sub .full-content ul li .note {
        margin-top: 1.5rem;
        border-bottom: 1px dashed #F4F4F4;
        padding-bottom: 3rem;
        color: #9F9F9F;
        font-weight: 300; }
  .account-dashboard-sub .btn-wrap {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;
    padding: 1.5rem 4rem;
    border-top: 1px solid #F4F4F4; }
    @media (max-width: 767px) {
      .account-dashboard-sub .btn-wrap {
        padding: 1.5rem 20px; } }
    .account-dashboard-sub .btn-wrap li {
      padding: 0 2rem; }
      .account-dashboard-sub .btn-wrap li:not(:last-child) {
        border-right: 1px solid #707070; }
      .account-dashboard-sub .btn-wrap li a {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #282828;
        text-decoration: underline;
        cursor: pointer; }
  .account-dashboard-sub .full-content.volunteer-history-content ul li:not(:last-child) {
    margin-bottom: 2rem; }
  .account-dashboard-sub .full-content.volunteer-history-content p {
    margin-bottom: 0; }
  .account-dashboard-sub .full-content.volunteer-history-content .content {
    color: #282828;
    padding-top: 1.5rem;
    padding-left: 1.8rem; }
    .account-dashboard-sub .full-content.volunteer-history-content .content ul:not(:last-child) {
      margin-bottom: 1rem; }
  .account-dashboard-sub .full-content.volunteer-history-content .bottom-content {
    font-size: 1.4rem;
    color: #707070;
    text-align: center;
    padding-top: 1.5rem;
    border-top: 1px solid #E1E1E1;
    margin-top: 2.5rem;
    font-style: italic; }

.edit-profile .main-tags .tag {
  display: inline-flex;
  align-items: center;
  padding: 0 1rem; }
  .edit-profile .main-tags .tag .remove {
    flex-shrink: 0;
    margin-left: 1rem;
    display: inline-block;
    cursor: pointer;
    width: 1rem; }
    .edit-profile .main-tags .tag .remove img {
      width: 100%;
      height: auto;
      filter: brightness(0) invert(1);
      transition: all .3s; }
    .edit-profile .main-tags .tag .remove:hover img {
      transform: scale(1.1); }

.edit-profile .accordions {
  padding: 4rem; }
  @media (max-width: 767px) {
    .edit-profile .accordions {
      padding: 4rem 20px; } }
  .edit-profile .accordions .accordion-item {
    background: #fff;
    box-shadow: 0px .3rem 1.2rem #0000000F;
    border-radius: .5rem; }
    .edit-profile .accordions .accordion-item:not(:last-child) {
      margin-bottom: 2rem; }
    .edit-profile .accordions .accordion-item .accordion-heading {
      padding-right: 3rem;
      font-weight: 600;
      font-size: 1.8rem;
      color: #452A50;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-shadow: 0px .3rem 1.2rem #0000000F;
      border-radius: .5rem; }
      @media (max-width: 767px) {
        .edit-profile .accordions .accordion-item .accordion-heading {
          padding-right: 2rem;
          font-size: 1.6rem; } }
      .edit-profile .accordions .accordion-item .accordion-heading .arrow {
        width: 1.4rem;
        height: .8rem;
        margin-left: auto;
        background-image: url(../img/icons/Arrow1_Grey.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        display: block;
        flex-shrink: 0; }
      .edit-profile .accordions .accordion-item .accordion-heading .txt {
        display: block;
        margin-right: 2rem; }
      .edit-profile .accordions .accordion-item .accordion-heading .number {
        margin-right: 2rem;
        width: 5rem;
        flex-shrink: 0;
        height: 5rem;
        border-radius: .5rem;
        background: #452A50;
        display: flex;
        box-shadow: 0px .3rem 1.2rem #0000000F;
        color: #fff;
        align-items: center;
        justify-content: center;
        font-weight: bold; }
    .edit-profile .accordions .accordion-item .accordion-content {
      display: none;
      padding: 4rem;
      background: #fff; }
      .edit-profile .accordions .accordion-item .accordion-content p:not(:last-child) {
        margin-bottom: 1.5rem; }

.edit-profile .wrapper {
  display: flex;
  flex-flow: wrap; }
  .edit-profile .wrapper .image {
    width: 26rem;
    flex-shrink: 0; }
    .edit-profile .wrapper .image img {
      width: 100%;
      height: auto; }
  .edit-profile .wrapper .content {
    width: 100%;
    flex: 1;
    padding-left: 5rem; }
  @media (max-width: 767px) {
    .edit-profile .wrapper .image, .edit-profile .wrapper .content {
      width: 100%; }
    .edit-profile .wrapper .content {
      padding-left: 0;
      padding-top: 3rem; } }

.edit-profile .heading {
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #282828;
  margin-bottom: 2rem; }

.edit-profile .form-wrap {
  max-width: 52rem; }

.edit-profile .list-content {
  list-style: none; }
  .edit-profile .list-content li {
    color: #707070; }
    .edit-profile .list-content li:not(:last-child) {
      margin-bottom: 1.5rem; }
    .edit-profile .list-content li .text {
      display: inline-block;
      font-weight: 300;
      margin-left: .5rem; }
  .edit-profile .list-content .clauses {
    text-decoration: underline;
    position: relative;
    padding-right: 3rem;
    width: fit-content; }
    .edit-profile .list-content .clauses a {
      color: #BD56A0; }
    .edit-profile .list-content .clauses:before {
      content: '';
      position: absolute;
      top: .2rem;
      right: 0;
      width: 1.8rem;
      height: 1.8rem;
      background-image: url(../img/icons/check.png);
      background-position: center;
      background-size: contain; }

.edit-profile .box:not(:last-child) {
  border-bottom: 1px solid #E1E1E1;
  padding-bottom: 4rem; }

.edit-profile .box:not(:first-child) {
  padding-top: 4rem; }

.edit-profile .my-child-tabs .child-tabs-nav-wrap {
  border-bottom: 1px solid #E1E1E1;
  max-width: 92rem;
  overflow: hidden;
  overflow-x: auto; }
  .edit-profile .my-child-tabs .child-tabs-nav-wrap::-webkit-scrollbar {
    height: 0; }

.edit-profile .my-child-tabs .child-tabs-nav {
  display: flex;
  list-style: none;
  align-items: center; }
  .edit-profile .my-child-tabs .child-tabs-nav li {
    font-size: 1.8rem;
    color: #707070;
    text-transform: capitalize;
    cursor: pointer;
    padding: 1rem 2.4rem;
    position: relative; }
    .edit-profile .my-child-tabs .child-tabs-nav li:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: .3rem;
      background: #452A50;
      width: 100%;
      opacity: 0;
      transition: all .2s;
      left: 0; }
    .edit-profile .my-child-tabs .child-tabs-nav li.active, .edit-profile .my-child-tabs .child-tabs-nav li:hover {
      color: #452A50;
      transition: all .2s;
      text-stroke: 1px currentColor;
      -webkit-text-stroke: 1px currentColor; }
      .edit-profile .my-child-tabs .child-tabs-nav li.active:after, .edit-profile .my-child-tabs .child-tabs-nav li:hover:after {
        opacity: 1;
        transition: all .2s; }

.edit-profile .my-child-tabs .child-tab-content {
  padding-top: 4rem;
  display: none; }

.edit-profile .personal-declaration {
  display: flex;
  align-items: center; }
  .edit-profile .personal-declaration .icon {
    display: block;
    width: 2rem;
    flex-shrink: 0; }
    .edit-profile .personal-declaration .icon img {
      width: 100%;
      height: auto; }
  .edit-profile .personal-declaration .txt {
    color: #BD55A0;
    text-transform: capitalize;
    padding-left: 2rem; }

.edit-profile .causes .list-item {
  display: flex;
  position: relative;
  flex-flow: wrap;
  z-index: 1;
  background: #fff;
  box-shadow: 0px 3px 12px #0000000F; }

.edit-profile .causes .item {
  width: calc(100% / 6);
  padding-top: calc(100% / 6);
  position: relative;
  text-align: center;
  transition: all .2s;
  border-bottom: 1px solid #F7F5F4;
  border-right: 1px solid #F7F5F4; }
  @media (max-width: 991px) {
    .edit-profile .causes .item {
      width: 25%;
      padding-top: 25%; } }
  @media (max-width: 575px) {
    .edit-profile .causes .item {
      width: 33.33%;
      padding-top: 33.33%; } }
  @media (max-width: 480px) {
    .edit-profile .causes .item {
      width: 50%;
      padding-top: 50%; } }
  .edit-profile .causes .item .remove {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    z-index: 9;
    background: #CC3333;
    padding: .4rem; }
    .edit-profile .causes .item .remove img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: scale-down;
      filter: brightness(0) invert(1); }
  .edit-profile .causes .item .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    padding: 2rem; }
  .edit-profile .causes .item .icon {
    height: 5rem;
    margin: 0 auto;
    transition: all .2s; }
    .edit-profile .causes .item .icon svg {
      height: 100%;
      width: auto; }
      .edit-profile .causes .item .icon svg path {
        fill: #BD55A0;
        transition: all .2s; }
  @media (max-width: 767px) {
    .edit-profile .causes .item .icon {
      height: 4rem; }
    .edit-profile .causes .item .wrap {
      padding: 1rem; } }
  @media (max-width: 575px) {
    .edit-profile .causes .item .icon {
      height: 3rem; } }
  .edit-profile .causes .item .title {
    color: #282828;
    font-weight: normal;
    margin-top: 1.5rem;
    text-transform: capitalize;
    font-size: 1.3rem;
    min-height: 3.6rem; }
  .edit-profile .causes .item:hover {
    background: #F7F5F4;
    transition: all .2s; }
    .edit-profile .causes .item:hover .title {
      color: #007885;
      transition: all .2s;
      font-weight: bold; }
    .edit-profile .causes .item:hover .icon {
      transform: scale(1.1);
      transition: all .2s; }
      .edit-profile .causes .item:hover .icon svg path {
        fill: #007885;
        transition: all .2s; }

.edit-profile .add-skillset {
  font-size: 1.5rem;
  text-decoration: underline;
  color: #BD56A0;
  margin-top: 1rem;
  text-transform: uppercase; }

.edit-profile .organisations .items {
  display: flex;
  flex-flow: wrap;
  margin: -.76rem; }
  .edit-profile .organisations .items .item {
    padding: .75rem;
    position: relative; }
    .edit-profile .organisations .items .item .image {
      height: 13rem;
      border: 1px solid #F5F5F5;
      width: auto;
      padding: 1rem; }
      .edit-profile .organisations .items .item .image img {
        height: 100%;
        width: auto; }
    .edit-profile .organisations .items .item .remove {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: 1.75rem;
      right: 1.75rem;
      cursor: pointer;
      z-index: 9;
      background: #CC3333;
      padding: .4rem; }
      .edit-profile .organisations .items .item .remove img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: scale-down;
        filter: brightness(0) invert(1); }

.edit-profile .declarations-submitted {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  padding-right: 3rem; }
  .edit-profile .declarations-submitted:before {
    content: '';
    position: absolute;
    top: .2rem;
    right: 0;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url(../img/icons/check.png);
    background-position: center;
    background-size: contain; }
  .edit-profile .declarations-submitted .icon {
    display: block;
    width: 2rem;
    flex-shrink: 0; }
    .edit-profile .declarations-submitted .icon img {
      width: 100%;
      height: auto; }
  .edit-profile .declarations-submitted .txt {
    color: #BD55A0;
    text-transform: capitalize;
    padding-left: 2rem; }

.check {
  text-decoration: underline;
  position: relative;
  padding-right: 3rem;
  width: fit-content; }
  .check a {
    color: #BD56A0; }
  .check:before {
    content: '';
    position: absolute;
    top: .2rem;
    right: 0;
    width: 1.8rem;
    height: 1.8rem;
    background-image: url(../img/icons/check.png);
    background-position: center;
    background-size: contain; }

.change-password {
  padding: 4rem; }
  @media (max-width: 767px) {
    .change-password {
      padding: 4rem 20px; } }
  .change-password .form-wrap {
    max-width: 100%; }
  .change-password .input {
    position: relative; }
    .change-password .input .toggle-pass {
      position: absolute;
      top: 50%;
      cursor: pointer;
      transform: translateY(-50%);
      right: 1.6rem; }
      .change-password .input .toggle-pass em {
        color: #707070;
        font-size: 1.6rem; }
      .change-password .input .toggle-pass.active em:before {
        content: "\ECB7"; }

.activities-indiv .top-content {
  margin-top: -15rem;
  position: relative;
  z-index: 1;
  border-radius: .5rem;
  box-shadow: 0px .3rem 1.2rem #0000000F;
  background: #F7F5F4;
  background-image: url(../img/campaigns/CHARIS_CrossElement.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 15% auto;
  padding: 3rem 4rem;
  display: flex;
  align-items: center;
  flex-flow: wrap; }
  .activities-indiv .top-content .box-left {
    width: calc( 50% + 5rem); }
  .activities-indiv .top-content .box-right {
    width: calc( 50% - 5rem); }
  @media (max-width: 767px) {
    .activities-indiv .top-content {
      padding: 3rem 20px;
      margin-top: -5rem; }
      .activities-indiv .top-content .box-left, .activities-indiv .top-content .box-right {
        width: 100%; }
      .activities-indiv .top-content .box-right {
        padding-top: 3rem; } }
  .activities-indiv .top-content .main-title {
    margin-top: 1.4rem;
    margin-bottom: 0;
    max-width: 54.3rem; }
  .activities-indiv .top-content .desc {
    margin-top: 2rem; }
  .activities-indiv .top-content .tag-red-brounded {
    font-weight: bold; }
  .activities-indiv .top-content .slots-wrapper {
    max-width: 40.2rem; }
    .activities-indiv .top-content .slots-wrapper .slots-txt {
      text-align: left;
      color: #BD55A0;
      margin-bottom: 1rem;
      text-transform: uppercase;
      font-weight: bold; }
    .activities-indiv .top-content .slots-wrapper .done {
      margin-top: 1rem;
      color: #707070;
      font-weight: bold; }
    .activities-indiv .top-content .slots-wrapper .slots {
      background-color: #E1E1E1;
      border-radius: 20px;
      position: relative;
      height: .5rem;
      width: 100%; }
      .activities-indiv .top-content .slots-wrapper .slots-done {
        background: #BD55A0;
        border-radius: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 0;
        opacity: 0;
        transition: 1s ease 0.3s; }
  .activities-indiv .top-content .list {
    padding-top: 3.8rem;
    list-style: none;
    display: flex;
    flex-flow: wrap;
    margin: -.5rem -1rem; }
    .activities-indiv .top-content .list li {
      width: 50%;
      padding: .5rem 1rem;
      display: flex;
      align-items: center; }
      .activities-indiv .top-content .list li .content {
        margin-left: .5rem; }
      .activities-indiv .top-content .list li .title {
        color: #9F9F9F;
        text-transform: capitalize; }
      .activities-indiv .top-content .list li .imgs {
        display: flex; }
        .activities-indiv .top-content .list li .imgs .img {
          flex-shrink: 0;
          height: 2rem; }
          .activities-indiv .top-content .list li .imgs .img img {
            height: 100%;
            width: auto; }
          .activities-indiv .top-content .list li .imgs .img:not(:last-child) {
            margin-right: 1.5rem; }
      @media (max-width: 575px) {
        .activities-indiv .top-content .list li {
          width: 100%; } }
  .activities-indiv .top-content .social-share {
    margin-top: 3rem;
    display: flex; }
    .activities-indiv .top-content .social-share .txt {
      margin-right: 1.5rem; }

.social-list {
  display: flex;
  list-style: none; }
  .social-list li {
    flex-shrink: 0; }
    .social-list li a {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem; }
      .social-list li a img {
        width: 100%;
        object-fit: contain;
        height: 100%; }
    .social-list li:not(:last-child) {
      margin-right: 1.5rem; }

.border-none {
  border: none !important; }

.activities-indiv-2 {
  padding: 10rem 0 7.5rem; }
  .activities-indiv-2 .inner {
    display: flex;
    align-items: center;
    flex-flow: wrap; }
    .activities-indiv-2 .inner .box-left {
      width: calc( 50% + 5rem); }
    .activities-indiv-2 .inner .box-right {
      width: calc( 50% - 5rem); }
    @media (max-width: 767px) {
      .activities-indiv-2 .inner .box-left, .activities-indiv-2 .inner .box-right {
        width: 100%; }
      .activities-indiv-2 .inner .box-right {
        padding-top: 3rem; } }
    .activities-indiv-2 .inner .slider {
      position: relative; }
      .activities-indiv-2 .inner .slider .swiper-pagination {
        bottom: -2.5rem;
        left: 0 !important;
        transform: none; }
    .activities-indiv-2 .inner .box-left .video-box {
      display: block;
      position: relative; }
      .activities-indiv-2 .inner .box-left .video-box .image {
        padding-top: 55.81395%;
        display: block;
        position: relative; }
        .activities-indiv-2 .inner .box-left .video-box .image img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover; }
        .activities-indiv-2 .inner .box-left .video-box .image img {
          border-radius: .5rem; }
      .activities-indiv-2 .inner .box-left .video-box:after {
        position: absolute;
        content: '';
        background: rgba(0, 0, 0, 0.2);
        inset: 0;
        z-index: 1; }
      .activities-indiv-2 .inner .box-left .video-box .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6rem;
        height: 6rem;
        z-index: 9;
        transform: translate(-50%, -50%); }
        .activities-indiv-2 .inner .box-left .video-box .play img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
  @media (min-width: 768px) {
    .activities-indiv-2 .box-right .box-content {
      padding-left: 5rem; } }
  .activities-indiv-2 .box-right .list {
    list-style: none;
    margin-top: 2.5rem;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-end;
    margin-bottom: -2.5rem; }
    .activities-indiv-2 .box-right .list li {
      display: flex;
      width: 50%;
      margin-bottom: 2.5rem; }
      .activities-indiv-2 .box-right .list li .icon {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        flex-shrink: 0; }
        .activities-indiv-2 .box-right .list li .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .activities-indiv-2 .box-right .list li .text {
        flex: 1;
        width: 100%;
        word-break: break-word;
        padding-left: 1.5rem; }
  .activities-indiv-2 .box-right .registration-ends {
    margin-top: 2.5rem;
    color: #707070;
    font-style: italic; }
  .activities-indiv-2 .box-right .bottom-wrap {
    padding-top: 2.5rem;
    border-top: 1px dashed #E1E1E1;
    margin-top: 1rem; }
    .activities-indiv-2 .box-right .bottom-wrap .heading {
      color: #707070;
      margin-bottom: 1.5rem;
      font-weight: bold; }
    .activities-indiv-2 .box-right .bottom-wrap ul {
      list-style: none; }
      .activities-indiv-2 .box-right .bottom-wrap ul li {
        color: #707070; }
        .activities-indiv-2 .box-right .bottom-wrap ul li:not(:last-child) {
          margin-bottom: 1.5rem; }
    .activities-indiv-2 .box-right .bottom-wrap .btn {
      width: 100%; }

.tags {
  display: flex;
  align-items: center; }
  .tags .tag:not(:last-child) {
    margin-right: 2rem; }

.tag {
  font-size: 1.3rem;
  text-transform: capitalize;
  letter-spacing: .026rem;
  color: #fff;
  line-height: 2.5rem;
  padding: 0 1rem;
  border-radius: .3rem;
  display: inline-block; }
  .tag-blue {
    background: #0078BF; }
  .tag-green {
    background: #33CC85; }
  .tag-red-brounded {
    background: #fff;
    border: 1px solid #CC3333;
    color: #CC3333 !important; }
  .tag-red {
    background: #CC3333; }

.login-popup {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .login-popup .popup-inner {
    position: absolute;
    inset: 0;
    overflow: auto;
    text-align: center;
    padding: 20px; }
    .login-popup .popup-inner:before {
      vertical-align: middle;
      display: inline-block;
      height: 100%;
      content: '';
      margin-right: -5px; }
  .login-popup .popup-content {
    position: relative;
    background: #fff;
    max-width: 49rem;
    width: 100%;
    text-align: left;
    display: inline-block;
    vertical-align: middle; }
  .login-popup .popup-overlay {
    content: '';
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(40, 40, 40, 0.66); }
  .login-popup .account-box {
    border: none;
    max-width: 49rem;
    width: 100%;
    position: relative;
    z-index: 9999; }
    .login-popup .account-box .head {
      position: relative; }
      .login-popup .account-box .head .popup-close {
        position: absolute;
        cursor: pointer;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        width: 1.5rem; }
        .login-popup .account-box .head .popup-close img {
          width: 100%;
          height: auto;
          filter: brightness(0) invert(1); }
  .login-popup .buttons {
    justify-content: center;
    display: flex; }
  .login-popup .btn {
    min-width: 13.6rem; }
  .login-popup .fancybox-close-small {
    top: 3.5rem !important; }
  .login-popup .custom-checkbox label:before {
    background: #F5F5F5; }

.footer {
  padding: 4rem 3rem;
  position: relative;
  background: #F7F5F4; }
  .footer ul {
    list-style: none; }
  .footer .footer-wrapper {
    display: flex; }
  .footer .logo-footer {
    display: block;
    width: 16rem; }
  .footer .box-left {
    flex-shrink: 0;
    width: 16rem; }
  .footer .box-right {
    width: 100%;
    flex: 1;
    padding-left: 5.3rem; }
  .footer .text {
    margin-bottom: 2rem; }
  .footer .social-list {
    display: flex;
    margin-bottom: 2rem;
    align-items: center; }
    .footer .social-list li:not(:last-child) {
      margin-right: 2rem; }
    .footer .social-list li a {
      display: block;
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
      overflow: hidden;
      background: #FFFFFF; }
      .footer .social-list li a img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
    .footer .social-list li:hover a {
      transform: scale(1.1);
      transition: .35s ease; }
  .footer .bottom {
    display: flex;
    justify-content: center;
    margin: -1rem -2rem;
    flex-flow: wrap;
    padding-top: 4rem; }
    .footer .bottom .copyright {
      padding: 1rem 2rem; }
    .footer .bottom ul {
      padding: 1rem 2rem;
      display: flex;
      align-items: center; }
      .footer .bottom ul li:not(:last-child) {
        margin-right: 4rem; }
      .footer .bottom ul li a {
        text-decoration: underline;
        color: #282828; }
  @media (max-width: 575px) {
    .footer .footer-wrapper {
      flex-flow: wrap; }
    .footer .box-left {
      margin-bottom: 4rem;
      width: 100%; }
      .footer .box-left .logo-footer {
        margin: 0 auto; }
    .footer .social-list {
      justify-content: center; }
    .footer .box-right {
      padding-left: 0;
      text-align: center; } }

.header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  transition: all .3s ease-in-out;
  background: #fff;
  box-shadow: 0px 8px 24px #0000000D; }
  .header .logo {
    width: 9.8rem;
    flex-shrink: 0; }
    .header .logo img {
      width: 100%;
      height: auto; }
  .header .right-box {
    width: 100%;
    flex: 1;
    padding-top: 1.5rem; }
  .header .account {
    margin-left: auto;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
    color: #BD55A0; }
    .header .account-name {
      display: none; }
  .header .cart {
    margin-left: 4rem; }
    .header .cart-toggle {
      height: 3.2rem;
      cursor: pointer;
      position: relative; }
      .header .cart-toggle img {
        height: 100%;
        width: auto; }
      .header .cart-toggle .count {
        position: absolute;
        top: -1rem;
        right: -1rem;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background: #BD55A0;
        color: #fff;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center; }
  .header .top-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 1.2rem;
    letter-spacing: .024rem;
    text-transform: capitalize;
    color: #707070;
    margin: 0 -1.2rem; }
    .header .top-wrap > div {
      padding: 0 1.2rem; }
    .header .top-wrap .main-site-link {
      color: #BD55A0;
      border-left: 1px solid #707070; }
    @media (max-width: 1199.98px) {
      .header .top-wrap {
        justify-content: flex-start; } }
  .header .header-wrapper {
    display: flex;
    align-items: center; }
    .header .header-wrapper .right-box {
      padding-left: 3.8rem; }
    .header .header-wrapper .bottom-wrap {
      display: flex;
      align-items: center;
      height: 6.8rem; }
    .header .header-wrapper .menu__list {
      list-style: none;
      display: flex;
      align-items: center;
      margin-bottom: 0; }
    .header .header-wrapper .menu__link {
      text-transform: uppercase;
      color: #282828;
      transition: all .2s;
      display: block;
      line-height: 1.4;
      position: relative; }
      .header .header-wrapper .menu__link:after {
        content: "";
        position: absolute;
        bottom: -.5rem;
        left: 0;
        height: 2px;
        opacity: 0;
        transition: all .2s;
        width: 100%;
        background: #007885; }
    .header .header-wrapper .menu .icon-dropdown {
      color: #282828;
      margin-left: 1rem;
      width: 1.5rem;
      flex-shrink: 0; }
      .header .header-wrapper .menu .icon-dropdown img {
        width: 100%;
        height: auto;
        transition: all .2s; }
    .header .header-wrapper .menu__item {
      line-height: 6.8rem;
      height: 6.8rem;
      padding: 0 1.5rem;
      display: inline-flex;
      align-items: center; }
      .header .header-wrapper .menu__item:hover .menu__link {
        color: #007885;
        text-stroke: 1px currentColor;
        -webkit-text-stroke: 1px currentColor; }
        .header .header-wrapper .menu__item:hover .menu__link:after {
          opacity: 1;
          transition: all .2s; }
      @media (min-width: 1200px) {
        .header .header-wrapper .menu__item.has-dropdown {
          position: relative;
          display: flex;
          align-items: center; }
          .header .header-wrapper .menu__item.has-dropdown .nav-sub {
            position: absolute;
            list-style: none;
            top: calc( 100% + 2rem);
            transition: all .3s ease-in-out;
            left: 0;
            opacity: 0;
            visibility: hidden;
            background: #fff;
            width: 20.3rem;
            display: block;
            z-index: 9;
            box-shadow: 0px 8px 24px #0000000D; }
            .header .header-wrapper .menu__item.has-dropdown .nav-sub li {
              line-height: 1.4;
              padding: 1rem 1.5rem;
              text-align: center; }
              .header .header-wrapper .menu__item.has-dropdown .nav-sub li a {
                text-transform: capitalize;
                color: #282828;
                font-size: 1.5rem;
                white-space: nowrap; }
              .header .header-wrapper .menu__item.has-dropdown .nav-sub li:hover, .header .header-wrapper .menu__item.has-dropdown .nav-sub li.active {
                background: #F7F5F4; }
                .header .header-wrapper .menu__item.has-dropdown .nav-sub li:hover a, .header .header-wrapper .menu__item.has-dropdown .nav-sub li.active a {
                  color: #282828; }
        .header .header-wrapper .menu__item:hover .icon-dropdown img {
          transform: scaleY(-1);
          transition: all .2s; }
        .header .header-wrapper .menu__item:hover .nav-sub {
          opacity: 1;
          visibility: visible;
          top: 100%; } }
  .header .toggle-btn {
    display: none; }
  .header .mobile-nav-inner {
    width: 100%;
    left: 0;
    position: absolute;
    top: 100%;
    background: #fff;
    height: calc(100vh - 9rem);
    overflow: hidden;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease-in-out;
    overflow-y: auto; }
    .header .mobile-nav-inner .menu ul {
      list-style: none; }
      .header .mobile-nav-inner .menu ul li {
        transition: transform 0.5s ease-in-out; }
        .header .mobile-nav-inner .menu ul li.has-dropdown {
          position: relative; }
          .header .mobile-nav-inner .menu ul li.has-dropdown > a {
            display: flex;
            align-items: center;
            justify-content: space-between; }
          .header .mobile-nav-inner .menu ul li.has-dropdown .icon-dropdown {
            display: block;
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 9;
            width: 30px;
            height: 30px;
            color: #020C22;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: .2rem; }
            .header .mobile-nav-inner .menu ul li.has-dropdown .icon-dropdown img {
              width: 15px;
              height: auto; }
            .header .mobile-nav-inner .menu ul li.has-dropdown .icon-dropdown.active img {
              transform: scaleY(-1); }
          .header .mobile-nav-inner .menu ul li.has-dropdown .nav-sub {
            width: 100%;
            display: none; }
            .header .mobile-nav-inner .menu ul li.has-dropdown .nav-sub li {
              line-height: 1.4;
              margin-bottom: 2rem; }
              .header .mobile-nav-inner .menu ul li.has-dropdown .nav-sub li a {
                text-transform: capitalize; }
              .header .mobile-nav-inner .menu ul li.has-dropdown .nav-sub li:hover a, .header .mobile-nav-inner .menu ul li.has-dropdown .nav-sub li.active a {
                color: #007885; }

@media (max-width: 1199.98px) {
  .header .right-box {
    padding: 1.5rem 0; }
  .header .top-wrap {
    padding: 1.5rem 20px; }
  .header .header-wrapper .account {
    margin-left: auto; }
  .header .header-wrapper .toggle-btn {
    display: block; }
  .header .header-wrapper .menu {
    display: none; }
  .mobile-nav-inner .search .search-toggle {
    display: none; }
  .mobile-nav-inner .search .searchbox {
    display: block !important;
    padding: 20px;
    border-bottom: 1px solid rgba(40, 40, 40, 0.1); }
    .mobile-nav-inner .search .searchbox input {
      width: 100%;
      padding: 0 20px; }
    .mobile-nav-inner .search .searchbox ::-webkit-input-placeholder {
      color: #999;
      text-transform: capitalize; }
  .mobile-nav-inner .menu {
    display: block;
    margin-right: 0; }
    .mobile-nav-inner .menu__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start; }
    .mobile-nav-inner .menu__item {
      margin-right: 0;
      padding: 0 20px;
      line-height: 50px;
      border-top: 1px solid rgba(40, 40, 40, 0.1);
      width: 100%;
      padding-right: 50px; }
      .mobile-nav-inner .menu__item a {
        text-transform: uppercase;
        color: #282828;
        font-size: 1.5rem; }
      .mobile-nav-inner .menu__item.active .menu__link {
        color: #007885;
        font-weight: bold; }
  .mobile-nav-inner.active {
    opacity: 1;
    visibility: visible;
    transition: all .4s ease-in-out; } }

.toggle-btn {
  margin-left: 3.5rem; }
  .toggle-btn span {
    width: 22px;
    height: 2px;
    background-color: #007885;
    border-radius: 2px;
    display: block;
    margin: 4px auto;
    transition: all 0.3s ease-in-out;
    text-align: right; }
    .toggle-btn span:nth-child(3) {
      width: 22px;
      margin-right: 0; }
  .toggle-btn:hover {
    cursor: pointer; }
  .toggle-btn.active span:nth-child(2) {
    opacity: 0; }
  .toggle-btn.active span:nth-child(1) {
    width: 22px;
    transform: translateY(6px) rotate(45deg); }
  .toggle-btn.active span:nth-child(3) {
    width: 22px;
    transform: translateY(-6px) rotate(-45deg); }

.back-drop {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: 9;
  visibility: hidden; }
  .back-drop.active {
    visibility: visible;
    opacity: 1;
    transition: all .3s; }

@media (min-width: 1200px) {
  .search {
    position: relative;
    margin-left: 3.5rem; }
    .search .search-toggle {
      width: 3.2rem;
      height: 3.2rem;
      cursor: pointer;
      position: relative;
      z-index: 2; }
      .search .search-toggle:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../img/icons/Nav_Search.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
      .search .search-toggle.active:after {
        background-image: url(../img/icons/Cart_Delete.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }
    .search .searchbox {
      position: absolute;
      right: 0;
      z-index: 1;
      top: calc( 100% + 1.8rem);
      display: flex;
      align-items: center;
      width: 30rem;
      display: none; }
      .search .searchbox input {
        width: 100%;
        flex: 1;
        background: #ebebeb;
        line-height: 4.5rem;
        height: 4.5rem;
        padding: 0 2rem;
        border-radius: none;
        font-size: 1.6rem;
        border: none; }
      .search .searchbox ::-webkit-input-placeholder {
        color: #282828;
        text-transform: capitalize; } }

.page-notice {
  background: #007885;
  text-align: center;
  padding: 1rem 0;
  color: #fff; }

#loader-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  transition: all 0.3s ease-in-out;
  background-color: #fff; }
  #loader-container .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 25rem; }
    #loader-container .loader__logo {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      position: relative;
      margin: 0 auto; }
      #loader-container .loader__logo:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        border: .3rem solid #ebebeb;
        border-right-color: #BD55A0;
        animation: circleLoading 1s forwards infinite linear; }
    #loader-container .loader__progress {
      display: none;
      position: relative;
      width: 100%;
      height: .2rem;
      margin-top: 1rem;
      border-radius: 1rem; }
      #loader-container .loader__progress #progress-percent {
        position: absolute;
        right: 0;
        top: calc( 100% + .5rem);
        font-size: 1rem;
        color: #622644; }
      #loader-container .loader__progress #progress-bar {
        height: 100%;
        max-width: 100%;
        background: #E0BCCE;
        width: 0%;
        height: 100%;
        position: relative; }

@keyframes circleLoading {
  0% { }
  100% {
    transform: rotate(360deg); } }

.banner-subpage {
  padding: 15rem 0; }
  .banner-subpage .heading {
    text-align: center;
    font-size: 4rem;
    letter-spacing: .4rem;
    line-height: calc(50/40);
    font-weight: bold;
    color: #fff; }

.haf-banner {
  border-radius: .5rem;
  overflow: hidden;
  position: relative;
  padding: 5rem; }
  @media (max-width: 767px) {
    .haf-banner {
      padding: 5rem 20px; } }
  .haf-banner .title {
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    line-height: 1.2; }
  .haf-banner .desc {
    color: #fff;
    max-width: 70rem;
    margin-top: 2rem; }

.btn-wrap .btn:not(:last-child) {
  margin-right: 2rem; }

.campaigns {
  padding: 4rem 0 10rem; }
  .campaigns .row-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .campaigns .row-wrapper .left-wrap {
      width: calc(20% -2rem); }
    .campaigns .row-wrapper .right-wrap {
      width: calc(80% -2rem); }
    @media (max-width: 767px) {
      .campaigns .row-wrapper .left-wrap, .campaigns .row-wrapper .right-wrap {
        width: 100%; } }
  .campaigns .left-wrap {
    margin-bottom: 4rem;
    position: relative; }
  .campaigns .mb-filter {
    margin-top: 0; }
    @media (min-width: 768px) {
      .campaigns .mb-filter {
        display: none; } }
  @media (max-width: 767px) {
    .campaigns .sidebar {
      display: none;
      position: absolute;
      left: -20px;
      width: 100vw;
      top: calc(100% + 2rem);
      background: #fff;
      padding: 0 20px;
      z-index: 50;
      padding-bottom: 3rem; } }
  .campaigns .sidebar .cate-txt {
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #0F5373;
    margin-bottom: 1rem;
    padding: 0 2rem;
    font-weight: 500; }
  .campaigns .sidebar .sidebar-item:not(:last-child) {
    margin-bottom: 3rem; }
  .campaigns .sidebar .sidebar-item .sidebar-text {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #452A50;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E1E1E1;
    justify-content: space-between; }
    .campaigns .sidebar .sidebar-item .sidebar-text .arrow {
      width: 1.3rem;
      height: 1rem;
      background-image: url(../img/icons/Arrow3_Purple.png);
      transform: scaleY(-1);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: all .2s; }
    .campaigns .sidebar .sidebar-item .sidebar-text.active .arrow {
      transform: scale(1);
      transition: all .2s; }
  .campaigns .sidebar .sidebar-item .menu {
    list-style: none;
    background: #F4F4F4;
    display: none;
    padding: .5rem 0;
    padding-top: 1.7rem; }
    .campaigns .sidebar .sidebar-item .menu .menu-item:not(:last-child) {
      margin-bottom: 1rem; }
    .campaigns .sidebar .sidebar-item .menu .menu-item .menu-link {
      font-size: 1.5rem;
      color: #282828;
      text-transform: capitalize;
      display: inline-block;
      transition: all .3s ease; }
    .campaigns .sidebar .sidebar-item .menu .menu-item:hover .menu-link, .campaigns .sidebar .sidebar-item .menu .menu-item.active .menu-link {
      color: #BD55A0;
      text-stroke: .1rem currentColor;
      -webkit-text-stroke: .1rem currentColor;
      transition: all .3s ease; }
  .campaigns .sidebar .sidebar-item .filter-list {
    list-style: none;
    margin-top: 1.5rem;
    display: none;
    overflow: hidden;
    overflow-y: auto;
    max-height: 25rem; }
    .campaigns .sidebar .sidebar-item .filter-list::-webkit-scrollbar {
      width: .3rem; }
    .campaigns .sidebar .sidebar-item .filter-list::-webkit-scrollbar-track {
      border-radius: 20px;
      background: transparent; }
    .campaigns .sidebar .sidebar-item .filter-list::-webkit-scrollbar-thumb {
      background: #007885;
      border-radius: 20px; }
    .campaigns .sidebar .sidebar-item .filter-list .filter-item label {
      font-size: 1.5rem; }
      .campaigns .sidebar .sidebar-item .filter-list .filter-item label:before {
        top: .2rem; }
    .campaigns .sidebar .sidebar-item .filter-list .filter-item:not(:last-child) {
      margin-bottom: 1rem; }
  .campaigns .sidebar .apply-filter {
    margin-top: 0;
    width: 100%; }
  .campaigns .list {
    margin: 0 -15px; }
    .campaigns .list .col, .campaigns .list [class*=col-] {
      padding-right: 15px;
      padding-left: 15px; }
    .campaigns .list .item {
      margin-bottom: 30px; }
  .campaigns .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem; }
    .campaigns .head .clear-all {
      color: #007885;
      text-transform: capitalize;
      text-decoration: underline; }
  .campaigns .showing {
    color: #707070;
    margin-bottom: 3rem; }

.fancybox-slide--html {
  padding: 20px !important; }

.fancybox-bg {
  background: rgba(40, 40, 40, 0.66); }

.popup-default {
  padding: 0 !important; }
  @media (min-width: 768px) {
    .popup-default {
      width: 35%; } }
  .popup-default .popup-head {
    font-weight: bold;
    color: #fff;
    text-align: center;
    padding: 2rem;
    background: #452A50; }
  .popup-default .popup-body {
    padding: 2.5rem; }
  .popup-default .donate-amount {
    list-style: none;
    display: flex;
    flex-flow: wrap;
    margin: -1rem; }
    .popup-default .donate-amount li {
      padding: 1rem;
      width: 33.33%; }
      .popup-default .donate-amount li a {
        display: block;
        text-align: center;
        font-size: 1.5rem;
        letter-spacing: .03rem;
        height: 3.5rem;
        line-height: 3.5rem;
        box-sizing: border-box;
        padding: 0 2.5rem;
        transition: all .2s;
        border-radius: .3rem;
        border: 1px solid #E1E1E1;
        color: #9F9F9F;
        background: #F5F5F5; }
      .popup-default .donate-amount li:hover a, .popup-default .donate-amount li.active a {
        color: #fff;
        transition: all .2s;
        border: 1px solid #007885;
        background: #007885; }
    .popup-default .donate-amount .other-amount {
      width: 66.66%; }
    @media (max-width: 575px) {
      .popup-default .donate-amount li {
        width: 50%; }
      .popup-default .donate-amount .other-amount {
        width: 100%; } }
  .popup-default .learn-more {
    display: block;
    width: fit-content;
    text-decoration: underline;
    margin: 0 auto;
    margin-top: 2rem;
    transition: all .2s;
    color: #282828;
    font-weight: bold;
    text-transform: uppercase; }
    .popup-default .learn-more:hover {
      letter-spacing: .1rem;
      transition: all .2s; }
  .popup-default .bottom-wrap {
    margin-top: 3rem;
    border-top: 1px dashed #0000001B;
    padding-top: 3rem; }
  .popup-default .text {
    line-height: 1.6; }
  .popup-default .btn-wrap {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .popup-default .btn-wrap .btn {
      margin-right: 0 !important;
      width: calc(50% -1rem); }
    @media (max-width: 575px) {
      .popup-default .btn-wrap {
        flex-direction: column; }
        .popup-default .btn-wrap .btn {
          width: 100%; } }
  .popup-default .options {
    display: flex;
    border-bottom: 1px solid #0000001B; }
  .popup-default .option {
    width: 50%;
    cursor: pointer; }
    .popup-default .option label {
      font-size: 1.5rem;
      color: #9F9F9F;
      background: #fff;
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      cursor: pointer;
      padding: 2rem;
      font-weight: 600; }
    .popup-default .option input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer; }
    .popup-default .option input:checked + label {
      color: #BD55A0;
      background: #F5E5F0; }

.donate-haf-popup .btn {
  width: 100% !important; }

.fancybox-close-small {
  display: block !important;
  padding: 0px !important;
  width: 1.5rem !important;
  top: 2.5rem !important;
  right: 2rem !important;
  height: 1.5rem !important; }
  .fancybox-close-small svg {
    display: none !important; }
  .fancybox-close-small:before {
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../img/icons/Cart_Delete.png);
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(0) invert(1);
    background-size: contain; }

.add-to-basket-popup {
  text-align: center; }
  .add-to-basket-popup .icon {
    width: 12rem;
    margin: 0 auto; }
    .add-to-basket-popup .icon img {
      width: 100%;
      height: auto; }
  .add-to-basket-popup .text {
    margin-top: 3rem; }
  .add-to-basket-popup .btn-wrap {
    justify-content: center; }

.campaigns-indiv .top-content {
  margin-top: -15rem;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: .5rem;
  box-shadow: 0px .3rem 1.2rem #0000000F;
  background: #F7F5F4;
  background-image: url(../img/campaigns/CHARIS_CrossElement.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 15% auto;
  padding: 3rem; }
  @media (max-width: 767px) {
    .campaigns-indiv .top-content {
      padding: 3rem 20px;
      margin-top: -5rem; } }
  .campaigns-indiv .top-content .inner {
    max-width: 66.2rem;
    margin: 0 auto; }
  .campaigns-indiv .top-content .main-title {
    margin-top: 1.4rem;
    margin-bottom: 0; }
  .campaigns-indiv .top-content .desc {
    margin-top: 2rem; }
  .campaigns-indiv .top-content .progress-wrapper {
    padding-top: 2.8rem;
    margin-top: auto; }
    .campaigns-indiv .top-content .progress-wrapper .progress-txt {
      text-align: left;
      color: #BD55A0;
      font-weight: bold;
      margin-bottom: 1rem; }
    .campaigns-indiv .top-content .progress-wrapper .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem;
      font-weight: bold; }
    .campaigns-indiv .top-content .progress-wrapper .progress {
      background-color: #E1E1E1;
      border-radius: 20px;
      position: relative;
      height: .5rem;
      width: 100%; }
      .campaigns-indiv .top-content .progress-wrapper .progress-done {
        background: #BD55A0;
        border-radius: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 0;
        opacity: 0;
        transition: 1s ease 0.3s; }
  .campaigns-indiv .top-content .list {
    padding-top: 1.5rem;
    list-style: none;
    display: flex;
    justify-content: space-around;
    flex-flow: wrap;
    margin: -1rem; }
    .campaigns-indiv .top-content .list li {
      padding: 1rem;
      display: flex;
      align-items: center; }
      .campaigns-indiv .top-content .list li .content {
        margin-left: .5rem; }
      .campaigns-indiv .top-content .list li .title {
        color: #9F9F9F;
        text-transform: capitalize; }
      .campaigns-indiv .top-content .list li .imgs {
        display: flex; }
        .campaigns-indiv .top-content .list li .imgs .img {
          flex-shrink: 0;
          height: 2rem; }
          .campaigns-indiv .top-content .list li .imgs .img img {
            height: 100%;
            width: auto; }
          .campaigns-indiv .top-content .list li .imgs .img:not(:last-child) {
            margin-right: 1.5rem; }
  .campaigns-indiv .top-content .social-share {
    margin-top: 3rem;
    display: flex;
    justify-content: center; }
    .campaigns-indiv .top-content .social-share .txt {
      margin-right: 1.5rem; }

.social-list {
  display: flex;
  list-style: none; }
  .social-list li {
    flex-shrink: 0; }
    .social-list li a {
      display: inline-block;
      width: 2.5rem;
      height: 2.5rem; }
      .social-list li a img {
        width: 100%;
        object-fit: contain;
        height: 100%; }
    .social-list li:not(:last-child) {
      margin-right: 1.5rem; }

.campaigns-indiv-2 {
  padding: 10rem 0 7.5rem; }
  .campaigns-indiv-2 .slider {
    position: relative; }
  .campaigns-indiv-2 .swiper-pagination {
    left: 0 !important;
    transform: none;
    bottom: -2.5rem; }
  .campaigns-indiv-2 .swiper-slide {
    box-sizing: border-box; }
  .campaigns-indiv-2 .inner {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: space-between; }
    .campaigns-indiv-2 .inner .box-left {
      width: calc(calc( 655/1166 *100%) -1rem);
      border-radius: .5rem; }
    .campaigns-indiv-2 .inner .box-right {
      width: calc(calc( 100% - 655/1166 *100%) -1rem);
      border-radius: .5rem; }
    @media (max-width: 767px) {
      .campaigns-indiv-2 .inner .box-left, .campaigns-indiv-2 .inner .box-right {
        width: 100%; }
      .campaigns-indiv-2 .inner .box-right {
        margin-top: 3rem; } }
    .campaigns-indiv-2 .inner .box-right {
      box-shadow: inset 0 0 0 1px #E1E1E1; }
      .campaigns-indiv-2 .inner .box-right .top-wrap {
        padding: 3rem; }
    .campaigns-indiv-2 .inner .box-left .video-box {
      display: block;
      position: relative; }
      .campaigns-indiv-2 .inner .box-left .video-box .image {
        padding-top: 55.81395%;
        display: block;
        position: relative; }
        .campaigns-indiv-2 .inner .box-left .video-box .image img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover; }
        .campaigns-indiv-2 .inner .box-left .video-box .image img {
          border-radius: .5rem; }
      .campaigns-indiv-2 .inner .box-left .video-box:after {
        position: absolute;
        content: '';
        background: rgba(0, 0, 0, 0.2);
        inset: 0;
        z-index: 1; }
      .campaigns-indiv-2 .inner .box-left .video-box .play {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6rem;
        height: 6rem;
        z-index: 9;
        transform: translate(-50%, -50%); }
        .campaigns-indiv-2 .inner .box-left .video-box .play img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
  .campaigns-indiv-2 .box-right .donate-amount {
    list-style: none;
    display: flex;
    flex-flow: wrap;
    margin: -1rem; }
    .campaigns-indiv-2 .box-right .donate-amount li {
      padding: 1rem;
      width: 33.33%; }
      .campaigns-indiv-2 .box-right .donate-amount li a {
        display: block;
        text-align: center;
        font-size: 1.5rem;
        letter-spacing: .03rem;
        height: 3.5rem;
        line-height: 3.5rem;
        box-sizing: border-box;
        padding: 0 2.5rem;
        transition: all .2s;
        border-radius: .3rem;
        border: 1px solid #E1E1E1;
        color: #9F9F9F;
        background: #F5F5F5; }
      .campaigns-indiv-2 .box-right .donate-amount li:hover a, .campaigns-indiv-2 .box-right .donate-amount li.active a {
        color: #fff;
        transition: all .2s;
        border: 1px solid #007885;
        background: #007885; }
    .campaigns-indiv-2 .box-right .donate-amount .other-amount {
      width: 66.66%; }
    @media (max-width: 575px) {
      .campaigns-indiv-2 .box-right .donate-amount li {
        width: 50%; }
      .campaigns-indiv-2 .box-right .donate-amount .other-amount {
        width: 100%; } }
  .campaigns-indiv-2 .box-right .learn-more {
    display: block;
    width: fit-content;
    text-decoration: underline;
    margin: 0 auto;
    margin-top: 2rem;
    transition: all .2s;
    color: #282828;
    font-weight: bold;
    text-transform: uppercase; }
    .campaigns-indiv-2 .box-right .learn-more:hover {
      letter-spacing: .1rem;
      transition: all .2s; }
  .campaigns-indiv-2 .box-right .bottom-wrap {
    padding: 3rem;
    border-top: 1px dashed #0000001B; }
  .campaigns-indiv-2 .box-right .text {
    line-height: 1.6; }
  .campaigns-indiv-2 .box-right .btn-wrap {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .campaigns-indiv-2 .box-right .btn-wrap .btn {
      white-space: nowrap;
      margin-right: 0 !important;
      width: calc(50% -1rem); }
    @media (max-width: 575px) {
      .campaigns-indiv-2 .box-right .btn-wrap {
        flex-direction: column; }
        .campaigns-indiv-2 .box-right .btn-wrap .btn {
          width: 100%; } }
  .campaigns-indiv-2 .box-right .options {
    display: flex;
    border-bottom: 1px solid #0000001B; }
  .campaigns-indiv-2 .box-right .option {
    width: 50%;
    cursor: pointer; }
    .campaigns-indiv-2 .box-right .option label {
      font-size: 1.5rem;
      color: #9F9F9F;
      background: #fff;
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      cursor: pointer;
      padding: 2rem;
      font-weight: 600; }
    .campaigns-indiv-2 .box-right .option input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer; }
    .campaigns-indiv-2 .box-right .option input:checked + label {
      color: #BD55A0;
      background: #F5E5F0; }

.indiv-articles {
  padding-bottom: 10rem; }
  .indiv-articles .article .image {
    position: relative; }
    .indiv-articles .article .image:after {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(247, 245, 244, 0.4);
      transition: all .3s ease-in-out; }
    .indiv-articles .article .image img {
      width: 100%;
      height: auto; }
  .indiv-articles .article.active .image:after {
    opacity: 0;
    transition: all .3s ease-in-out; }
  .indiv-articles .content-wrapper {
    position: relative;
    z-index: 2; }
    .indiv-articles .content-wrapper .bar {
      display: flex;
      align-items: stretch;
      color: #fff;
      margin-top: -4rem;
      max-width: 82rem; }
      .indiv-articles .content-wrapper .bar .left {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        width: 100%;
        height: 8rem;
        background: #452A50; }
      .indiv-articles .content-wrapper .bar .heading {
        font-size: 2.4rem;
        color: #fff;
        text-transform: uppercase;
        font-weight: 800;
        margin-right: 4rem; }
      .indiv-articles .content-wrapper .bar .location {
        font-weight: bold; }
      @media (max-width: 575px) {
        .indiv-articles .content-wrapper .bar {
          flex-direction: column; }
          .indiv-articles .content-wrapper .bar .left {
            width: 100%; } }
    .indiv-articles .content-wrapper .less-more-btn {
      background: #007885;
      cursor: pointer;
      width: fit-content;
      flex-shrink: 0;
      min-width: 21rem;
      text-transform: uppercase;
      display: inline-flex;
      color: #fff;
      height: 8rem;
      align-items: center;
      padding: 0 4rem 0 3rem; }
      .indiv-articles .content-wrapper .less-more-btn .icon {
        width: 2rem;
        margin-right: 3rem; }
        .indiv-articles .content-wrapper .less-more-btn .icon img {
          width: 100%;
          height: auto;
          transition: all .2s;
          filter: brightness(0) invert(1); }
        .indiv-articles .content-wrapper .less-more-btn .icon.up img {
          transform: scaleY(-1);
          transition: all .2s; }
    .indiv-articles .content-wrapper .more-content {
      padding-top: 4rem;
      display: none; }
      .indiv-articles .content-wrapper .more-content .top-content {
        padding: 0 4rem;
        padding-bottom: 4rem; }
        .indiv-articles .content-wrapper .more-content .top-content .inner {
          background: #F8EBF4;
          padding: 3rem 2rem;
          border-radius: .5rem;
          margin: 0 auto; }
        .indiv-articles .content-wrapper .more-content .top-content .wrap {
          display: flex;
          flex-flow: wrap;
          justify-content: space-between; }
          .indiv-articles .content-wrapper .more-content .top-content .wrap .box-img {
            width: calc( 30% - 1rem); }
          .indiv-articles .content-wrapper .more-content .top-content .wrap .box-content {
            width: calc( 70% - 1rem); }
            .indiv-articles .content-wrapper .more-content .top-content .wrap .box-content .title {
              color: #282828;
              text-decoration: underline;
              margin-bottom: 3rem;
              font-weight: bold; }
          @media (max-width: 575px) {
            .indiv-articles .content-wrapper .more-content .top-content .wrap .box-img, .indiv-articles .content-wrapper .more-content .top-content .wrap .box-content {
              width: 100%; }
            .indiv-articles .content-wrapper .more-content .top-content .wrap .box-content {
              padding-top: 3rem; } }
      .indiv-articles .content-wrapper .more-content .content p:not(:last-child) {
        margin-bottom: 2rem; }
  .indiv-articles .article:not(:last-child) {
    margin-bottom: 5rem; }

.people-also-viewed .slider {
  position: relative; }

.people-also-viewed .swiper-slide {
  box-sizing: border-box; }

.people-also-viewed .inner {
  border-top: 1px solid #E1E1E1;
  padding: 5rem 0 10rem; }

@media (max-width: 1199.98px) {
  .people-also-viewed .swiper-next, .people-also-viewed .swiper-prev {
    display: none; } }

.tag {
  font-size: 1.3rem;
  text-transform: capitalize;
  letter-spacing: .026rem;
  color: #fff;
  line-height: 2.5rem;
  padding: 0 1rem;
  border-radius: .3rem;
  display: inline-block; }
  .tag-blue {
    background: #0078BF; }
  .tag-green {
    background: #33CC85; }

.donation-closed .inner {
  align-items: stretch; }

.donation-closed .box-right {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.page-checkout {
  padding: 10rem 0; }
  .page-checkout .heading {
    font-size: 1.8rem;
    color: #A51890;
    text-transform: capitalize;
    margin-bottom: 2.5rem;
    letter-spacing: .054rem;
    font-weight: 600; }
  .page-checkout .cart-total {
    margin-top: 3.8rem;
    border-radius: .5rem;
    padding: 1.5rem 0;
    background: #F7F5F4; }
    .page-checkout .cart-total table {
      width: 100%; }
      .page-checkout .cart-total table td {
        font-weight: 600;
        padding: .5rem 2rem; }
        .page-checkout .cart-total table td:nth-child(2) {
          text-align: right; }
      .page-checkout .cart-total table tr:last-child td {
        padding: 2rem;
        border-top: 1px solid #E1E1E1;
        border-bottom: 1px solid #E1E1E1;
        color: #A51890;
        text-transform: uppercase; }
      .page-checkout .cart-total table tr:nth-last-child(2) td {
        padding-bottom: 2rem; }
  .page-checkout .bottom-wrap {
    padding: 2rem; }
    .page-checkout .bottom-wrap .terms {
      margin-top: 2.5rem; }
    .page-checkout .bottom-wrap label {
      font-size: 1.5rem; }
      .page-checkout .bottom-wrap label:before {
        border: 1px solid #282828; }
    .page-checkout .bottom-wrap .btn {
      min-width: 23.2rem;
      background: #A51890;
      margin-top: 3rem; }
  .page-checkout .payment .custom-checkbox:not(:last-child) {
    margin-bottom: 2rem; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .bottom-wrap {
  padding: 2rem 3rem;
  border-top: 1px dashed #0000001B; }
  .campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .bottom-wrap .text .price {
    border-bottom: 1px solid #007885;
    display: inline-block;
    margin-left: .5rem; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .top-wrap {
  padding: 2rem 3rem; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .text {
  line-height: 1.6; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .btn-wrap .btn {
  width: 100%; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .social-share {
  margin-top: 2rem;
  display: flex;
  justify-content: center; }
  .campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .social-share .txt {
    margin-right: 1.5rem; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .options {
  display: flex;
  border-bottom: 1px solid #0000001B; }

.campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .option {
  width: 50%;
  cursor: pointer; }
  .campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .option label {
    font-size: 1.8rem;
    color: #9F9F9F;
    background: #E1E1E1;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    cursor: pointer;
    letter-spacing: .054rem;
    padding: 2rem;
    font-weight: 600; }
  .campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .option input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer; }
  .campaigns-indiv-2.campaigns-indiv-haf-2 .box-right .option input:checked + label {
    color: #fff;
    background: #452A50; }

.higthlights {
  padding-bottom: 7rem; }
  @media (max-width: 767px) {
    .higthlights {
      background: transparent linear-gradient(67deg, #F5E4F0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      padding-bottom: 5rem;
      margin-top: 5rem; } }
  .higthlights .slider {
    position: relative; }
    .higthlights .slider:after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: calc(100% -5rem);
      width: calc( 100% + calc(100vw - (1206/1366*100vw))/2 +20px);
      background: transparent linear-gradient(67deg, #F5E4F0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box; }
      @media (max-width: 767px) {
        .higthlights .slider:after {
          display: none; } }
    .higthlights .slider .swiper-next, .higthlights .slider .swiper-prev {
      position: static;
      transform: none; }
      .higthlights .slider .swiper-next img, .higthlights .slider .swiper-prev img {
        width: 1.5rem; }
    .higthlights .slider .swiper-next {
      margin-left: 2rem; }
    .higthlights .slider .swiper-btn {
      position: absolute;
      bottom: 8rem;
      left: 0;
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        .higthlights .slider .swiper-btn {
          bottom: 0;
          left: 50%;
          transform: translateX(-50%); } }
    .higthlights .slider .swiper-slide {
      box-sizing: border-box;
      padding-top: 5rem; }
    .higthlights .slider .swiper-container {
      padding-bottom: 4.5rem; }
    .higthlights .slider .wrapper {
      display: flex;
      flex-flow: wrap;
      margin: -1.5rem; }
      .higthlights .slider .wrapper .box-left {
        padding: 1.5rem;
        width: 65%; }
        .higthlights .slider .wrapper .box-left .box-content {
          padding-bottom: 9rem; }
      .higthlights .slider .wrapper .box-right {
        padding: 1.5rem;
        width: 35%; }
        .higthlights .slider .wrapper .box-right .box-img img {
          width: 100%;
          height: auto; }
        @media (min-width: 768px) {
          .higthlights .slider .wrapper .box-right .box-img {
            transform: translateY(5rem); } }
      @media (max-width: 767px) {
        .higthlights .slider .wrapper .box-left, .higthlights .slider .wrapper .box-right {
          width: 100%; }
        .higthlights .slider .wrapper .box-left .box-content {
          padding-bottom: 0; } }
    .higthlights .slider .big-title {
      font-size: 2.4rem;
      letter-spacing: .24rem;
      color: #424242;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 3rem; }
    .higthlights .slider .title {
      font-size: 1.8rem;
      color: #452A50;
      text-decoration: underline;
      text-transform: capitalize;
      margin-bottom: 2rem;
      font-weight: bold; }
    .higthlights .slider p:not(:last-child) {
      margin-bottom: 1.5rem; }

.page-faq {
  margin-top: calc( -7.5rem /2);
  padding-bottom: 6.5rem; }
  .page-faq .my-tabs {
    box-shadow: 0 .3rem .6rem #00000029; }
    .page-faq .my-tabs .tabs-nav-wrap .tabs-nav {
      list-style: none;
      display: flex;
      align-items: center;
      flex-flow: wrap; }
      .page-faq .my-tabs .tabs-nav-wrap .tabs-nav li {
        width: 33.33%;
        font-size: 1.8rem;
        text-transform: capitalize;
        color: #9F9F9F;
        text-align: center;
        height: 7.5rem;
        display: inline-flex;
        background: #fff;
        justify-content: center;
        align-items: center;
        transition: all .2s;
        cursor: pointer;
        border-bottom: 1px solid #e1e1e1; }
        .page-faq .my-tabs .tabs-nav-wrap .tabs-nav li:not(:last-child) {
          border-right: 1px solid #E1E1E1; }
        .page-faq .my-tabs .tabs-nav-wrap .tabs-nav li:hover, .page-faq .my-tabs .tabs-nav-wrap .tabs-nav li.active {
          color: #fff;
          background: #452A50;
          border-color: #452A50;
          transition: all .2s; }
        @media (max-width: 767px) {
          .page-faq .my-tabs .tabs-nav-wrap .tabs-nav li {
            width: 50%;
            border-bottom: 1px solid #E1E1E1;
            border-right: 1px solid #E1E1E1; } }
    .page-faq .my-tabs .tab-content {
      display: none;
      padding: 4rem;
      background: #fff; }
      @media (max-width: 767px) {
        .page-faq .my-tabs .tab-content {
          padding: 20px; } }

.accordions .accordion-item {
  background: #fff;
  box-shadow: 0px .3rem 1.2rem #0000000F;
  border-radius: .5rem; }
  .accordions .accordion-item:not(:last-child) {
    margin-bottom: 2rem; }
  .accordions .accordion-item .accordion-heading {
    padding-right: 3rem;
    font-weight: 600;
    font-size: 1.8rem;
    color: #452A50;
    cursor: pointer;
    display: flex;
    align-items: center;
    box-shadow: 0px .3rem 1.2rem #0000000F;
    border-radius: .5rem; }
    @media (max-width: 767px) {
      .accordions .accordion-item .accordion-heading {
        padding-right: 2rem;
        font-size: 1.6rem; } }
    .accordions .accordion-item .accordion-heading .arrow {
      width: 1.4rem;
      height: .8rem;
      margin-left: auto;
      background-image: url(../img/icons/Arrow1_Grey.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      display: block;
      flex-shrink: 0; }
    .accordions .accordion-item .accordion-heading .txt {
      display: block;
      margin-right: 2rem; }
    .accordions .accordion-item .accordion-heading .number {
      margin-right: 2rem;
      width: 5rem;
      flex-shrink: 0;
      height: 5rem;
      border-radius: .5rem;
      background: #452A50;
      display: flex;
      box-shadow: 0px .3rem 1.2rem #0000000F;
      color: #fff;
      align-items: center;
      justify-content: center;
      font-weight: bold; }
  .accordions .accordion-item .accordion-content {
    display: none;
    padding: 4rem;
    background: #fff; }
    .accordions .accordion-item .accordion-content p:not(:last-child) {
      margin-bottom: 1.5rem; }
  @media (max-width: 767px) {
    .accordions .accordion-item .accordion-content {
      padding: 20px; } }
  .accordions .accordion-item.active .accordion-heading .arrow {
    background-image: url(../img/icons/Arrow1_Grey-2.png); }

.page-cart {
  padding: 10rem 0; }
  .page-cart .cart-table table {
    width: 100%;
    border-left: 1px solid #F7F5F5;
    border-right: 1px solid #F7F5F5; }
    .page-cart .cart-table table thead {
      background: #452A50;
      color: #fff;
      font-size: 1.8rem;
      font-weight: 600; }
      .page-cart .cart-table table thead th {
        padding: 2.8rem 3rem; }
        .page-cart .cart-table table thead th:nth-child(1) {
          width: 70%; }
    .page-cart .cart-table table tr:not(:last-child) {
      border-bottom: 1px solid #F7F5F5; }
    .page-cart .cart-table table tr td {
      padding: 2.8rem 3rem; }
      .page-cart .cart-table table tr td .wrap {
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .page-cart .cart-table table tr td .edit, .page-cart .cart-table table tr td .remove {
        height: 2.5rem; }
        .page-cart .cart-table table tr td .edit img, .page-cart .cart-table table tr td .remove img {
          height: 100%;
          width: auto; }
    .page-cart .cart-table table .total {
      background: #F5E4F0;
      padding: 2.8rem 3rem; }
      .page-cart .cart-table table .total td {
        font-weight: bold;
        text-transform: uppercase; }
        .page-cart .cart-table table .total td:first-child {
          text-align: right; }
    @media (max-width: 767px) {
      .page-cart .cart-table table th, .page-cart .cart-table table td {
        padding: 2rem !important; }
      .page-cart .cart-table table thead th:nth-child(1) {
        width: 50%; } }
  .page-cart .btn-wrap {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .page-cart .btn-wrap .btn {
      min-width: 37.4rem;
      margin-top: 3rem;
      margin-right: 0 !important; }
      @media (max-width: 767px) {
        .page-cart .btn-wrap .btn {
          width: 100%; } }

.donate-haf .img {
  width: 10.5rem;
  margin: 0 auto;
  margin-bottom: 2rem; }
  .donate-haf .img img {
    width: 100%;
    height: auto; }

.donate-haf .txt {
  margin-bottom: 2rem;
  text-align: center; }

.donate-haf .donate-amount {
  justify-content: center; }
  .donate-haf .donate-amount li:hover a, .donate-haf .donate-amount li.active a {
    background: #0078BF; }

.donate-haf .btn {
  margin-top: 3rem; }

.page-account {
  padding: 8rem 0 13.5rem; }

.account-box {
  max-width: 49rem;
  box-shadow: 0px 3px 12px #0000000A;
  width: 100%; }
  .account-box .head {
    padding: 2.8rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
    background: #452A50; }
  .account-box .body {
    padding: 2.5rem 2.5rem 4rem;
    background: #fff; }
    .account-box .body .list-login {
      list-style: none;
      padding-bottom: 2rem;
      border-bottom: 1px dashed #E1E1E1; }
      .account-box .body .list-login li {
        display: flex;
        align-items: center;
        border: 1px solid #E1E1E1;
        border-radius: .3rem; }
        .account-box .body .list-login li .icon {
          flex-shrink: 0;
          width: 3.5rem;
          height: 3.5rem;
          padding: 1rem;
          border-right: 1px solid #E1E1E1; }
          .account-box .body .list-login li .icon img {
            width: 100%;
            height: 100%;
            object-fit: scale-down; }
        .account-box .body .list-login li .text {
          flex: 1;
          width: 100%;
          text-align: center; }
        .account-box .body .list-login li:not(:last-child) {
          margin-bottom: 1.5rem; }
    .account-box .body .forgot-pass {
      margin-top: -1rem;
      display: block;
      color: #0078BF;
      text-decoration: underline;
      font-style: italic;
      font-weight: 300; }
    .account-box .body .custom-checkbox {
      margin-top: 2rem; }
      .account-box .body .custom-checkbox label {
        font-size: 1.5rem;
        font-weight: 300; }
        .account-box .body .custom-checkbox label a {
          color: #BD55A0;
          margin-left: .5rem;
          display: inline-block;
          word-break: break-word;
          text-decoration: underline; }
    .account-box .body .link {
      display: block;
      text-align: center;
      margin-top: 3rem; }
      .account-box .body .link a {
        color: #BD55A0;
        text-decoration: underline;
        margin-left: .5rem; }
  .account-box .form-wrap {
    padding-top: 2rem; }
  .account-box .btn {
    margin-top: 3rem; }

.member-organisation-indiv .banner img {
  width: 100%;
  height: auto; }

.member-organisation-indiv .top-content {
  margin-top: -15rem;
  position: relative;
  z-index: 1;
  border-radius: .5rem;
  box-shadow: 0px .3rem 1.2rem #0000000F;
  background: #F7F5F4;
  background-image: url(../img/campaigns/CHARIS_CrossElement.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 15% auto;
  padding: 3.5rem 6rem; }
  @media (max-width: 767px) {
    .member-organisation-indiv .top-content {
      padding: 3rem 20px;
      margin-top: -5rem; } }
  .member-organisation-indiv .top-content .inner {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .member-organisation-indiv .top-content .inner .box-img {
      width: calc(30% - 2.5rem); }
      .member-organisation-indiv .top-content .inner .box-img img {
        width: 100%;
        height: auto; }
    .member-organisation-indiv .top-content .inner .box-content {
      width: calc( 70% - 2.5rem); }
    @media (max-width: 575px) {
      .member-organisation-indiv .top-content .inner .box-img, .member-organisation-indiv .top-content .inner .box-content {
        width: 100%; }
      .member-organisation-indiv .top-content .inner .box-content {
        padding-top: 3rem; } }
    .member-organisation-indiv .top-content .inner .main-title {
      margin-bottom: 3rem; }
    .member-organisation-indiv .top-content .inner .list li {
      display: flex;
      align-items: center; }
      .member-organisation-indiv .top-content .inner .list li .content {
        margin-left: .5rem; }
      .member-organisation-indiv .top-content .inner .list li .title {
        color: #9F9F9F;
        text-transform: capitalize; }
      .member-organisation-indiv .top-content .inner .list li .imgs {
        display: flex; }
        .member-organisation-indiv .top-content .inner .list li .imgs .img {
          flex-shrink: 0;
          height: 2rem; }
          .member-organisation-indiv .top-content .inner .list li .imgs .img img {
            height: 100%;
            width: auto; }
          .member-organisation-indiv .top-content .inner .list li .imgs .img:not(:last-child) {
            margin-right: 1.5rem; }
      .member-organisation-indiv .top-content .inner .list li:not(:last-child) {
        margin-bottom: 2rem; }

.member-organisation-indiv-2 {
  padding: 5rem 0 10rem;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.0032388) 20%, #f5e4f0 20%); }
  .member-organisation-indiv-2 .my-tabs {
    box-shadow: 0 .3rem .6rem #00000029;
    border: 1px solid #F4F4F4; }
    .member-organisation-indiv-2 .my-tabs .tabs-nav-wrap .tabs-nav {
      list-style: none;
      display: flex;
      align-items: center;
      flex-flow: wrap; }
      .member-organisation-indiv-2 .my-tabs .tabs-nav-wrap .tabs-nav li {
        width: 25%;
        font-size: 1.8rem;
        text-transform: capitalize;
        color: #9f9f9f;
        text-align: center;
        height: 7.5rem;
        display: inline-flex;
        background: #fff;
        justify-content: center;
        align-items: center;
        transition: all .2s;
        cursor: pointer;
        border-bottom: 1px solid #e1e1e1; }
        .member-organisation-indiv-2 .my-tabs .tabs-nav-wrap .tabs-nav li:not(:last-child) {
          border-right: 1px solid #E1E1E1; }
        .member-organisation-indiv-2 .my-tabs .tabs-nav-wrap .tabs-nav li:hover, .member-organisation-indiv-2 .my-tabs .tabs-nav-wrap .tabs-nav li.active {
          color: #fff;
          background: #452A50;
          border-color: #452A50;
          transition: all .2s; }
        @media (max-width: 767px) {
          .member-organisation-indiv-2 .my-tabs .tabs-nav-wrap .tabs-nav li {
            width: 50%;
            border-bottom: 1px solid #E1E1E1;
            border-right: 1px solid #E1E1E1; } }
    .member-organisation-indiv-2 .my-tabs .tab-content {
      display: none;
      padding: 4rem 5rem 5rem;
      background: #fff; }
      .member-organisation-indiv-2 .my-tabs .tab-content p:not(:last-child) {
        margin-bottom: 3rem; }
      .member-organisation-indiv-2 .my-tabs .tab-content .programmes-slider:not(:last-child) {
        margin-bottom: 3rem; }
      .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between; }
        .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item .box-img {
          width: calc(40% - 1.5rem); }
        .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item .box-content {
          width: calc(60% - 1.5rem); }
          .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item .box-content .title {
            color: #452A50;
            text-decoration: underline;
            margin-bottom: 2rem;
            font-weight: bold; }
        @media (max-width: 575px) {
          .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item .box-img, .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item .box-content {
            width: 100%; }
          .member-organisation-indiv-2 .my-tabs .tab-content .slick-container .item .box-content {
            padding-top: 3rem; } }
      .member-organisation-indiv-2 .my-tabs .tab-content .contact-us select:invalid {
        color: #282828;
        font-weight: 400; }
      .member-organisation-indiv-2 .my-tabs .tab-content .contact-us .heading {
        font-size: 1.8rem;
        margin-bottom: 2rem;
        text-transform: uppercase; }
      .member-organisation-indiv-2 .my-tabs .tab-content .info ul {
        list-style: none; }
      .member-organisation-indiv-2 .my-tabs .tab-content .info li .title {
        text-transform: capitalize;
        color: #707070;
        font-weight: bold; }
      .member-organisation-indiv-2 .my-tabs .tab-content .info li .name {
        margin-bottom: 0 !important; }
      .member-organisation-indiv-2 .my-tabs .tab-content .info li a {
        display: block;
        color: #BD55A0; }
      .member-organisation-indiv-2 .my-tabs .tab-content .info li:not(:last-child) {
        margin-bottom: 2rem; }
      @media (min-width: 768px) {
        .member-organisation-indiv-2 .my-tabs .tab-content .info {
          padding-left: 8rem; } }
      @media (max-width: 767px) {
        .member-organisation-indiv-2 .my-tabs .tab-content .info {
          padding-top: 5rem; } }

.member-organisation-indiv-3 .banner {
  border-radius: .5rem;
  overflow: hidden;
  position: relative;
  padding: 5rem; }
  @media (max-width: 767px) {
    .member-organisation-indiv-3 .banner {
      padding: 5rem 20px; } }
  .member-organisation-indiv-3 .banner .title {
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    line-height: 1.2; }
  .member-organisation-indiv-3 .banner .desc {
    color: #fff;
    max-width: 70rem;
    margin-top: 2rem; }

.cbpm .main-title, .abpm .main-title {
  margin-bottom: 2.5rem; }

.cbpm .tab-content, .abpm .tab-content {
  display: none;
  position: relative; }

.cbpm .slick-container, .abpm .slick-container {
  margin: -1rem 0; }

.cbpm .slick-list, .abpm .slick-list {
  margin: 0 -2rem;
  padding: 1rem 0; }
  .cbpm .slick-list .slick-slide, .abpm .slick-list .slick-slide {
    padding: 0 2rem; }
  @media (max-width: 767px) {
    .cbpm .slick-list, .abpm .slick-list {
      margin: 0 -1.5rem; }
      .cbpm .slick-list .slick-slide, .abpm .slick-list .slick-slide {
        padding: 0 1.5rem; } }

.cbpm .slick-disabled, .abpm .slick-disabled {
  opacity: .4; }

.cbpm .tabs-nav, .abpm .tabs-nav {
  list-style: none;
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 4.7rem; }
  .cbpm .tabs-nav li, .abpm .tabs-nav li {
    text-transform: capitalize;
    color: #707070;
    cursor: pointer;
    transition: all .2s;
    border-bottom: 1px solid transparent; }
    .cbpm .tabs-nav li:not(:last-child), .abpm .tabs-nav li:not(:last-child) {
      margin-right: 3rem; }
    .cbpm .tabs-nav li.active, .abpm .tabs-nav li.active {
      border-bottom: 1px solid #007885;
      color: #007885;
      transition: all .2s; }

.page-policy {
  padding: 8rem 0 10rem; }
  .page-policy .heading {
    font-size: 1.8rem;
    color: #452A50;
    margin-bottom: 2rem;
    font-weight: bold; }
  .page-policy ul {
    list-style: none; }
    .page-policy ul li:not(:last-child) {
      margin-bottom: 2rem; }

.page-pre-checkout .inner {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start; }

.page-pre-checkout .or {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: bold;
  background: #fff;
  position: relative; }
  .page-pre-checkout .or:after {
    content: '';
    position: absolute;
    background: #E1E1E1; }
  .page-pre-checkout .or:before {
    content: '';
    position: absolute;
    background: #E1E1E1; }
  @media (min-width: 992px) {
    .page-pre-checkout .or {
      padding: 31.5rem 0; }
      .page-pre-checkout .or:after {
        width: 1px;
        height: 29rem;
        top: 0;
        left: 50%;
        transform: translateX(-50%); }
      .page-pre-checkout .or:before {
        width: 1px;
        height: 29rem;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%); } }

@media (max-width: 991px) {
  .page-pre-checkout .inner {
    flex-direction: column;
    align-items: center; }
    .page-pre-checkout .inner .or {
      width: 100%;
      text-align: center; }
      .page-pre-checkout .inner .or:after {
        height: 1px;
        width: 40%;
        top: 50%;
        left: 0;
        transform: translateY(-50%); }
      .page-pre-checkout .inner .or:before {
        height: 1px;
        width: 40%;
        top: 50%;
        right: 0;
        transform: translateY(-50%); } }

.page-pre-checkout .proceed-box, .page-pre-checkout .account-box {
  margin: 10rem 0; }

.proceed-box {
  max-width: 49rem;
  width: 100%;
  box-shadow: 0px 3px 12px #0000000A;
  border: 1px solid #F4F4F4; }
  .proceed-box .head {
    padding: 2.8rem;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 1.8rem;
    background: #452A50; }
  .proceed-box .body {
    padding: 2rem 4rem 4rem;
    background: #fff; }
  .proceed-box ul {
    padding: 0;
    margin: 0;
    margin-bottom: 1.5rem;
    list-style-position: inside; }
  .proceed-box p:not(:last-child) {
    margin-bottom: 1.5rem; }

.banner-subpage {
  padding: 15rem 0; }
  .banner-subpage .heading {
    text-align: center;
    font-size: 4rem;
    letter-spacing: .4rem;
    line-height: calc(50/40);
    font-weight: bold;
    color: #fff; }

.member-organisation {
  padding: 5rem 0 10rem;
  border-bottom: 1px solid #E1E1E1; }
  .member-organisation .row-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between; }
    .member-organisation .row-wrapper .left-wrap {
      width: calc(20% -2rem); }
    .member-organisation .row-wrapper .right-wrap {
      width: calc(80% -2rem); }
    @media (max-width: 767px) {
      .member-organisation .row-wrapper .left-wrap, .member-organisation .row-wrapper .right-wrap {
        width: 100%; } }
  .member-organisation .left-wrap {
    margin-bottom: 4rem;
    position: relative; }
  .member-organisation .mb-filter {
    margin-top: 0; }
    @media (min-width: 768px) {
      .member-organisation .mb-filter {
        display: none; } }
  @media (max-width: 767px) {
    .member-organisation .sidebar {
      display: none;
      position: absolute;
      left: -20px;
      width: 100vw;
      top: calc(100% + 2rem);
      background: #fff;
      padding: 0 20px;
      z-index: 50;
      padding-bottom: 3rem; } }
  .member-organisation .sidebar .cate-txt {
    font-size: 2.5rem;
    text-transform: capitalize;
    color: #0F5373;
    margin-bottom: 1rem;
    padding: 0 2rem;
    font-weight: 500; }
  .member-organisation .sidebar .sidebar-item:not(:last-child) {
    margin-bottom: 3rem; }
  .member-organisation .sidebar .sidebar-item .sidebar-text {
    position: relative;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: #452A50;
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid #E1E1E1;
    justify-content: space-between; }
    .member-organisation .sidebar .sidebar-item .sidebar-text .arrow {
      width: 1.3rem;
      height: 1rem;
      background-image: url(../img/icons/Arrow3_Purple.png);
      transform: scaleY(-1);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      transition: all .2s; }
    .member-organisation .sidebar .sidebar-item .sidebar-text.active .arrow {
      transform: scale(1);
      transition: all .2s; }
  .member-organisation .sidebar .sidebar-item .menu {
    list-style: none;
    background: #F4F4F4;
    display: none;
    padding: .5rem 0;
    padding-top: 1.7rem; }
    .member-organisation .sidebar .sidebar-item .menu .menu-item:not(:last-child) {
      margin-bottom: 1rem; }
    .member-organisation .sidebar .sidebar-item .menu .menu-item .menu-link {
      font-size: 1.5rem;
      color: #282828;
      text-transform: capitalize;
      display: inline-block;
      transition: all .3s ease; }
    .member-organisation .sidebar .sidebar-item .menu .menu-item:hover .menu-link, .member-organisation .sidebar .sidebar-item .menu .menu-item.active .menu-link {
      color: #BD55A0;
      text-stroke: .1rem currentColor;
      -webkit-text-stroke: .1rem currentColor;
      transition: all .3s ease; }
  .member-organisation .sidebar .sidebar-item .filter-list {
    list-style: none;
    margin-top: 1.5rem;
    display: none;
    overflow: hidden;
    overflow-y: auto;
    max-height: 25rem; }
    .member-organisation .sidebar .sidebar-item .filter-list::-webkit-scrollbar {
      width: .3rem; }
    .member-organisation .sidebar .sidebar-item .filter-list::-webkit-scrollbar-track {
      border-radius: 20px;
      background: transparent; }
    .member-organisation .sidebar .sidebar-item .filter-list::-webkit-scrollbar-thumb {
      background: #007885;
      border-radius: 20px; }
    .member-organisation .sidebar .sidebar-item .filter-list .filter-item label {
      font-size: 1.5rem; }
      .member-organisation .sidebar .sidebar-item .filter-list .filter-item label:before {
        top: .2rem; }
    .member-organisation .sidebar .sidebar-item .filter-list .filter-item:not(:last-child) {
      margin-bottom: 1rem; }
  .member-organisation .sidebar .apply-filter {
    margin-top: 0;
    width: 100%; }
  .member-organisation .list {
    margin: 0 -15px;
    margin-bottom: -40px; }
    .member-organisation .list .col, .member-organisation .list [class*=col-] {
      padding-right: 15px;
      padding-left: 15px; }
    .member-organisation .list .item {
      margin-bottom: 40px;
      border: 1px solid #F5F5F5; }
      .member-organisation .list .item img {
        width: 100%;
        height: 100%;
        object-fit: contain; }
  .member-organisation .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3rem; }
    .member-organisation .head .clear-all {
      color: #007885;
      text-transform: capitalize;
      text-decoration: underline; }
  .member-organisation .showing {
    color: #707070;
    margin-bottom: 3rem; }
  .member-organisation .sort {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 2rem; }
    .member-organisation .sort .txt {
      margin-right: 1.2rem;
      color: #707070;
      font-weight: 300; }

.dashboard-section {
  display: flex;
  width: 100%;
  background: #F8F8F8; }

.dashboard-main.active {
  padding-left: 0px !important; }

.dashboard-main {
  padding-left: 200px;
  transition: padding 0.5s;
  min-height: 100vh;
  display: flex;
  width: 100%;
  flex-direction: column; }

.header-navbar {
  padding: 0 2rem;
  height: 70px;
  display: flex;
  width: 100%;
  align-items: center;
  box-shadow: 0px .2rem .6rem #0000000A;
  background: #fff;
  position: sticky;
  top: 0;
  right: 0;
  z-index: 999; }
  .header-navbar .navbar {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between; }
  .header-navbar .aside-toggle-btn {
    color: #282828;
    background: #F5E4F0;
    cursor: pointer;
    width: 3.8rem;
    height: 3.8rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .header-navbar .aside-toggle-btn svg {
      width: 2rem;
      height: auto; }
  .header-navbar .right-wrap {
    display: flex;
    align-items: center; }
  .header-navbar .notification {
    border-right: 1px solid #EBEBF2;
    padding-right: 2rem;
    margin-right: 2rem; }
    .header-navbar .notification-toggle {
      width: 16px;
      position: relative;
      cursor: pointer; }
      .header-navbar .notification-toggle svg {
        width: 100%;
        height: auto; }
        .header-navbar .notification-toggle svg path {
          fill: #BCBCCB; }
      .header-navbar .notification-toggle .badge {
        position: absolute;
        width: 10px;
        height: 10px;
        top: -2px;
        right: -2px;
        border-radius: 50%;
        display: block;
        border: .2rem solid #fff;
        background: #BD56A0; }
  .header-navbar .account {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center; }
    .header-navbar .account-toggle {
      display: flex;
      cursor: pointer;
      align-items: center; }
      .header-navbar .account-toggle .name {
        color: #707070;
        text-transform: capitalize; }
      .header-navbar .account-toggle .caret {
        flex-shrink: 0;
        width: 10px;
        margin-left: 10px; }
        .header-navbar .account-toggle .caret img {
          width: 100%;
          height: auto; }
      .header-navbar .account-toggle .avatar {
        width: 3.8rem;
        height: 3.8rem;
        flex-shrink: 0;
        margin-left: 1rem;
        border-radius: 50%;
        overflow: hidden;
        background: #F5E4F0; }
        .header-navbar .account-toggle .avatar img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .header-navbar .account .dropdown-menu {
      position: absolute;
      top: 100%;
      right: 0;
      width: 20rem;
      background: #fff;
      list-style: none;
      opacity: 0;
      visibility: hidden;
      box-shadow: 0px .5rem .4rem #272D3B05;
      transition: all .3s; }
      .header-navbar .account .dropdown-menu li {
        line-height: 4rem;
        transition: all .3s; }
        .header-navbar .account .dropdown-menu li a {
          transition: all .3s;
          font-size: 1.5rem;
          display: block;
          color: #707070;
          padding: 0 1.5rem;
          text-transform: capitalize; }
        .header-navbar .account .dropdown-menu li:hover {
          background: #F5E4F0;
          transition: all .3s; }
    .header-navbar .account:hover .dropdown-menu {
      opacity: 1;
      visibility: visible; }

.aside-nav.active {
  transform: translateX(-100%); }

.aside-nav {
  overflow-y: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #452A50;
  width: 200px;
  transition: transform 0.5s;
  height: 100vh;
  padding: 1rem 0;
  z-index: 9999; }
  .aside-nav .aside-brand {
    width: 105px;
    display: block;
    margin: 0 auto;
    margin-bottom: .6rem; }
    .aside-nav .aside-brand svg {
      width: 100%;
      height: auto; }
  .aside-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    letter-spacing: .03rem;
    text-transform: capitalize;
    color: #fff;
    line-height: 5.3rem;
    white-space: nowrap;
    padding: 0 2.5rem; }
    .aside-nav .nav-link .icon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      margin-right: 1rem; }
      .aside-nav .nav-link .icon img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        filter: brightness(0) invert(1); }
  .aside-nav .nav-item {
    transition: all .3s ease-in-out; }
    .aside-nav .nav-item:hover, .aside-nav .nav-item.active {
      background: #32183C;
      transition: all .3s ease-in-out;
      box-shadow: inset 5px 0 0 0 #BD56A0; }

@media (min-width: 300px) and (max-width: 1023.98px) {
  .dashboard-main {
    padding-left: 0px !important; }
  .aside-nav {
    top: 70px !important;
    transform: translateX(-100%); }
    .aside-nav.active {
      transform: none; }
  .dashboard-main {
    overflow: hidden; }
  .header-navbar {
    position: fixed !important; }
  .dashboard-main {
    margin-top: 70px; } }

#content-container {
  padding: 4rem; }
  @media (max-width: 1023.98px) {
    #content-container {
      padding: 2rem; } }
  #content-container .filter-wrapper {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    margin-top: -2rem;
    padding-top: 2rem; }
    #content-container .filter-wrapper .filter-item {
      margin-right: 2rem;
      margin-top: 2rem;
      display: inline-flex;
      align-items: center; }
      #content-container .filter-wrapper .filter-item .txt {
        font-size: 1.5rem;
        color: #707070;
        text-transform: capitalize;
        margin-right: 1.5rem;
        font-weight: 300; }
      @media (max-width: 575px) {
        #content-container .filter-wrapper .filter-item {
          display: flex;
          width: 100%; } }
    #content-container .filter-wrapper .btn {
      margin-left: 2rem;
      margin-top: 2rem; }
      @media (max-width: 575px) {
        #content-container .filter-wrapper .btn {
          margin-left: 0; } }
    #content-container .filter-wrapper .dropdown-select {
      background: #fff; }
    #content-container .filter-wrapper .form-group {
      margin-bottom: 0;
      position: relative; }
      #content-container .filter-wrapper .form-group input {
        background: #fff;
        width: 26.7rem; }
      #content-container .filter-wrapper .form-group [type="datetime-local"] {
        width: 30.8rem; }
      #content-container .filter-wrapper .form-group [type="date"], #content-container .filter-wrapper .form-group [type="datetime-local"] {
        font-weight: 300;
        padding-right: 5rem; }
      #content-container .filter-wrapper .form-group [type="date"]::-webkit-inner-spin-button, #content-container .filter-wrapper .form-group [type="datetime-local"]::-webkit-inner-spin-button {
        display: none; }
      #content-container .filter-wrapper .form-group [type="date"]::-webkit-calendar-picker-indicator, #content-container .filter-wrapper .form-group [type="datetime-local"]::-webkit-calendar-picker-indicator {
        width: 3.2rem;
        height: 3.2rem;
        cursor: pointer;
        border-radius: .3rem;
        background: #F5E4F0;
        position: absolute;
        right: 0;
        top: 0;
        border: none;
        box-shadow: none;
        background-image: url(../img/common-icons/calendar.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 1.7rem auto; }
  #content-container .heading {
    font-size: 2.4rem;
    letter-spacing: .048rem;
    text-transform: capitalize;
    color: #BD56A0;
    margin-bottom: 2rem;
    font-weight: bold; }
  #content-container .heading-wrap {
    display: flex;
    flex-flow: wrap;
    margin-left: -2rem;
    align-items: center;
    margin-top: -1rem;
    margin-bottom: 2rem; }
    #content-container .heading-wrap .heading {
      margin-left: 2rem;
      margin-top: 1rem;
      margin-bottom: 0; }
    #content-container .heading-wrap .tag {
      font-size: 1.5rem;
      text-transform: capitalize;
      color: #fff;
      border-radius: .5rem;
      height: 2.5rem;
      padding: 0 2rem;
      margin-top: 1rem;
      margin-left: 2rem; }
      #content-container .heading-wrap .tag.green {
        background: #27E284; }
      #content-container .heading-wrap .tag.blue {
        background: #2699FB; }
  #content-container .table-responsive {
    border-radius: .5rem;
    box-shadow: 0px .5rem .4rem #272D3B05;
    background: #FFFFFF;
    padding: 0 3.5rem 3.5rem; }
    #content-container .table-responsive.table-sticky .table th:last-child, #content-container .table-responsive.table-sticky .table td:last-child {
      position: sticky;
      right: 0;
      top: 0;
      z-index: 999;
      overflow: visible;
      background: #fff; }
    #content-container .table-responsive.table-sticky .table th.active, #content-container .table-responsive.table-sticky .table td.active {
      z-index: 9999; }
  #content-container .table {
    width: 100%; }
    #content-container .table thead {
      border-bottom: 1px solid #F4F2F2; }
      #content-container .table thead th {
        padding: 1.5rem;
        color: #000000;
        text-transform: capitalize;
        font-weight: bold;
        white-space: nowrap;
        max-width: 29rem; }
        #content-container .table thead th:first-child {
          padding-left: 0; }
        #content-container .table thead th:last-child {
          padding-right: 0; }
    #content-container .table tr {
      border-bottom: 1px solid #F4F2F2; }
      #content-container .table tr td {
        color: #000000;
        padding: 1.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 29rem; }
        #content-container .table tr td:first-child {
          padding-left: 0; }
        #content-container .table tr td:last-child {
          padding-right: 0; }
  #content-container .dropdown.actions-dropdown {
    min-width: 11.8rem;
    margin-left: auto; }
    #content-container .dropdown.actions-dropdown .dropdown-select {
      padding: 0 .5rem 0 1rem;
      height: 2.7rem;
      border-radius: .5rem;
      border: 1px solid #BD56A0;
      background: #fff; }
      #content-container .dropdown.actions-dropdown .dropdown-select .dropdown-selected {
        font-weight: normal !important;
        font-size: 1.5rem;
        color: #BD56A0; }
      #content-container .dropdown.actions-dropdown .dropdown-select .dropdown-caret {
        width: unset;
        height: unset;
        background: none; }
        #content-container .dropdown.actions-dropdown .dropdown-select .dropdown-caret em {
          color: #BD56A0;
          font-size: 1.8rem; }
    #content-container .dropdown.actions-dropdown .dropdown-list {
      border: 1px solid #EAEAEA;
      border-radius: .5rem;
      overflow: hidden;
      top: calc(100% + .5rem); }
      #content-container .dropdown.actions-dropdown .dropdown-list .dropdown-item {
        font-size: 1.5rem;
        color: #6E6E6E;
        font-weight: normal !important;
        padding: .8rem 1.5rem;
        border-bottom: 0;
        line-height: 1;
        text-align: left; }
        #content-container .dropdown.actions-dropdown .dropdown-list .dropdown-item.selected, #content-container .dropdown.actions-dropdown .dropdown-list .dropdown-item:hover {
          color: #452A50;
          background: #F5E4F0; }
    #content-container .dropdown.actions-dropdown.open .dropdown-select {
      background: #BD56A0; }
      #content-container .dropdown.actions-dropdown.open .dropdown-select .dropdown-selected {
        color: #fff; }
      #content-container .dropdown.actions-dropdown.open .dropdown-select .dropdown-caret em {
        color: #fff; }
  #content-container .draft, #content-container .pending, #content-container .revise, #content-container .published, #content-container .completed, #content-container .closed, #content-container .dot-green, #content-container .dot-dark, #content-container .edited, #content-container .submitted {
    padding-left: 20px;
    position: relative;
    white-space: nowrap; }
    #content-container .draft:before, #content-container .pending:before, #content-container .revise:before, #content-container .published:before, #content-container .completed:before, #content-container .closed:before, #content-container .dot-green:before, #content-container .dot-dark:before, #content-container .edited:before, #content-container .submitted:before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%; }
  #content-container .draft:before, #content-container .edited:before {
    background: #2699FB; }
  #content-container .pending:before, #content-container .submitted:before {
    background: #FF8373; }
  #content-container .revise:before {
    background: #FB2626; }
  #content-container .closed:before {
    background: #707070; }
  #content-container .completed:before {
    background: #FFDA83; }
  #content-container .published:before {
    background: #27E284; }
  #content-container .dot-green:before {
    background: #27E284; }
  #content-container .dot-dark:before {
    background: #B7B7B7; }
  #content-container .view-btn, #content-container .edit-btn {
    border: 1px solid #BD56A0;
    border-radius: .3rem;
    padding: 0 1.2rem;
    height: 2.7rem;
    display: inline-flex;
    align-items: center;
    color: #BD56A0;
    cursor: pointer;
    text-transform: uppercase;
    transition: all .2s; }
    #content-container .view-btn:hover, #content-container .edit-btn:hover {
      transition: all .2s;
      color: #707070;
      background: #F4F2F2;
      border: 1px solid #F4F2F2; }
  #content-container .btn-wrap {
    text-align: right; }
  @media screen and (min-width: 768px) {
    #content-container .form-wrap .form-row {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      grid-gap: 0 6rem; } }
  #content-container .form-wrap .input-wrap {
    position: relative; }
    #content-container .form-wrap .input-wrap .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 3.6rem;
      height: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: .3rem 0 0 .3rem;
      background: #E1E1E1; }
      #content-container .form-wrap .input-wrap .icon img {
        width: 2rem;
        height: auto; }
    #content-container .form-wrap .input-wrap input {
      text-indent: 3.6rem; }
  #content-container .form-wrap input {
    border: 1px solid #F4F2F2;
    background: none; }
  #content-container .form-wrap .sharing-link {
    display: flex;
    align-items: center;
    border: 1px solid #F4F2F2; }
    #content-container .form-wrap .sharing-link .icon {
      width: 3.6rem;
      height: 3.6rem;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      border-radius: .3rem 0 0 .3rem;
      background: #E1E1E1;
      margin-right: 2rem; }
      #content-container .form-wrap .sharing-link .icon img {
        width: 2rem;
        height: auto; }
  #content-container .form-wrap .form-group {
    margin-bottom: 2rem; }
  #content-container .tooltip {
    padding-top: 1.5rem; }
    #content-container .tooltip label {
      margin-bottom: 1.5rem; }
    #content-container .tooltip input, #content-container .tooltip textarea, #content-container .tooltip .select-box {
      border: none;
      background: #fff; }
    #content-container .tooltip .group {
      display: flex;
      align-items: center;
      padding-left: 2rem; }
      #content-container .tooltip .group .custom-checkbox:not(:last-child) {
        margin-right: 10rem; }
      #content-container .tooltip .group .custom-checkbox label {
        color: #707070;
        font-size: 1.5rem;
        font-weight: 300;
        margin-bottom: 0; }
        #content-container .tooltip .group .custom-checkbox label:before {
          background: #f8f8f8; }
      #content-container .tooltip .group .custom-checkbox input[type="radio"]:focus + label:before {
        border: 1px solid #E1E1E1;
        background: #BD56A0;
        box-shadow: inset 0 0 0 .2rem #fff; }
      #content-container .tooltip .group .custom-checkbox input[type="radio"]:checked + label:before {
        border: 1px solid #E1E1E1;
        background: #BD56A0;
        box-shadow: inset 0 0 0 .2rem #fff; }
      #content-container .tooltip .group .custom-checkbox input[type="checkbox"]:focus + label:before {
        border: 1px solid #E1E1E1;
        background: #BD56A0;
        box-shadow: inset 0 0 0 .2rem #fff; }
      #content-container .tooltip .group .custom-checkbox input[type="checkbox"]:checked + label:before {
        border: 1px solid #E1E1E1;
        background: #BD56A0;
        box-shadow: inset 0 0 0 .2rem #fff; }
    #content-container .tooltip .tooltip-content .group {
      padding-left: 0; }
  #content-container .tooltip-toggle {
    color: #030303;
    margin-top: 2rem;
    text-transform: capitalize;
    font-weight: 600;
    padding-left: 2rem;
    width: fit-content;
    cursor: pointer; }
  #content-container .tooltip-content {
    border: 1px solid #70707033;
    padding: 2rem;
    border-radius: .5rem;
    margin-top: 1rem;
    background: #f8f8f8;
    width: 100%;
    position: relative;
    font-weight: normal;
    color: #707070;
    display: none; }
    #content-container .tooltip-content:before {
      content: '';
      height: 1.6rem;
      width: 1.6rem;
      position: absolute;
      background-color: #f8f8f8;
      top: -.8rem;
      left: 2rem;
      border-top: #70707033 solid 1px;
      border-left: #70707033 solid 1px;
      transform: rotate(45deg); }
    #content-container .tooltip-content .form-group .txt {
      font-weight: bold;
      display: block;
      padding-left: 1.5rem; }
    #content-container .tooltip-content ul {
      list-style: disc;
      padding-left: 1.8rem; }
      #content-container .tooltip-content ul li {
        font-weight: bold; }
    #content-container .tooltip-content p:not(:last-child) {
      margin-bottom: 2rem; }
  #content-container .list {
    list-style: none; }
    #content-container .list > li {
      color: #030303;
      font-weight: 600; }
      #content-container .list > li:not(:last-child) {
        margin-bottom: 2rem; }
  #content-container .accordions .accordion-item .accordion-heading .txt {
    font-size: 2rem;
    letter-spacing: .04rem;
    font-weight: bold; }
    @media (max-width: 767px) {
      #content-container .accordions .accordion-item .accordion-heading .txt {
        font-size: 1.8rem; } }
    @media (max-width: 575px) {
      #content-container .accordions .accordion-item .accordion-heading .txt {
        font-size: 1.6rem; } }
  #content-container .accordions .next {
    margin-bottom: 4.8rem;
    text-align: left !important;
    display: none; }
    #content-container .accordions .next .btn {
      margin-top: 1rem; }
  #content-container .box {
    padding: 2.5rem;
    box-shadow: 0px .5rem .6rem #272D3B14;
    position: relative;
    border-radius: .5rem;
    background: #fff; }
    @media screen and (min-width: 768px) {
      #content-container .box .form-row {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        grid-gap: 0 1.5rem; } }
    #content-container .box .remove-box {
      position: absolute;
      top: 2.5rem;
      right: 2.5rem;
      width: 2.5rem;
      z-index: 9;
      cursor: pointer; }
      #content-container .box .remove-box img {
        width: 100%;
        height: auto;
        transition: all .3s; }
      #content-container .box .remove-box:hover img {
        transform: scale(1.15); }
  #content-container .documents .document {
    display: inline-flex;
    align-items: center;
    font-size: 1.8rem;
    color: #BD56A0;
    text-decoration: underline;
    font-weight: 600;
    border: 1px solid #DBDBDB;
    border-radius: .5rem;
    height: 4.8rem;
    padding: 0 2.5rem;
    background: #FAFAFA; }
    #content-container .documents .document:nth-child(1) {
      margin-right: 3.5rem; }

.upload-2 {
  display: flex; }
  .upload-2 .box-img {
    width: 11rem;
    flex-shrink: 0;
    border: 1px solid #DBDBDB;
    background: #F5E4F0;
    position: relative;
    border-radius: .5rem;
    height: 8.8rem; }
    .upload-2 .box-img img {
      border-radius: .5rem;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .upload-2 .box-img .remove {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: -.75rem;
      right: -.75rem;
      cursor: pointer;
      z-index: 9;
      background: #CC3333;
      padding: .4rem;
      transition: all .3s; }
      .upload-2 .box-img .remove img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: scale-down;
        filter: brightness(0) invert(1); }
      .upload-2 .box-img .remove:hover {
        transform: scale(1.15); }
  .upload-2 .p-image {
    flex: 1;
    width: 100%;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .upload-2 .p-image .txt {
      color: #707070;
      margin-bottom: 1rem; }
    .upload-2 .p-image .wrap {
      display: flex;
      align-items: center; }
      .upload-2 .p-image .wrap .upload-button {
        color: #fff;
        text-transform: capitalize;
        letter-spacing: .045rem;
        padding: 0 1.5rem;
        height: 2.5rem;
        flex-shrink: 0;
        line-height: 2.5rem;
        border-radius: .5rem;
        cursor: pointer;
        background: #BD56A0; }
        .upload-2 .p-image .wrap .upload-button:hover, .upload-2 .p-image .wrap .upload-button.active {
          box-shadow: 0.1rem 0.3rem 0.5rem rgba(0, 0, 0, 0.3); }
    .upload-2 .p-image input[type="file"] {
      display: none; }
    .upload-2 .p-image .file-upload-name {
      font-size: 1.3rem;
      color: #807E6F;
      font-weight: 300;
      word-break: break-word;
      font-style: italic;
      margin-left: 1.5rem; }
  @media (max-width: 575px) {
    .upload-2 {
      flex-direction: column; }
      .upload-2 .p-image {
        padding-left: 0;
        padding-top: 2rem; } }

.upload-multiple {
  max-width: 70rem; }
  .upload-multiple .buttons {
    display: flex;
    align-items: center;
    margin-top: 2rem; }
    .upload-multiple .buttons .btn-prev, .upload-multiple .buttons .btn-next {
      display: block !important; }
      .upload-multiple .buttons .btn-prev img, .upload-multiple .buttons .btn-next img {
        width: 1rem; }
    .upload-multiple .buttons .btn-prev {
      margin-right: 2.5rem; }
  .upload-multiple .image {
    border: 1px solid #DBDBDB;
    display: block;
    position: relative;
    border-radius: .5rem;
    height: 8.8rem;
    margin-top: .75rem; }
    .upload-multiple .image:not(:last-child) {
      margin-right: 2rem; }
    .upload-multiple .image img {
      border-radius: .5rem;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .upload-multiple .image .remove {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      position: absolute;
      top: -.75rem;
      right: -.75rem;
      cursor: pointer;
      z-index: 9;
      background: #CC3333;
      padding: .4rem;
      transition: all .3s; }
      .upload-multiple .image .remove img {
        width: 100%;
        height: 100%;
        cursor: pointer;
        object-fit: scale-down;
        filter: brightness(0) invert(1);
        transition: all .3s; }
      .upload-multiple .image .remove:hover {
        transform: scale(1.1); }
    .upload-multiple .image.hide {
      visibility: hidden;
      position: absolute; }
  .upload-multiple .txt {
    color: #707070;
    margin-bottom: 2rem; }
  .upload-multiple .wrap {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem; }
    .upload-multiple .wrap .upload-button {
      color: #fff;
      text-transform: capitalize;
      letter-spacing: .045rem;
      padding: 0 1.5rem;
      height: 2.5rem;
      flex-shrink: 0;
      line-height: 2.5rem;
      border-radius: .5rem;
      cursor: pointer;
      background: #BD56A0; }
      .upload-multiple .wrap .upload-button:hover, .upload-multiple .wrap .upload-button.active {
        box-shadow: 0.1rem 0.3rem 0.5rem rgba(0, 0, 0, 0.3); }
  .upload-multiple input[type="file"] {
    display: none; }
  .upload-multiple .file-upload-name {
    font-size: 1.3rem;
    color: #807E6F;
    font-weight: 300;
    word-break: break-word;
    font-style: italic;
    margin-left: 1.5rem; }
  .upload-multiple.custom {
    display: flex;
    flex-flow: wrap;
    max-width: 100%;
    margin: -1rem; }
    .upload-multiple.custom .list-image {
      width: 60%;
      padding: 1rem; }
    .upload-multiple.custom .left-wrap {
      padding: 1rem;
      width: 40%; }
    @media (max-width: 767px) {
      .upload-multiple.custom .list-image, .upload-multiple.custom .left-wrap {
        width: 100%; } }

.paging_simple_numbers {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #D7DAE2;
  border-radius: .2rem;
  width: fit-content;
  margin: 0 auto;
  font-size: 1.3rem;
  margin-top: 3.5rem;
  color: #4D4F5C; }
  .paging_simple_numbers a, .paging_simple_numbers .ellipsis {
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    color: #4D4F5C;
    align-items: center;
    transition: all .2s;
    cursor: pointer;
    justify-content: center; }
    .paging_simple_numbers a img, .paging_simple_numbers .ellipsis img {
      height: 8px;
      width: auto; }
    .paging_simple_numbers a:hover, .paging_simple_numbers a.current, .paging_simple_numbers .ellipsis:hover, .paging_simple_numbers .ellipsis.current {
      transition: all .2s;
      color: #BD56A0;
      background: #F5E4F0; }
  .paging_simple_numbers .ellipsis {
    border-left: 0;
    border-right: 1px solid #D7DAE2; }
  .paging_simple_numbers span {
    display: flex;
    align-items: center;
    border-left: 1px solid #D7DAE2; }
    .paging_simple_numbers span a {
      border-right: 1px solid #D7DAE2; }

.dashboard-head {
  margin-bottom: 2rem;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  margin-top: -1rem; }
  .dashboard-head .back {
    display: inline-flex;
    align-items: center;
    color: #BD56A0;
    font-weight: bold;
    margin-top: 1rem; }
    .dashboard-head .back .icon {
      display: block;
      width: 6px;
      flex-shrink: 0;
      margin-right: 1rem; }
      .dashboard-head .back .icon img {
        width: 100%;
        height: auto; }
  .dashboard-head .btn-wrap {
    margin-right: -1rem;
    text-align: left !important;
    display: flex;
    flex-flow: wrap;
    align-items: center; }
    .dashboard-head .btn-wrap .btn {
      min-width: 17.5rem;
      margin-top: 1rem;
      margin-right: 1rem; }
      .dashboard-head .btn-wrap .btn .icon {
        display: block;
        width: 2.4rem;
        flex-shrink: 0;
        margin-right: .5rem; }
        .dashboard-head .btn-wrap .btn .icon img {
          width: 100%;
          height: auto;
          filter: brightness(0) invert(1); }

.btn-bottom {
  margin-right: -2rem;
  text-align: left !important;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  margin-top: 6.7rem; }
  .btn-bottom .btn {
    min-width: 17.5rem;
    margin-top: 1rem;
    margin-right: 2rem; }
    .btn-bottom .btn .icon {
      display: block;
      width: 2.4rem;
      flex-shrink: 0;
      margin-right: .5rem; }
      .btn-bottom .btn .icon img {
        width: 100%;
        height: auto;
        filter: brightness(0) invert(1); }
  @media (min-width: 992px) {
    .btn-bottom .save-btn {
      margin-left: auto; } }

.dataTables_wrapper {
  overflow: hidden;
  overflow-x: auto; }
  .dataTables_wrapper::-webkit-scrollbar {
    height: .6rem; }
  .dataTables_wrapper::-webkit-scrollbar-track {
    background: #DDDDDD; }
  .dataTables_wrapper::-webkit-scrollbar-thumb {
    background: #B7B7B7; }

.box-item {
  border-radius: .5rem;
  padding: 1.5rem;
  background: #fff;
  margin-top: 4rem; }
  .box-item .items {
    display: flex;
    flex-flow: wrap;
    margin: -1rem; }
  .box-item .item {
    padding: 1rem; }
    .box-item .item-1 {
      width: 45%; }
      .box-item .item-1 .wrap {
        position: relative; }
    .box-item .item .wrap {
      border-radius: .5rem;
      min-height: 28.6rem;
      box-shadow: 0px .3rem .6rem #272D3B33;
      height: 100%;
      display: flex;
      flex-direction: column; }
    .box-item .item .progress-wrapper {
      padding: 3rem; }
      .box-item .item .progress-wrapper .top-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        margin-bottom: 1rem; }
        .box-item .item .progress-wrapper .top-txt .days {
          font-size: 1.5rem;
          font-weight: bold; }
      .box-item .item .progress-wrapper .progress-txt {
        font-size: 2rem; }
      .box-item .item .progress-wrapper .bottom {
        margin-top: 1rem;
        color: #fff; }
        .box-item .item .progress-wrapper .bottom .donated {
          margin-left: .5rem; }
      .box-item .item .progress-wrapper .progress {
        background-color: #BD55A0;
        border-radius: 20px;
        position: relative;
        height: .5rem;
        width: 100%; }
        .box-item .item .progress-wrapper .progress-done {
          background: #fff;
          border-radius: 20px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 0;
          opacity: 0;
          transition: 1s ease 0.3s; }
    .box-item .item .text {
      font-size: 1.3rem;
      color: #fff;
      font-weight: 300; }
    .box-item .item .title {
      font-size: 2.5rem;
      font-weight: bold;
      color: #fff;
      margin-top: .5rem; }
    .box-item .item .subtitle {
      max-width: 21rem; }
    .box-item .item .price-txt {
      color: #fff;
      margin-top: .5rem; }
    .box-item .item .image {
      padding-top: 1.5rem;
      margin-top: auto; }
      .box-item .item .image img {
        width: 100%;
        height: auto; }
    .box-item .item-2 .wrap, .box-item .item-5 .wrap {
      background: transparent linear-gradient(180deg, #EA5D95 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      padding: 1.5rem 3rem;
      text-align: center; }
    .box-item .item-3 .wrap {
      background: transparent linear-gradient(180deg, #BD56A0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      padding: 1.5rem 2.5rem;
      text-align: right; }
    .box-item .item-2 {
      width: 22%; }
    .box-item .item-3 {
      width: 33%; }
    .box-item .item-4 {
      width: 50%; }
      .box-item .item-4 .wrap {
        padding: 3rem; }
        .box-item .item-4 .wrap .text {
          max-width: 17rem;
          margin-top: .5rem; }
        .box-item .item-4 .wrap .title {
          margin-top: 0; }
    .box-item .item-5 {
      width: 25%; }
    .box-item .item-6 {
      width: 25%; }
      .box-item .item-6 .wrap {
        background: transparent linear-gradient(180deg, #BD56A0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
        padding: 1.5rem 2.5rem;
        text-align: center; }
    .box-item .item.left-item {
      width: 33.33%; }
      .box-item .item.left-item .wrap {
        padding: 3rem 2.5rem; }
    .box-item .item.box-chart {
      width: 66.66%; }
  @media (max-width: 767px) {
    .box-item .item-1, .box-item .item-4 {
      width: 100%; }
    .box-item .item-2 {
      width: 40%; }
    .box-item .item-3 {
      width: 60%; }
    .box-item .item-5, .box-item .item-6 {
      width: 50%; }
    .box-item .item.left-item {
      width: 100%; }
    .box-item .item.box-chart {
      width: 100%; } }
  @media (max-width: 575px) {
    .box-item .item-1, .box-item .item-4 {
      width: 100%; }
    .box-item .item-2, .box-item .item-5 {
      width: 100%; }
    .box-item .item-3, .box-item .item-6 {
      width: 100%; } }

.popup-default.logs-popup {
  padding: 3rem 4rem !important;
  width: 100%;
  max-width: 108.9rem; }
  .popup-default.logs-popup .heading {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #452A50; }
  .popup-default.logs-popup .fancybox-close-small {
    border-radius: 50%;
    width: 2.5rem !important;
    height: 2.5rem !important;
    background: #BD55A0; }
    .popup-default.logs-popup .fancybox-close-small:before {
      width: 1rem;
      height: 1rem; }
  .popup-default.logs-popup .accordions.logs-accordion {
    border: 1px solid #E1E1E1; }
    .popup-default.logs-popup .accordions.logs-accordion .accordion-item {
      padding: 0;
      box-shadow: none; }
      .popup-default.logs-popup .accordions.logs-accordion .accordion-item:not(:last-child) {
        margin-bottom: 0;
        border-bottom: 1px solid #E1E1E1; }
      .popup-default.logs-popup .accordions.logs-accordion .accordion-item .accordion-heading {
        padding: 1rem;
        font-size: 1.5rem;
        color: #000000;
        font-weight: bold;
        border-radius: 0;
        box-shadow: none;
        background: none; }
      .popup-default.logs-popup .accordions.logs-accordion .accordion-item .accordion-content {
        display: none;
        padding: 0; }
      .popup-default.logs-popup .accordions.logs-accordion .accordion-item.active .accordion-heading {
        background: #F5E4F0; }
  .popup-default.logs-popup .table {
    background: #F7F5F5;
    width: 100%; }
    .popup-default.logs-popup .table thead {
      background: #E1E1E1; }
      .popup-default.logs-popup .table thead th {
        font-size: 1.5rem;
        color: #282828;
        text-transform: capitalize;
        font-weight: bold; }
    .popup-default.logs-popup .table th, .popup-default.logs-popup .table td {
      padding: 1rem 2.5rem; }
    .popup-default.logs-popup .table .time, .popup-default.logs-popup .table .date {
      display: inline-block; }
    .popup-default.logs-popup .table .time {
      margin-left: 2rem; }
    @media (max-width: 767px) {
      .popup-default.logs-popup .table thead {
        position: absolute;
        left: -9999px;
        overflow: hidden;
        width: 0px;
        height: 0px; }
      .popup-default.logs-popup .table tr, .popup-default.logs-popup .table td, .popup-default.logs-popup .table th, .popup-default.logs-popup .table thead, .popup-default.logs-popup .table tbody, .popup-default.logs-popup .table table {
        display: block; }
      .popup-default.logs-popup .table tbody tr {
        border-top: 0; }
        .popup-default.logs-popup .table tbody tr:not(:first-child) {
          border-top: 1px solid #DDDDDD; }
      .popup-default.logs-popup .table tbody td::before {
        display: inline-block;
        width: 19rem;
        flex: 0 0 19rem;
        min-width: 19rem;
        text-align: left;
        color: #000;
        font-weight: bold;
        text-transform: capitalize;
        content: attr(data-title); } }

.popup-default.gallery-popup {
  padding: calc(3.8rem/2) !important;
  width: 100%;
  max-width: 108.9rem;
  background: none !important; }
  .popup-default.gallery-popup .heading {
    font-size: 1.8rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #452A50;
    margin-bottom: 1.5rem; }
  .popup-default.gallery-popup .fancybox-close-small {
    border-radius: 50%;
    width: 3.8rem !important;
    height: 3.8rem !important;
    background: #BD55A0;
    opacity: 1 !important;
    top: 0 !important;
    right: 0 !important; }
    .popup-default.gallery-popup .fancybox-close-small:before {
      width: 1.5rem;
      height: 1.5rem; }
  .popup-default.gallery-popup .inner {
    padding: 3rem 1.5rem 3rem 3rem;
    background: #fff;
    border-radius: .5rem; }
    .popup-default.gallery-popup .inner .btn {
      min-width: 17.5rem;
      width: auto; }
  .popup-default.gallery-popup .wrapper {
    max-height: 50rem;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 1.7rem; }
    .popup-default.gallery-popup .wrapper::-webkit-scrollbar {
      width: .3rem; }
    .popup-default.gallery-popup .wrapper::-webkit-scrollbar-track {
      background: #F4F2F2;
      border-radius: 20px; }
    .popup-default.gallery-popup .wrapper::-webkit-scrollbar-thumb {
      background: #707070;
      border-radius: 20px; }
  .popup-default.gallery-popup .images {
    display: flex;
    flex-flow: wrap;
    margin: 0 -1.5rem; }
    .popup-default.gallery-popup .images .image {
      margin-top: 3rem;
      padding: 0 1.5rem;
      width: 33.33%; }
      @media (max-width: 575px) {
        .popup-default.gallery-popup .images .image {
          width: 50%; } }
      .popup-default.gallery-popup .images .image input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: none;
        cursor: pointer; }
      .popup-default.gallery-popup .images .image label {
        display: block; }
      .popup-default.gallery-popup .images .image .img {
        border-radius: .5rem;
        padding-top: 56.94915%;
        display: block;
        position: relative;
        box-sizing: border-box;
        border: .3rem solid transparent; }
        .popup-default.gallery-popup .images .image .img img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover; }
        .popup-default.gallery-popup .images .image .img::after {
          content: "";
          position: absolute;
          top: -1.5rem;
          right: -1.5rem;
          width: 3rem;
          height: 3rem;
          background-image: url(../img/common-icons/check.png);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
          opacity: 0; }
        .popup-default.gallery-popup .images .image .img img {
          border-radius: .5rem; }
      .popup-default.gallery-popup .images .image .title {
        font-size: 1.2rem;
        color: #707070;
        letter-spacing: .024rem;
        margin-top: 1rem;
        font-weight: normal; }
      .popup-default.gallery-popup .images .image input:checked + label .img {
        border: .3rem solid #B05C9D; }
        .popup-default.gallery-popup .images .image input:checked + label .img::after {
          opacity: 1; }

.highlighted {
  color: #707070;
  padding-left: 6rem;
  position: relative; }
  .highlighted:before {
    content: '';
    position: absolute;
    top: .8rem;
    left: 0;
    width: 5rem;
    height: .5rem; }
  .highlighted.green:before {
    background: #27E284; }

#content-container .dashboard-head .dropdown.actions-dropdown {
  min-width: 17.5rem; }
  #content-container .dashboard-head .dropdown.actions-dropdown .dropdown-select {
    padding: 0 1rem 0 1.5rem;
    height: 3.5rem;
    border-radius: .5rem;
    border: 1px solid #BD56A0;
    background: #BD56A0; }
    #content-container .dashboard-head .dropdown.actions-dropdown .dropdown-select .dropdown-selected {
      color: #fff; }
    #content-container .dashboard-head .dropdown.actions-dropdown .dropdown-select .dropdown-caret em {
      color: #fff; }
  #content-container .dashboard-head .dropdown.actions-dropdown .dropdown-list .dropdown-item {
    padding: 0rem 1.5rem;
    line-height: 3.5rem;
    text-align: left; }
    #content-container .dashboard-head .dropdown.actions-dropdown .dropdown-list .dropdown-item.selected, #content-container .dashboard-head .dropdown.actions-dropdown .dropdown-list .dropdown-item:hover {
      color: #452A50;
      background: #F5E4F0; }
  #content-container .dashboard-head .dropdown.actions-dropdown.open .dropdown-select {
    background: #BD56A0; }
    #content-container .dashboard-head .dropdown.actions-dropdown.open .dropdown-select .dropdown-selected {
      color: #fff; }
    #content-container .dashboard-head .dropdown.actions-dropdown.open .dropdown-select .dropdown-caret em {
      color: #fff; }
      #content-container .dashboard-head .dropdown.actions-dropdown.open .dropdown-select .dropdown-caret em:before {
        content: '\f143'; }

#content-container .box-item-2 {
  padding: 15px;
  background: #fff;
  border-radius: .5rem;
  box-shadow: 0px 5px 4px #272D3B05; }
  #content-container .box-item-2 .items {
    display: flex;
    align-items: stretch;
    flex-flow: wrap;
    margin: -1rem; }
    #content-container .box-item-2 .items .item {
      width: 50%;
      padding: 1rem; }
      @media (max-width: 767px) {
        #content-container .box-item-2 .items .item {
          width: 100%; } }
      #content-container .box-item-2 .items .item .wrap {
        display: block;
        border-radius: .5rem;
        box-shadow: 0px 5px 4px #272D3B05;
        padding: 8rem 2.5rem 7rem;
        background: #FCF4F9; }
      #content-container .box-item-2 .items .item .icon {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background: #F5E4F0;
        padding: 1.5rem;
        margin-bottom: 1.5rem; }
        #content-container .box-item-2 .items .item .icon img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      #content-container .box-item-2 .items .item .title {
        font-size: 2rem;
        letter-spacing: .04rem;
        color: #452A50;
        text-transform: capitalize;
        font-weight: 800;
        margin-bottom: .5rem; }
      #content-container .box-item-2 .items .item .desc {
        color: #452A50;
        font-size: 2rem;
        letter-spacing: .04rem; }

#content-container .head {
  display: flex;
  flex-flow: wrap;
  margin: -1rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }
  #content-container .head .heading {
    padding: 1rem;
    margin-bottom: 0; }
  #content-container .head .roles {
    padding: 1rem;
    display: flex;
    align-items: center; }
    #content-container .head .roles .txt {
      font-size: 1.5rem;
      text-transform: capitalize;
      color: #707070;
      margin-right: 1.5rem;
      font-weight: 300; }
    #content-container .head .roles .dropdown {
      min-width: 35rem; }
      #content-container .head .roles .dropdown-select {
        border: 1px solid #E2E2E2;
        background: #fff;
        border-radius: .5rem; }

.popup-default.cr-popup {
  border-radius: .5rem;
  box-shadow: 0px 5px 4px #272D3B05;
  width: 100%;
  max-width: 60.4rem; }
  .popup-default.cr-popup .inner {
    padding: 5rem; }
    @media (max-width: 767px) {
      .popup-default.cr-popup .inner {
        padding: 15px; } }
  .popup-default.cr-popup .txt {
    font-size: 2rem;
    color: #000000;
    text-align: center;
    letter-spacing: .04rem;
    font-weight: bold; }
  .popup-default.cr-popup .image {
    max-width: 20rem;
    margin: 0 auto;
    margin-bottom: 3rem; }
  .popup-default.cr-popup .btn-wrap {
    justify-content: center;
    gap: 0 2rem; }
    .popup-default.cr-popup .btn-wrap .btn {
      width: auto;
      min-width: 17.5rem; }

.chart-tooltip {
  box-shadow: none !important;
  outline: none;
  border: none;
  font-size: 1.5rem;
  color: #43425D;
  font-weight: normal;
  background: none; }

@media (max-width: 767px) {
  #chart {
    min-height: 30rem; } }

.activated {
  width: 1.8rem;
  height: 1.8rem; }
  .activated img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.resend-email {
  font-size: 1.2rem;
  color: #BD56A0;
  letter-spacing: .042rem;
  font-weight: normal; }

.export-btn {
  display: inline-flex;
  align-items: stretch;
  background: #452A50;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
  border-radius: .3rem;
  overflow: hidden; }
  .export-btn .icon {
    width: 3.5rem;
    height: 3.5rem;
    background: #BD56A0;
    padding: 1rem;
    flex-shrink: 0; }
    .export-btn .icon img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      filter: brightness(0) invert(1); }
  .export-btn .txt {
    padding: 0 7rem;
    line-height: 3.5rem; }

.mo-admin-donations-indiv ul {
  list-style: none; }
  .mo-admin-donations-indiv ul li {
    margin-bottom: 1.5rem;
    color: #282828;
    font-weight: 300; }

#content-container .dashboard-mo .form-wrap input, #content-container .dashboard-mo .form-wrap .select-box, #content-container .dashboard-mo .form-wrap textarea {
  background: #fff;
  border: none; }

#content-container .dashboard-mo .form-wrap select:invalid {
  color: #282828; }

#content-container .dashboard-mo .box .form-wrap input, #content-container .dashboard-mo .box .form-wrap .select-box, #content-container .dashboard-mo .box .form-wrap textarea {
  border: 1px solid #f4f2f2;
  background: none; }

#content-container .dashboard-mo textarea {
  height: 20rem; }

#content-container .table-responsive .tbl-wrap {
  padding-bottom: 0; }
  #content-container .table-responsive .tbl-wrap .table tbody tr:last-child {
    border: none; }
  @media (max-width: 400px) {
    #content-container .table-responsive .tbl-wrap {
      overflow: hidden;
      overflow-x: auto; }
      #content-container .table-responsive .tbl-wrap .table {
        min-width: 400px; }
      #content-container .table-responsive .tbl-wrap::-webkit-scrollbar {
        height: .6rem; }
      #content-container .table-responsive .tbl-wrap::-webkit-scrollbar-track {
        background: #DDDDDD; }
      #content-container .table-responsive .tbl-wrap::-webkit-scrollbar-thumb {
        background: #B7B7B7; } }

#content-container .box-item-2.caas {
  background: none;
  padding: 0;
  margin-top: 4rem;
  overflow: hidden; }
  #content-container .box-item-2.caas .items {
    margin: -2.25rem; }
    #content-container .box-item-2.caas .items .item {
      padding: 2.25rem; }
      #content-container .box-item-2.caas .items .item .wrap {
        background: #D7DAE2;
        padding: 12rem 3rem 3rem; }
        #content-container .box-item-2.caas .items .item .wrap .icon {
          background: #fff; }
        #content-container .box-item-2.caas .items .item .wrap .title {
          color: #452A50; }
        #content-container .box-item-2.caas .items .item .wrap .desc {
          color: #0A0A0A; }

#content-container .table-responsive {
  position: relative; }
  #content-container .table-responsive.overlay:after {
    content: '';
    width: 30rem;
    height: 100%;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FCFCFC00 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    z-index: 99; }
    @media (max-width: 991px) {
      #content-container .table-responsive.overlay:after {
        width: 20rem; } }
    @media (max-width: 767px) {
      #content-container .table-responsive.overlay:after {
        width: 10rem; } }
  #content-container .table-responsive.overlay.left:after {
    left: 0;
    right: unset; }

.cl-popup {
  border-radius: .5rem;
  box-shadow: 0px 5px 4px #272D3B05;
  width: 100%; }
  .cl-popup .fancybox-close-small {
    display: none !important; }
  .cl-popup .inner {
    padding: 3rem 1rem 3rem 3rem; }
  .cl-popup .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: wrap;
    margin: -1rem; }
    .cl-popup .head .popup-title {
      padding: 1rem; }
    .cl-popup .head .sort {
      padding: 1rem;
      display: inline-flex;
      align-items: center; }
      .cl-popup .head .sort .txt {
        font-size: 1.5rem;
        color: #707070;
        text-transform: capitalize;
        margin-right: 1.5rem;
        font-weight: 300; }
      @media (max-width: 575px) {
        .cl-popup .head .sort {
          display: flex;
          width: 100%; } }
  .cl-popup .tab-content {
    display: none; }
  .cl-popup .tabs-nav {
    list-style: none;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F4F2F2;
    margin-bottom: 1.5rem; }
    .cl-popup .tabs-nav li {
      cursor: pointer;
      color: #707070;
      text-transform: capitalize;
      padding-bottom: 1rem;
      border-bottom: 1px solid transparent; }
      .cl-popup .tabs-nav li:hover, .cl-popup .tabs-nav li.active {
        color: #452A50;
        border-bottom: 1px solid #452A50; }
      .cl-popup .tabs-nav li:not(:last-child) {
        margin-right: 2rem; }
  .cl-popup .table-responsive.overlay {
    position: relative; }
    .cl-popup .table-responsive.overlay:after {
      content: '';
      width: 10rem;
      height: 100%;
      background: transparent linear-gradient(180deg, #FFFFFF 0%, #FCFCFC00 100%) 0% 0%;
      position: absolute;
      top: 0;
      pointer-events: none;
      right: 0;
      z-index: 99; }
    .cl-popup .table-responsive.overlay.left:after {
      left: 0;
      right: unset; }
  .cl-popup .table-responsive .tbl-wrap {
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;
    height: 48rem; }
    @media (max-width: 767px) {
      .cl-popup .table-responsive .tbl-wrap {
        padding-bottom: 2rem; } }
    .cl-popup .table-responsive .tbl-wrap::-webkit-scrollbar {
      height: .6rem;
      width: .5rem; }
    .cl-popup .table-responsive .tbl-wrap::-webkit-scrollbar-track:vertical {
      border-radius: 20px; }
    .cl-popup .table-responsive .tbl-wrap::-webkit-scrollbar-track {
      background: #DDDDDD; }
    .cl-popup .table-responsive .tbl-wrap::-webkit-scrollbar-thumb {
      background: #B7B7B7; }
    .cl-popup .table-responsive .tbl-wrap::-webkit-scrollbar-thumb:vertical {
      background: #707070;
      border-radius: 20px; }
  .cl-popup .table-responsive .table {
    margin-right: 2rem;
    width: 100%; }
    @media (max-width: 767px) {
      .cl-popup .table-responsive .table {
        min-width: 767px; } }
    .cl-popup .table-responsive .table thead {
      border-bottom: 1px solid #F4F2F2; }
      .cl-popup .table-responsive .table thead th {
        padding: 1.5rem;
        color: #000000;
        text-transform: capitalize;
        font-weight: bold;
        padding-top: 0; }
        .cl-popup .table-responsive .table thead th:first-child {
          padding-left: 4rem; }
        .cl-popup .table-responsive .table thead th:last-child {
          padding-right: 0; }
    .cl-popup .table-responsive .table tr {
      border-bottom: 1px solid #F4F2F2; }
      .cl-popup .table-responsive .table tr td {
        color: #000000;
        padding: 1.5rem; }
        .cl-popup .table-responsive .table tr td:first-child {
          padding-left: 0; }
        .cl-popup .table-responsive .table tr td:last-child {
          padding-right: 0; }
    .cl-popup .table-responsive .table .pin {
      padding-left: 4rem;
      position: relative; }
      .cl-popup .table-responsive .table .pin::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 1rem;
        width: 1.2rem;
        height: 2.5rem;
        transform: translateY(-50%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
      .cl-popup .table-responsive .table .pin.red::before {
        background-image: url(../img/common-icons/pin-red.png); }
      .cl-popup .table-responsive .table .pin.dark::before {
        background-image: url(../img/common-icons/pin-dark.png); }
  .cl-popup .popup-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .cl-popup .popup-btn .btn:nth-child(1) {
      margin-right: 2rem; }
    @media (max-width: 575px) {
      .cl-popup .popup-btn {
        justify-content: space-between; } }

@media (max-width: 767px) {
  .submit-for-review {
    margin-left: unset !important; } }

.showcase .tabs-nav-wrap {
  box-shadow: 0 .3rem 1.2rem #0000000F; }
  .showcase .tabs-nav-wrap .tabs-nav {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0 auto;
    list-style: none; }
    .showcase .tabs-nav-wrap .tabs-nav li {
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: .12rem;
      color: #9F9F9F;
      line-height: calc(45/20);
      padding: 2rem 8rem;
      transition: all .3s;
      cursor: pointer;
      border-bottom: .4rem solid transparent; }
      .showcase .tabs-nav-wrap .tabs-nav li.active {
        border-bottom: .4rem solid #452A50;
        color: #452A50;
        font-weight: bold;
        transition: all .3s; }
      @media (max-width: 767px) {
        .showcase .tabs-nav-wrap .tabs-nav li {
          padding: 2rem 4rem; } }

.showcase .tab-content {
  display: none; }

.page-thank-you {
  margin-bottom: 10rem; }
  .page-thank-you .banner img {
    width: 100%;
    height: auto; }
  .page-thank-you .top-content {
    margin-top: -15rem;
    position: relative;
    z-index: 1;
    text-align: center;
    border-radius: .5rem;
    box-shadow: 0px .3rem 1.2rem #0000000F;
    background: #F7F5F4;
    background-image: url(../img/campaigns/CHARIS_CrossElement.png);
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: 15% auto;
    padding: 6.7rem 3rem 3rem; }
    @media (max-width: 767px) {
      .page-thank-you .top-content {
        padding: 3rem 20px;
        margin-top: -5rem; } }
    .page-thank-you .top-content .inner .main-title {
      margin-bottom: 3.5rem; }
    .page-thank-you .top-content .inner .ref-no {
      font-size: 2.4rem;
      letter-spacing: .24rem;
      color: #452A50; }
    .page-thank-you .top-content .inner p:not(:last-child) {
      margin-bottom: 2rem; }
  .page-thank-you .cart-total {
    margin-top: 5rem;
    border-radius: .5rem;
    padding-top: 1.5rem;
    background: #F7F5F4; }
    .page-thank-you .cart-total table {
      width: 100%; }
      .page-thank-you .cart-total table td {
        font-weight: 600;
        padding: .5rem 2rem; }
        .page-thank-you .cart-total table td:nth-child(2) {
          text-align: right; }
      .page-thank-you .cart-total table tr:last-child td {
        padding: 2rem;
        border-top: 1px solid #E1E1E1;
        color: #A51890;
        text-transform: uppercase; }
      .page-thank-you .cart-total table tr:nth-last-child(2) td {
        padding-bottom: 2rem; }

.email-page {
  background: #F7F7F7;
  min-height: 100vh; }
  .email-page .email-banner {
    padding: 15rem 0;
    margin-top: 2rem; }
    @media (max-width: 575px) {
      .email-page .email-banner {
        padding: 7rem 0; } }
    .email-page .email-banner .title {
      text-align: center;
      letter-spacing: 1px;
      line-height: calc(50/40);
      font-weight: bold;
      color: #fff;
      font-size: 16px; }
      @media (min-width: 375px) {
        .email-page .email-banner .title {
          font-size: calc(2.4218vw + 6.91826px); } }
      @media (min-width: 1366px) {
        .email-page .email-banner .title {
          font-size: 40px; } }
      @media (min-width: 375px) {
        .email-page .email-banner .title {
          letter-spacing: calc(0.30272vw - 0.13522px); } }
      @media (min-width: 1366px) {
        .email-page .email-banner .title {
          letter-spacing: 4px; } }
      @media (min-width: 1366px) {
        .email-page .email-banner .title {
          font-size: 40px;
          letter-spacing: 4px; } }
  .email-page .email-header {
    padding: 2rem 0;
    box-shadow: 0px 3px 12px #0000000F;
    position: relative; }
    .email-page .email-header:after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(79deg, #b05c9d 0%, #fafafa 65%);
      opacity: .75; }
    .email-page .email-header .logo {
      display: block;
      max-width: 10rem;
      margin: 0 auto;
      position: relative;
      z-index: 1; }
      .email-page .email-header .logo img {
        width: 100%;
        height: auto; }
  .email-page .inner {
    padding: 0 2rem; }
  .email-page .content {
    text-align: center;
    max-width: 80rem;
    margin: 0 auto;
    word-break: break-word;
    padding: 5rem 0; }
    .email-page .content .heading {
      text-transform: capitalize;
      font-weight: 800;
      color: #452A50;
      margin-bottom: 5rem;
      font-size: 16px; }
      @media (min-width: 375px) {
        .email-page .content .heading {
          font-size: calc(2.4218vw + 6.91826px); } }
      @media (min-width: 1366px) {
        .email-page .content .heading {
          font-size: 40px; } }
      @media (min-width: 375px) {
        .email-page .content .heading {
          letter-spacing: calc(0.30272vw - 0.13522px); } }
      @media (min-width: 1366px) {
        .email-page .content .heading {
          letter-spacing: 4px; } }
      @media (min-width: 1366px) {
        .email-page .content .heading {
          font-size: 40px;
          letter-spacing: 4px; } }
    .email-page .content .btn-wrap {
      max-width: 65%;
      margin: 0 auto; }
    .email-page .content .small-txt {
      font-size: 1.3rem; }
  .email-page .email-footer {
    padding: 5rem 0;
    background: #fff; }
    .email-page .email-footer .social-list {
      display: flex;
      margin-bottom: 3rem;
      align-items: center;
      justify-content: center; }
      .email-page .email-footer .social-list li:not(:last-child) {
        margin-right: 2rem; }
      .email-page .email-footer .social-list li a {
        display: block;
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        overflow: hidden;
        background: #FFFFFF; }
        .email-page .email-footer .social-list li a img {
          width: 100%;
          height: 100%;
          object-fit: contain; }
      .email-page .email-footer .social-list li:hover a {
        transform: scale(1.1);
        transition: .35s ease; }
    .email-page .email-footer .ft-content {
      text-align: center; }
      .email-page .email-footer .ft-content p {
        color: #282828;
        font-style: italic; }
      .email-page .email-footer .ft-content a {
        color: #BD55A0; }

.page-search .banner {
  box-shadow: 0px 3px 12px #0000000F;
  opacity: 0.75; }
  .page-search .banner img {
    width: 100%;
    height: auto; }

.page-search .top-content {
  margin-top: -15rem;
  position: relative;
  z-index: 1;
  text-align: center;
  border-radius: .5rem;
  box-shadow: 0px .3rem 1.2rem #0000000F;
  background: #F7F5F4;
  background-image: url(../img/campaigns/CHARIS_CrossElement.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: 15% auto;
  padding: 6.5rem 3rem; }
  @media (max-width: 767px) {
    .page-search .top-content {
      padding: 3rem 20px;
      margin-top: -5rem; } }
  .page-search .top-content .inner .main-title {
    margin-bottom: 3rem; }

.page-search .content-result {
  padding-top: 5rem; }
  .page-search .content-result .result {
    color: #9F9F9F;
    text-align: center;
    margin-bottom: 3rem; }
    .page-search .content-result .result .count {
      position: relative;
      padding-left: 2rem;
      margin-left: 2rem; }
      .page-search .content-result .result .count:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 7px;
        display: block;
        height: 7px;
        border-radius: 50%;
        background: #9F9F9F; }
  .page-search .content-result .items {
    margin-bottom: -40px; }
    .page-search .content-result .items .campaigns-card, .page-search .content-result .items .activities-card {
      margin-bottom: 40px;
      height: auto; }

.page-search .activities {
  position: relative; }
  .page-search .activities:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 1px;
    width: 100%;
    background: #E1E1E1;
    transform: translateX(-50%);
    max-width: 100rem; }

.form-search {
  display: flex;
  max-width: 65rem;
  margin: 0 auto;
  margin-top: 4rem;
  align-items: stretch;
  border: 1px solid #E1E1E1; }
  .form-search button {
    height: 3.5rem;
    width: 3.5rem; }
    .form-search button img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .form-search input {
    height: 3.5rem;
    background: #fff; }
  .form-search ::-webkit-input-placeholder {
    color: #E1E1E1;
    text-transform: capitalize; }

.interested-in {
  padding: 5rem 0 10rem; }
  .interested-in .slider {
    position: relative; }
  .interested-in .swiper-slide {
    box-sizing: border-box; }

.activities-card {
  box-shadow: 0px 3px 12px #0000000F;
  background: #fff; }
  .activities-card .top-wrap {
    position: relative; }
    .activities-card .top-wrap .card-tags {
      position: absolute;
      top: 2rem;
      left: 2.5rem;
      z-index: 9;
      display: flex;
      align-items: center; }
      .activities-card .top-wrap .card-tags .tag {
        font-size: 1.3rem;
        text-transform: capitalize;
        letter-spacing: .026rem;
        color: #fff;
        line-height: 2.5rem;
        padding: 0 1rem;
        border-radius: .3rem;
        display: inline-block; }
        .activities-card .top-wrap .card-tags .tag:not(:last-child) {
          margin-right: 1rem; }
        .activities-card .top-wrap .card-tags .tag-blue {
          background: #0078BF; }
        .activities-card .top-wrap .card-tags .tag-green {
          background: #33CC85; }
        .activities-card .top-wrap .card-tags .tag-red-brounded {
          background: #fff;
          border: 1px solid #CC3333;
          color: #CC3333; }
  .activities-card .box-img {
    display: block;
    padding-top: 55.49451%;
    display: block;
    position: relative;
    border-radius: .5rem;
    overflow: hidden; }
    .activities-card .box-img img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
    .activities-card .box-img img {
      transition: all .4s ease; }
  .activities-card .caption {
    padding: 3rem 2.5rem; }
    .activities-card .caption .title a {
      display: block;
      text-transform: uppercase;
      font-size: 1.8rem;
      font-weight: bold;
      color: #282828;
      letter-spacing: .054rem; }
    .activities-card .caption .desc {
      margin-top: 1.5rem; }
      .activities-card .caption .desc a {
        display: inline;
        margin-left: .5rem;
        color: #BD55A0;
        text-decoration: underline; }
    .activities-card .caption .slots {
      font-size: 1.3rem;
      text-transform: capitalize;
      letter-spacing: .026rem;
      line-height: 2.5rem;
      padding: 0 1rem;
      border-radius: .3rem;
      display: inline-block;
      background: #fff;
      border: 1px solid #452A50;
      color: #452A50;
      margin-top: 1.5rem; }
    .activities-card .caption .list {
      list-style: none;
      margin-top: 1.7rem;
      margin-left: 0;
      margin-right: 0; }
      .activities-card .caption .list li {
        display: flex; }
        .activities-card .caption .list li .icon {
          display: block;
          width: 2.5rem;
          height: 2.5rem;
          flex-shrink: 0; }
          .activities-card .caption .list li .icon img {
            width: 100%;
            height: 100%;
            object-fit: contain; }
        .activities-card .caption .list li .text {
          flex: 1;
          width: 100%;
          word-break: break-word;
          padding-left: 1.5rem; }
        .activities-card .caption .list li:not(:last-child) {
          margin-bottom: 1.5rem; }
    .activities-card .caption .skillset {
      color: #9F9F9F;
      margin-top: 1.5rem;
      font-style: italic; }
    .activities-card .caption .btn {
      width: 100%; }
  .activities-card:hover .box-img img {
    transition: all .4s ease;
    transform: scale(1.15); }

.countup-module {
  box-shadow: 0px 3px 12px #0000000F;
  border-radius: .5rem;
  background: #fff;
  position: relative;
  z-index: 1;
  margin-top: -11.5rem; }
  .countup-module .list-item {
    max-width: 90rem;
    margin: 0 auto;
    transform: translateY(-6rem);
    display: flex;
    align-items: center;
    flex-flow: wrap;
    justify-content: space-between; }
    .countup-module .list-item .item {
      text-align: center;
      width: 33.33%; }
      .countup-module .list-item .item .icon {
        box-shadow: 0px 3px 12px #0000000F;
        width: 12rem;
        height: 12rem;
        border-radius: 50%;
        display: flex;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        background: #fff;
        padding: 2.5rem;
        margin-bottom: 2rem; }
        .countup-module .list-item .item .icon img {
          width: 100%;
          height: 100%;
          object-fit: scale-down; }
      .countup-module .list-item .item .number {
        color: #000;
        font-size: 4rem;
        font-weight: 800; }
      .countup-module .list-item .item .title {
        margin-top: 1rem;
        font-size: 1.8rem;
        text-transform: capitalize; }
  @media (max-width: 575px) {
    .countup-module {
      margin-top: -10rem; }
      .countup-module .list-item {
        transform: translateY(-3.5rem); }
        .countup-module .list-item .item .icon {
          width: 7rem;
          height: 7rem;
          padding: 1.5rem; }
        .countup-module .list-item .item .number {
          font-size: 2.5rem; }
        .countup-module .list-item .item .title {
          padding: 0 1.5rem;
          min-height: 4.5rem; } }

.donate-banner {
  margin-top: 10rem;
  border-radius: .5rem;
  overflow: hidden;
  position: relative;
  padding: 5rem; }
  @media (max-width: 767px) {
    .donate-banner {
      padding: 5rem 20px; } }
  .donate-banner .title {
    text-transform: capitalize;
    color: #fff;
    font-weight: bold;
    line-height: 1.2; }
  .donate-banner .desc {
    color: #fff;
    max-width: 70rem;
    margin-top: 2rem; }

.latest-campaigns {
  padding-top: 10rem; }
  .latest-campaigns .slider {
    position: relative; }
  .latest-campaigns .swiper-slide {
    box-sizing: border-box;
    height: auto !important; }

.campaigns-card {
  position: relative;
  border-radius: .5rem;
  overflow: hidden;
  display: flex;
  height: 100%;
  padding-top: 163.73626%; }
  .campaigns-card:after {
    content: "";
    inset: 0;
    position: absolute;
    background: transparent linear-gradient(180deg, #00000000 0%, #0000001B 21%, #000000BA 61%, #000000 100%) 0% 0% no-repeat padding-box; }
  .campaigns-card .card-tags {
    position: absolute;
    top: 2rem;
    left: 2.5rem;
    z-index: 9;
    display: flex;
    align-items: center; }
    .campaigns-card .card-tags .tag {
      font-size: 1.3rem;
      text-transform: capitalize;
      letter-spacing: .026rem;
      color: #fff;
      line-height: 2.5rem;
      padding: 0 1rem;
      border-radius: .3rem;
      display: inline-block; }
      .campaigns-card .card-tags .tag:not(:last-child) {
        margin-right: 1rem; }
      .campaigns-card .card-tags .tag-blue {
        background: #0078BF; }
      .campaigns-card .card-tags .tag-green {
        background: #33CC85; }
  .campaigns-card .caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    padding: 0 2.5rem 2.5rem;
    color: #fff;
    display: flex;
    flex-direction: column; }
    .campaigns-card .caption .title {
      font-size: 1.8rem;
      color: #fff;
      letter-spacing: .054rem;
      text-transform: uppercase;
      font-weight: bold; }
    .campaigns-card .caption .desc {
      margin-top: 1.5rem; }
      .campaigns-card .caption .desc a {
        color: #fff;
        display: inline;
        word-break: break-word;
        margin-left: .5rem;
        text-decoration: underline; }
  .campaigns-card .progress-wrapper {
    padding-top: 2.8rem;
    margin-top: auto; }
    .campaigns-card .progress-wrapper .progress-txt {
      margin-bottom: 1rem; }
    .campaigns-card .progress-wrapper .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1rem; }
    .campaigns-card .progress-wrapper .progress {
      background-color: #fff;
      border-radius: 20px;
      position: relative;
      height: .5rem;
      width: 100%; }
      .campaigns-card .progress-wrapper .progress-done {
        background: #BD55A0;
        border-radius: 20px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 0;
        opacity: 0;
        transition: 1s ease 0.3s; }
  .campaigns-card .btn {
    width: 100%; }
    .campaigns-card .btn:hover {
      background: none;
      border: 1px solid #fff;
      color: #fff; }

.other-causes {
  padding-top: 10rem; }
  .other-causes .list-item {
    display: flex;
    position: relative;
    flex-flow: wrap;
    z-index: 1;
    background: #fff;
    box-shadow: 0px 3px 12px #0000000F; }
  .other-causes .item {
    width: calc(100% / 6);
    padding-top: calc(100% / 6);
    position: relative;
    text-align: center;
    transition: all .2s; }
    @media (max-width: 991px) {
      .other-causes .item {
        width: 25%;
        padding-top: 25%; } }
    @media (max-width: 575px) {
      .other-causes .item {
        width: 33.33%;
        padding-top: 33.33%; } }
    .other-causes .item .wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      inset: 0;
      padding: 2rem; }
    .other-causes .item .icon {
      height: 7rem;
      margin: 0 auto;
      transition: all .2s; }
      .other-causes .item .icon svg {
        height: 100%;
        width: auto; }
        .other-causes .item .icon svg path {
          fill: #BD55A0;
          transition: all .2s; }
    @media (max-width: 767px) {
      .other-causes .item .icon {
        height: 5rem; }
      .other-causes .item .wrap {
        padding: 1rem; } }
    @media (max-width: 575px) {
      .other-causes .item .icon {
        height: 4rem; } }
    .other-causes .item .title {
      color: #282828;
      font-weight: normal;
      margin-top: 2rem;
      min-height: 4.6rem;
      line-height: 1.6; }
    .other-causes .item:not(:last-child) {
      border-right: 1px solid #F7F5F4; }
    .other-causes .item:hover {
      background: #F7F5F4;
      transition: all .2s; }
      .other-causes .item:hover .title {
        color: #007885;
        transition: all .2s;
        font-weight: bold; }
      .other-causes .item:hover .icon {
        transform: scale(1.1);
        transition: all .2s; }
        .other-causes .item:hover .icon svg path {
          fill: #007885;
          transition: all .2s; }
  .other-causes .banner {
    margin-top: -12rem; }
    .other-causes .banner img {
      width: 100%;
      height: auto; }
    @media (max-width: 767px) {
      .other-causes .banner {
        margin-top: -5rem; } }

.get-involved {
  padding: 10rem 0; }
  .get-involved .items {
    margin-bottom: -20px; }
    .get-involved .items .blog-card {
      margin-bottom: 20px; }

.blog-card .box-img {
  display: block;
  border-radius: .5rem;
  overflow: hidden;
  padding-top: 59.35829%;
  display: block;
  position: relative; }
  .blog-card .box-img img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; }
  .blog-card .box-img img {
    transition: all .3s ease; }

.blog-card .caption {
  padding-top: 3rem; }
  .blog-card .caption .title a {
    font-size: 2.4rem;
    text-transform: capitalize;
    color: #282828;
    font-weight: bold; }
  .blog-card .caption .desc {
    margin-top: 2rem; }

.blog-card:hover .box-img img {
  transform: scale(1.15);
  transition: all .3s ease; }

.trending-campaigns {
  padding-top: 10rem; }
  .trending-campaigns .slider {
    position: relative; }
  .trending-campaigns .swiper-slide {
    box-sizing: border-box;
    height: auto !important; }

.hero-banner {
  position: relative; }
  .hero-banner .slider {
    position: relative; }
    .hero-banner .slider .swiper-slide {
      position: relative;
      display: flex;
      align-items: center; }
      .hero-banner .slider .swiper-slide:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(245, 228, 240, 0.21); }
      .hero-banner .slider .swiper-slide .content {
        padding: 0 15px;
        position: relative;
        z-index: 1;
        text-align: center;
        max-width: 706px;
        margin: 0 auto; }
        @media (max-width: 991px) {
          .hero-banner .slider .swiper-slide .content {
            max-width: 100%; } }
        @media (max-width: 767px) {
          .hero-banner .slider .swiper-slide .content {
            text-align: center; } }
        .hero-banner .slider .swiper-slide .content .heading {
          color: #fff;
          text-transform: none;
          font-weight: 900;
          opacity: 0;
          letter-spacing: .4rem;
          font-size: 4rem;
          transform: translate3d(0, 50px, 0);
          transition: .2s ease .4s; }
        .hero-banner .slider .swiper-slide .content .desc {
          color: #fff;
          font-size: 2.2rem;
          opacity: 0;
          transform: translate3d(50px, 0px, 0);
          transition: .2s ease .4s; }
        .hero-banner .slider .swiper-slide .content .btn-wrap {
          opacity: 0;
          transform: translate3d(50px, 0px, 0);
          transition: .2s ease .4s; }
      .hero-banner .slider .swiper-slide-active .content .heading {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1s; }
      .hero-banner .slider .swiper-slide-active .content .desc {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.2s; }
      .hero-banner .slider .swiper-slide-active .content .btn-wrap {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: .4s ease 1.4s; }
    .hero-banner .slider .swiper-pagination {
      left: unset !important;
      right: 3.5rem;
      bottom: unset;
      top: 50%;
      flex-direction: column;
      transform: translateY(-50%); }
      .hero-banner .slider .swiper-pagination span {
        margin: 10px 0; }
