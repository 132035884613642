// Fontawesome icons
.fa-bars:before {
	content: '\f0c9'; }
.fa-user:before {
	content: '\f007'; }
.fa-shopping-cart:before {
	content: '\f07a'; }
.fa-facebook-f:before {
	content: '\f39e'; }
.fa-youtube:before {
	content: '\f167'; }
.fa-twitter:before {
	content: '\f099'; }
.fa-google-plus-g:before {
	content: '\f0d5'; }
.fa-instagram:before {
	content: '\f16d'; }
.fa-home:before {
	content: '\f015'; }
.fa-envelope:before {
	content: '\f0e0'; }
.fa-clock:before {
	content: '\f017'; }
.fa-phone-volume:before {
	content: '\f2a0'; }
.fa-quote-left:before {
	content: '\f10e'; }
.fa-quote-right:before {
	content: '\f10d'; }
.fa-key:before {
	content: "\f084"; }
.fa-folder-open:before {
	content: "\f07c"; }
.fa-cog:before {
	content: "\f013"; }
.fa-edit:before {
	content: "\f044"; }
.fa-file:before {
	content: "\f15b"; }
.fa-chevron-circle-right:before {
	content: "\f138"; }
.fa-pencil:before {
	content: "\f303"; }
.fa-map-marker-alt:before {
	content: '\f3c5'; }
.fa-pinterest-p:before {
	content: '\f231'; }
// Material icons
.mdi-arrow-left:before {
	content: '\f04d'; }
.mdi-reload:before {
	content: '\f453'; }
.mdi-account:before {
	content: '\f004'; }
.mdi-magnify:before {
	content: '\f349'; }
.mdi-home:before {
	content: '\f2dc'; }
.mdi-close:before {
	content: '\f156'; }
.mdi-menu:before {
	content: '\f35c'; }
.mdi-phone:before {
	content: '\f3f2'; }
.mdi-email:before {
	content: '\f1ee'; }
.mdi-plus-circle:before {
	content: '\f417'; }
.mdi-alert-circle-outline:before {
	content: '\f5d6'; }
.mdi-calendar:before {
	content: '\f0ed'; }
.mdi-map-marker:before {
	content: '\f34e'; }
.mdi-chevron-double-left:before {
	content: '\f13d'; }
.mdi-chevron-double-right:before {
	content: '\f13e'; }
.mdi-cloud-download:before {
	content: '\f162'; }
.mdi-briefcase:before {
	content: '\f0d6'; }
.mdi-fax:before {
	content: '\f212'; }
.mdi-whatsapp:before {
	content: '\f5a3'; }
.mdi-map:before {
	content: '\f34d'; }
.mdi-printer:before {
	content: '\f42a'; }
.mdi-file-outline:before {
	content: '\f224'; }
.mdi-chevron-down:before {
	content: '\f140'; }
.mdi-chevron-left:before {
	content: '\f141'; }
.mdi-chevron-right:before {
	content: '\f142'; }
.mdi-link:before {
	content: '\f337'; }
.mdi-arrow-right:before {
	content: '\f054'; }
.mdi-facebook:before {
	content: '\f20c'; }
.mdi-linkedin:before {
	content: '\f33b'; }
.fa-twitter:before {
	content: '\f099'; }
.mdi-instagram:before {
	content: '\f2fe'; }
.mdi-twitter:before {
	content: '\f544'; }
.mdi-youtube:before {
	content: '\f5c3'; }
.mdi-play-circle-outline:before {
	content: '\f40d'; }
.mdi-cart:before {
	content: '\f110'; }
.mdi-check-circle-outline:before {
	content: '\f5e1'; }
// Linearicons icons
.lnr-arrow-right:before {
	content: '\e87a'; }
.lnr-arrow-left:before {
	content: '\e879'; }
.lnr-chevron-right:before {
	content: '\e876'; }
.lnr-chevron-left:before {
	content: '\e875'; }
.lnr-chevron-down:before {
	content: '\e874'; }
.lnr-chevron-up:before {
	content: '\e873'; }
.lnr-download:before {
	content: '\e865'; }
.lnr-arrow-up:before {
	content: '\e877'; }
//Remixicon
.ri-home-line:before {
	content: '\EE2B'; }
.ri-search:before {
	content: "\F0D1"; }
.ri-phone-line:before {
	content: "\EFEC"; }
.ri-home-5-fill:before {
	content: "\EE1E"; }
.ri-phone-fill:before {
	content: "\EFE9"; }
.ri-mail-fill:before {
	content: "\EEF3"; }
.ri-time-fill:before {
	content: "\F20E"; }
.ri-sound-module-line:before {
	content: "\F162"; }
.ri-subtract-line:before {
	content: "\F1AF"; }
.ri-add-fill:before {
	content: "\EA12"; }
.ri-check-line:before {
	content: "\EB7B"; }
.ri-send-plane-2-line:before {
	content: "\F0D8"; }
.ri-arrow-right-line:before {
	content: "\EA6C"; }
.ri-shopping-bag-line:before {
	content: "\F118"; }
.ri-more-fill:before {
	content: "\EF78"; }
.ri-home-gear-fill:before {
	content: "\EE27"; }
.ri-phone-fill:before {
	content: "\EFE9"; }
.ri-menu-2-line:before {
	content: "\EF32"; }
.ri-calendar-line:before {
	content: "\EB27"; }
.ri-add-line:before {
	content: "\EA13"; }
.ri-subtract-fill:before {
	content: "\F1AE"; }
.ri-grid-fill:before {
	content: "\EDDE"; }
.ri-arrow-down-s-fill:before {
	content: "\EA4D"; }
.mdi-menu-down:before {
	content: '\f35d'; }
.ri-arrow-up-s-fill:before {
	content: "\EA77"; }
.ri-menu-add-line:before {
	content: "\EF3A"; }
.ri-eye-line:before {
	content: "\ECB5"; }
.ri-eye-off-line:before {
	content: "\ECB7"; }
