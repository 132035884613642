//main-title
.main-title {
	font-size: 4rem;
	letter-spacing: .4rem;
	text-transform: capitalize;
	font-weight: bold;
	color: $dark;
	margin-bottom: 5rem;
	@extend .ff-2;
	@media (max-width: 767px) {
		font-size: 3rem;
		letter-spacing: .3rem; } }
.text-center {
	text-align: center; }
.text-left {
	text-align: left; }
.text-right {
	text-align: right; }
.color-red {
	color: $red!important; }
.btn-position {
	.swiper-next, .swiper-prev {
		transform: none; } }
.mb-15 {
	margin-bottom: 1.5rem!important; }
.m-0 {
	margin: 0!important; }
.text-uppercase {
	text-transform: uppercase; }
a {
	outline: none!important; }
.text-center {
	text-align: center!important; }
.color-black {
	color: $black!important; }
.color-white {
	color: #fff!important; }
.color-purple-bd {
	color: #BD56A0!important; }
.color-purple-45 {
	color: #452A50!important; }
.color-dark-70 {
	color: #707070!important; }
.d-inline-flex {
	display: inline-flex; }
.border-none {
	border: none!important; }
.d-flex {
	display: flex; }
.text-align-left {
	text-align: left!important; }
.text-align-right {
	text-align: right!important; }
.italic {
	font-style: italic!important; }
.flex-end {
	justify-content: flex-end!important; }
.flex-start {
	justify-content: flex-start!important; }
.slick-disabled {
	pointer-events: none;
	cursor: grab; }
.fz-13 {
	font-size: 1.3rem; }
.d-block {
	display: block; }
.p-3 {
	padding: 3rem!important; }
.m-l-auto {
	margin-left: auto!important; }
.text-uppercase {
	text-transform: uppercase!important; }
.text-capitalize {
	text-transform: capitalize!important; }
.ls-0 {
	letter-spacing: 0!important; }
.w-100 {
	width: 100%!important; }
.mw-100 {
	max-width: 100%!important; }
.p-0 {
	padding: 0!important; }
.btn {
	font-size: 1.5rem;
	letter-spacing: .03rem;
	height: 3.6rem;
	line-height: 3.6rem;
	box-sizing: border-box;
	padding: 0 2.5rem;
	display: inline-flex;
	min-width: 13.5rem;
	text-align: center;
	justify-content: center;
	align-items: center;
	border-radius: .3rem;
	box-shadow: none;
	text-transform: uppercase;
	border: none;
	margin-top: 2rem;
	transition: all .2s;
	.icon {
		width: 1.6rem;
		flex-shrink: 0;
		margin-right: 1rem;
		img {
			width: 100%;
			height: auto;
			filter: brightness(0) invert(1); } }
	&-white {
		background: #fff;
		color: $purple;
		&--brounded {
			border: 1px solid #fff;
			color: #fff;
			background: none;
			&:hover {
				background: #fff;
				color: $purple;
				transition: all .3s; } } }
	&-purple {
		background: $purple;
		color: #fff;
		&--brounded {
			border: 1px solid $purple;
			color: $purple;
			background: none;
			&:hover {
				background: $purple;
				color: #fff;
				transition: all .3s; } } }
	&-purple-45 {
		background: #452A50;
		color: #fff;
		&--brounded {
			border: 1px solid #452A50;
			color: #452A50;
			background: none;
			&:hover {
				background: #452A50;
				color: #fff;
				transition: all .3s; } } }

	&-blue {
		background: $blue;
		color: #fff;
		&--brounded {
			border: 1px solid $blue;
			color: $blue;
			background: none;
			&:hover {
				background: $blue;
				color: #fff;
				transition: all .3s; } } }
	&-green {
		background: $green;
		color: #fff;
		&--brounded {
			border: 1px solid $green;
			color: $green;
			background: none;
			&:hover {
				background: $green;
				color: #fff;
				transition: all .3s; } } }
	&-dark-light {
		background: #9F9F9F;
		color: #fff;
		&--brounded {
			border: 1px solid #9F9F9F;
			color: #9F9F9F;
			background: none;
			&:hover {
				background: #9F9F9F;
				color: #fff;
				transition: all .3s; } } }
	&-dark-70 {
		background: #707070;
		color: #fff;
		&--brounded {
			border: 1px solid #707070;
			color: #707070;
			background: none;
			&:hover {
				background: #707070;
				color: #fff;
				transition: all .3s; } } }
	&-red {
		background: #D33B27;
		color: #fff;
		&--brounded {
			border: 1px solid #D33B27;
			color: #D33B27;
			background: none;
			&:hover {
				background: #D33B27;
				color: #fff;
				transition: all .3s; } } }

	&:hover,&.active {
		box-shadow: .1rem .5rem 1rem rgba(0,0,0,0.3); } }
.text-center {
	text-align: center; }
//swiper
//swiper-pagination
.swiper-pagination {
	position: absolute;
	left: 50%!important;
	transform: translateX(-50%);
	display: flex;
	bottom: 7rem;
	z-index: 9;
	justify-content: center;
	span {
		width: 10px;
		height: 10px;
		border: none;
		opacity: 1;
		border-radius: 5px;
		margin: 0 5px;
		background: #F5E5F0;
		position: static;
		outline: none;
		transition: all .2s;
		&.swiper-pagination-bullet-active {
			background: $purple;
			transition: all .2s; } } }
.slick-dots {
	list-style: none;
	display: flex;
	align-items: center;
	margin-top: 1rem;
	li {
		width: 10px;
		height: 10px;
		border: none;
		opacity: 1;
		border-radius: 5px;
		margin: 0 5px;
		background: #F5E5F0;
		position: static;
		outline: none;
		transition: all .2s;
		&.slick-active {
			background: $purple;
			transition: all .2s; } }
	button {
		display: none; } }

//swiper-nav
.swiper-prev,.swiper-next {
	z-index: 9;
	outline: none;
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 2.5rem;
		height: auto;
		@media (max-width: 991px) {
			width: 2rem; } } }
.swiper-prev {
	left: -5.6rem; }
.swiper-next {
	right: -5.6rem; }
@media (max-width: 1199.98px) {
	.swiper-prev {
		left: 15px; }
	.swiper-next {
		right: 15px; } }
.swiper-button-disabled {
	opacity: .4; }

//amount
.amount {
	display: inline-flex;
	align-items: stretch;
	border: 1px solid rgba(#04244E,.1);
	overflow: hidden;
	background: #fff;
	.minus,.plus {
		width: 5rem;
		height: 5rem;
		flex-shrink: 0;
		background: $blue;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 1.8rem; } }
	.input {
		text-align: center;
		border: none;
		width: 7.8rem;
		outline: none;
		background: none;
		color: #000000;
		font-size: 2rem;
		height: 5rem;
		padding: 0; }
	::-webkit-outer-spin-button,::-webkit-inner-spin-button {
		display: none; } }
//pagination
.pagi {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style: none;
	&-item {
		font-size: 1.8rem;
		color: #02234F;
		cursor: pointer;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0!important;
		cursor: pointer;
		transition: .3s;
		&:not(:last-child) {
			margin-right: 1rem; } }
	&-item.is-disabled {
		cursor: not-allowed; }
	&-item:hover,
	&-item.is-active {
		color: #88D7B0;
		text-stroke: 1.5px currentColor;
		-webkit-text-stroke: 1.5px currentColor;
		transition: .3s; }
	img {
		width: 5px; } }
//custom select
.dropdown {
	min-width: 23.8rem;
	position: relative;
	width: fit-content;
	background: #F5F5F5;
	&-caret {
		position: relative;
		margin-left: 5px;
		width: 3.6rem;
		height: 3.6rem;
		background: #E1E1E1;
		display: flex;
		justify-content: center;
		align-items: center;
		img {
			width: 1.5rem;
			height: auto; } }
	&-select {
		display: flex;
		align-items: center;
		cursor: pointer;
		justify-content: space-between;
		background: none;
		height: 3.6rem;
		line-height: 3.6rem;
		padding-left: 1.5rem;
		height: 100%;
		.dropdown-selected {
			color: $dark;
			font-weight: 300; } }

	&-list {
		position: absolute;
		width: 100%;
		left: 0;
		top: calc(100% + .5rem);
		z-index: 9;
		border: none;
		background: #fff;
		display: none;
		padding: 0;
		list-style: none;
		border: 1px solid #E2E2E2;
		border-radius: .5rem; }
	&-item {
		z-index: 2;
		font-weight: 300;
		color: $dark;
		line-height: 4.8rem;
		padding: 0 1.5rem;
		text-transform: capitalize;
		cursor: pointer;
		&.selected,&:hover {
			background: #F5E4F0; } }
	&.open {
		.dropdown-select {}
		.dropdown-caret {}
		.dropdown-list {
			z-index: 9999!important;
			.dropdown-item {
				display: block; } } } }
//checkbox-custom
.custom-checkbox,.custom-radio {
	cursor: pointer;
	label {
		font-size: 1.8rem;
		color: $dark;
		line-height: 2.5rem;
		text-transform: capitalize; }
	input {
		padding: 0;
		height: initial;
		width: initial;
		margin-bottom: 0;
		display: none;
		cursor: pointer; }
	&.style-1 {
		label {
			position: relative;
			cursor: pointer;
			padding-left: 3.5rem;
			&:before {
				content: '';
				-webkit-appearance: none;
				width: 2rem;
				height: 2rem;
				background: none;
				border: 1px solid #E1E1E1;
				display: inline-block;
				border-radius: 0.2rem;
				position: absolute;
				top: .2rem;
				left: 0;
				vertical-align: middle;
				cursor: pointer;
				margin-right: 5px; } }
		input[type="radio"]:checked + label {
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: .65rem;
				left: .7rem;
				width: .5rem;
				height: .8rem;
				border: solid #000000;
				border-width: 0 1.5px 1.5px 0;
				transform: rotate(45deg); } }
		input[type="checkbox"]:checked + label {
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: .65rem;
				left: .7rem;
				width: .5rem;
				height: .8rem;
				border: solid #000000;
				border-width: 0 1.5px 1.5px 0;
				transform: rotate(45deg); } } }
	&.style-2 {
		label {
			position: relative;
			cursor: pointer;
			padding-left: 3.5rem;
			&:before {
				content: "";
				width: 2rem;
				height: 2rem;
				border-radius: 50%;
				background: none;
				border: 1px solid #E1E1E1;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				cursor: pointer; } }
		input[type="radio"]:focus + label {
			&:before {
				border: 1px solid #707070;
				background: #707070;
				box-shadow: inset 0 0 0 .2rem #fff; } }
		input[type="radio"]:checked + label {
			&:before {
				border: 1px solid #707070;
				background: #707070;
				box-shadow: inset 0 0 0 .2rem #fff; } }
		input[type="checkbox"]:focus + label {
			&:before {
				border: 1px solid #707070;
				background: #707070;
				box-shadow: inset 0 0 0 .2rem #fff; } }
		input[type="checkbox"]:checked + label {
			&:before {
				border: 1px solid #707070;
				background: #707070;
				box-shadow: inset 0 0 0 .2rem #fff; } } } }
input,textarea {
	outline: none;
	font-size: 1.5rem;
	color: $dark;
	border-radius: .3rem;
	border: none;
	font-weight: 300;
	background: #F5F5F5;
	box-shadow: none;
	height: 3.6rem;
	width: 100%;
	padding: 0rem 1.5rem; }
textarea {
	resize: none;
	padding: 1.5rem;
	height: 13.3rem; }
.select-box {
	overflow: hidden;
	border: none;
	background: #F5F5F5;
	border: none;
	height: 3.6rem;
	border-radius: .3rem;
	position: relative;
	&:before {
		font-family: Linearicons-Free;
		content: "\e874";
		position: absolute;
		top: 50%;
		right: .5rem;
		transform: translate(-50%,-50%);
		font-size: 1.2rem;
		color: #707070; } }
select {
	text-transform: capitalize;
	outline: none;
	width: 100%;
	outline: none;
	line-height: 3.6rem;
	padding: 0 1.5rem;
	font-weight: 300;
	font-size: 1.5rem;
	color: $dark;
	background: none;
	border: none;
	box-shadow: none;
	outline: none;
	-webkit-appearance: none; }
select:invalid {
	color: #707070; }
select {
	option {
		color: $dark; } }
button {
	outline: none!important;
	border: none;
	background: none;
	box-shadow: none; }
::-webkit-input-placeholder {
	color: $dark;
	text-transform: none; }
@media screen and (min-width: 768px) {
	.form-row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
		grid-gap: 0 1.7rem; } }
.form-group {
	margin-bottom: 1.5rem;
	.input {
		position: relative; }
	label {
		font-size: 1.5rem;
		color: #707070;
		font-weight: 300;
		margin-bottom: .5rem;
		span {
			color: red; } } }
.range-price-slide {
	.slider-box {
		width: 100%; }
	label {
		border: none;
		display: inline-block;
		vertical-align: top; }
	input {
		border: none;
		outline: none;
		width: 100%;
		background: none;
		font-size: 1.4rem;
		border: none!important;
		color: rgba(#020C22,.8);
		margin-top: 1rem;
		width: fit-content;
		padding: 0;
		height: auto;
		line-height: 1;
		text-align: left; }
	.slider {
		background: rgba(#020C22,.2);
		margin-top: 2rem;
		border: none;
		height: .4rem;
		border-radius: 0;
		margin-left: 1rem;
		.ui-slider-range {
			background: #020C22;
			border: none; }
		.ui-slider-handle.ui-corner-all.ui-state-default {
			border-radius: 50%;
			background: #ED1B24;
			border: none;
			outline: none;
			top: 50%;
			height: 1rem;
			width: 1rem;
			transform: translateY(-50%); } } }
.ck-editor__editable_inline {
	min-height: 30rem; }
