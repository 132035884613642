
.page-about-2
  padding: 10rem 0
  position: relative
  &:after
    content: ''
    position: absolute
    width: 100%
    height: 50%
    bottom: 0
    z-index: -1
    left: 0
    background-repeat: no-repeat
    background-image: url(../img/about/WhySupportCHARIS_BgBanner.jpg)
    background-position: center
    background-size: cover
  .content
    box-shadow: 0px .3rem 1.2rem #00000029
    border: 1px solid #F4F4F4
    padding: 4rem 6rem
    background: #fff
    p
      &:not(:last-child)
        margin-bottom: 2rem
    @media (max-width: 767px)
      padding: 4rem 20px
.page-about-1
  .box-content
    p
      &:not(:last-child)
        margin-bottom: 2rem
    .btn
      width: 100%
    @media (max-width: 767px)
      padding-top: 5rem
  .video-gallery
    .video
      display: block
      position: relative
      border-radius: .5rem
      overflow: hidden
      .video-btn
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        img
          width: 100%
          height: 100%
          object-fit: contain
    .my-swiper
      .image
        +img(360/645)
      .video
        .video-btn
          width: 7rem
          height: 7rem
    .my-swiper2
      margin-top: 20px
      @media (max-width: 575px)
        margin-top: 15px
      .image
        +img(124/222)
      .video
        .video-btn
          width: 4rem
          height: 4rem
.page-intro-3
  .items
    margin-bottom: -40px
    .blog-card
      margin-bottom: 40px
