
.page-pre-checkout
  .inner
    display: flex
    flex-flow: wrap
    justify-content: space-between
    align-items: flex-start
  .or
    font-size: 2.5rem
    text-transform: uppercase
    font-weight: bold
    background: #fff
    position: relative
    &:after
      content: ''
      position: absolute
      background: #E1E1E1
    &:before
      content: ''
      position: absolute
      background: #E1E1E1
    @media (min-width: 992px)
      padding: 31.5rem 0
      &:after
        width: 1px
        height: 29rem
        top: 0
        left: 50%
        transform: translateX(-50%)
      &:before
        width: 1px
        height: 29rem
        bottom: 0
        left: 50%
        transform: translateX(-50%)
  @media (max-width: 991px)
    .inner
      flex-direction: column
      align-items: center
      .or
        width: 100%
        text-align: center
        &:after
          height: 1px
          width: 40%
          top: 50%
          left: 0
          transform: translateY(-50%)
        &:before
          height: 1px
          width: 40%
          top: 50%
          right: 0
          transform: translateY(-50%)
  .proceed-box,.account-box
    margin: 10rem 0
.proceed-box
  max-width: 49rem
  width: 100%
  box-shadow: 0px 3px 12px #0000000A
  border: 1px solid #F4F4F4
  .head
    padding: 2.8rem
    text-align: center
    color: #fff
    font-weight: 600
    font-size: 1.8rem
    background: #452A50

  .body
    padding: 2rem 4rem 4rem
    background: #fff
  ul
    padding: 0
    margin: 0
    margin-bottom: 1.5rem
    list-style-position: inside
  p
    &:not(:last-child)
      margin-bottom: 1.5rem
