.footer
  padding: 4rem 3rem
  position: relative
  background: #F7F5F4
  ul
    list-style: none
  .footer-wrapper
    display: flex
  .logo-footer
    display: block
    width: 16rem
  .box-left
    flex-shrink: 0
    width: 16rem
  .box-right
    width: 100%
    flex: 1
    padding-left: 5.3rem
  .text
    margin-bottom: 2rem
  .social-list
    display: flex
    margin-bottom: 2rem
    align-items: center
    li
      &:not(:last-child)
        margin-right: 2rem
      a
        display: block
        width: 4rem
        height: 4rem
        border-radius: 50%
        overflow: hidden
        background: #FFFFFF
        img
          width: 100%
          height: 100%
          object-fit: contain
      &:hover
        a
          transform: scale(1.1)
          transition: .35s ease
  .bottom
    display: flex
    justify-content: center
    margin: -1rem -2rem
    flex-flow: wrap
    padding-top: 4rem
    .copyright
      padding: 1rem 2rem
    ul
      padding: 1rem 2rem
      display: flex
      align-items: center
      li
        &:not(:last-child)
          margin-right: 4rem
        a
          text-decoration: underline
          color: $dark
  @media (max-width: 575px)
    .footer-wrapper
      flex-flow: wrap

    .box-left
      margin-bottom: 4rem
      width: 100%
      .logo-footer
        margin: 0 auto
    .social-list
      justify-content: center
    .box-right
      padding-left: 0
      text-align: center
