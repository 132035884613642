.other-causes
  padding-top: 10rem
  .list-item
    display: flex
    position: relative
    flex-flow: wrap
    z-index: 1
    background: #fff
    box-shadow: 0px 3px 12px #0000000F
  .item
    width: calc(100% / 6)
    padding-top: calc(100% / 6)
    position: relative
    text-align: center
    transition: all .2s
    @media (max-width: 991px)
      width: 25%
      padding-top: 25%
    @media (max-width: 575px)
      width: 33.33%
      padding-top: 33.33%
    .wrap
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-end
      position: absolute
      inset: 0
      padding: 2rem

    .icon
      height: 7rem
      margin: 0 auto
      transition: all .2s
      svg
        height: 100%
        width: auto
        path
          fill: $purple
          transition: all .2s
    @media (max-width: 767px)
      .icon
        height: 5rem
      .wrap
        padding: 1rem
    @media (max-width: 575px)
      .icon
        height: 4rem
    .title
      color: $dark
      font-weight: normal
      margin-top: 2rem
      min-height: 4.6rem
      line-height: 1.6
    &:not(:last-child)
      border-right: 1px solid #F7F5F4
    &:hover
      background: #F7F5F4
      transition: all .2s
      .title
        color: $green
        transition: all .2s
        font-weight: bold
      .icon
        transform: scale(1.1)
        transition: all .2s
        svg
          path
            fill: $green
            transition: all .2s
  .banner
    margin-top: -12rem
    img
      width: 100%
      height: auto
    @media (max-width: 767px)
      margin-top: -5rem
.get-involved
  padding: 10rem 0
  .items
    margin-bottom: -20px
    .blog-card
      margin-bottom: 20px
.blog-card
  .box-img
    display: block
    border-radius: .5rem
    overflow: hidden
    +img(333/561)
    img
      transition: all .3s ease
  .caption
    padding-top: 3rem
    .title
      a
        font-size: 2.4rem
        text-transform: capitalize
        color: #282828
        @extend .ff-2
        font-weight: bold
    .desc
      margin-top: 2rem
  &:hover
    .box-img
      img
        transform: scale(1.15)
        transition: all .3s ease
