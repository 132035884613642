[data-aos="box"] {
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0);
  &.aos-animate {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    transition: 1s cubic-bezier(0.77, 0, 0.18, 1); } }
[data-aos="box-reverse"] {
  -webkit-clip-path: inset(0 0 0 100%);
  clip-path: inset(0 0 0 100%);
  &.aos-animate {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    transition: 1s cubic-bezier(0.77, 0, 0.18, 1); } }
[data-aos="fadeup"] {
  transform: translateY(2rem);
  &.aos-animate {
    transform: translateY(0rem); } }

@keyframes rotate {
  0% {
    transform: rotate(0); }
  50% {
    z-index: 2; }
  100% {
    transform: rotate(360deg);
    z-index: 0; } }
@keyframes rotate2 {
  0% {
    transform: rotate(0); }
  50% {}
  100% {
    transform: rotate(-360deg); } }
