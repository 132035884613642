
.hidden-mobile {
	@media (max-width: 767px) {
		display: none; } }
.hidden-desktop {
	@media (min-width: 768px) {
		display: none; } }
//- spacing
//-fake bold
.flex {
	display: flex; }
.align-center {
	align-items: center;
	display: flex; }
.flex-end {
	justify-content: flex-end; }
.flex-start {
	justify-content: flex-start; }
.justify-center {
	justify-content: center;
	display: flex; }
.row-reserve {
	flex-direction: row-reverse!important; }
.columns-reserve {
	flex-direction: column-reverse!important; }
.img-white {
	filter: brightness(0) invert(1); }
.fake-bold {
	text-stroke: 1px currentColor;
	-webkit-text-stroke: 1px currentColor; }
.center {
	text-align: center; }
.w-100 {
	width: 100%; }
.mw-100 {
	max-width: 100%; }

//-mixin spacing
$spacer: 1rem;
$spacer-x: $spacer;
$spacer-y: $spacer;

$spacers: ();

@for $i from 0 through 200 {
	$spacers: map-merge($spacers, ($i: (x: $spacer-x * $i,y: $spacer-y * $i))); }

@each $prop, $abbrev in (margin: m, padding: p) {
	@each $size, $lengths in $spacers {
		$length-x: map-get($lengths, x);
		$length-y: map-get($lengths, y);

		.#{$abbrev}-a-#{$size} {
			#{$prop}: $length-y $length-x !important; }
		.#{$abbrev}-t-#{$size} {
			#{$prop}-top: $length-y !important; }
		.#{$abbrev}-r-#{$size} {
			#{$prop}-right: $length-x !important; }
		.#{$abbrev}-b-#{$size} {
			#{$prop}-bottom: $length-y !important; }
		.#{$abbrev}-l-#{$size} {
			#{$prop}-left: $length-x !important; }

		.#{$abbrev}-x-#{$size} {
			#{$prop}-right: $length-x !important;
			#{$prop}-left: $length-x !important; }

		.#{$abbrev}-y-#{$size} {
			#{$prop}-top: $length-y !important;
			#{$prop}-bottom: $length-y !important; } } }

.triangle {
	margin: 5px;
	display: inline-block;
	width: 0;
	height: 0;
	&--top {
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-bottom: 1rem solid #000; }

	&--bottom {
		border-left: 1rem solid transparent;
		border-right: 1rem solid transparent;
		border-top: 1rem solid #000; }

	&--right {
		border-top: 1rem solid transparent;
		border-bottom: 1rem solid transparent;
		border-left: 1rem solid #000; }

	&--left {
		border-top: 1rem solid transparent;
		border-bottom: 1rem solid transparent;
		border-right: 1rem solid #000; } }
.flex-ui {
	$spacing: 15px;
	$col: 3;
	display: flex;
	flex-flow: wrap;
	margin-left: calc( -1* #{$spacing});
	margin-bottom: calc( -1* #{$spacing});
	.flex-item {
		margin-left: $spacing;
		margin-bottom: $spacing;
		width: calc( 100% / #{$col} - #{$spacing}); } }
.font-weight-400 {
	font-weight: normal!important; }
.font-weight-300 {
	font-weight: 300!important; }
.font-weight-500 {
	font-weight: 500!important; }
.font-weight-600 {
	font-weight: 600!important; }
.font-weight-700 {
	font-weight: 700!important; }
.font-weight-800 {
	font-weight: 800!important; }
.font-weight-900 {
	font-weight: 900!important; }
.font-weight-200 {
	font-weight: 200!important; }
.font-weight-100 {
	font-weight: 100!important; }
