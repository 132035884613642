.activities-indiv
	.top-content
		margin-top: -15rem
		position: relative
		z-index: 1
		border-radius: .5rem
		box-shadow: 0px .3rem 1.2rem #0000000F
		background: #F7F5F4
		background-image: url(../img/campaigns/CHARIS_CrossElement.png)
		background-repeat: no-repeat
		background-position: 100% 0
		background-size: 15% auto
		padding: 3rem 4rem
		display: flex
		align-items: center
		flex-flow: wrap
		.box-left
			width: calc( 50% + 5rem )
		.box-right
			width: calc( 50% - 5rem )
		@media (max-width: 767px)
			padding: 3rem 20px
			margin-top: -5rem
			.box-left,.box-right
				width: 100%
			.box-right
				padding-top: 3rem
		.main-title
			@extend .ff-2
			margin-top: 1.4rem
			margin-bottom: 0
			max-width: 54.3rem
		.desc
			margin-top: 2rem
		.tag
			&-red-brounded
				font-weight: bold
		.slots-wrapper
			max-width: 40.2rem
			.slots-txt
				text-align: left
				color: $purple
				margin-bottom: 1rem
				text-transform: uppercase
				font-weight: bold
				.total

			.done
				margin-top: 1rem
				color: #707070
				font-weight: bold
			.slots
				background-color: #E1E1E1
				border-radius: 20px
				position: relative
				height: .5rem
				width: 100%
				&-done
					background: $purple
					border-radius: 20px
					color: #fff
					display: flex
					align-items: center
					justify-content: center
					height: 100%
					width: 0
					opacity: 0
					transition: 1s ease 0.3s
		.list
			padding-top: 3.8rem
			list-style: none
			display: flex
			flex-flow: wrap
			margin: -.5rem -1rem
			li
				width: 50%
				padding: .5rem 1rem
				display: flex
				align-items: center
				.content
					margin-left: .5rem
				.title
					color: #9F9F9F
					text-transform: capitalize
				.imgs
					display: flex
					.img
						flex-shrink: 0
						height: 2rem
						img
							height: 100%
							width: auto
						&:not(:last-child)
							margin-right: 1.5rem
				@media (max-width: 575px)
					width: 100%
		.social-share
			margin-top: 3rem
			display: flex
			.txt
				margin-right: 1.5rem
.social-list
	display: flex
	list-style: none
	li
		flex-shrink: 0
		a
			display: inline-block
			width: 2.5rem
			height: 2.5rem
			img
				width: 100%
				object-fit: contain
				height: 100%
		&:not(:last-child)
			margin-right: 1.5rem
.border-none
	border: none!important
.activities-indiv-2
	padding: 10rem 0 7.5rem
	.inner
		display: flex
		align-items: center
		flex-flow: wrap
		.box-left
			width: calc( 50% + 5rem )
		.box-right
			width: calc( 50% - 5rem )
		@media (max-width: 767px)
			.box-left,.box-right
				width: 100%
			.box-right
				padding-top: 3rem
		.slider
			position: relative
			.swiper-pagination
				bottom: -2.5rem
				left: 0!important
				transform: none
		.box-left
			.video-box
				display: block
				position: relative
				.image
					+img(360/645)
					img
						border-radius: .5rem
				&:after
					position: absolute
					content: ''
					background: rgba(#000000,.2)
					inset: 0
					z-index: 1
				.play
					position: absolute
					top: 50%
					left: 50%
					width: 6rem
					height: 6rem
					z-index: 9
					transform: translate(-50%,-50%)
					img
						width: 100%
						height: 100%
						object-fit: contain
	.box-right
		.box-content
			@media (min-width: 768px)
				padding-left: 5rem
		.list
			list-style: none
			margin-top: 2.5rem
			display: flex
			flex-flow: wrap
			justify-content: flex-end
			margin-bottom: -2.5rem
			li
				display: flex
				width: 50%
				margin-bottom: 2.5rem
				.icon
					display: block
					width: 2.5rem
					height: 2.5rem
					flex-shrink: 0
					img
						width: 100%
						height: 100%
						object-fit: contain
				.text
					flex: 1
					width: 100%
					word-break: break-word
					padding-left: 1.5rem
		.registration-ends
			margin-top: 2.5rem
			color: #707070
			font-style: italic
		.bottom-wrap
			padding-top: 2.5rem
			border-top: 1px dashed #E1E1E1
			margin-top: 1rem
			.heading
				color: #707070
				margin-bottom: 1.5rem
				font-weight: bold
			ul
				list-style: none
				li
					color: #707070
					&:not(:last-child)
						margin-bottom: 1.5rem
			.btn
				width: 100%
.tags
	display: flex
	align-items: center
	.tag
		&:not(:last-child)
			margin-right: 2rem
.tag
	font-size: 1.3rem
	text-transform: capitalize
	letter-spacing: .026rem
	color: #fff
	line-height: 2.5rem
	padding: 0 1rem
	border-radius: .3rem
	display: inline-block
	&-blue
		background: $blue
	&-green
		background: #33CC85
	&-red-brounded
		background: #fff
		border: 1px solid #CC3333
		color: #CC3333!important
	&-red
		background: #CC3333
.login-popup
	position: fixed
	z-index: 999
	top: 0
	left: 0
	width: 100%
	height: 100%
	.popup-inner
		position: absolute
		inset: 0
		overflow: auto
		text-align: center
		padding: 20px
		&:before
			vertical-align: middle
			display: inline-block
			height: 100%
			content: ''
			margin-right: -5px
	.popup-content
		position: relative
		background: #fff
		max-width: 49rem
		width: 100%
		text-align: left
		display: inline-block
		vertical-align: middle
	.popup-overlay
		content: ''
		position: absolute
		cursor: pointer
		top: 0
		left: 0
		z-index: 9999
		width: 100%
		height: 100%
		background: rgba(#282828,.66)
	.account-box
		border: none
		max-width: 49rem
		width: 100%
		position: relative
		z-index: 9999
		.head
			position: relative
			.popup-close
				position: absolute
				cursor: pointer
				top: 50%
				right: 2rem
				transform: translateY(-50%)
				width: 1.5rem
				img
					width: 100%
					height: auto
					filter: brightness(0) invert(1)
	.buttons
		justify-content: center
		display: flex
	.btn
		min-width: 13.6rem
	.fancybox-close-small
		top: 3.5rem!important
	.custom-checkbox
		label
			&:before
				background: #F5F5F5
