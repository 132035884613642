$padding-horizontal: 20px;
$padding-vertical: 20px;
$container-width: 1206;
$full-width: 1366;
.col,
[class*="col-"] {
	width: 100%;
	padding-right: $padding-horizontal;
	padding-left: $padding-horizontal; }
.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -1 * $padding-horizontal;
	margin-left: -1 * $padding-horizontal; }
.container-fluid {
	width: 100%;
	padding-right: $padding-horizontal;
	padding-left: $padding-horizontal;
	margin-right: auto;
	margin-left: auto; }

.container {
	width: 100%;
	padding-right: $padding-horizontal;
	padding-left: $padding-horizontal;
	margin-right: auto;
	margin-left: auto;
	&-13 {
		width: 100%;
		padding-right: $padding-horizontal;
		padding-left: $padding-horizontal;
		margin-right: auto;
		margin-left: auto; } }
@media (min-width: 1200px) {
	.container {
		max-width: calc(#{$container-width} / #{$full-width} * 100%); }
	.container-13 {
		max-width: calc(1186 / #{$full-width} * 100%); } }
.no-gutters {
	margin-right: 0;
	margin-left: 0;
	& > .col,
	& > [class*="col-"] {
		padding-right: 0;
		padding-left: 0; } }
@media (max-width: 767px) {
	.row {
		margin-right: -10px;
		margin-left: -10px;
		& > .col,
		& > [class*="col-"] {
			padding-right: 10px;
			padding-left: 10px; } } }
