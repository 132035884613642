
.email-page
  background: #F7F7F7
  min-height: 100vh
  .email-banner
    padding: 15rem 0
    margin-top: 2rem
    @media (max-width: 575px)
      padding: 7rem 0
    .title
      text-align: center
      letter-spacing: 1px
      @extend .ff-2
      line-height: calc(50/40)
      font-weight: bold
      color: #fff
      font-size: 16px
      $map: (375px: 16px,1366px: 40px)
      +fz('font-size', $map)
      $ls: (375px: 1px,1366px: 4px)
      +fz('letter-spacing', $ls)
      @media (min-width: 1366px)
        font-size: 40px
        letter-spacing: 4px
  .email-header
    padding: 2rem 0
    box-shadow: 0px 3px 12px #0000000F
    position: relative
    &:after
      content: ''
      position: absolute
      inset: 0
      background: linear-gradient(79deg, rgba(176,92,157,1) 0%, rgba(250,250,250,1) 65%)
      opacity: .75
    .logo
      display: block
      max-width: 10rem
      margin: 0 auto
      position: relative
      z-index: 1
      img
        width: 100%
        height: auto
  .inner
    padding: 0 2rem
  .content
    text-align: center
    max-width: 80rem
    margin: 0 auto
    word-break: break-word
    padding: 5rem 0
    .heading
      text-transform: capitalize
      font-weight: 800
      color: #452A50
      margin-bottom: 5rem
      font-size: 16px
      $map: (375px: 16px,1366px: 40px)
      +fz('font-size', $map)
      $ls: (375px: 1px,1366px: 4px)
      +fz('letter-spacing', $ls)
      @media (min-width: 1366px)
        font-size: 40px
        letter-spacing: 4px
    .btn-wrap
      max-width: 65%
      margin: 0 auto
    .small-txt
      font-size: 1.3rem
  .email-footer
    padding: 5rem 0
    background: #fff
    .social-list
      display: flex
      margin-bottom: 3rem
      align-items: center
      justify-content: center
      li
        &:not(:last-child)
          margin-right: 2rem
        a
          display: block
          width: 4rem
          height: 4rem
          border-radius: 50%
          overflow: hidden
          background: #FFFFFF
          img
            width: 100%
            height: 100%
            object-fit: contain
        &:hover
          a
            transform: scale(1.1)
            transition: .35s ease
    .ft-content
      text-align: center
      p
        color: #282828
        font-style: italic
      a
        color: #BD55A0
