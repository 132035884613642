
.page-index-2
.countup-module
  box-shadow: 0px 3px 12px #0000000F
  border-radius: .5rem
  background: #fff
  position: relative
  z-index: 1
  margin-top: -11.5rem
  .list-item
    max-width: 90rem
    margin: 0 auto
    transform: translateY(-6rem )
    display: flex
    align-items: center
    flex-flow: wrap
    justify-content: space-between
    .item
      text-align: center
      width: 33.33%
      .icon
        box-shadow: 0px 3px 12px #0000000F
        width: 12rem
        height: 12rem
        border-radius: 50%
        display: flex
        margin: 0 auto
        align-items: center
        justify-content: center
        background: #fff
        padding: 2.5rem
        margin-bottom: 2rem
        img
          width: 100%
          height: 100%
          object-fit: scale-down
      .number
        @extend .ff-2
        color: #000
        font-size: 4rem
        font-weight: 800
      .title
        margin-top: 1rem
        font-size: 1.8rem
        text-transform: capitalize
  @media (max-width: 575px)
    margin-top: -10rem
    .list-item
      transform: translateY(-3.5rem)
      .item
        .icon
          width: 7rem
          height: 7rem
          padding: 1.5rem
        .number
          font-size: 2.5rem
        .title
          padding: 0 1.5rem
          min-height: 4.5rem
.donate-banner
  margin-top: 10rem
  border-radius: .5rem
  overflow: hidden
  position: relative
  padding: 5rem
  @media (max-width: 767px)
    padding: 5rem 20px
  .title
    text-transform: capitalize
    color: #fff
    font-weight: bold
    @extend .ff-2
    line-height: 1.2
  .desc
    color: #fff
    max-width: 70rem
    margin-top: 2rem
