.banner-subpage
  padding: 15rem 0
  .heading
    text-align: center
    font-size: 4rem
    letter-spacing: .4rem
    @extend .ff-2
    line-height: calc(50/40)
    font-weight: bold
    color: #fff

.member-organisation
  padding: 5rem 0 10rem
  border-bottom: 1px solid #E1E1E1
  .row-wrapper
    display: flex
    flex-flow: wrap
    justify-content: space-between
    .left-wrap
      width: calc(20% -2rem)
    .right-wrap
      width: calc(80% -2rem)
    @media (max-width: 767px)
      .left-wrap,.right-wrap
        width: 100%
  .left-wrap
    margin-bottom: 4rem
    position: relative
  .mb-filter
    margin-top: 0
    @media (min-width: 768px)
      display: none
  .sidebar
    @media (max-width: 767px)
      display: none
      position: absolute
      left: -20px
      width: 100vw
      top: calc(100% + 2rem)
      background: #fff
      padding: 0 20px
      z-index: 50
      padding-bottom: 3rem
    .cate-txt
      font-size: 2.5rem
      text-transform: capitalize
      @extend .ff-2
      color: #0F5373
      margin-bottom: 1rem
      padding: 0 2rem
      font-weight: 500
    .sidebar-item
      &:not(:last-child)
        margin-bottom: 3rem
      .sidebar-text
        position: relative
        cursor: pointer
        font-weight: 600
        text-transform: uppercase
        color: #452A50
        display: flex
        align-items: center
        padding-bottom: 1rem
        border-bottom: 1px solid #E1E1E1
        justify-content: space-between
        .arrow
          width: 1.3rem
          height: 1rem
          background-image: url(../img/icons/Arrow3_Purple.png)
          transform: scaleY(-1)
          background-repeat: no-repeat
          background-size: contain
          background-position: center
          transition: all .2s
        &.active
          .arrow
            transform: scale(1)
            transition: all .2s
      .menu
        list-style: none
        background: #F4F4F4
        display: none
        padding: .5rem 0
        padding-top: 1.7rem
        .menu-item
          &:not(:last-child)
            margin-bottom: 1rem
          .menu-link
            font-size: 1.5rem
            color: $dark
            text-transform: capitalize
            display: inline-block
            transition: all .3s ease
          &:hover,&.active
            .menu-link
              color: $purple
              text-stroke: .1rem currentColor
              -webkit-text-stroke: .1rem currentColor
              transition: all .3s ease
      .filter-list
        list-style: none
        margin-top: 1.5rem
        display: none
        overflow: hidden
        overflow-y: auto
        max-height: 25rem
        &::-webkit-scrollbar
          width: .3rem
        &::-webkit-scrollbar-track
          border-radius: 20px
          background: transparent
        &::-webkit-scrollbar-thumb
          background: #007885
          border-radius: 20px
        .filter-item
          label
            font-size: 1.5rem
            &:before
              top: .2rem
          &:not(:last-child)
            margin-bottom: 1rem
    .apply-filter
      margin-top: 0
      width: 100%
  .list
    margin: 0 -15px
    margin-bottom: -40px
    .col, [class*=col-]
      padding-right: 15px
      padding-left: 15px
    .item
      margin-bottom: 40px
      border: 1px solid #F5F5F5
      img
        width: 100%
        height: 100%
        object-fit: contain
  .head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 3rem
    .clear-all
      color: $green
      text-transform: capitalize
      text-decoration: underline
  .showing
    color: #707070
    margin-bottom: 3rem
  .sort
    margin-left: auto
    display: flex
    align-items: center
    justify-content: flex-end
    margin-bottom: 2rem
    .txt
      margin-right: 1.2rem
      color: #707070
      font-weight: 300
