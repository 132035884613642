
.campaigns-indiv-2.campaigns-indiv-haf-2
	.box-right
		.bottom-wrap
			padding: 2rem 3rem
			border-top: 1px dashed #0000001B
			.text
				.price
					border-bottom: 1px solid $green
					display: inline-block
					margin-left: .5rem
		.top-wrap
			padding: 2rem 3rem
		.text
			line-height: 1.6
		.btn-wrap
			.btn
				width: 100%
		.social-share
			margin-top: 2rem
			display: flex
			justify-content: center
			.txt
				margin-right: 1.5rem
		.options
			display: flex
			border-bottom: 1px solid #0000001B
		.option
			width: 50%
			cursor: pointer
			label
				font-size: 1.8rem
				color: #9F9F9F
				background: #E1E1E1
				text-transform: capitalize
				text-align: center
				width: 100%
				cursor: pointer
				letter-spacing: .054rem
				padding: 2rem
				font-weight: 600
			input
				padding: 0
				height: initial
				width: initial
				margin-bottom: 0
				display: none
				cursor: pointer
			input:checked + label
				color: #fff
				background: #452A50
.higthlights
	padding-bottom: 7rem
	@media (max-width: 767px)
		background: transparent linear-gradient(67deg, #F5E4F0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box
		padding-bottom: 5rem
		margin-top: 5rem
	.slider
		position: relative
		&:after
			content: ""
			position: absolute
			right: 0
			top: 0
			height: calc(100% -5rem)
			width: calc( 100% + calc(100vw - (1206/1366*100vw))/2 +20px )
			background: transparent linear-gradient(67deg, #F5E4F0 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box
			@media (max-width: 767px)
				display: none
		.swiper-next,.swiper-prev
			position: static
			transform: none
			img
				width: 1.5rem
		.swiper-next
			margin-left: 2rem
		.swiper-btn
			position: absolute
			bottom: 8rem
			left: 0
			display: flex
			align-items: center
			@media (max-width: 767px)
				bottom: 0
				left: 50%
				transform: translateX(-50%)
		.swiper-slide
			box-sizing: border-box
			padding-top: 5rem

		.swiper-container
			padding-bottom: 4.5rem
		.wrapper
			display: flex
			flex-flow: wrap
			margin: -1.5rem
			.box-left
				padding: 1.5rem
				width: 65%
				.box-content
					padding-bottom: 9rem
			.box-right
				padding: 1.5rem
				width: 35%
				.box-img
					img
						width: 100%
						height: auto
					@media (min-width: 768px)
						transform: translateY(5rem )
			@media (max-width: 767px)
				.box-left,.box-right
					width: 100%
				.box-left
					.box-content
						padding-bottom: 0

		.big-title
			font-size: 2.4rem
			letter-spacing: .24rem
			@extend .ff-2
			color: #424242
			text-transform: uppercase
			font-weight: bold
			margin-bottom: 3rem
		.title
			font-size: 1.8rem
			color: #452A50
			text-decoration: underline
			text-transform: capitalize
			margin-bottom: 2rem
			font-weight: bold
		p
			&:not(:last-child)
				margin-bottom: 1.5rem
